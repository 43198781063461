import Vue from "vue";
import Router from "vue-router";
import Login from "./components/Login";
import DashBoard from "./components/DashBoard";
import Register from "./components/Register";
import MissingPassword from "./components/ForgotPass";

Vue.use(Router);
const routes = [
  {
    path: "/",
    name: "home",
    component: DashBoard,
    props: {
      auth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "registry",
    component: Register,
  },
  {
    path: "/missingPassword",
    name: "forgotpass",
    component: MissingPassword,
  },
  {
    path: "*",
    redirect: "/",
  },
];

export default new Router({
  routes: routes,
});
