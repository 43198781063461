<template>
  <v-layout justify-center>
    <title>PDU device</title>
    <v-flex xs12 sm12 md12 sm6 :class="PDU[modul].isLoading && PDU[model].list.length == 0?'':'d-none'">
      <v-layout row align-center justify-center>
        <strong class="mt-3">Bạn chưa có thiết bị</strong>
      </v-layout>
    </v-flex>

    <!--Danh sách thiết bị và scenes-->
    <v-flex xs12 sm12 md12 sm6 v-if="!SKL.loading && PDU[model].list.length>0" :class="APP.drawer == false && $vuetify.breakpoint.name != 'xs' && 'padding_content_not_mobile'">
      <v-layout row wrap mt-0>

        <breadcumbar></breadcumbar> <!--breadcumbar component-->
        <v-container fluid class='no_padding_margin' v-if="PDU[modul].obj && PDU[modul].obj.hardwareVersion && AudioMasterHardwareVersionAvailble == true && (PDU[modul].obj.musicProgress >= 0 && PDU[modul].obj.musicProgress != 100)">
          <v-alert class="mb-0" :value="AudioMasterHardwareVersionAvailble == true && (PDU[modul].obj.musicProgress <= 0 || PDU[modul].obj.musicProgress < 100)" color="secondary" icon="warning" outlined>
            {{ `Thiết bị [${PDU[modul].obj.user_device_descriptions || PDU[modul].obj.device_id}] đang tải âm mới...` + `${PDU[modul].obj.musicProgress === 0 ? '' : ((PDU[modul].obj.musicProgressName ? '[' + PDU[modul].obj.musicProgressName + ']' : '') + ' - ' + PDU[modul].obj.musicProgress + '%')}`}}
            (<strong id="timer_up">00:00</strong>)
          </v-alert>
        </v-container>
        <v-container fluid class='no_padding_margin' v-if="PDU[modul].obj.USBFormat == 'waiting'">
          <v-alert class="mb-0" :value="AudioMasterHardwareVersionAvailble == true && PDU[modul].obj.USBFormat == 'waiting'" color="secondary" icon="warning" outlined>{{ `Thiết bị [${PDU[modul].obj.user_device_descriptions || PDU[modul].obj.device_id}] đang định dạng bộ nhớ...`}}</v-alert>
        </v-container>
        <v-container fluid class='no_padding_margin' v-if="PDU[modul].obj && PDU[modul].obj.hardwareVersion && Object.values(PDU[modul].obj.hardwareVersion)[0].Da == 10 && PDU[modul].obj.MusicUSBIn == 'waiting'">
          <v-alert class="mb-0" :value="Object.values(PDU[modul].obj.hardwareVersion)[0].Da == 10" color="secondary" icon="warning" outlined>
            {{ `Thiết bị [${PDU[modul].obj.user_device_descriptions || PDU[modul].obj.device_id}] đang sao chép âm sang bộ nhớ thiết bị ...`}}
            (<strong id="timerUSBin"></strong>)
          </v-alert>
        </v-container>
        <v-container fluid class='no_padding_margin' v-if="PDU[modul].obj.firmwareUpdateStatus == 'waiting'">
          <v-alert class="mb-0" :value=" PDU[modul].obj.firmwareUpdateStatus == 'waiting'" color="secondary" icon="warning" outlined>{{ `Thiết bị [${PDU[modul].obj.user_device_descriptions || PDU[modul].obj.device_id}] đang cập nhật phần mềm mới...`}}</v-alert>
        </v-container>
        <v-container fluid class='no_padding_margin' v-if="PDU[modul].obj.childRefresh == 'waiting'">
          <v-alert class="mb-0" :value=" PDU[modul].obj.childRefresh == 'waiting'" color="secondary" icon="warning" outlined>
            {{ `Thiết bị [${PDU[modul].obj.user_device_descriptions || PDU[modul].obj.device_id}] đang cập nhật thiết bị con mới... `}}
            (<strong id="timer"></strong>)
          </v-alert>
        </v-container>
        <v-container fluid class='no_padding_margin' v-if="PDU[modul].obj.isManualReboot === true">
          <v-alert class="mb-0" :value="PDU[modul].obj.isManualReboot === true" color="secondary" icon="warning" outlined>
            {{ `Thiết bị [${PDU[modul].obj.user_device_descriptions || PDU[modul].obj.device_id}] đang reset... `}}
            <strong v-if="PDU[modul].obj.countUpReboot !== null">({{onConvertSecondToTimer(PDU[modul].obj.countUpReboot)}})</strong>
          </v-alert>
        </v-container>
        <!--nesthouse-->
        <v-container fluid class="no-padding-margin pt-0" v-if="PDU[modul].obj.hardwareVersion && AudioMasterHardwareVersionAvailble == true" >
          <v-flex xl12 lg12 md12 sm12 xs12>
            
            <!--master device interface-->
            <v-layout row wrap :class="AudioMasterHardwareAvailble == true ? '':'d-none'" v-if="list_MusicChannel.length>0">
              <v-flex xs12 sm12 style="padding:0">
                <v-card  flat>
                  <v-toolbar dense class="font-weight-bold white--text toolbar_padding_left" style="font-size: 18px" :color="!PDU[modul].obj.active?'rgba(11, 10, 10, 0.5)':'#009688'">Bộ điều khiển âm ly<v-spacer />
                    <v-avatar role="button" @click="dialog_NesthouseUpdateDescription = true"><img style="width:24px" :src="image_edit_white"/></v-avatar>
                  </v-toolbar>
                  <v-card-text class="pa-0">
                    <div>
                      <v-card class="my-0">
                        <v-card-text class="pt-0 pb-0 pr-2">
                            <v-flex xs12 sm12 v-for="k in 4" :key="k" class="px-0">

                              <v-skeleton-loader
                                :loading="SKL.loading"
                                :transition-group="SKL.transition"
                                height="50"
                                type="card"
                                class="my-2 px-2"
                              >

                              <v-layout row wrap class="mt-2">
                                {{list_MusicChannel[k-1] && Object.values(list_MusicChannel[k-1])[0] && Object.values(list_MusicChannel[k-1])[0].attribute_descriptions? Object.values(list_MusicChannel[k-1])[0].attribute_descriptions : `Âm ly `+ k}}
                              </v-layout>

                              <v-layout row class="Rectangle-18 my-2" wrap @click="onChannelDetail(list_MusicChannel[k-1])">
                                <v-flex xs12 sm12 md5 class="py-3">
                                  <div role="button">
                                    <v-layout row wrap align-center align-content-center>
                                      <v-flex xs1 sm2 md2 align-center>
                                        <v-badge color="#fc9003" right >
                                          <img style="width:24px" :src="image_timer"/>
                                          <template v-slot:badge>
                                            <span class="font_badge">{{Object.values(list_MusicChannel[k-1])[0].list_scene_timer.length>0? Object.values(list_MusicChannel[k-1])[0].list_scene_timer.length: 0}}</span>
                                          </template>
                                        </v-badge>
                                      </v-flex>
                                      <v-flex xs8 sm8 md7 wrap justify-center align-center>
                                        <v-list-item-content class="music_name_color mb-0">
                                          <v-list-item-title class="mb-0">{{
                                          usbremain === 0 ? 'Lỗi bộ nhớ' :
                                          ((Object.values(list_MusicChannel[k-1])[0].Da == 'No setting'?
                                          (Object.values(list_MusicChannel[k-1])[0].list_scene_timer && Object.values(list_MusicChannel[k-1])[0].list_scene_timer.length>0 ?
                                          'Im lặng':'Kênh chưa được cài đặt' ) : (Object.values(list_MusicChannel[k-1])[0].Da == 'Can not play' ?
                                            'Không thể chơi': decodeURIComponent(Object.values(list_MusicChannel[k-1])[0].Da))))
                                          }}</v-list-item-title>
                                        </v-list-item-content>
                                      </v-flex>
                                      <v-flex xs3 sm1 md1 wrap>
                                        <v-list-item class="px-0">
                                        <img class="margin_sm" style="width:24px; margin: 3px 10px 0 0;" :src="PDU[modul].obj.active == false ? image_volume_offline: (Object.values(Object.values(list_MusicChannel[k-1])[0].volume)[0].Da != 0 ? image_volume_up_yellow :image_volume_off_red)"/>
                                        <p :class="PDU[modul].obj.active == false ? 'offline_color mb-0 mt-1': (Object.values(Object.values(list_MusicChannel[k-1])[0].volume)[0].Da != 0? 'volume_up_color mb-0 mt-1' : 'volume_off_color mb-0 mt-1')">
                                          {{Math.floor(Object.values(Object.values(list_MusicChannel[k-1])[0].volume)[0].Da * 100 || 0)}}
                                        </p>
                                        </v-list-item>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-flex>

                                <v-flex xs12 sm12 md7 v-if="list_audio_status && list_audio_status.length>0" class="pt-3 pb-2 px-1">
                                  <div role="button">
                                    <v-layout row wrap align-center align-content-center style="padding: 0 4px;">
                                      <v-flex xs5 sm5 md2>
                                        <v-layout row align-center><p class="music_name_color mb-0">{{Object.values(list_audio_status[(k-1)*2])[0].attribute_descriptions || Object.values(list_audio_status[(k-1)*2])[0].Na}}</p></v-layout>
                                      </v-flex>
                                      
                                      <v-flex xs2 sm2 md1>
                                        <v-avatar><img style="width:24px" :src="PDU[modul].obj.active ==  false ? image_volume_offline: (Object.values(list_audio_status[(k-1)*2])[0].Da ? image_volume_up_blue :image_volume_off_red)"/></v-avatar>
                                      </v-flex>
                                      
                                      <v-flex xs5 sm5 md3>
                                        <v-layout row align-center><p class="music_name_color mb-0">Cập nhật {{onCaculator_timestamp(Object.values(list_audio_status[(k-1)*2])[0].Re)}}</p></v-layout>
                                      </v-flex>

                                      <v-flex xs5 sm5 md2>
                                        <v-layout row align-center><p class="music_name_color mb-0">{{Object.values(list_audio_status[(k-1)*2+1])[0].attribute_descriptions || Object.values(list_audio_status[(k-1)*2+1])[0].Na}}</p></v-layout>
                                      </v-flex>
                                      <v-flex xs2 sm2 md1>
                                        <v-avatar><img style="width:24px" :src="PDU[modul].obj.active ==  false ? image_volume_offline:(Object.values(list_audio_status[(k-1)*2+1])[0].Da ? image_volume_up_blue :image_volume_off_red)"/></v-avatar>  
                                      </v-flex>
                                      
                                      <v-flex xs5 sm5 md3>
                                        <v-layout row align-center><p class="music_name_color mb-0">Cập nhật {{onCaculator_timestamp(Object.values(list_audio_status[(k-1)*2+1])[0].Re)}}</p></v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </div>
                                </v-flex>
                              </v-layout>
                              </v-skeleton-loader>
                            </v-flex>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout row justify-center align-center v-else> 
              <p class="pb-0 mt-4">Đang tải dữ liệu</p>
            </v-layout>
          
            <!--PDU device interface-->
            <v-layout  :class="PDU['DeviceDetail'].obj.hardware == 4? 'Rectangle-24 mt-2':'d-none'" row wrap justify-center v-if="device_outled.length>0">
              <v-list dense style="width:100%; background-color: transparent;">
                  <v-list-item v-for="(item, j) in device_outled" :key="j" class="px-0">
                    <v-layout row wrap justify-center :class="'Rectangle-24'" >
                      <v-card v-for="(i,k) in item.list_outled" :key="k" :class="!item.active || !PDU[modul].obj.active?'Rectangle-28':'Rectangle-25'">
                        <v-layout row align-center justify-center wrap>
                          <p class="H-thng-camera" @click="onCallUpdateView(i)">{{Object.values(i)[0].attribute_descriptions || Object.values(i)[0].Na}} <v-icon @click="onCallUpdateView(i)" small right role="button">edit</v-icon> </p>
                        </v-layout>

                        <v-card-actions>
                          <v-layout column>
                            <v-layout row align-center justify-center class="my-2">
                              <v-btn fab :class="(!item.active || !PDU[modul].obj.active)? 'Ellipse-23 white--text': (Object.values(i)[0].Da==true?'Ellipse-23_1 white--text':'Ellipse-23 white--text')" @click="onsetAttribute(i)">
                                <v-avatar>
                                  <img style="width: 25px"  :src="image_power">
                                </v-avatar>
                              </v-btn>
                            </v-layout>

                              <v-list-item class="my-3">
                                <v-layout row justify-center align-center>
                                  <p class="Thng mb-0">Thường</p> 
                                  
                                  <v-switch v-model="Object.values(i)[0].Auto"  inset :disabled="!item.active || !PDU[modul].obj.active" 
                                  :readonly="!item.active || !PDU[modul].obj.active || onCheckRole() == false" 
                                  :color="Object.values(i)[0].Auto? '#fc9003':''" class="justify-center align-center margin_style pa-0 Vector" 
                                  @change="disableScenes({item: i})"/>
                                  
                                  <p class="Thng mb-0">Tự động</p>
                                </v-layout>
                              </v-list-item>

                              <v-list-item>
                              <v-layout class="layout_1 pl-5 mt-1" column align-content-start justify-space-around align-content-center>
                                <v-btn :disabled="!item.active || !PDU[modul].obj.active"  :class="item.active  && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length == 0 && Object.values(i)[0].list_scene_timer && Object.values(i)[0].list_scene_timer.length > 0  && Object.values(i)[0].Auto 
                                  ? 'Rectangle-29_1 mb-2': 'Rectangle-29 mb-2'"  @click="onOutletDetail(i)" >
                                  <v-avatar><img style="width: 25px"  :src="item.active  && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length == 0 && Object.values(i)[0].list_scene_timer && Object.values(i)[0].list_scene_timer.length > 0  && Object.values(i)[0].Auto ? image_clock_color: image_clock"></v-avatar>
                                </v-btn>
                                <p :class="item.active  && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length == 0 && Object.values(i)[0].list_scene_timer && Object.values(i)[0].list_scene_timer.length > 0  && Object.values(i)[0].Auto ?  'color_text mb-0': 'Thngs mb-0'">Timer</p>
                              </v-layout>
                    

                              <v-layout class="layout_1 pr-5 mt-1" column align-end justify-center align-content-center>
                                <v-btn  :disabled="!item.active || !PDU[modul].obj.active" :class="item.active  && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0
                                  ? 'Rectangle-29_1 mb-2' : 'Rectangle-29 mb-2'" @click="onTHDetail(i)">
                                  <v-avatar><img style="width: 25px"  :src="item.active  && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0 ? image_dropnet_color: image_dropnet"></v-avatar>
                                </v-btn>
                                <p :class="item.active  && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0 ? 'color_text mr-2': 'Thngs mr-2 ml-1'">T/H</p>
                              </v-layout>
                              </v-list-item>
                          </v-layout>
                        </v-card-actions>
                      </v-card>
                    </v-layout>
                  </v-list-item>
              </v-list>
            </v-layout>
            <v-layout row justify-center align-center v-if="device_outled.length==0 && PDU['DeviceDetail'].obj.hardware == 4">
              <p class="pb-0 mt-4">Thiết bị chưa được thêm</p>
            </v-layout>

            <!--sensor device interface-->
            <v-layout  row wrap justify-center :class="PDU['DeviceDetail'].obj.hardware == 6? 'Rectangle-24 mt-2':'d-none'" v-if="device_temper.length>0" >
              <div  v-for="(item, j) in device_temper" :key="j" >
                  <v-card  v-for="(i,k) in item.list_temper" :key="k" :class="!item.active || !PDU[modul].obj.active?'Rectangle-26 pa-3 ma-3':'Rectangle-25_1 pa-3 ma-3'">
                    <v-layout row align-center justify-center wrap>
                      <p class="H-thng-camera" @click="onCallUpdateView(i)">{{Object.values(i)[0].attribute_descriptions || Object.values(i)[0].Na}} <v-icon @click="onCallUpdateView(i)" small right role="button">edit</v-icon></p>
                    </v-layout>

                    <v-card-actions>
                      <v-layout column justify-center align-center class="mt-1">
                        <v-card flat :class="!item.active || !PDU[modul].obj.active?'Rectangle-29_5  mt-4':'Rectangle-29_2 mt-4'"  :disabled="!item.active || !PDU[modul].obj.active">
                          <v-layout column align-center justify-center >
                            <v-avatar><img :class="item.active && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0 &&
                              Object.values(i)[0].temper_outlet_status && Object.values(i)[0].temper_outlet_status.Da == true
                              ? 'flashit':''" style="width: 30px" :src="item.active && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0 &&
                              Object.values(i)[0].temper_outlet_status && Object.values(i)[0].temper_outlet_status.Da == true ? image_temper_yellow:
                              (item.active && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0 && 
                              Object.values(i)[0].temper_outlet_status && Object.values(i)[0].temper_outlet_status.Da == false? image_temper_green : image_temper)
                              "></v-avatar>

                            <p :class="item.active && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0 &&
                              Object.values(i)[0].temper_outlet_status && Object.values(i)[0].temper_outlet_status.Da == true ? 'Thng_2 flashit mb-1 Thng_1':
                              ( item.active && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0 && 
                              Object.values(i)[0].temper_outlet_status && Object.values(i)[0].temper_outlet_status.Da == false? 'Thng_3 mb-1 Thng_1' :'Thng Thng_1 mb-1')">
                              {{Object.values(i)[0].Da}}</p>
                            
                          </v-layout>
                        </v-card>

                        <span class=" mb-8">
                          <v-list-item-content class="py-0" style="width:100px; height: 45px !important" >
                          </v-list-item-content>
                        </span>
                        <v-layout class="layout_1" column justify-center>
                            <v-btn :disabled="!item.active || !PDU[modul].obj.active"  :class="item.active  && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0
                              ? ' Rectangle-29_1 mb-2 ml-3': 'Rectangle-29 mb-2 ml-3'"  @click="onSensorDetail({ i, item })" >
                              <v-avatar><img style="width: 25px"  :src="item.active  && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0 ? image_setting_color:image_setting "></v-avatar>
                            </v-btn>
                            <p :class="item.active  && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0 ?  'color_text mb-0': 'Thng mb-0'">Điều khiển</p>
                        </v-layout>           
                      </v-layout>
                      
                      <v-divider vertical  style="height: 60px !important;margin-top: 30px;border: 1.2px solid rgba(0,0,0,0.3);"/>

                      <v-layout column justify-center align-center class="mt-1">
                        <v-card flat :class="!item.active || !PDU[modul].obj.active?'Rectangle-29_5 mt-4': 'Rectangle-29_2 mt-4'"   :disabled="!item.active || !PDU[modul].obj.active">
                          <v-layout column align-center justify-center>

                            <v-avatar><img style="width: 30px" :class="item.active && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0 &&
                              Object.values(i)[0].humi_outlet_status && Object.values(i)[0].humi_outlet_status.Da == true
                              ? 'flashit':''" :src="item.active && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0
                              && Object.values(i)[0].humi_outlet_status && Object.values(i)[0].humi_outlet_status.Da == true?image_dropnet_yellow : 
                              (item.active && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0
                              && Object.values(i)[0].humi_outlet_status && Object.values(i)[0].humi_outlet_status.Da == false? image_dropnet_color :image_dropnet)
                              "></v-avatar>
                            
                            <p :class="item.active && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0
                              && Object.values(i)[0].humi_outlet_status && Object.values(i)[0].humi_outlet_status.Da == true? 'Thng_2 flashit mb-1 Thng_1': 
                              (item.active && PDU[modul].obj.active && Object.values(i)[0].list_scene_temper && Object.values(i)[0].list_scene_temper.length > 0
                              && Object.values(i)[0].humi_outlet_status && Object.values(i)[0].humi_outlet_status.Da == false?'Thng_3 mb-1 Thng_1' :'Thng Thng_1 mb-1')">
                              {{Object.values(Object.values(i)[0].Humi)[0].Da}}</p>
                          </v-layout>
                        </v-card>
                        
                        <span class=" mb-8">
                          <v-list-item-content class="py-0" style="width:100px; height: 45px !important" >
                          </v-list-item-content>
                        </span>

                        
                        
                        <v-layout class="layout_1" column justify-center>
                          <v-btn :disabled="!item.active || !PDU[modul].obj.active" :class="item.active && PDU[modul].obj.active && ((Object.values(i)[0].cHiTempAlarmSetting && Object.values(i)[0].cHiTempAlarmSetting[0] > 0) || (Object.values(i)[0].cLoTempAlarmSetting && Object.values(i)[0].cLoTempAlarmSetting[0] > 0) || (Object.values(i)[0].cHiHumiAlarmSetting && Object.values(i)[0].cHiHumiAlarmSetting[0] > 0) || (Object.values(i)[0].cLoHumiAlarmSetting && Object.values(i)[0].cLoHumiAlarmSetting[0] > 0))
                              ? ' Rectangle-29_1 mb-2 ml-3': 'Rectangle-29 mb-2 ml-3'" @click="PDU['Protect'].obj = fnCopy(i); onSensorWarningDetail({ i, temperature: item });" >
                            <v-avatar><img style="width: 25px; height:25px"  :src="item.active && PDU[modul].obj.active && ((Object.values(i)[0].cHiTempAlarmSetting && Object.values(i)[0].cHiTempAlarmSetting[0] > 0) || (Object.values(i)[0].cLoTempAlarmSetting && Object.values(i)[0].cLoTempAlarmSetting[0] > 0) || (Object.values(i)[0].cHiHumiAlarmSetting && Object.values(i)[0].cHiHumiAlarmSetting[0] > 0) || (Object.values(i)[0].cLoHumiAlarmSetting && Object.values(i)[0].cLoHumiAlarmSetting[0] > 0)) ? image_warning_color:image_warning " ></v-avatar>
                          </v-btn>
                          <p :class="item.active && PDU[modul].obj.active && ((Object.values(i)[0].cHiTempAlarmSetting && Object.values(i)[0].cHiTempAlarmSetting[0] > 0) || (Object.values(i)[0].cLoTempAlarmSetting && Object.values(i)[0].cLoTempAlarmSetting[0] > 0) || (Object.values(i)[0].cHiHumiAlarmSetting && Object.values(i)[0].cHiHumiAlarmSetting[0] > 0) || (Object.values(i)[0].cLoHumiAlarmSetting && Object.values(i)[0].cLoHumiAlarmSetting[0] > 0)) ?  'color_text mb-2 ml-1': 'Thng mb-2 ml-1'">Cảnh báo</p>
                        </v-layout>
                      </v-layout>
                    </v-card-actions>
                  </v-card>
              </div>
            </v-layout>
            <v-layout row justify-center align-center v-if="device_temper.length==0 && PDU['DeviceDetail'].obj.hardware == 6">
              <p class="pb-0 mt-4">Thiết bị chưa được thêm</p>
            </v-layout>
          </v-flex>
        </v-container>

        <!--channel monitor device-->
        <v-container fluid class="no-padding-margin pt-2" v-if="PDU[modul].obj.hardwareVersion && Object.values(PDU[modul].obj.hardwareVersion)[0].Da == 7">
          <v-flex xl12 lg12 md12 sm12 xs12>            
            <v-layout row wrap mt-2>
                <v-flex xs12 sm12>
                  <v-card>
                    <v-toolbar dense class="font-weight-bold white--text toolbar_padding_left" style="font-size: 18px" :color="!PDU[modul].obj.active?'rgba(11, 10, 10, 0.5)':'#009688'">Đầu dò kênh<v-spacer />
                      <v-avatar role="button" @click="onSelectAllDetail();dialog_ChannelMonitorUpdateDescription = true"><img style="width:24px" :src="image_edit_white"/></v-avatar>
                    </v-toolbar>

                    <v-card-text class="pa-0" >
                        <div>
                          <v-card class="my-0" flat>
                            <v-card-text class="pa-0">
                              <v-layout row wrap v-for="k in 16" :key="k" :class="k%2!=0? 'Rectangle-18 mx-0 px-2': 'Rectangle-18_1 mx-0 px-2'"
                                v-if="list_channel_monitor_AudioUsed.length> 0 && list_channel_monitor_AudioUsed[k-1] && Object.values(list_channel_monitor_AudioUsed[k-1])[0] && Object.values(list_channel_monitor_AudioUsed[k-1])[0].Da == true"  >
                                  <v-flex xs12 sm12 md12 class="py-2 px-0" v-if="list_channel_monitor_AudioStatus[k-1]">
                                    <div role="button" @click="fnCallUpdateView({model: 'AudioTimeOff',item: list_channel_monitor_AudioTimerHealthy[k-1]}); onChannelMonitorDetail(list_channel_monitor_AudioStatus[k-1], list_channel_monitor_AudioTimerHealthy[k-1])">
                                      <v-layout row wrap align-center align-content-center>

                                        <v-flex xs1 sm1 md1><v-list-item-content :class="$vuetify.breakpoint.name == 'xs'? 'mb-1':'pl-10'"><p class="mb-0">{{k}}</p></v-list-item-content></v-flex>

                                        <v-flex xs7 sm3 md3><v-list-item-content class="mb-0">{{Object.values(list_channel_monitor_AudioStatus[k-1])[0].attribute_descriptions || Object.values(list_channel_monitor_AudioStatus[k-1])[0].Na}}</v-list-item-content></v-flex>

                                        <v-flex xs2 sm1 md1><img class="mt-2" style="width:24px" :src="PDU[modul].obj.active ==  false ? image_grey_circle: (Object.values(list_channel_monitor_AudioStatus[k-1])[0].Da ? image_green_circle :image_red_circle)"/></v-flex>

                                        <v-flex xs2 sm2 md2 class="hidden-sm-and-up" >
                                          <!-- <img style="width:24px" :src="(PDU[modul].obj.active ==  false) ? image_setting: (Object.values(list_channel_monitor_AudioHealthy[k-1])[0].Da==true ? image_setting_color :image_setting_color_red)"/> -->
                                          <img style="width:24px" :src="image_setting"/>
                                        </v-flex>

                                        <v-flex xs6 sm3 md3><v-list-item-content class="music_name_color">Cập nhật {{onCaculator_timestamp(Object.values(list_channel_monitor_AudioStatus[k-1])[0].Re)}}</v-list-item-content></v-flex>

                                        <v-flex xs6 sm3 md3>
                                          <v-list-item-content class="music_name_color mb-0" v-if="list_channel_monitor_AudioTimerHealthy[k-1] && Object.values(list_channel_monitor_AudioTimerHealthy[k-1])[0] && Object.values(list_channel_monitor_AudioTimerHealthy[k-1])[0].Da && Object.values(list_channel_monitor_AudioTimerHealthy[k-1])[0].Da.En !== null && Object.values(list_channel_monitor_AudioTimerHealthy[k-1])[0].Da.Ds !== null">
                                          {{`${onGetTimeSettingCM(Object.values(list_channel_monitor_AudioTimerHealthy[k-1])[0].Da.En) == '00:00' && (Object.values(list_channel_monitor_AudioTimerHealthy[k-1])[0].Da.Ds == 86399 || onGetTimeSettingCM(Object.values(list_channel_monitor_AudioTimerHealthy[k-1])[0].Da.Ds) === '23:59') ? 'Luôn Luôn Bật' : ('Bật: ' + onGetTimeSettingCM(Object.values(list_channel_monitor_AudioTimerHealthy[k-1])[0].Da.En) + ' | ' + 'Tắt: ' + onGetTimeSettingCM(Object.values(list_channel_monitor_AudioTimerHealthy[k-1])[0].Da.Ds)) }`}}
                                          </v-list-item-content>
                                          <v-list-item-content v-else>
                                            không có dữ liệu
                                          </v-list-item-content>
                                        </v-flex>

                                        <v-flex xs2 sm1 md1 :class="$vuetify.breakpoint.name == 'xs'?'d-none':''">
                                          <!-- <img style="width:24px" :src="(PDU[modul].obj.active ==  false) ? image_setting: (Object.values(list_channel_monitor_AudioHealthy[k-1])[0].Da==true ? image_setting_color :image_setting_color_red)"/> -->
                                          <img style="width:24px" :src="image_setting"/>
                                        </v-flex>
                                      </v-layout>
                                    </div>
                                  </v-flex>
                                  <v-flex xs12 sm12 md12 class="py-2 px-0" v-else>
                                    <p class="mb-0">Kênh chưa được khởi tạo đủ dữ liệu</p>
                                  </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-card>
                          
                          <v-card flat :class="checkChMoIsVisible == false? '': 'd-none'">
                            <v-list-item>
                              <v-layout row align-center justify-center>
                                  <strong class="mt-3">Không có kênh hiện hành</strong>
                              </v-layout>
                              </v-list-item>
                          </v-card>
                        </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
            </v-layout>
          </v-flex>
        </v-container>
      </v-layout>
    </v-flex>

    <v-flex xs12 sm12 md12 sm6 v-else-if="SKL.loading && PDU[model].list.length>0">
      <v-layout row wrap mt-0>
        <v-container fluid class="no-padding-margin pt-2" v-if="PDU[modul].obj.hardwareVersion && Object.values(PDU[modul].obj.hardwareVersion)[0].Da == 7">
         <v-flex xl12 lg12 md12 sm12 xs12>
            <v-flex xs12 sm12 >
                <v-skeleton-loader
                  :loading="SKL.loading"
                  :transition="SKL.transition"
                  height="50"
                  style="mb-4"
                  type="card"
                >
                </v-skeleton-loader>
              <v-card v-for="i in 12" :key="i" flat>
                <v-skeleton-loader
                            :loading="SKL.loading"
                            :transition="SKL.transition"
                            height="62"
                            class="pa-0 ma-0"
                            type="list-item-avatar-two-line"
                          >
                </v-skeleton-loader>
              </v-card>
            </v-flex>
         </v-flex>
        </v-container>

        <v-container fluid class="no-padding-margin pt-0" v-if="PDU[modul].obj.hardwareVersion && AudioMasterHardwareVersionAvailble == true">
          <v-flex xl12 lg12 md12 sm12 xs12>
            <v-layout row wrap :class="AudioMasterHardwareAvailble == true ? '':'d-none'" v-if="list_MusicChannel.length>0">
              <v-flex xs12 sm12 style="padding:0">
                <v-skeleton-loader
                  :loading="SKL.loading"
                  :transition="SKL.transition"
                  height="50"
                  style="mb-4"
                  type="card"
                >
                </v-skeleton-loader>

                <v-card v-for="i in 4" :key="i" flat>
                <v-skeleton-loader 
                            :loading="SKL.loading"
                            :transition="SKL.transition"
                            height="100"
                            type="list-item-avatar-three-line"
                          >
                </v-skeleton-loader>
                </v-card>
                </v-flex>              
            </v-layout>

            <v-layout  :class="PDU['DeviceDetail'].obj.hardware == 4? 'Rectangle-24 mt-2':'d-none'" row wrap justify-center v-if="device_outled.length>0">
              <v-list dense style="width:100%; background-color: transparent;">
                <v-layout row wrap justify-center class="Rectangle-24" >
                    <v-flex xs12 sm12>
                      <v-skeleton-loader
                        :loading="SKL.loading"
                        :transition="SKL.transition"
                        height="50"
                        style="mb-4"
                        type="card"
                      >
                      </v-skeleton-loader>
                    </v-flex>
                      <v-skeleton-loader v-for="i in 8" :key="i" class="Rectangle-28"
                              :loading="SKL.loading"
                              :transition="SKL.transition"
                              type="card"
                            >
                      </v-skeleton-loader>
                </v-layout>
              </v-list>
            </v-layout>

            <v-layout  row wrap justify-center :class="PDU['DeviceDetail'].obj.hardware == 6? 'Rectangle-24 mt-2':'d-none'" v-if="device_temper.length>0" >
              <v-list dense style="width:100%; background-color: transparent;">
                <v-layout row wrap justify-center class="Rectangle-24" >
                    <v-flex xs12 sm12>
                      <v-skeleton-loader
                        :loading="SKL.loading"
                        :transition="SKL.transition"
                        height="50"
                        style="mb-4"
                        type="card"
                      >
                      </v-skeleton-loader>
                    </v-flex>
                      <v-skeleton-loader v-for="i in 8" :key="i" class="Rectangle-28"
                              :loading="SKL.loading"
                              :transition="SKL.transition"
                              type="card"
                            >
                      </v-skeleton-loader>
                </v-layout>
              </v-list>
            </v-layout>
          </v-flex>
        </v-container>
      </v-layout>
    </v-flex>
    
    <!--dialog update device name-->
    <v-form @submit.prevent v-if="PDU[model].obj.isNameDevice">
      <v-dialog persistent v-model="PDU[model].obj.isNameDevice" scrollable max-width="500">
        <v-form @submit.prevent="onDeviceUpdate(model)">
            <v-card>
              <v-toolbar class="toolbar_padding_left white--text" :color="PDU.teal_color" flat  dense>Cập Nhật Tên Thiết Bị</v-toolbar>
              <v-card-text>
                <v-list-item-content><v-text-field class="my-3 Rectangle-5 Nhp-mt-khu mt-4 px-3" :color="PDU.teal_color" label="Tên Thiết Bị" v-model="PDU[model].obj.user_device_descriptions" required :rules="[PDU.rules.required]" /></v-list-item-content>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="PDU.teal_color" class="white--text" type="submit" :disabled="!PDU[model].obj.user_device_descriptions">Đồng Ý</v-btn>
                <v-btn color="secondary" @click="fnCallResetView(model)">Hủy</v-btn>
              </v-card-actions>
            </v-card>
        </v-form>
      </v-dialog>
    </v-form>
    <!--dialog update PDU name-->
    <v-form @submit.prevent v-if="PDU['PDU_description'].obj.isNameDevice">
      <v-dialog persistent v-model="PDU['PDU_description'].obj.isNameDevice" scrollable max-width="500">
        <v-form @submit.prevent="onAttributeUpdatePDU('PDU_description');fnCallResetView('PDU_description')">
            <v-card>
              <v-toolbar class="toolbar_padding_left" flat  dense>Cập Nhật Tên</v-toolbar>
              <v-card-text>
                <v-list-item-content><v-text-field class="my-3 Rectangle-5 Nhp-mt-khu mt-4 px-3" :color="PDU.teal_color" label="Tên Thiết Bị" v-model="PDU['PDU_description'].obj.attribute_descriptions_copy" required :rules="[PDU.rules.required]" /></v-list-item-content>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="PDU.teal_color" class="white--text" type="submit" :disabled="!PDU['PDU_description'].obj.attribute_descriptions_copy">Đồng Ý</v-btn>
                <v-btn color="secondary" @click="fnCallResetView('PDU_description')">Hủy</v-btn>
              </v-card-actions>
            </v-card>
        </v-form>
      </v-dialog>
    </v-form>
    <!--dialog delete device -->
    <v-form @submit.prevent v-if="PDU[model].isDelete">
      <v-dialog  persistent v-model="PDU[model].obj.isDel" scrollable max-width="290">
            <v-card>
              <v-toolbar dense flat>
                  <v-spacer/>
                  <v-btn icon @click="fnCallResetView(model)"><v-icon>close</v-icon></v-btn>
              </v-toolbar>
              <v-card-text class="pt-0">
                <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
                <v-list-item>
                  <v-layout row justify-center align-center align-content-center>
                    <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Bạn có chắc muốn xóa thiết bị <strong>"{{PDU[model].obj.title }}"</strong></p>
                  </v-layout>
                </v-list-item>
              </v-card-text>
              <v-card-actions>
                <v-list-item style="justify-content: center">
                  <v-btn class="white--text" :color="PDU.teal_color" dark  @click="onDeviceChildDelete()">Đồng ý</v-btn>
                </v-list-item>
              </v-card-actions>
            </v-card> 
      </v-dialog>
    </v-form>
    <!-- dialog update name attribute-->
    <v-form @submit.prevent v-if="PDU['DeviceAttribute'].obj.isNameDevice">
      <v-dialog persistent v-model="PDU['DeviceAttribute'].obj.isNameDevice" scrollable max-width="500">
        <v-form @submit.prevent="onAttributeUpdate('DeviceAttribute');fnCallResetView('DeviceAttribute')">
              <v-card>
                <v-toolbar class="toolbar_padding_left white--text" :color="PDU.teal_color" flat  dense>Cập Nhật Tên</v-toolbar>
                <v-card-text>
                  <v-list-item-content>
                    <v-text-field class="Rectangle-5 Nhp-mt-khu my-4 px-3" :color="PDU.teal_color" label="Tên thuộc tính" v-model="Object.values(PDU['DeviceAttribute'].obj)[0].attribute_descriptions" required :rules="[PDU.rules.required]" />
                  </v-list-item-content>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" :color="PDU.teal_color" :class="onCheckRole() == false? 'd-none':'white--text'" :disabled="!Object.values(PDU['DeviceAttribute'].obj)[0].attribute_descriptions">Đồng Ý</v-btn>
                  <v-btn color="secondary" @click="fnCallResetView('DeviceAttribute')">Hủy</v-btn>
                </v-card-actions>
              </v-card>
        </v-form>
      </v-dialog>
    </v-form>
    <!--dialog share device-->
    <v-dialog max-width="500" v-model="BREA.dialogSharing" persistent>
      <v-toolbar dense class="toolbar_padding_left white--text" :color="PDU.teal_color">Lịch sử chia sẻ
        <v-spacer/>
      </v-toolbar>
      <v-card  >
        <v-list v-if="PDU['ShareDevice'].list.length">
          <v-list-item v-for="(item,i) in PDU['ShareDevice'].list" :key="i">
            <v-list-item-action>
              <v-btn icon small><v-icon color="red" @click="fnCallDeleteView({ model:'ShareDevice', item: item })">delete</v-icon></v-btn>
            </v-list-item-action>

            <v-layout row>
              <v-flex xs8 md8>{{item.shared}}</v-flex>
              <v-flex xs4 md4>({{item.user_role == 'admin'?'Quản trị':'Khách'}})</v-flex>
            </v-layout>
          </v-list-item>
        </v-list>
        <v-list-item-content v-else>
          <div style="width:1px;height:10px"></div>
          <v-layout row align-center justify-center >
            <p>Thiết bị chưa có lịch sử chia sẻ</p>
          </v-layout>
        </v-list-item-content>
        <v-card-actions>
          <v-spacer/><v-btn @click="fnCallUpdateView({ model: 'ShareDevice', item: PDU['DeviceDetail'].obj })" class="white--text" :color="PDU.teal_color">Thêm mới</v-btn>
          <v-btn color="secondary" @click="BREA.dialogSharing = false">Hủy</v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog delete share device -->
    <v-dialog persistent v-model="PDU['ShareDevice'].isDelete" scrollable max-width="290">
      <v-card>
        <v-toolbar dense flat>
            <v-spacer/>
            <v-btn icon @click="fnCallResetView('ShareDevice')"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-0">
          <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
          <v-list-item>
            <v-layout row justify-center align-center align-content-center>
              <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Bạn có chắc muốn xóa thiết bị share này?</p>
            </v-layout>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-list-item style="justify-content: center">
            <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :color="PDU.teal_color" dark  @click="onDeleteSharing();fnCallResetView('ShareDevice')">Đồng ý</v-btn>
          </v-list-item>
        </v-card-actions>
      </v-card> 
    </v-dialog>
    <!--dialog create new share device-->
    <v-form v-model="PDU['ShareDevice'].isValid" @submit.prevent v-if="PDU['ShareDevice'].isUpdate">
      <v-dialog persistent v-model="PDU['ShareDevice'].obj.isForm" scrollable max-width="500">
        <v-form @submit.prevent="onShareDevice();fnCallResetView('ShareDevice')">
          <v-card>
            <v-toolbar class="toolbar_padding_left white--text" :color="PDU.teal_color" flat  dense>Chia sẻ Thiết Bị</v-toolbar>
            <v-card-text class="pt-4">
              <v-list-item>
                  <v-text-field class="Rectangle-5 Nhp-mt-khu mt-0 px-3" :color="PDU.teal_color" label="Tên Tài Khoản" v-model="shared_username" required :rules="[PDU.rules.required]"></v-text-field>
                  <v-select class="mt-4 mx-1" :color="PDU.teal_color" :items="['Quản trị','Khách']" label="Quyền quản lý" :item-color="PDU.teal_color" v-model="select"></v-select>
              </v-list-item>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :color="PDU.teal_color" class="white--text" :disabled="!PDU['ShareDevice'].isValid || !shared_username" type="submit">Đồng Ý</v-btn>
              <v-btn color="secondary" @click="fnCallResetView('ShareDevice')">Hủy</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-form>
    <!--dialog add new device-->
    <v-form  @submit.prevent v-if="PDU['NewDevice'].isCreate">
      <v-dialog persistent v-model="PDU['NewDevice'].isCreate" scrollable max-width="500">
        <v-form @submit.prevent="onDeviceCreate()">
        <v-card>
          <v-toolbar flat  dense class="toolbar_padding_left white--text" :color="PDU.teal_color">
            Thêm Thiết Bị Mới
            <v-spacer></v-spacer>
            <v-btn icon @click="PDU['NewDevice'].obj.paused = false" :disabled="PDU['NewDevice'].isLoading"><v-icon color="#fff">refresh</v-icon></v-btn>
            <v-btn icon @click="fnCallResetView('NewDevice')" :disabled="PDU['NewDevice'].isLoading"><v-icon color="#fff">close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text v-if="!PDU['NewDevice'].obj.paused">
            <p v-if="PDU['NewDevice'].obj.error" class="error--text">{{ PDU['NewDevice'].obj.error }}</p>

            <qrcode-stream camera="auto" @decode="onDecode" @init="onInit" :paused="PDU['NewDevice'].obj.paused" />
          </v-card-text>
          <v-card-text v-if="PDU['NewDevice'].obj.paused">
            <v-list-item-content><v-text-field class="Rectangle-5 Nhp-mt-khu my-4 px-3" :color="PDU.teal_color" label="Tên" v-model="PDU['NewDevice'].obj.user_device_descriptions" required :rules="[PDU.rules.required]"/></v-list-item-content>
            <v-list-item-content><v-text-field class="Rectangle-5 Nhp-mt-khu mt-4 px-3" :color="PDU.teal_color" label="ID" v-model="PDU['NewDevice'].obj.mac" required :rules="[PDU.rules.required]" readonly/></v-list-item-content>
          </v-card-text>
          <v-card-actions v-if="PDU['NewDevice'].obj.paused">
            <v-spacer />
            <v-btn :color="PDU.teal_color" class="white--text" :loading="PDU['NewDevice'].isLoading" :disabled="!PDU['NewDevice'].obj.user_device_descriptions" type="submit">Đồng Ý</v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-dialog>
    </v-form>
    <!--dialog create/edit scene in PDU device-->
    <v-dialog max-width="500" v-model="dialog" persistent>
      <v-card>
          <v-list-item>
            <v-list-item-content class="mx-2">
              <v-dialog ref="dialog1" width="290px" persistent v-model="dialog_time" :return-value.sync="editedItem.timer">
                <template v-slot:activator="{ on }">
                    <v-text-field class="Rectangle-5 Nhp-mt-khu mt-4 px-3" v-on="on" v-model="editedItem.timer" label="Thời Gian" readonly required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
                </template>
                <v-time-picker v-if="dialog_time" :color="PDU.teal_color" format="24hr" v-model="editedItem.timer">
                <v-spacer></v-spacer>
                <v-btn :color="PDU.teal_color" dark @click="PreventOOh();">Đồng Ý</v-btn>
                <v-btn color="secondary" @click="dialog_time = false">Hủy</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-list-item-content>
          </v-list-item>

            <v-list-item>
            <v-list-item-content class="ml-4">
              <v-list-item-content class="mb-0 py-0 align-content-center"><p class="mb-0">Trạng thái</p></v-list-item-content>
              <v-layout row align-center justify-center>
                <p :class="editedItem.status==false?'mb-0 status_color' : 'mb-0'">Tắt</p>
                <v-switch class="ml-5" inset v-model="editedItem.status" :color="PDU.teal_color"></v-switch>
                <p :class="editedItem.status==true?'mb-0 status_color':'mb-0'">Bật</p>
              </v-layout>
            </v-list-item-content>
            </v-list-item>
        
        <v-card-actions>
          <v-spacer/>
          <v-btn :color="PDU.teal_color" :class="onCheckRole()==false?'d-none':'white--text'"  @click="onAddScene_OutletControl();">Đồng ý</v-btn>
          <v-btn color="secondary" @click="editedIndex = -1;dialog = false">Hủy</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog create/edit auto generator scene in PDU device-->
    <v-dialog max-width="500" v-model="dialog_setting_PDU_automation" persistent>
      <v-form @submit.prevent="onBeforeSubmitAutomation()" v-model="PDU['PDU_automation'].isValid">
      <v-card>
          <v-list-item>
                <v-list-item-content class="mx-2 pb-8">
                  <v-dialog ref="dialog3" width="290px" persistent v-model="dialog_automation_time1" :return-value.sync="PDU['PDU_automation'].obj.start_time">
                    <template v-slot:activator="{ on }">
                        <v-text-field class="Rectangle-5 Nhp-mt-khu mt-4 px-3" v-on="on" v-model="PDU['PDU_automation'].obj.start_time" label="Thời Gian Bắt Đầu" readonly required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
                    </template>
                    <v-time-picker v-if="dialog_automation_time1" :color="PDU.teal_color" format="24hr" v-model="PDU['PDU_automation'].obj.start_time">
                    <v-spacer></v-spacer>
                    <v-btn :color="PDU.teal_color" dark @click="PreventOOh3();">Đồng Ý</v-btn>
                    <v-btn color="secondary" @click="dialog_automation_time1 = false">Hủy</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-list-item-content>

                <v-list-item-content class="mx-2 pb-8">
                  <v-dialog ref="dialog4" width="290px" persistent v-model="dialog_automation_time2" :return-value.sync="PDU['PDU_automation'].obj.stop_time">
                    <template v-slot:activator="{ on }">
                        <v-text-field class="Rectangle-5 Nhp-mt-khu mt-4 px-3" v-on="on" v-model="PDU['PDU_automation'].obj.stop_time" label="Thời Gian Kết thúc" readonly required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
                    </template>
                    <v-time-picker v-if="dialog_automation_time2" :color="PDU.teal_color" format="24hr" v-model="PDU['PDU_automation'].obj.stop_time">
                    <v-spacer></v-spacer>
                    <v-btn :color="PDU.teal_color" dark @click="PreventOOh4();">Đồng Ý</v-btn>
                    <v-btn color="secondary" @click="dialog_automation_time2 = false">Hủy</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="mx-2">
              <v-autocomplete class="Rectangle-5 Nhp-mt-khu px-3 my-4" :items="PDU['PDU_automation'].obj.list" :item-color="PDU.teal_color"
              :color="PDU.teal_color" hide-no-data dense label="Thời gian bật"
              style="padding-top: 13px !important;" v-model="PDU['PDU_automation'].obj.time_on"
              required :rules="[PDU.rules.required]"
              >
              </v-autocomplete>
            </v-list-item-content>

            <v-list-item-content class="mx-2">

              <v-autocomplete class="Rectangle-5 Nhp-mt-khu px-3 my-4" :items="PDU['PDU_automation'].obj.list" :item-color="PDU.teal_color"
              :color="PDU.teal_color" hide-no-data dense label="Thời gian tắt"
              style="padding-top: 13px !important;" v-model="PDU['PDU_automation'].obj.time_off"
              required :rules="[PDU.rules.required]"
              >
              </v-autocomplete>
            </v-list-item-content>
          </v-list-item>
            
          <v-list-item>
            <v-list-item-content class="ml-4">
              <v-list-item-content class="mb-0 py-0 align-content-center"><p class="mb-0">Trạng thái bắt đầu</p></v-list-item-content>
              <v-layout row align-center justify-center>
                    <p :class="start_state==false?'mb-0 status_color' : 'mb-0'">Tắt</p>
                    <v-switch class="ml-5" inset v-model="start_state" :color="PDU.teal_color"></v-switch>
                    <p :class="start_state==true?'mb-0 status_color':'mb-0'">Bật</p>
              </v-layout>
            </v-list-item-content>
          </v-list-item>
        
        <v-card-actions>
          <v-spacer/>
          <v-btn type="submit" :loading="!AutomationLoading" :color="PDU.teal_color" :disabled="!PDU['PDU_automation'].isValid" :class="onCheckRole()==false?'d-none':'white--text'" >Khởi tạo</v-btn>
          <v-btn color="secondary" @click="dialog_setting_PDU_automation = false">Hủy</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <!--////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////-->
    <!--dialog create/edit scene in PDU - Sensor-->
    <v-dialog max-width="500" v-model="dialogPDUSensor" persistent>
      <v-card>
          <v-list-item>
            <v-list-item-content class="mx-2">
              <v-dialog ref="dialog2" width="290px" persistent v-model="PDU['Sensor'].obj.editedItem.dialog_time" :return-value.sync="PDU['Sensor'].obj.editedItem.timer">
                <template v-slot:activator="{ on }">
                    <v-text-field class="Rectangle-5 Nhp-mt-khu mt-4 px-3" v-on="on" v-model="PDU['Sensor'].obj.editedItem.timer" label="Thời Gian" readonly required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
                </template>
                <v-time-picker v-if="PDU['Sensor'].obj.editedItem.dialog_time" :color="PDU.teal_color" format="24hr" v-model="PDU['Sensor'].obj.editedItem.timer">
                <v-spacer></v-spacer>
                <v-btn :color="PDU.teal_color" dark @click="PreventOOh2();">Đồng Ý</v-btn>
                <v-btn color="secondary" @click="PDU['Sensor'].obj.editedItem.dialog_time = false">Hủy</v-btn>
                </v-time-picker>
              </v-dialog>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content class="ml-4">
              <v-list-item-content class="mb-0 py-0 align-content-center"><p class="mb-0">Trạng thái</p></v-list-item-content>
              <v-list-item-content><v-divider/></v-list-item-content>
              <v-layout row align-center justify-center>
                
                <p :class="PDU['Sensor'].obj.editedItem.status==false?'mb-0 status_color' : 'mb-0'">Tắt</p>
                <v-switch class="ml-5" inset v-model="PDU['Sensor'].obj.editedItem.status" :color="PDU.teal_color"></v-switch>
                <p :class="PDU['Sensor'].obj.editedItem.status==true?'mb-0 status_color':'mb-0'">Tự động</p>
              </v-layout>
            </v-list-item-content>
          </v-list-item>
        
        <v-card-actions>
          <v-spacer/>
          <v-btn :color="PDU.teal_color" :class="onCheckRole() == false? 'd-none':'white--text'"  @click="onAddScene_PDUSensor(PDU['Sensor'].obj.cata);dialogPDUSensor = false">Đồng ý</v-btn>
          <v-btn color="secondary" @click="dialogPDUSensor = false">Hủy</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog list music-->
    <v-dialog persistent v-model="BREA.dialogMusic" scrollable max-width="600">
      <v-card >
        <v-toolbar class="toolbar_padding_left white--text" :color="PDU.teal_color" dense flat >Danh Sách Âm<v-spacer /><v-btn icon @click="BREA.dialogMusic = false"><v-icon color="#fff">close</v-icon></v-btn></v-toolbar>
        <v-card-text class="pa-0">
          <v-list dense scrollable v-for="(item, i) in list_ListMusic" :key="i" v-if="PDU[modul].obj.active" class="py-0">
            <v-list-item ripple v-if="PDU[modul].obj.active">
              <v-list-item-action class="my-0">
                <v-btn :disabled="PDU[modul].obj.active != true" icon @click="onCheckDelMusic(item)" :class="onCheckRole() == false? 'd-none':''">
                  <v-icon color="red">delete_outline</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content class="caption text-truncate">{{ decodeURIComponent(item) }}</v-list-item-content>
            </v-list-item>
          </v-list>
          <p v-if="!list_ListMusic || list_ListMusic.length === 0" class="py-2" style="text-align: center;font-size: 16px;font-weight: bold;">Thiết bị chưa có âm nào</p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--dialog channel music setting-->
    <v-dialog persistent v-model="dialogMusicTimer" scrollable max-width="500">
      <v-card>
        <v-toolbar flat class="toolbar_padding_left "  dense  >{{ 'Danh Sách Âm'}}<v-spacer />
          <v-btn @click="onContructual();dialog_updateSceneNesthouse = true" :class="onCheckRole() == false ? 'd-none': 'mr-3'"  color="#fc9003" :disabled="PDU['Scene'].isLoading" add outlined small><v-icon>add</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pa-0" v-if="NesthouseTimerArr.length">
          <v-list v-for="timer in NesthouseTimerArr" :key="timer.id">
            <v-list-item>
                <v-list-item class="px-2">
                    <v-layout row wrap class="Rectangle-34" align-center>
                      <v-flex xs12 sm12 wrap class="Rectangle-35">  
                        <v-list-item class="pl-2 pr-0" style="width:100%">
                          <v-flex xs8 sm9 wrap>
                            <v-list-item-content>
                              
                                <v-list-item-title class="mb-0" style="width: 100px">
                                  {{decodeURIComponent(timer.music_name)}}
                                </v-list-item-title>
                              
                            </v-list-item-content>
                          </v-flex>
                          
                          <v-flex xs4 sm3 wrap style="text-align: center;">
                            <v-btn icon @click="editSceneNesthouse(timer)" :class="onCheckRole() == false? 'd-none':'mr-1'"><v-icon :color="COLO.DarkGreen">edit</v-icon></v-btn>
                            <v-btn icon @click="fnSetMusicStorage({ model: 'MusicTimer', item: timer })" :class="onCheckRole() == false? 'd-none':'ml-1'"><v-icon>delete_outline</v-icon></v-btn>
                          </v-flex>
                        </v-list-item>
                      </v-flex>

                      <v-flex xs12 sm12 class="mx-2" wrap>
                        <v-list-item class="px-0">
                          <v-list-item class="px-0">
                            <img class="mr-2" style="width:24px" :src="timer.status == false? image_timer_color_grey:image_timer"/>
                            <p :class="timer.status == true?'color_on mb-0 mt-1': 'color_off mb-0 mt-1'">{{timer.timer}}</p>
                            <v-spacer/>
                            <v-chip style="width: 20px; height: 20px; box-shadow: none;margin-right: 10px;padding: 0;" fab :color="timer.status==true ? COLO.LightOrange : ''"></v-chip>
                            <p :class="timer.status == true?'mb-0 color_on':'mb-0 color_off mr-2'" style="font-size: 12px">{{timer.status==true?'Phát nhạc':'Tắt nhạc'}}</p>
                          </v-list-item>
                        </v-list-item>
                      </v-flex>

                      <v-flex xs12 sm12>  
                        <v-list-item class="pl-2 pr-0">
                          <p style="width:25px;    text-align: center;" :class="timer.status== true ?'color_on_green mr-1 mb-5' : 'color_off mr-1 mb-5'">{{ Math.floor(timer.music_volume*100)}}</p>
                          <img class="mb-6" style="width:24px" :src="timer.status==true? image_volume_up_blue:image_volume_up_grey"/>
                          <v-slider class="slider-scene-musicchanel" thumb-size="0" :thumb-color="timer.status==true?'#009688':'#b2b2b2'" :height="0" :value="100*timer.music_volume" readonly :color="PDU.teal_color" :track-color="timer.status == true? '#aeaeae':'#b2b2b2'"></v-slider>
                        </v-list-item>
                      </v-flex>
                    </v-layout>
                </v-list-item>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-text v-else>
          <v-card flat >
            <v-layout row justify-center align-center class="mt-2">
              <span>{{'Kênh chưa có kịch bản'}}</span>
            </v-layout>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn style="width:90px" :class="onCheckRole() == false? 'd-none':''" :color="PDU.teal_color" :loading="PDU['Scene'].isLoading" dark @click="onSaveScene_NesthouseControl();">Lưu</v-btn>
          <v-btn color="secondary" :loading="PDU['Scene'].isLoading" @click="fnResetListSceneID();dialogMusicTimer=false">Thoát</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog delete channel music setting-->
    <v-dialog max-width="500" v-model="PDU['MusicTimer'].obj.isDel" persistent>
      <v-card>
        <v-card-text class="pt-4">Bạn có chắc muốn xóa kịch bản này?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :class="onCheckRole() == false? 'd-none':''" :color="PDU.teal_color" dark @click="isDel();">Xóa</v-btn>
          <v-btn color="secondary" @click="fnCallResetView('MusicTimer')">Hủy</v-btn>
        </v-card-actions>
      </v-card> 
    </v-dialog>
    <!--dialog delete PDU timer scene-->
    <v-dialog max-width="500" v-model="PDU['PDUTimer'].obj.isDel" persistent>
      <v-card>
        <v-card-text class="pt-4">Bạn có chắc muốn xóa kịch bản này?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :class="onCheckRole() == false? 'd-none':''" :color="PDU.teal_color" dark @click="isDel_timer();">Xóa</v-btn>
          <v-btn color="secondary" @click="fnCallResetView('PDUTimer')">Hủy</v-btn>
        </v-card-actions>
      </v-card> 
    </v-dialog>
    <!--dialog delete music-->
    <v-dialog max-width="290" v-model="PDU['Music'].obj.isDel" persistent>
      <v-card>
        <v-toolbar dense flat>
            <v-spacer/>
            <v-btn icon @click="fnCallResetView('Music')"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-0">
          <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
          <v-list-item>
            <v-layout row justify-center align-center align-content-center>
              <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Bạn có chắc xóa âm này?</p>
            </v-layout>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
           <v-list-item style="justify-content: center">
              <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :color="PDU.teal_color" dark  @click="onDelMusic(); fnCallResetView('Music')">Đồng ý</v-btn>
          </v-list-item>
        </v-card-actions>
      </v-card> 
    </v-dialog>
    <!--dialog delete scene PDU temperature-->
    <v-dialog max-width="500" v-model="PDU['PDUSensor'].obj.isDel" persistent>
      <v-card>
        <v-card-text class="pt-4">Bạn có chắc muốn xóa kịch bản này?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :class="onCheckRole() == false? 'd-none':''" :color="PDU.teal_color" dark @click="isDel_PDUSensor(PDU['PDUSensor'].obj.cata);">Xóa</v-btn>
          <v-btn color="secondary" @click="fnCallResetView('PDUSensor')">Hủy</v-btn>
        </v-card-actions>
      </v-card> 
    </v-dialog>
    <!--dialog add new music from computer device-->
    <v-dialog persistent v-model="PDU['Music'].isCreate" scrollable max-width="500">
      <v-card>
        <v-toolbar flat  dense class="toolbar_padding_left">Thêm Âm Mới</v-toolbar>
        <v-btn :disabled="!PDU[modul].obj.active" class="mx-3 white--text" :color="PDU.teal_color" @click="$refs.fileInput.value = null;$refs.fileInput.click()" :loading="PDU['Music'].isLoading"><span>{{!PDU[modul].obj.active && 'Thiết bị đang bận hoặc mất kết nối' || 'Chọn âm từ thiết bị'}}</span></v-btn>
        <input :loading="PDU['Music'].isLoading" multiple type="file"  ref="fileInput" @change="onUpload($event)" style="display: none" accept="audio/mp3,audio/*;capture=microphone"/>
        
        <v-list dense v-if="PDU['Music'].obj.file_list">
          <v-list-item v-for="(music, i) in PDU['Music'].obj.file_list" :key="i">
          <v-list-item-content >
            {{music.name}} ({{(music.size/1000/1000).toFixed(1)}}M)
          </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text v-if="PDU['Music'].obj.progress" class="text-sm-center text-xs-center">{{`Đang tải lên... ${PDU['Music'].obj.progress}%`  }}</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="PDU['Music'].isLoading" :color="PDU.teal_color" class="white--text" :disabled="!PDU['Music'].obj.isValid || !PDU[modul].obj.active"  @click="onUploadMusic()">Đồng Ý</v-btn>
            <v-btn color="secondary" :loading="PDU['Music'].isLoading" @click="fnCallResetView('Music'); ">Hủy</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog format memory-->
    <v-dialog persistent v-model="PDU['UsbFormat'].isCreate" scrollable max-width="290">
      <v-card>
        <v-toolbar dense flat>
            <v-spacer/>
            <v-btn icon @click="fnCallResetView('UsbFormat')"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-0">
          <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
          <v-list-item>
            <v-layout row justify-center align-center align-content-center>
              <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Bạn có chắc định dạng<br/>bộ nhớ thiết bị?</p>
            </v-layout>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-list-item style="justify-content:center">
            <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :color="PDU.teal_color" dark  @click="onFormatUSB()">Đồng ý</v-btn>
          </v-list-item>
        </v-card-actions>
      </v-card> 
    </v-dialog>
    <!--dialog copy music from USB to SDCard-->
    <v-dialog persistent v-model="PDU['MusicUSBIn'].isCreate" scrollable max-width="290">
      <v-card>
        <v-toolbar dense flat>
            <v-spacer/>
            <v-btn icon @click="fnCallResetView('MusicUSBIn')"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-0">
          <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
          <v-list-item>
            <v-layout row justify-center align-center align-content-center>
              <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Sao tất cả âm vào bộ nhớ thiết bị?</p>
            </v-layout>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-list-item-content class="py-0">
            <v-layout row justify-center>
              <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :color="PDU.teal_color" dark  @click="onCopyMusicToSDcard()">Đồng ý</v-btn>
            </v-layout>
          </v-list-item-content>
        </v-card-actions>
      </v-card> 
    </v-dialog>
    <!--dialog create/edit channel music setting-->
    <v-dialog persistent v-model="dialog_updateSceneNesthouse" scrollable max-width="500">
      <v-form @submit.prevent="onAddScene_NesthouseControl()">
        <v-card>
          <v-tabs dense fixed-tabs centered center-active active-class="ac-tab" v-model="tabs_setting_mucsicChannel" @change="onChangeSettingChannelMucsic()">
            <v-tabs-slider color="#fc9003"></v-tabs-slider>
            <v-tab href='#tab-0'>Phát nhạc</v-tab>
            <v-tab href='#tab-1'>Tắt nhạc</v-tab>

            <v-tab-item value='tab-0'>
              <v-card flat>
                <v-card-text class="pa-4 pb-0">
                  <v-list-item-content>
                      <v-dialog ref="dialog" v-model="dialogTime" :return-value.sync="NesthouseTimerItem.timer" persistent width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field class="Rectangle-5 Nhp-mt-khu px-3" v-on="on" v-model="NesthouseTimerItem.timer" label="Thời Gian" readonly required :rules="[PDU.rules.required]" :color="PDU.teal_color"/>
                        </template>
                        <v-time-picker v-if="dialogTime" v-model="NesthouseTimerItem.timer" full-width format="24hr" :color="PDU.teal_color">
                          <v-spacer></v-spacer>
                          <v-btn :color="PDU.teal_color" dark @click="PreventOOh1()">Đồng Ý</v-btn>
                          <v-btn color="secondary" @click="dialogTime = false">Hủy</v-btn>
                        </v-time-picker>
                      </v-dialog>
                  </v-list-item-content>
                  
                  <v-list-item-content class="pt-0">
                    <v-select class="Rectangle-5 Nhp-mt-khu px-3 my-4" label="Âm" v-if="NesthouseTimerItem.status" v-model="NesthouseTimerItem.music_name" :items="list_ListMusic.map(e=>{return decodeURIComponent(e)})" clearable required :rules="[PDU.rules.required]" :item-color="PDU.teal_color" :color="PDU.teal_color">
                      <template slot="no-data">Thiết Bị Chưa Có Âm Nào</template>
                    </v-select>
                  </v-list-item-content >

                  <v-list-item-content class="py-0" v-if="NesthouseTimerItem.status">
                    <v-flex xs12 sm12>
                      <v-list-item class="px-0">
                        <p class="mr-1 mb-5">{{ Math.floor(NesthouseTimerItem.music_volume * 100) || 0 }}</p>
                        <v-slider class="slider-edit-musicchanel" :min="0.0" :max="1.0" :step="0.01" v-model="NesthouseTimerItem.music_volume" :prepend-icon="NesthouseTimerItem.music_volume ? 'volume_up' : 'volume_off'"
                        :color="PDU.teal_color" track-color="#aeaeae"/>
                      </v-list-item>
                    </v-flex>
                  </v-list-item-content>
                
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value='tab-1'>
              <v-card flat>
                <v-card-text class="pa-4 pb-0">
                  <v-list-item-content>
                      <v-dialog ref="dialog" v-model="dialogTime" :return-value.sync="NesthouseTimerItem.timer" persistent width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field  class="Rectangle-5 Nhp-mt-khu px-3" v-on="on" v-model="NesthouseTimerItem.timer" label="Thời Gian" readonly required :rules="[PDU.rules.required]" :color="PDU.teal_color"/>
                        </template>
                        <v-time-picker v-if="dialogTime" v-model="NesthouseTimerItem.timer" full-width format="24hr" :color="PDU.teal_color">
                          <v-spacer></v-spacer>
                          <v-btn :color="PDU.teal_color" dark @click="PreventOOh1()">Đồng Ý</v-btn>
                          <v-btn color="secondary" @click="dialogTime = false">Hủy</v-btn>
                        </v-time-picker>
                      </v-dialog>
                  </v-list-item-content>
                  
                  <v-list-item-content class="pt-0">
                    <v-select class="Rectangle-5 Nhp-mt-khu px-3 my-4" label="Âm" v-if="NesthouseTimerItem.status" v-model="NesthouseTimerItem.music_name" :items="list_ListMusic.map(e=>{return decodeURIComponent(e)})" clearable required :rules="[PDU.rules.required]" :item-color="PDU.teal_color" :color="PDU.teal_color">
                      <template slot="no-data">Thiết Bị Chưa Có Âm Nào</template>
                    </v-select>
                  </v-list-item-content >

                  <v-list-item-content class="py-0" v-if="NesthouseTimerItem.status">
                    <v-flex xs12 sm12>
                      <v-list-item class="pl-2 pr-0 pt-3">
                        <p class="mr-1 mb-5">{{ Math.floor(NesthouseTimerItem.music_volume * 100) || 0 }}</p>
                        <v-slider :min="0.0" :max="1.0" :step="0.01" v-model="NesthouseTimerItem.music_volume" :prepend-icon="NesthouseTimerItem.music_volume ? 'volume_up' : 'volume_off'"
                        :color="PDU.teal_color" track-color="#aeaeae"/>
                      </v-list-item>
                    </v-flex>
                  </v-list-item-content>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :color="PDU.teal_color" :class="onCheckRole() == false? 'd-none':'white--text'" 
            :disabled="((NesthouseTimerItem.music_name == null || NesthouseTimerItem.music_name == 'Im lặng') && NesthouseTimerItem.status)" type="submit">Đồng Ý</v-btn>
            <v-btn color="secondary" @click="dialog_updateSceneNesthouse = false">Hủy</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!--dialog update channel description-->
    <v-dialog persistent v-model="dialog_NesthouseUpdateDescription" scrollable max-width="500">
      <v-form @submit.prevent="onAttributeUpdate_Nesthouse();dialog_NesthouseUpdateDescription = false;">
            <v-card>
              <v-toolbar :color="PDU.teal_color" flat  dense class="toolbar_padding_left white--text">Cập Nhật Thông Tin Kênh Thiết Bị</v-toolbar>
              <v-card-text v-if="list_MusicChannel && list_MusicChannel.length>0" class="pt-0 pl-3">
                <div v-for="(i,k) in list_MusicChannel" :key="k"  class="pa-1 mt-3">
                  <p v-if="$vuetify.breakpoint.name === 'xs'" style="font-size: 18px;font-weight: 500;" class="mb-1">{{Object.values(i)[0].attribute_descriptions_copy || `Âm ly ${k + 1}`}}</p>
                  <div  style="width:1px;height:10px"></div>
                  <v-row justify="space-around">
                    <v-flex xs12 sm5 class="ma-1 px-1">
                      <v-text-field class="Rectangle-5 Nhp-mt-khu mt-0 pl-2" style="width:100%"
                      :color="PDU.teal_color" :label="`Âm ly ${k + 1}`"
                      v-model="Object.values(i)[0].attribute_descriptions_copy" required />
                    </v-flex>
                    <v-flex xs5 sm3 class="ma-1" v-if="list_audio_status && list_audio_status.length > 0">
                      <v-text-field class="Rectangle-5 Nhp-mt-khu ma-0 pl-2" style="width:100%"
                          :color="PDU.teal_color" v-model="Object.values(list_audio_status[k*2])[0].attribute_descriptions_copy" required :label="`Kênh ${k*2 + 1}`"/>
                    </v-flex>
                    <v-flex xs6 sm3 class="ma-1" v-if="list_audio_status && list_audio_status.length > 0">
                      <v-text-field class="Rectangle-5 Nhp-mt-khu ma-0 pl-2" style="width:100%"
                          :color="PDU.teal_color" v-model="Object.values(list_audio_status[k*2+1])[0].attribute_descriptions_copy" required :label="`Kênh ${k*2 + 2}`"/>
                    </v-flex>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :class="onCheckRole() == false? 'd-none':''" :color="PDU.teal_color"  class="white--text" type="submit">Đồng Ý</v-btn>
                <v-btn color="secondary" @click="dialog_NesthouseUpdateDescription = false">Hủy</v-btn>
              </v-card-actions>
            </v-card>
      </v-form>
    </v-dialog>
    <!--dialog update channel notification-->
    <v-dialog persistent v-model="BREA.dialog_NesthouseUpdateNotification" scrollable max-width="500">
      <v-form @submit.prevent="save_notify_content();BREA.dialog_NesthouseUpdateNotification = false;">
        <v-card>
          <v-toolbar flat  dense class="toolbar_padding_left white--text" :color="PDU.teal_color">Cài đặt thông báo</v-toolbar>
          <v-card-text class="pa-0">
            <v-tabs icons-and-text :color="PDU.teal_color" center-active active-class="ac-tab" fixed-tabs v-if="list_MusicChannel && list_MusicChannel.length > 0" v-model="BREA.tabNoti">
              <v-tabs-slider color="#fc9003"></v-tabs-slider>
              <v-tab href="#tab-0">Không tín hiệu<v-icon color="red">error</v-icon></v-tab>
              <v-tab href="#tab-1">Có tín hiệu<v-icon color="green">error</v-icon></v-tab>
              <v-tab-item value='tab-0'>
                <v-list-item style="justify-content:center; height: 16px">
                  <span style="font-weight:bold;font-size: 16px">Thông báo khi kênh không có tín hiệu</span>
                </v-list-item>
                <v-list-item v-for="(i,k) in list_audio_status" :key="k">
                  <div class="mt-4">
                    <span class="ml-2">{{Object.values(i)[0].active ? 'Bật' : 'Tắt'}}</span>
                    <v-switch class="mt-0" :color="PDU.teal_color" inset v-model="Object.values(i)[0].active"></v-switch>
                  </div>
                  <v-text-field v-if="Object.values(i)[0].active" v-model="Object.values(i)[0].notify_content" :color="PDU.teal_color" class="Rectangle-5 Nhp-mt-khu mt-4 px-3"
                  :label="`${(Object.values(list_MusicChannel[Math.floor(k/2)])[0].attribute_descriptions_copy || 'Âm ly ' + (Math.floor(k/2) + 1))} - ${Object.values(i)[0].attribute_descriptions_copy || Object.values(i)[0].attribute_descriptions}`"></v-text-field>
                  <v-text-field v-else :disabled="!Object.values(i)[0].active" :color="PDU.teal_color" class="Rectangle-5 Nhp-mt-khu mt-4 px-3"
                  :label="`${(Object.values(list_MusicChannel[Math.floor(k/2)])[0].attribute_descriptions_copy || 'Âm ly ' + (Math.floor(k/2) + 1))} - ${Object.values(i)[0].attribute_descriptions_copy || Object.values(i)[0].attribute_descriptions}`"></v-text-field>
                </v-list-item>
              </v-tab-item>
              <v-tab-item value='tab-1'>
                <v-list-item style="justify-content:center; height: 16px">
                  <span style="font-weight:bold;font-size: 16px">Thông báo khi kênh có tín hiệu</span>
                </v-list-item>
                <v-list-item v-for="(i,k) in list_audio_status" :key="k">
                  <div class="mt-4">
                    <span class="ml-2">{{Object.values(i)[0].active1 ? 'Bật' : 'Tắt'}}</span>
                    <v-switch class="mt-0" :color="PDU.teal_color" inset v-model="Object.values(i)[0].active1"></v-switch>
                  </div>
                  <v-text-field v-if="Object.values(i)[0].active1" v-model="Object.values(i)[0].notify_content1" :color="PDU.teal_color" class="Rectangle-5 Nhp-mt-khu mt-4 px-3"
                  :label="`${(Object.values(list_MusicChannel[Math.floor(k/2)])[0].attribute_descriptions_copy || 'Âm ly ' + (Math.floor(k/2) + 1))} - ${(Object.values(i)[0].attribute_descriptions_copy || Object.values(i)[0].attribute_descriptions)}`"></v-text-field>
                  <v-text-field v-else :disabled="!Object.values(i)[0].active1" :color="PDU.teal_color" class="Rectangle-5 Nhp-mt-khu mt-4 px-3"
                  :label="`${(Object.values(list_MusicChannel[Math.floor(k/2)])[0].attribute_descriptions_copy || 'Âm ly ' + (Math.floor(k/2) + 1))} - ${(Object.values(i)[0].attribute_descriptions_copy || Object.values(i)[0].attribute_descriptions)}`"></v-text-field>
                </v-list-item>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :class="onCheckRole() == false? 'd-none':''" :color="PDU.teal_color"  class="white--text" type="submit">Đồng Ý</v-btn>
            <v-btn :class="NOTI.data && NOTI.data.length === 16 ? '' : 'd-none'" color="secondary" @click="onResetAuditoStatusDecription();BREA.dialog_NesthouseUpdateNotification = false">Hủy</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!--dialog update channel monitor description-->
    <v-dialog persistent v-model="dialog_ChannelMonitorUpdateDescription" scrollable max-width="500">
      <v-form  @submit.prevent="onAttributeUpdate_ChannelMonitor();dialog_ChannelMonitorUpdateDescription = false;">
        <v-card>
          <v-toolbar flat  dense class="toolbar_padding_left white--text" :color="PDU.teal_color">Cài đặt hiển thị kênh</v-toolbar>
          <v-card-text class="pa-0">
              <v-list-item style="background-color: #f6f6f6">
                <v-list-item-content class="py-0">
                  <p class="mb-0 Nhp-mt-khu">{{`Hiển thị`}}</p>
                </v-list-item-content>
                <v-list-item-content class="py-0 mr-4">
                  <p class="mb-0 Nhp-mt-khu">Đặt tên kênh</p>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="(i,k) in list_channel_monitor_AudioStatus.length" :key="k">
                <div class="mt-4">  
                  <span class="ml-2">{{Object.values(list_channel_monitor_AudioUsed[k])[0].Da_copy ? 'Bật' : 'Tắt'}}</span>
                  <v-switch class="mt-0" inset @change="onInitChMoItem_1($event)" :color="PDU.teal_color" v-model="Object.values(list_channel_monitor_AudioUsed[k])[0].Da_copy"/>
                </div>
                <v-text-field v-if="Object.values(list_channel_monitor_AudioUsed[k])[0].Da_copy" class="Rectangle-5 Nhp-mt-khu mt-4 px-3" :label="`Kênh ${k+1}`"
                :color="PDU.teal_color" v-model="Object.values(list_channel_monitor_AudioStatus[k])[0].attribute_descriptions_copy" required />
                <v-text-field v-else :disabled="!Object.values(list_channel_monitor_AudioUsed[k])[0].Da_copy" :color="PDU.teal_color" class="Rectangle-5 Nhp-mt-khu mt-4 px-3" :label="`Kênh ${k+1}`"></v-text-field>
              </v-list-item>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :class="onCheckRole() == false? 'd-none':''" :color="PDU.teal_color"  class="white--text" type="submit">Đồng Ý</v-btn>
            <v-btn color="secondary" @click="onResetlist_channel_monitor_AudioUsed(); dialog_ChannelMonitorUpdateDescription = false">Hủy</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!--dialog sensor setting-->
    <v-dialog max-width="540" v-model="dialog_Sensor_settings" persistent scrollable >
      <v-card class="no_padding_margin px-0" flat scrollable>
        <v-toolbar class="toolbar_padding_left white--text" :color="PDU.teal_color" dense flat >
          {{`${this.PDU.Na}`}}<v-spacer />
          <v-btn icon @click="fnResetListSceneID() ;dialog_Sensor_settings = false" class="mr-1"><v-icon color="#fff">close</v-icon></v-btn>
        </v-toolbar>

        <v-card-text style="height:100%" class=" ca-te-moblie-style">
          <v-tabs v-if="dialog_Sensor_settings" dense fixed-tabs centered center-active active-class="ac-tab" v-model="tabs_temper">
            <v-tabs-slider color="#fc9003"></v-tabs-slider>
            <v-tab href='#tab-0'>Nhiệt độ</v-tab>
            <v-tab href='#tab-1'>Độ ẩm</v-tab>

            <v-tab-item value='tab-0'>
              <v-list-item class="li-it-pa mt-4 px-0">
                <v-list-item-content class="py-0">
                    <v-list-item class="px-1">
                          <v-flex xs1 justify-center>
                            <v-list-item-content class="py-0">
                              <v-checkbox @change="onRenderSceneID()" class=" ml-1 mr-0 mt-3 no_padding_margin scalesize" :color="PDU.teal_color" v-model="TemperHumiItem.temper.check_high"></v-checkbox>
                            </v-list-item-content>
                          </v-flex>

                          <v-flex xs3 justify-center class="ml-1 mr-1">
                            <v-list-item-content class="py-0">
                              <span class="scalesize_1">Cao hơn</span>
                            </v-list-item-content>
                          </v-flex>

                          <v-flex xs4 justify-center align-center>
                            <v-list-item-content class="py-0">
                            <v-text-field class="mt-2" :rules="[PDU.rules.required_number]" :color="PDU.teal_color" style="width:50px" height="15" 
                            type="number" v-model="TemperHumiItem.temper.higher.data"  :disabled="!TemperHumiItem.temper.check_high" min=0 max=100></v-text-field>
                            </v-list-item-content>
                          </v-flex>
                          
                          <v-flex xs2><v-btn fab small class="scalesize mb-2" @click="TemperHumiItem.temper.higher.status = false;TemperHumiItem.temper.shorter.status = true" :color="!TemperHumiItem.temper.higher.status && TemperHumiItem.temper.check_high ? 'teal darken-1 white--text':''">TẮT</v-btn></v-flex>
                      
                          <v-flex xs2><v-btn fab small class="scalesize mb-2" @click="TemperHumiItem.temper.higher.status = true;TemperHumiItem.temper.shorter.status = false" :color="TemperHumiItem.temper.higher.status && TemperHumiItem.temper.check_high ? 'teal darken-1 white--text':''">Bật</v-btn></v-flex>
                    </v-list-item>
                </v-list-item-content>
              </v-list-item>


              <v-list-item class="li-it-pa px-0">
                <v-list-item-content class="py-0">
                  <v-list-item class="px-1">
                      <v-flex xs1 justify-center>
                        <v-list-item-content class="py-0">
                        <v-checkbox @change="onRenderSceneID()" class="ml-1 mr-0 mt-3 no_padding_margin scalesize" :color="PDU.teal_color" v-model="TemperHumiItem.temper.check_short"></v-checkbox>
                        </v-list-item-content>
                      </v-flex>
                      
                      <v-flex xs3 justify-center class="ml-1 mr-1">
                        <span class="scalesize">Thấp hơn</span>  
                      </v-flex>

                      <v-flex xs4 justify-center>
                        <v-list-item-content class="py-0">
                        <v-text-field class="mt-2" :rules="[PDU.rules.required_number]" :color="PDU.teal_color" style="width:50px" height="15" type="number" v-model="TemperHumiItem.temper.shorter.data" :disabled="!TemperHumiItem.temper.check_short" min=0 max=100></v-text-field>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs2><v-btn fab small class="scalesize mb-2" @click="TemperHumiItem.temper.shorter.status = false;TemperHumiItem.temper.higher.status = true" :color="!TemperHumiItem.temper.shorter.status && TemperHumiItem.temper.check_short ? 'teal darken-1 white--text':''">TẮT</v-btn></v-flex>
                      
                      <v-flex xs2><v-btn fab small class="scalesize mb-2" @click="TemperHumiItem.temper.shorter.status = true;TemperHumiItem.temper.higher.status = false" :color="TemperHumiItem.temper.shorter.status && TemperHumiItem.temper.check_short ? 'teal darken-1 white--text':''">Bật</v-btn></v-flex>

                  </v-list-item>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="li-it-pa px-0" >
                  <v-list-item style="background-color: #f0f0f0" class="px-0">
                      <v-flex wrap justify-center align-center class="px-4">
                            <v-select :item-color="PDU.teal_color" :color="PDU.teal_color" dense item-disabled="disable" @change="onselected(TemperHumiItem.temper.select)"  
                            class="scalefont scalesize mt-2" style="width:100%; color: #aeaeae !important;font-size: 20px !important;" full-width label="Ổ cắm"
                            v-model="TemperHumiItem.temper.select"  :items="list_outlet" item-text="name" item-value="id_outlet"  return-object></v-select>
                      </v-flex>
                  </v-list-item>
              </v-list-item>

              <v-list-item class="li-it-pa mt-4 mb-2 px-0">
                <v-list-item-content class="py-0">

                    <v-flex xs10 sm10 wrap justify-center align-center>
                        <v-list-item class="pl-1 pr-0">
                          <v-flex xs1 sm1 class="mr-1"><img style="width: 24px" class="mt-2" :src="image_timer_color_green"/></v-flex>
                          <v-list-item-content class="py-0 mt-1">
                            <v-list-item-title style="color: #009688">Timer</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                    </v-flex>

                    <v-flex xs2 sm2 wrap justify-end align-end>
                      <v-btn :class="onCheckRole() == false?'d-none':'recover_btn_add'" color="#fc9003" add outlined small @click="onInitTimerPDUSensor('temper')"><v-icon>add</v-icon></v-btn>
                    </v-flex>
                  

                    <v-flex xs12 sm12 wrap justify-center align-center>
                      <v-divider/>
                    </v-flex>
                  
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="li-it-pa px-0">
                <v-list-item-content class="py-0" v-if="TemperHumiItem.temper.PDUList && TemperHumiItem.temper.PDUList.length>0">
                  
                    <v-list dense v-for="(item,k) in TemperHumiItem.temper.PDUList" :key="k" class="py-0">
                      <v-list-item  class="pl-1 pr-0 Thng">
                        
                        <v-flex xs1 sm1><img style="width: 22px" class="mt-1" :src="image_timer_color_black"/></v-flex>

                        <v-flex xs3 sm3>{{item.timer}}</v-flex>
                        
                        <v-flex xs2 sm2>
                          
                          <v-switch inset readonly v-model="item.status" color="#fc9003"></v-switch>
                        </v-flex>

                        <v-flex xs3 sm5>{{item.status == true ? "Tự động": "Tắt"}}</v-flex>

                        <v-flex xs2 sm2>
                          <v-list-item class="px-0">
                              <v-icon color="#009688" @click="editItemPDUSensor({item:item,cata:'temper'})" class="mr-2">edit</v-icon>
                              <v-icon @click="onDeleteItemPDUSensor({item:item, cata:'temper'})" class="ml-2">delete_outline</v-icon>
                          </v-list-item>
                        </v-flex>

                      </v-list-item>
                    </v-list>
                </v-list-item-content>
              </v-list-item>
            </v-tab-item >

            <v-tab-item value='tab-1'>
              <v-list-item class="li-it-pa mt-4 px-0">
                <v-list-item-content class="py-0">
                  
                    <v-list-item class="px-1">
                          <v-flex xs1 justify-center>
                            <v-list-item-content class="py-0">
                              <v-checkbox @change="onRenderSceneID()" class=" ml-1 mr-0 mt-3 no_padding_margin scalesize" :color="PDU.teal_color" v-model="TemperHumiItem.humi.check_high"></v-checkbox>
                            </v-list-item-content>
                          </v-flex>

                          <v-flex xs3 justify-center class="ml-1 mr-1">
                            <v-list-item-content class="py-0">
                              <span class="scalesize_1">Cao hơn</span>
                            </v-list-item-content>
                          </v-flex>


                          <v-flex xs4 justify-center align-center>
                            <v-list-item-content class="py-0">
                            <v-text-field class="mt-2" :rules="[PDU.rules.required_number]" :color="PDU.teal_color" style="width:50px" height="15" 
                            type="number" v-model="TemperHumiItem.humi.higher.data"  :disabled="!TemperHumiItem.humi.check_high" min=0 max=100></v-text-field>
                            </v-list-item-content>
                          </v-flex>
                          
                          <v-flex xs2><v-btn fab small class="scalesize mb-2" @click="TemperHumiItem.humi.higher.status = false;TemperHumiItem.humi.shorter.status = true" :color="!TemperHumiItem.humi.higher.status && TemperHumiItem.humi.check_high ? 'teal darken-1 white--text':''">TẮT</v-btn></v-flex>
                      
                          <v-flex xs2><v-btn fab small class="scalesize mb-2" @click="TemperHumiItem.humi.higher.status = true;TemperHumiItem.humi.shorter.status = false" :color="TemperHumiItem.humi.higher.status && TemperHumiItem.humi.check_high ? 'teal darken-1 white--text':''">Bật</v-btn></v-flex>
                    </v-list-item>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="li-it-pa px-0">
                <v-list-item-content class="py-0">
                  
                  <v-list-item class="px-1">
                      <v-flex xs1 justify-center>
                        <v-list-item-content class="py-0">
                        <v-checkbox @change="onRenderSceneID()" class="ml-1 mr-0 mt-3 no_padding_margin scalesize" :color="PDU.teal_color" v-model="TemperHumiItem.humi.check_short"></v-checkbox>
                        </v-list-item-content>
                      </v-flex>
                      
                      <v-flex xs3 justify-center class="ml-1 mr-1">
                        <span class="scalesize">Thấp hơn</span>  
                      </v-flex>

                      <v-flex xs4 justify-center>
                        <v-list-item-content class="py-0">
                        <v-text-field class="mt-2" :rules="[PDU.rules.required_number]" :color="PDU.teal_color" style="width:50px" height="15" type="number" v-model="TemperHumiItem.humi.shorter.data" :disabled="!TemperHumiItem.humi.check_short" min=0 max=100></v-text-field>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs2><v-btn fab small class="scalesize mb-2" @click="TemperHumiItem.humi.shorter.status = false;TemperHumiItem.humi.higher.status = true" :color="!TemperHumiItem.humi.shorter.status && TemperHumiItem.humi.check_short ? 'teal darken-1 white--text':''">TẮT</v-btn></v-flex>
                      
                      <v-flex xs2><v-btn fab small class="scalesize mb-2" @click="TemperHumiItem.humi.shorter.status = true;TemperHumiItem.humi.higher.status = false" :color="TemperHumiItem.humi.shorter.status && TemperHumiItem.humi.check_short ? 'teal darken-1 white--text':''">Bật</v-btn></v-flex>

                  </v-list-item>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="li-it-pa px-0" >  
                  <v-list-item style="background-color: #f0f0f0" class="px-0">
                      <v-flex xs12 wrap justify-center align-center class="px-4">
                            <v-select :item-color="PDU.teal_color" :color="PDU.teal_color" dense item-disabled="disable" @change="onselected(TemperHumiItem.humi.select)"  
                            class="scalefont scalesize mt-2" style="width:100%; color: #aeaeae !important;font-size: 20px !important;" full-width label="Ổ cắm"
                            height="15px" v-model="TemperHumiItem.humi.select"  :items="list_outlet" item-text="name" item-value="id_outlet" return-object></v-select>
                      </v-flex>
                  </v-list-item>
              </v-list-item>

              <v-list-item class="li-it-pa mt-4 mb-2 px-0">
                <v-list-item-content class="py-0">
                    <v-flex xs10 sm10 wrap justify-center align-center>
                        <v-list-item class="pl-1 pr-0">
                          <v-flex xs1 sm1 class="mr-1"><img style="width: 24px" class="mt-2" :src="image_timer_color_green"/></v-flex>
                          <v-list-item-content class="py-0 mt-1">
                            <v-list-item-title style="color: #009688">Timer</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                    </v-flex>

                    <v-flex xs2 sm2 wrap justify-center align-center>
                      <v-btn :class="onCheckRole==false?'d-none':'recover_btn_add'" color="#fc9003" add outlined small @click="onInitTimerPDUSensor('humi')"><v-icon>add</v-icon></v-btn>
                    </v-flex>

                    <v-flex xs12 sm12 wrap justify-center align-center>
                      <v-divider/>
                    </v-flex>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="li-it-pa px-0">
                <v-list-item-content class="py-0" v-if="TemperHumiItem.humi.PDUList && TemperHumiItem.humi.PDUList.length>0">
                    <v-list dense v-for="(item,k) in TemperHumiItem.humi.PDUList" :key="k" class="py-0">
                      <v-list-item  class="pl-1 pr-0 Thng">
                        
                        <v-flex xs1 sm1><img style="width: 22px" class="mt-1" :src="image_timer_color_black"/></v-flex>

                        <v-flex xs3 sm3>{{item.timer}}</v-flex>

                        <v-flex xs2 sm2>
                        
                          <v-switch inset readonly v-model="item.status" color="#fc9003"></v-switch>
                        </v-flex>

                        <v-flex xs3 sm5>{{item.status == true ? "Tự động": "Tắt"}}</v-flex>

                        <v-flex xs2 sm2>
                          <v-list-item class="px-0">
                              <v-icon color="#009688" @click="editItemPDUSensor({item:item,cata:'humi'})" class="mr-2">edit</v-icon>
                              <v-icon @click="onDeleteItemPDUSensor({item:item, cata:'humi'})" class="ml-2">delete_outline</v-icon>
                          </v-list-item>
                        </v-flex>
                      </v-list-item>
                    </v-list>
                </v-list-item-content>
              </v-list-item>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        
        <v-card-actions>
          <v-list-item-content>
          <v-layout row justify-center>
            <v-btn :loading="PDU['Sensor'].isLoading" :color="PDU.teal_color" :class="onCheckRole()==false?'d-none':'Rectangle-7 my-4 white--text'"   @click="save_sensorControl()">
              <p class="ng-nhp_1 ma-0 ">Lưu</p>
            </v-btn>
          </v-layout>
          </v-list-item-content>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog sensor warning setting-->
    <v-dialog max-width="540" persistent v-model="dialog_Temper_Humi_warning">
      <v-card class="no_padding_margin px-0" flat scrollable>
        <v-toolbar class="toolbar_padding_left white--text" :color="PDU.teal_color" dense flat >
          {{`[${this.PDU.Na}] Cài đặt cảnh báo`}}<v-spacer />
          <v-btn icon @click="fnResetListSceneID() ;dialog_Temper_Humi_warning = false" class="mr-1"><v-icon color="#fff">close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text style="height:100%" class=" ca-te-moblie-style">
          <v-tabs v-if="dialog_Temper_Humi_warning" dense fixed-tabs centered center-active active-class="ac-tab" v-model="tabs_warning">
            <v-tabs-slider color="#fc9003"></v-tabs-slider>
            <v-tab href='#tab-0'>Nhiệt độ</v-tab>
            <v-tab href='#tab-1'>Độ ẩm</v-tab>

            <v-tab-item value='tab-0'>
              <v-list-item class="li-it-pa mt-4 px-0">
                <v-list-item-content class="py-0">
                    <v-list-item class="px-1">
                      <v-flex xs1 justify-center>
                        <v-list-item-content class="py-0">
                          <v-checkbox class=" ml-1 mr-0 mt-3 no_padding_margin scalesize" :color="PDU.teal_color" v-model="TemperHumiItemWarning.temper.check_high"></v-checkbox>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs3 justify-center class="ml-1 mr-1">
                        <v-list-item-content class="py-0">
                          <span class="scalesize_1">Cao hơn</span>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs2 justify-center align-center>
                        <v-list-item-content class="py-0">
                        <v-text-field class="mt-2" :rules="[PDU.rules.required_number]" :color="PDU.teal_color" style="width:50px" height="15" 
                        type="number" v-model="TemperHumiItemWarning.temper.higher.data"  :disabled="!TemperHumiItemWarning.temper.check_high" min=0 max=100></v-text-field>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs2 justify-center class="ml-1 mr-1">
                        <v-list-item-content class="py-0">
                          <span class="scalesize_1">Trong</span>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs4 justify-center align-center>
                        <v-list-item-content class="py-0">
                        <v-select :item-color="PDU.teal_color" :color="PDU.teal_color" class="sen-war-combb" :disabled="!TemperHumiItemWarning.temper.check_high" v-model="TemperHumiItemWarning.temper.higher.time" :items="TemperWarning" dense return-object item-text="text" item-value="value"></v-select>
                        </v-list-item-content>
                      </v-flex>
                    </v-list-item>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="li-it-pa px-0">
                <v-list-item-content class="py-0">
                    <v-list-item class="px-1">
                      <v-flex xs1 justify-center>
                        <v-list-item-content class="py-0">
                          <v-checkbox class=" ml-1 mr-0 mt-3 no_padding_margin scalesize" :color="PDU.teal_color" v-model="TemperHumiItemWarning.temper.check_short"></v-checkbox>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs3 justify-center class="ml-1 mr-1">
                        <span class="scalesize">Thấp hơn</span>  
                      </v-flex>

                      <v-flex xs2 justify-center align-center>
                        <v-list-item-content class="py-0">
                        <v-text-field class="mt-2" :rules="[PDU.rules.required_number]" :color="PDU.teal_color" style="width:50px" height="15" 
                        type="number" v-model="TemperHumiItemWarning.temper.shorter.data"  :disabled="!TemperHumiItemWarning.temper.check_short" min=0 max=100></v-text-field>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs2 justify-center class="ml-1 mr-1">
                        <v-list-item-content class="py-0">
                          <span class="scalesize_1">Trong</span>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs4 justify-center align-center>
                        <v-list-item-content class="py-0">
                        <v-select :item-color="PDU.teal_color" :color="PDU.teal_color" class="sen-war-combb" :disabled="!TemperHumiItemWarning.temper.check_short" v-model="TemperHumiItemWarning.temper.shorter.time" :items="TemperWarning" dense return-object item-text="text" item-value="value"></v-select>
                        </v-list-item-content>
                      </v-flex>
                    </v-list-item>
                </v-list-item-content>
              </v-list-item>

              <v-list-item style="justify-content:center">
                <v-text-field :disabled="!TemperHumiItemWarning.temper.check_high" class="Rectangle-5 Nhp-mt-khu mt-0 px-3 mr-1" v-model="TemperHumiItemWarning.temper.higher.message" label="Thông báo trên ngưỡng" required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
              </v-list-item>

              <v-list-item style="justify-content:center">
                <v-text-field :disabled="!TemperHumiItemWarning.temper.check_short" class="Rectangle-5 Nhp-mt-khu mt-4 px-3 mr-1" v-model="TemperHumiItemWarning.temper.shorter.message" label="Thông báo dưới ngưỡng" required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
              </v-list-item>

            </v-tab-item>
            <v-tab-item value='tab-1'>
              <v-list-item class="li-it-pa mt-4 px-0">
                <v-list-item-content class="py-0">
                    <v-list-item class="px-1">
                      <v-flex xs1 justify-center>
                        <v-list-item-content class="py-0">
                          <v-checkbox class=" ml-1 mr-0 mt-3 no_padding_margin scalesize" :color="PDU.teal_color" v-model="TemperHumiItemWarning.humi.check_high"></v-checkbox>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs3 justify-center class="ml-1 mr-1">
                        <v-list-item-content class="py-0">
                          <span class="scalesize_1">Cao hơn</span>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs2 justify-center align-center>
                        <v-list-item-content class="py-0">
                        <v-text-field class="mt-2" :rules="[PDU.rules.required_number]" :color="PDU.teal_color" style="width:50px" height="15" 
                        type="number" v-model="TemperHumiItemWarning.humi.higher.data"  :disabled="!TemperHumiItemWarning.humi.check_high" min=0 max=100></v-text-field>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs2 justify-center class="ml-1 mr-1">
                        <v-list-item-content class="py-0">
                          <span class="scalesize_1">Trong</span>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs4 justify-center align-center>
                        <v-list-item-content class="py-0">
                        <v-select :item-color="PDU.teal_color" :color="PDU.teal_color" class="sen-war-combb" :disabled="!TemperHumiItemWarning.humi.check_high" v-model="TemperHumiItemWarning.humi.higher.time" :items="TemperWarning" dense return-object item-text="text" item-value="value"></v-select>
                        </v-list-item-content>
                      </v-flex>
                    </v-list-item>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="li-it-pa px-0">
                <v-list-item-content class="py-0">
                    <v-list-item class="px-1">
                      <v-flex xs1 justify-center>
                        <v-list-item-content class="py-0">
                          <v-checkbox class=" ml-1 mr-0 mt-3 no_padding_margin scalesize" :color="PDU.teal_color" v-model="TemperHumiItemWarning.humi.check_short"></v-checkbox>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs3 justify-center class="ml-1 mr-1">
                        <span class="scalesize">Thấp hơn</span>  
                      </v-flex>

                      <v-flex xs2 justify-center align-center>
                        <v-list-item-content class="py-0">
                        <v-text-field class="mt-2" :rules="[PDU.rules.required_number]" :color="PDU.teal_color" style="width:50px" height="15" 
                        type="number" v-model="TemperHumiItemWarning.humi.shorter.data"  :disabled="!TemperHumiItemWarning.humi.check_short" min=0 max=100></v-text-field>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs2 justify-center class="ml-1 mr-1">
                        <v-list-item-content class="py-0">
                          <span class="scalesize_1">Trong</span>
                        </v-list-item-content>
                      </v-flex>

                      <v-flex xs4 justify-center align-center>
                        <v-list-item-content class="py-0">
                        <v-select :item-color="PDU.teal_color" :color="PDU.teal_color" class="sen-war-combb" :disabled="!TemperHumiItemWarning.humi.check_short" v-model="TemperHumiItemWarning.humi.shorter.time" :items="TemperWarning" dense return-object item-text="text" item-value="value"></v-select>
                        </v-list-item-content>
                      </v-flex>
                    </v-list-item>
                </v-list-item-content>
              </v-list-item>

              <v-list-item style="justify-content:center">
                <v-text-field :disabled="!TemperHumiItemWarning.humi.check_high" class="Rectangle-5 Nhp-mt-khu mt-0 px-3 mr-1" v-model="TemperHumiItemWarning.humi.higher.message" label="Thông báo trên ngưỡng" required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
              </v-list-item>

              <v-list-item style="justify-content:center">
                <v-text-field :disabled="!TemperHumiItemWarning.humi.check_short" class="Rectangle-5 Nhp-mt-khu mt-4 px-3 mr-1" v-model="TemperHumiItemWarning.humi.shorter.message" label="Thông báo dưới ngưỡng" required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
              </v-list-item>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions>
          <v-list-item-content>
          <v-layout row justify-center>
            <v-btn :loading="PDU['Sensor'].isLoading" :color="PDU.teal_color" :class="onCheckRole()==false?'d-none':'Rectangle-7 my-4 white--text'" @click="save_sensorWarning()">
              <p class="ng-nhp_1 ma-0 ">Lưu</p>
            </v-btn>
          </v-layout>
          </v-list-item-content>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog warning PDU setting-->
    <v-dialog max-width="500" v-model="dialog_Outlet_protect" persistent>
      <v-toolbar   class="toolbar_padding_left">
        <v-icon>settings_power</v-icon>
        <v-toolbar-title class="subtitle-2 font-regular font_size">Cài đặt ngưỡng cảnh báo và bảo vệ tải</v-toolbar-title>
      </v-toolbar>
      <v-flex xl12 lg12>
        <v-card class="pa-2"  >
          <v-layout row>
            <v-checkbox :color="PDU.teal_color" class="my-2" style="width: 0px" v-model="protectItem.Max_check"></v-checkbox>
            <v-card-text style="width: 50%">Dòng điện tối đa</v-card-text>
            <v-text-field :disabled="!protectItem.Max_check" class="mx-2 stylish" height="17" type="number" v-model="protectItem.MaxCurrent_content" min=0></v-text-field>
            <v-select :item-color="PDU.teal_color" solo :disabled="!protectItem.Max_check" style="width: 5px"   v-model="protectItem.MaxCurrent_unit" :items="['mA','A']" @change="onCaculator(true)" ></v-select>
          </v-layout>
          <v-layout row>
            <v-checkbox :color="PDU.teal_color" class="my-2" style="width: 0px" v-model="protectItem.Min_check"></v-checkbox>
            <v-card-text  style="width: 50%">Dòng điện tối thiểu</v-card-text>
            <v-text-field :disabled="!protectItem.Min_check" class="mx-2 stylish" height="17" type="number" v-model="protectItem.MinCurrent_content" min=0></v-text-field>
            <v-select :item-color="PDU.teal_color" solo :disabled="!protectItem.Min_check"   style="width: 5px" v-model="protectItem.MinCurrent_unit" :items="['mA','A']" @change="onCaculator(false)" ></v-select>
          </v-layout>
          <v-layout row v-if=" (protectItem.Max_check && protectItem.MaxCurrent_content) || (protectItem.Min_check && protectItem.MinCurrent_content)">
            <v-text-field class="mx-2" label="Nội dung thông báo" v-model="protectItem.notify_content"></v-text-field>
          </v-layout>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="secondary" @click="dialog_Outlet_protect = false">Hủy</v-btn>
            <v-btn :color="PDU.teal_color" class="white--text" :disabled="!onCheckAvalable" @click="save_OutletProtect()">Đồng ý</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-dialog>
    <!--dialog controll realtime PDU device-->
    <v-dialog max-width="540" height="300px" persistent v-model="dialog_Outlet_settings" scrollable>
      <v-card scrollable>
        <v-card-actions>
          <img style="width: 24px" class="mb-1 mx-4" :src="image_timer_color_green"/>
          <span>{{`${this.PDU.Na}`}}</span>
          <v-spacer/>
          <v-btn @click="onBeforeCreatePopUpAutomation()" :class="onCheckRole() == false ? 'd-none': 'mr-3'"  color="#fc9003" :disabled="PDU['Scene'].isLoading" add outlined small><v-icon>loop</v-icon></v-btn>
          <v-btn @click="onContructual();dialog = true" :class="onCheckRole() == false ? 'd-none': 'mr-3'"  color="#fc9003" :disabled="PDU['Scene'].isLoading" add outlined small><v-icon>add</v-icon></v-btn>
        </v-card-actions>

        <v-card-text style="height:100%" class="px-2">
          <v-list-item-content class="px-4" v-if="items && items.length>0">
            <v-list v-for="(item,k) in items" :key="k" dense class="py-0">
              <v-list-item class="px-0 Thng">
                  <v-flex xs1 sm1><img style="width: 22px" class="mt-1" :src="image_timer_color_black"/></v-flex>

                  <v-flex xs3 sm3>{{item.timer}}</v-flex>

                  <v-flex xs2 sm2>
                    <v-switch inset readonly v-model="item.status" color="#fc9003"></v-switch>
                  </v-flex>

                  <v-flex xs3 sm5>{{item.status == true ? "Bật": "Tắt"}}</v-flex>
                    <v-flex xs2 sm2>
                    <v-list-item class="px-0">
                        <v-icon color="#009688" @click="editItem(item)" class="mr-2">edit</v-icon>
                        <v-icon @click="fnSetMusicStorage({ model: 'PDUTimer', item: item })" class="ml-2">delete_outline</v-icon>
                    </v-list-item>
                  </v-flex>
              </v-list-item>
            </v-list>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-layout row justify-center><strong>Kênh chưa được cài đặt</strong></v-layout>
          </v-list-item-content>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn style="width:90px" :class="onCheckRole()==false?'d-none':''" :color="PDU.teal_color" :loading="PDU['Scene'].isLoading" dark @click="onResetOutled_Auto_inPopUpSetting();save_OutletControl(); dialog_Outlet_settings =false; ">Lưu</v-btn>
          <v-btn color="secondary" :loading="PDU['Scene'].isLoading" @click="onResetOutled_Auto_inPopUpSetting();fnResetListSceneID();dialog_Outlet_settings =false">Thoát</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog time of setting channel monitor-->
    <v-dialog scrollable max-width="500" v-model="dialog_ChannelMonitor_settings" persistent>
      <v-card >
        <v-toolbar flat :color="PDU.teal_color" dense class="toolbar_padding_left white--text"><span>{{PDU['AudioTimeOff'].obj.name}}</span> <span class="ml-1">[{{item_CM_setting.channel_description}}] Cài đặt thông báo</span></v-toolbar>
        <v-card-text class="pt-0 px-0">
          <v-list-item style="min-height: 0px; justify-content: center; padding: 0 8px 0px 20px; background-color: #f6f6f6">
            <v-spacer/>
            <span>Luôn luôn bật</span><v-switch :color="PDU.teal_color" inset style="margin-left: 15px" v-model="item_CM_setting.mode_allway_on"></v-switch>
          </v-list-item>
          <v-list-item style="min-height: 0px; padding: 0 8px" v-if="item_CM_setting.mode_allway_on === false">
            <v-dialog ref="dialog5" width="290px" persistent v-model="item_CM_setting.picker_start" :return-value.sync="item_CM_setting.time_start">
              <template v-slot:activator="{ on }">
                <v-text-field class="Rectangle-5 Nhp-mt-khu mt-4 px-3 mr-1" v-on="on" v-model="item_CM_setting.time_start" label="Thời Gian Bật" readonly required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
              </template>
              <v-time-picker v-if="item_CM_setting.picker_start" :color="PDU.teal_color" format="24hr" v-model="item_CM_setting.time_start">
                <v-spacer></v-spacer>
                <v-btn :color="PDU.teal_color" dark @click="PreventOOh5()">Đồng Ý</v-btn>
                <v-btn color="secondary" @click="item_CM_setting.picker_start = false">Hủy</v-btn>
              </v-time-picker>
            </v-dialog>
            <v-dialog ref="dialog6" width="290px" persistent v-model="item_CM_setting.picker_end" :return-value.sync="item_CM_setting.time_end">
                <template v-slot:activator="{ on }">
                  <v-text-field class="Rectangle-5 Nhp-mt-khu mt-4 px-3 ml-1" v-on="on" v-model="item_CM_setting.time_end" label="Thời Gian Tắt" readonly required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
                </template>
                <v-time-picker v-if="item_CM_setting.picker_end" :color="PDU.teal_color" format="24hr" v-model="item_CM_setting.time_end">
                  <v-spacer></v-spacer>
                  <v-btn :color="PDU.teal_color" dark @click="PreventOOh6()">Đồng Ý</v-btn>
                  <v-btn color="secondary" @click="item_CM_setting.picker_end = false">Hủy</v-btn>
                </v-time-picker>
            </v-dialog>
          </v-list-item>
          <div style="width:1px; height: 20px"></div>
          <span class="ml-2" style="text-decoration: underline;">Nội dung thông báo</span>
          <v-list-item style="min-height: 0px;padding: 0 8px;">
            <v-text-field :color="PDU.teal_color" class="Rectangle-5 Nhp-mt-khu mt-4 px-3" style="width: 100%" label="Khi kênh tắt" v-model="item_CM_setting.message_error"></v-text-field>
          </v-list-item>
          <v-list-item style="min-height: 0px;padding: 0 8px;">
            <v-text-field :color="PDU.teal_color" class="Rectangle-5 Nhp-mt-khu my-4 px-3" style="width: 100%" label="Khi kênh bật trở lại" v-model="item_CM_setting.message_normal"></v-text-field>
          </v-list-item>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer/>
          <!-- <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :disabled="!PDU['AudioTimeOff'].obj.timer" :color="PDU.teal_color" @click="onUpload_ChannelMonitorSettings(); dialog_ChannelMonitor_settings =false">Đồng ý</v-btn> -->
          <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :disabled="!item_CM_setting.message_error || !item_CM_setting.message_normal || (item_CM_setting.mode_allway_on === false && (!item_CM_setting.time_start || !item_CM_setting.time_end))" :color="PDU.teal_color" @click="onUpload_ChannelMonitorSettings1(); dialog_ChannelMonitor_settings =false">Đồng ý</v-btn>
          <v-btn color="secondary" @click="dialog_ChannelMonitor_settings = false">Hủy</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog device information-->
    <v-dialog  max-width="500" height="300px" v-model="BREA.dialog_device_info" persistent>
      <v-card>
        <v-toolbar dense flat  class="toolbar_padding_left white--text" :color="PDU.teal_color"><v-toolbar-title class="subtitle-2 font_size">Thông tin thiết bị</v-toolbar-title></v-toolbar>
        <v-list-item :class="AudioMasterHardwareAvailble1 == true ? '':'d-none'">
          <v-list-item-content class="fontstyle">Bộ nhớ khả dụng ({{PDU[modul].obj && PDU[modul].obj.USBRemain?(Object.values(PDU[modul].obj.USBRemain)[0].Da/1000/1000).toFixed(1):null}}Gb)</v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="fontstyle">
            <v-list-item-title>Phiên bản App ({{PDU[modul].obj && PDU[modul].obj.firmwareVersion?Object.values(PDU[modul].obj.firmwareVersion)[0].Da:null}})</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action :class="onCheckRole() == false?'d-none':''">

          <v-btn :loading="PDU[modul].obj.firmwareUpdateStatus === 'waiting'" small color="teal darken-1" :disabled="!PDU[modul].obj.firmwareAvailable || !this.PDU[this.modul].obj.active" :class="onCheckRole() == false ? 'd-none': (PDU[modul].obj.firmwareAvailable && this.PDU[this.modul].obj.active ? 'flashit  white--text': ' white--text')" @click="dialog_update_firmware = true" >Cập nhật</v-btn>
          
          </v-list-item-action>
        </v-list-item>

        <v-list-item v-if="PDU[modul].obj.firmwareSlaveVersion">
          <v-list-item-content class="fontstyle">
            <v-list-item-title>Phiên bản Firmware ({{PDU[modul].obj && PDU[modul].obj.firmwareSlaveVersion}})</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="fontstyle">Phiên bản Hardware ({{PDU[modul].obj && PDU[modul].obj.hardwareVersion ? Object.values(PDU[modul].obj.hardwareVersion)[0].Da:null}})</v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="fontstyle">Địa chỉ ID ({{PDU[modul].obj.device_id}})</v-list-item-content>

        </v-list-item>
        <v-list-item>
          <v-list-item-content class="fontstyle">Địa chỉ IP ({{PDU[modul].obj.IPaddr}})</v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-spacer/>
          <v-btn  dark :color="PDU.teal_color" @click="BREA.dialog_device_info = false">Thoát</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog comfirm update firmware-->
    <v-dialog max-width="290" v-model="dialog_update_firmware" persistent>
      <v-card>
        <v-toolbar dense flat>
            <v-spacer/>
            <v-btn icon @click="dialog_update_firmware = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-0">
          <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
          <v-list-item>
            <v-layout row justify-center align-center align-content-center>
              <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Bạn có muốn cập nhật?</p>
            </v-layout>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-list-item style="justify-content: center">
            <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :color="PDU.teal_color" dark  @click="onUpdateFirmware()">Đồng ý</v-btn>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog comfirm children refresh-->
    <v-dialog max-width="290" v-model="BREA.dialog_children_refresh" persistent>
      <v-card>
        <v-toolbar dense flat>
            <v-spacer/>
            <v-btn icon @click="BREA.dialog_children_refresh = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-0">
          <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
          <v-list-item>
            <v-layout row justify-center align-center align-content-center>
              <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Bạn có muốn làm mới thiết bị con?</p>
            </v-layout>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-list-item style="justify-content: center">
            <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :color="PDU.teal_color" dark  @click="onRefreshChildDevice(); BREA.dialog_children_refresh = false">Đồng ý</v-btn>
          </v-list-item>
        </v-card-actions>
      </v-card> 
    </v-dialog>
    <!--dialog tiếp tục thêm thiết bị mới-->
    <v-dialog max-width="500" v-model="PDU['NewDevice'].isConfirm" persistent>
          <v-card>
                <v-card-text>Tiếp tục thêm thiết bị mới?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :color="PDU.teal_color" dark  @click="PDU['NewDevice'].isConfirm = false;PDU['NewDevice'].isCreate = true;">Có</v-btn>
                  <v-btn color="secondary" @click="PDU['NewDevice'].isConfirm = false; onDeviceCreateFinished()">Không</v-btn>
                </v-card-actions>
          </v-card> 
    </v-dialog>
    <!--dialog notification only-->
    <v-dialog max-width="290" persistent v-model="PDU.notification.dialog_notification_only">
      <v-card>
        <v-card-text>
          <v-list-item-content><img style="width: 70px; height: 70px" :src="PDU.notification.type"/></v-list-item-content>
          <v-list-item><v-layout row justify-center align-center align-content-center><p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">{{PDU.notification.message}}</p></v-layout></v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-list-item-content :class="PDU.notification.reload == false? '':'d-none'">
            <v-layout row justify-center><v-btn @click="PDU.notification.dialog_notification_only = false" :color="PDU.teal_color" dark>Đồng ý</v-btn></v-layout>
          </v-list-item-content>
          <v-list-item-content :class="PDU.notification.reload == true? '':'d-none'">
            <v-layout row justify-center><v-btn @click="onReload()" :color="PDU.teal_color" dark>Tải lại</v-btn></v-layout>
          </v-list-item-content>
          <v-list-item-content :class="PDU.notification.reload == 'refresh'? '':'d-none'">
            <v-layout row justify-center> <v-btn :loading="PDU['MQTTConnect'].isLoading" @click="onRefreshConnectMQTT()" :color="PDU.teal_color" dark>Đồng ý</v-btn> </v-layout>
          </v-list-item-content>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog confirm undo-->
    <v-dialog max-width="300" v-model="dialog_confirm_redo" persistent>
          <v-card>
                <v-list-item-content><v-icon large :color="undo.is_scene ? PDU.teal_color : ''">undo</v-icon></v-list-item-content>
                <v-list-item><v-layout row justify-center align-center align-content-center>
                  <p :class="undo.is_scene && 'mb-0' || 'd-none'" style="text-align: center">Phục hồi <strong>{{undo.text}}</strong></p>
                  <p :class="!undo.is_scene && 'mb-0' || 'd-none'" style="text-align: center"><strong>{{undo.text}}</strong></p>
                </v-layout></v-list-item>
                  
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" v-if="undo.is_scene" :color="PDU.teal_color" dark @click="onBeforePostUndoScene();dialog_confirm_redo = false">Đồng ý</v-btn>
                  <v-btn color="secondary" @click="onResetOutled_Auto({device_id: undo.device_id, key: undo.key, status: false});dialog_confirm_redo = false;">Hủy</v-btn>
                </v-card-actions>
          </v-card> 
    </v-dialog>
    <!--dialog comfirm change auto mode to normal mode-->
    <v-dialog max-width="290" v-model="dialog_confirm_changeto_normal" persistent>
      <v-card>
        <v-toolbar dense flat>
            <v-spacer/>
            <v-btn icon @click="onResetOutled_Auto({device_id: Object.values(item_outlet)[0].device_id, key: Object.keys(item_outlet)[0], status: true});dialog_confirm_changeto_normal = false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-0">
          <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
          <v-list-item>
            <v-layout row justify-center align-center align-content-center>
              <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Xác nhận chuyển sang chế độ điều khiển tay</p>
            </v-layout>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-list-item style="justify-content: center">
              <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :color="PDU.teal_color" dark  @click="disableScenes_1();dialog_confirm_changeto_normal = false">Đồng ý</v-btn>
          </v-list-item>
        </v-card-actions>
      </v-card> 
    </v-dialog>
    <!--dialog reset auto manual mode notification-->
    <v-dialog max-width="290" persistent v-model="dialog_noti_with_reset_auto_manual">
      <v-card>
        <v-card-text>
          <v-list-item-content><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
          <v-list-item><v-layout row justify-center align-center align-content-center><p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Đang đợi phản hồi từ thiết bị</p></v-layout></v-list-item>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="onResetOutled_Auto_inPopUpSetting();dialog_noti_with_reset_auto_manual = false">Đồng ý</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Device groups-->
    <!--dialog list available device to create group-->
    <v-dialog v-model="GROUP.dialog_group" max-width="299">
      <v-toolbar dense   :color="PDU.teal_color" dark>Lựa chọn
        <v-spacer/><v-btn icon @click="GROUP.dialog_group =false"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card>
        <v-list v-for="(item,i) in GROUP.group_choosen" :key="i">
          <v-list-item avatar role="button" ripple>
            <v-icon left>{{item.icon}}</v-icon><v-list-item-content>{{item.text}}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <!--dialog create group-->
    <v-dialog v-model="GROUP.dialog_add_group" max-width="500" persistent scrollable>
      <v-form @submit.prevent="onCreateGroup(); GROUP.checkbox_creategroup =[]">
      <v-card>
        <v-toolbar flat  dense class="toolbar_padding_left white--text" :color="PDU.teal_color">
          Hãy chọn từ 1 thiết bị trở lên
        </v-toolbar>

        <v-card-text style="height:100%" class="px-3">
          <v-list min-height="250">
            <v-list-item v-for="device in PDU['devices'].list" :key="device.device_id">
              <v-list-item-content><v-list-item-title>{{device.user_device_descriptions || device.device_id}}</v-list-item-title></v-list-item-content>
              <v-list-item-action>
                <v-checkbox :value="device.device_id" v-model="GROUP.checkbox_creategroup" :color="PDU.teal_color"></v-checkbox>
              </v-list-item-action>
            </v-list-item>
            <v-list-item-content><v-text-field class="Rectangle-5 Nhp-mt-khu my-4 px-3" v-if="GROUP.checkbox_creategroup && GROUP.checkbox_creategroup.length >=1" label="Tên nhóm mới" :color="PDU.teal_color"  :rules="[PDU.rules.required]" v-model="GROUP.group.name"></v-text-field></v-list-item-content>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn :disabled="GROUP.checkbox_creategroup && GROUP.checkbox_creategroup.length <1 || !GROUP.group.name" type="submit" :color="PDU.teal_color" class="white--text">Tạo nhóm mới</v-btn>
           <v-btn color="secondary" @click="GROUP.checkbox_creategroup = []; GROUP.dialog_add_group =false">Hủy</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <!--delete group -->
    <v-dialog max-width="290" v-model="PDU['group'].obj.isDel" persistent>
      <v-card>
        <v-toolbar dense flat>
            <v-spacer/>
            <v-btn icon @click="fnCallResetView('group'); GROUP.checkbox_creategroup = []"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-0">
          <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
          <v-list-item>
            <v-layout row justify-center align-center align-content-center>
              <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Bạn có chắc muốn xóa nhóm [{{this.PDU['group'].obj.name}}]?</p>
            </v-layout>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
            <v-list-item style="justify-content: center">
              <v-btn :class="onCheckRole() == false? 'd-none':'white--text justify-center'" :color="PDU.teal_color" dark  @click="onDeleteGroup();fnCallResetView('group'); GROUP.checkbox_creategroup=[]">Đồng ý</v-btn>
            </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog edit group step 1-->
    <v-dialog v-model="GROUP.dialog_edit_group_1" max-width="500" persistent scrollable>
      <v-card v-if="PDU['group'].group_list && PDU['group'].group_list.length">
        <v-toolbar flat  dense class="toolbar_padding_left white--text" :color="PDU.teal_color">
          Chọn nhóm cần sửa đổi
        </v-toolbar>

        <v-card-text style="height:100%" class="px-3">
          <v-list-item  v-for="(groups,i) in PDU['group'].group_list" :key="i" role="button">
            <v-list-item-action class="my-0">
              <v-btn icon @click="fnCallDeleteView({ model: 'group', item: groups })">
                <v-icon color="red">delete_outline</v-icon>
              </v-btn>
            </v-list-item-action>
            
            <v-list-item :class="i%2!=0? 'Rectangle-18': 'Rectangle-18_1'" @click="onFilterGroupSelected(groups)">
              <v-list-item-title>
                {{groups.name}}
              </v-list-item-title>
            </v-list-item>
          </v-list-item>
        </v-card-text>

        <v-card-actions>
          <v-spacer/><v-btn color="secondary" @click="GROUP.dialog_edit_group_1 = false">Thoát</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>Bạn chưa có nhóm</v-card-title>
        <v-card-actions>
          <v-spacer/><v-btn color="secondary" @click="GROUP.dialog_edit_group_1 = false">Hủy</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog edit group step 2-->
    <v-dialog v-model="GROUP.dialog_edit_group_2" max-width="500" persistent scrollable>
      <v-form @submit.prevent="GROUP.dialog_edit_group_3 = true"> 
      <v-card flat scrollable>

        <v-toolbar flat  dense class="toolbar_padding_left white--text" :color="PDU.teal_color">
          Sửa đổi nhóm [{{GROUP.group.name}}]
        </v-toolbar>

         <v-card-text style="height:100%" class="px-3">
          <v-list v-for="(device,i) in PDU['devices'].list" :key="i">
            <v-list-item>
              <v-list-item-content><v-list-item-title>{{device.user_device_descriptions || device.device_id}}</v-list-item-title></v-list-item-content>
              <v-list-item-action>
                <v-checkbox :value="device.device_id" v-model="GROUP.checkbox_creategroup" :color="PDU.teal_color"></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list-item-content><v-text-field :class="GROUP.checkbox_creategroup.length >=1 ? 'Rectangle-5 Nhp-mt-khu mt-4 px-3': 'd-none'" label="Tên nhóm" class="px-2" :color="PDU.teal_color"  :rules="[PDU.rules.required]" v-model="PDU['group'].obj.name"></v-text-field></v-list-item-content>
         </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn :disabled="GROUP.checkbox_creategroup.length==0 ||!PDU['group'].obj.name" :color="PDU.teal_color" class="white--text"  type="submit" >Sửa đổi</v-btn>
          <v-btn color="secondary" @click="GROUP.checkbox_creategroup =[];GROUP.dialog_edit_group_2 = false">Hủy</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <!--dialog edit group step 3-->
    <v-dialog persistent scrollable max-width="290" v-model="GROUP.dialog_edit_group_3">
      <v-card>
        <v-toolbar dense flat>
            <v-spacer/>
            <v-btn icon @click="GROUP.checkbox_creategroup = [];GROUP.dialog_edit_group_3 =false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pt-0">
          <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
          <v-list-item>
            <v-layout row justify-center align-center align-content-center>
              <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Hãy chắc chắn sửa nhóm</p>
            </v-layout>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-list-item style="justify-content: center">
            <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :color="PDU.teal_color" dark  @click="onEditGroup(); GROUP.checkbox_creategroup = []">Đồng ý</v-btn>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialog change password-->
    <v-form v-model="PDU['ChangePass'].isValid" @submit.prevent v-if="PDU['ChangePass'].isUpdate">
      <v-dialog persistent v-model="PDU['ChangePass'].obj.isForm" scrollable max-width="500">
        <v-card>
          <v-toolbar class="toolbar_padding_left white--text" flat  dense :color="PDU.teal_color">Thay đổi mật khẩu <v-spacer /><v-icon color="#fff" @click="fnCallResetView('ChangePass')">close</v-icon></v-toolbar>
              <v-card-text class="cha-pass">
                <v-list-item-content>
                <v-text-field
                  class="Rectangle-5 Nhp-mt-khu my-5 px-3"
                  :color="PDU.teal_color"
                  label="Mật Khẩu Cũ"
                  v-model="PDU['ChangePass'].obj.passwordOld"
                  required
                  :rules="[PDU.rules.required, rules.minPass]"
                  prepend-icon="lock_outline"
                  :append-icon="ePass ? 'visibility_off' : 'visibility'"
                  @click:append="() => (ePass = !ePass)"
                  :type="ePass ? 'password' : 'text'"
                ></v-text-field>
                </v-list-item-content>
                  <v-list-item-content>
                <v-text-field
                  class="Rectangle-5 Nhp-mt-khu my-5 px-3"
                  :color="PDU.teal_color"
                  label="Mật Khẩu Mới"
                  v-model="PDU['ChangePass'].obj.passwordNew"
                  required
                  :rules="[PDU.rules.required, rules.minPass]"
                  prepend-icon="lock_open"
                  :append-icon="ePass ? 'visibility_off' : 'visibility'"
                  @click:append="() => (ePass = !ePass)"
                  :type="ePass ? 'password' : 'text'"
                ></v-text-field>
                </v-list-item-content>
              </v-card-text>
                <v-card-text>
                  <v-list-item style="justify-content:center">
                    <v-btn
                      style="width: 270px; height: 58px"
                      class="white--text"
                      :color="PDU.teal_color"
                      :loading="PDU['ChangePass'].isLoading"
                      :disabled="!PDU['ChangePass'].isValid"
                      @click="fnChangePassword('ChangePass')"
                    >Đồng Ý</v-btn>
                  </v-list-item>
                </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>
  </v-layout>
</template>

<script>
const pause = ms => new Promise(resolve => setTimeout(resolve, ms));
import breadcumbar from "./BreadCumBar";
export default {
  data() {
    return {
      /////////////////////////////////////////////////////////////////////////DIALOG///////////////////////////////////////////////////////////
      dialog_Temper_Humi_warning: false,
      dialog_Sensor_settings: false,
      dialog_Outlet_protect: false,
      dialog_Outlet_settings: false,
      dialog: false,
      dialog_device_info: false,
      dialog_time: false,
      dialog_automation_time1: false,
      dialog_automation_time2: false,
      dialog_update_firmware: false,
      dialog_children_refresh: false,
      dialog_ChannelMonitorUpdateDescription: false,
      dialog_ChannelMonitor_settings: false,
      dialog_notification_only: false,
      dialogPDUSensor: false,
      dialog_setting_PDU_automation: false,
      dialog_NesthouseUpdateNotification: false,
      dialog_confirm_redo: false,
      AutomationLoading: true,
      dialog_confirm_changeto_normal: false,
      dialog_noti_with_reset_auto_manual: false,
      dialogMusic: false,
      dialog_updateSceneNesthouse: false,
      dialogMusicTimer: false,
      dialog_NesthouseUpdateDescription: false,
      dialogTime: false,
      /////////////////////////////////////////////////////////////////////////GENERAL///////////////////////////////////////////////////////////
      tabs_setting_mucsicChannel: "tab-0",
      model: "devices",
      modul: "DeviceDetail",
      camera: "auto",
      shared_username: "",
      timerOff: [],
      value: "",
      time: "",
      undo: {},
      items: [],
      editedIndex: -1,
      editedItem: {
        timer: new Date().getHours() + ":" + new Date().getMinutes(),
      },
      protectItem: {
        Max_check: false,
        Min_check: false,
        MaxCurrent_unit: "mA",
        MinCurrent_unit: "mA",
        notify_content: "",
        status: false,
      },
      TemperHumiItem: {
        temper: {
          check_high: "",
          check_short: "",
          higher: {
            data: 100,
            select: { name: "aaa", id_outlet: "" },
            status: false,
          },
          shorter: {
            data: 100,
            select: { name: "aaa", id_outlet: "" },
            status: false,
          },
        },
        humi: {
          check_high: "",
          check_short: "",
          higher: {
            data: 100,
            select: { name: "aaa", id_outlet: "" },
            status: false,
          },
          shorter: {
            data: 100,
            select: { name: "aaa", id_outlet: "" },
            status: false,
          },
        },
      },
      TemperHumiItemWarning: {
        temper: {
          check_high: "",
          check_short: "",
          higher: {
            data: 35,
            time: { text: "3h", value: 180 },
            message: "Canh bao T > 35 - 3h"
          },
          shorter: {
            data: 20,
            time: { text: "3h", value: 180 },
            message: "Canh bao T < 20 - 3h"
          },
        },
        humi: {
          check_high: "",
          check_short: "",
          higher: {
            data: 90,
            time: { text: "2h", value: 120 },
            message: "Canh bao H > 90 - 2h"
          },
          shorter: {
            data: 80,
            time: { text: "2h", value: 120 },
            message: "Canh bao H < 80 - 2h"
          },
        },
      },
      TemperWarning: [],
      select: "Khách",
      data: {},
      status: false,
      childDevice: [],
      start_state: true,
      item_outlet: {},
      rules: {
        minPass: v =>
          (!!v && v.length >= 6) || "Mật khẩu phải có ít nhất 6 ký tự",
      },
      ePass: true,
      item_CM_setting: {
        picker_start: false,
        picker_end: false,
        time_start: null,
        time_end: null,
        mode_allway_on: true,
        message_error: null,
        message_normal: null,
        message_error_default: null,
        message_normal_default: null,
        channel_description: null,
        status_key: null,
        noti_content_key: null
      },
      list_sceneID_Notify_Content_ON: [],
      list_sceneID_Notify_Content_OFF: [],
      /////////////////////////////////////////////////////////////////////////NESTHOUSE///////////////////////////////////////////////////////////
      NesthouseTimerItem: {
        music_name: null,
        music_volume: 0,
        timer: new Date().getHours() + ":" + new Date().getMinutes(),
        status: true,
        id: "",
      },
      NesthouseTimerArr: [],
      NesthouseList: [],
      ChMoUsedItem: false,
      editedNesthouseIndex: -1,
      tabs: null,
      tabs_temper: null,
      tabs_warning: null,
      /////////////////////////////////////////////////////////////////////////IMAGE///////////////////////////////////////////////////////////
      image_dropnet: require("@/assets/water.svg"),
      image_dropnet_color: require("@/assets/water_color.svg"),
      image_dropnet_yellow: require("@/assets/water_yellow.svg"),
      image_temper: require("@/assets/temper.svg"),
      image_temper_yellow: require("@/assets/temper_yellow.svg"),
      image_temper_green: require("@/assets/temper_green.svg"),
      image_setting_color: require("@/assets/setting_color.svg"),
      image_setting_color_white: require("@/assets/setting_color_white.svg"),
      image_setting_color_red: require("@/assets/setting_color_red.svg"),
      image_setting: require("@/assets/setting.svg"),
      image_warning_color: require("@/assets/warning_color.svg"),
      image_warning: require("@/assets/warning.svg"),
      image_power: require("@/assets/power.svg"),
      image_clock: require("@/assets/clock.svg"),
      image_clock_color: require("@/assets/clock_color.svg"),
      image_edit: require("@/assets/edit.svg"),
      image_edit_color: require("@/assets/edit-color.svg"),
      image_edit_white: require("@/assets/edit_white.svg"),
      image_edit_green: require("@/assets/edit_green.svg"),
      image_settings: require("@/assets/settings.svg"),
      image_timer: require("@/assets/timer.svg"),
      image_timer_color_grey: require("@/assets/timer_color_grey.svg"),
      image_timer_color_black: require("@/assets/timer_color_black.svg"),
      image_timer_color_green: require("@/assets/timer_color_green.svg"),
      image_volume_up_yellow: require("@/assets/volume_up_yellow.svg"),
      image_volume_up_blue: require("@/assets/volume_up_blue.svg"),
      image_volume_off_red: require("@/assets/volume_off_red.svg"),
      image_volume_up_grey: require("@/assets/volume_up_grey.svg"),
      image_volume_offline: require("@/assets/volume_offline.svg"),
      image_err_yellow: require("@/assets/err_yellow.svg"),
      image_green_circle: require("@/assets/green_circle.svg"),
      image_red_circle: require("@/assets/red_circle.svg"),
      image_grey_circle: require("@/assets/grey_circle.svg"),
    };
  },
  components: {
    breadcumbar,
  },
  created() {
    this.PDU["Sensor"].obj.editedItem = {
      ...this.PDU["Sensor"].obj.editedItem,
      dialog: false,
      timer: "00:00",
      status: false,
      dialog_time: false,
      isDel: false,
    }
    localStorage.removeItem('REB')
  },
  methods: {
    async gotoDeviceDetail() {
      this.fnCallResetView(this.modul);

      await this.fnRead(this.model);

      if (this.PDU["devices"].list.length > 0) {
        var device = this.PDU["devices"].list[0];
        await this.fnRead("DevicesAttributes");
        await this.onDeviceDetail({
          device: device,
          hardware: device.hardware,
        });
        var hardware = device.hardware;
        this.PDU.breadcrumbs = [];

        this.PDU.breadcrumbs.push({
          text: device.user_device_descriptions || device.device_id,
          disable: false,
          device: device,
          hardware: hardware,
        });
        if (hardware == 3 || hardware == 10 || hardware == 20 || hardware == 11) {
          this.PDU.breadcrumbs.push({
            text: "Điều khiển âm ly",
            disable: false,
            device: device,
            hardware: hardware,
          });
        }
      } else {
        this.PDU["DeviceDetail"].isLoading = true;
      }
      await this.fnRead("group");
    },
    onCheckManualReboot () {
      var arrREB = []
      var arrManualReboot = localStorage.getItem('REB')
      arrREB = arrManualReboot ? JSON.parse(arrManualReboot) : []
      if (arrREB && arrREB.length > 0) {
        var index = arrREB.findIndex(e => { return e.device_id === this.PDU[this.modul].obj.device_id })
        if (index >= 0) {
          this.$set(this.PDU[this.modul].obj, 'isManualReboot', true)
          this.fnToastNotificationOnly({
            message: "Thiết bị đang khởi động lại",
            type: "error",
            reload: false
          });
          return true
        }
      }
      return false
    },
    onCheckDeviceOnlineAvailble() {
      if (
        !this.PDU[this.modul].obj.active ||
        this.PDU[this.modul].obj.USBFormat == "waiting" ||
        this.PDU[this.modul].obj.firmwareUpdateStatus == "waiting" ||
        (this.AudioMasterHardwareVersionAvailble == true &&
          (this.PDU[this.modul].obj.musicProgress >= 0 &&
            this.PDU[this.modul].obj.musicProgress != 100)) ||
        this.PDU[this.modul].obj.childRefresh == "waiting"
      ) {
        this.fnToastNotificationOnly({
          message: this.MES.deviceAvailble,
          type: "error",
          reload: false,
        });

        return false;
      } else {
        return true;
      }
    },
    onCheckchildDeviceOnlineAvailble(id) {
      var device = this.PDU["DeviceDetail"].obj.child_attribute.find(
        e => e.device_id == id
      );
      if (device) {
        if (!device.active || !this.PDU[this.modul].obj.active) {
          // this.fnToast({
          //   message:
          //     this.MES.deviceAvailble,
          //   type: "error"
          // });
          this.fnToastNotificationOnly({
            message:
              this.MES.deviceAvailble,
            type: "error",
            reload: false,
          });
          return false;
        } else return true;
      } else return true;
    },
    onDecode(data) {
      let namedevice = "nesthouse";
      if (data.endsWith(namedevice)) {
        let mac = data.split(namedevice)[0];

        var len = mac.length;
        if (len <= 12) {
          mac = mac.concat("ffde");
        }

        this.PDU["NewDevice"].obj = {
          ...this.PDU["NewDevice"].obj,
          mac,
          paused: true,
        };
      }
    },
    onCheckRole() {
      if (
        this.PDU[this.modul].obj.user_role &&
        this.PDU[this.modul].obj.user_role == "guest"
      ) {
        return false;
      } else return true;
    },
    async onRefreshConnectMQTT() {
      this.PDU["MQTTConnect"].isLoading = true;

      this.PDU[this.model].list.forEach(device => {
        this.fnIotSubscribe({ device_id: device.device_id, unSub: true });
      });
      if (this.PDU["DeviceDetail"].obj.childDevice) {
        await pause(1000);
        this.PDU[this.modul].obj.child_attribute.forEach(device => {
          this.fnIotSubscribe({ device_id: device.device_id, unSub: true });
        });
      }

      this.PDU[this.model].list.forEach(device => {
        this.fnIotSubscribe({ device_id: device.device_id, unSub: false });
      });
      if (this.PDU["DeviceDetail"].obj.childDevice) {
        await pause(1000);
        this.PDU[this.modul].obj.child_attribute.forEach(device => {
          this.fnIotSubscribe({ device_id: device.device_id, unSub: false });
        });
      }

      // if(this.PDU['MQTTConnect'].count > 1){
      //   await pause(2000)
      //   this.PDU['MQTTConnect'].isLoading = false
      //   this.PDU.notification.dialog_notification_only = false
      //   this.fnToastNotificationOnly({message: "Không thể thiết lập kết nối. Vui lòng tải lại trang", type: 'error', reload: true})
      //   return;
      // }

      // this.PDU['MQTTConnect'].count ++ ;

      this.PDU["MQTTConnect"].isLoading = false;
      this.PDU.notification.dialog_notification_only = false;
    },
    onChangeSettingChannelMucsic() {
      this.NesthouseTimerItem.music_name = null;

      switch (this.tabs_setting_mucsicChannel) {
        case "tab-0":
          {
            this.NesthouseTimerItem.status = true;
          }
          break;
        case "tab-1":
          {
            this.NesthouseTimerItem.status = false;
          }
          break;
      }
    },
    onRandomMinute() {
      var arr = [],
        arr_1 = [];
      var MAX_p = 59,
        MAX_h = 23;

      for (var i = 5; i <= MAX_p; i++) {
        arr.push({ text: `${i} phút`, value: i });
      }

      for (var i = 1; i <= MAX_h; i++) {
        arr_1.push({ text: `${i} giờ`, value: i * 60 });
      }

      var arr_2 = arr.concat(arr_1);
      return arr_2;
    },
    onBeforeCreatePopUpAutomation() {
      if (
        (this.PDU["DeviceAttribute"].obj &&
          Object.values(this.PDU["DeviceAttribute"].obj)[0] &&
          Object.values(this.PDU["DeviceAttribute"].obj)[0].list_scene_timer
            .length > 0) ||
        this.items.length > 0
      ) {
        this.fnToastNotificationOnly({
          message: "Cài đặt vòng lặp chỉ thực hiện khi kênh trống",
          type: "error",
          reload: false,
        });
        return;
      }

      this.PDU["PDU_automation"].obj.start_time = "00:01";
      this.PDU["PDU_automation"].obj.stop_time = "23:59";

      this.PDU["PDU_automation"].obj.list = this.onRandomMinute() || [];

      this.dialog_setting_PDU_automation = true;
    },

    async onDeviceCreate() {
      try {
        this.PDU["NewDevice"].isLoading = true;
        var name =
          this.PDU["NewDevice"].obj.user_device_descriptions ||
          this.PDU["NewDevice"].obj.mac;
        var index = this.PDU[this.model].list.findIndex(e => { return e.user_device_descriptions === name })
        if (index >= 0) {
          this.fnToast({
            message: `${name} đã tồn tại`,
            type: "error",
          });
          this.PDU["NewDevice"].isConfirm = false;
          this.PDU["NewDevice"].isLoading = false;
          return
        }
        var attribute = {
          device_list: [
            {
              user_name: this.PDU.userSession.username,
              device_id: this.PDU["NewDevice"].obj.mac,
              user_role: "owner",
              user_device_descriptions: name,
            },
          ],
        };

        await this.fnCreate(attribute);
        this.PDU[this.modul].isLoading = false;

        this.fnCallResetView("NewDevice");
        this.PDU["NewDevice"].isLoading = false;

        /////////

        await this.onDeviceCreateFinished();
      } catch (err) {
        console.log(err);
        this.PDU["NewDevice"].isConfirm = false;
        this.PDU["NewDevice"].isLoading = false;
        this.PDU["NewDevice"].isCreate = false;
      }
    },
    async onDeviceDelete() {
      try {
        var device_sub = this.fnCopy(this.PDU[this.modul].obj);
        var arr = [];
        if (this.PDU[this.model].list.length == 1) {
          var childDevice = this.PDU[this.modul].obj.childDevice;
          childDevice.forEach(child => {
            this.fnIotSubscribe({ device_id: child, unSub: true });
          });
        }

        await this.fnDelete(this.model);

        this.fnCallResetView(this.model);
        await this.gotoDeviceDetail();
      } catch (err) {
        console.log(err);
      }
    },
    async onDeviceChildDelete() {
      var detail_device_id = this.PDU[this.modul].obj.device_id;
      var child_device_id = this.PDU[this.model].obj.device_id
      var parent_device_id = this.PDU[this.model].obj.parent_device_id || this.PDU[this.model].obj.parent_id;
      if (
        (child_device_id === parent_device_id) || this.PDU[this.model].obj.hardware == 7
      ) {
        this.onDeviceDelete();
      } else {
        var availble = this.onCheckDeviceOnlineAvailble();
        if (availble) {
          if (this.onCheckManualReboot() === false) {
            var childDevice = this.PDU[this.modul].obj.childDevice || [];
            var list_child = this.fnCopy(childDevice);
            var idx = list_child.findIndex(e => {
              return e == this.PDU[this.model].obj.device_id;
            });

            if (this.onCheckRole() == false) {
              this.fnToastNotificationOnly({
                message: "Không được phép xóa!",
                type: "error",
                reload: false,
              });
              return;
            }

            if (idx >= 0) {
              list_child.splice(idx, 1);
            } else {
              this.fnToastNotificationOnly({
                message: "Không tìm thấy thiết bị!",
                type: "error",
                reload: false,
              });
              return;
            }

            var obj = { 192: { Dt: "ARRAY", Da: list_child } };

            var attribute_post = Object.assign({}, obj, {
              device_id: detail_device_id,
            });

            var attribute = {
              user_name: this.PDU.userSession.username,
              attribute_list: [attribute_post],
            };

            await this.fnIotPost(attribute).then(data => {
              if (data.success == true) {
                this.fnToast({
                  message: `Xóa thiết bị thành công`,
                  type: "success",
                });

                this.onDeleteScenesByChildDevice({
                  hardware: this.PDU[this.model].obj.hardware,
                  device_id: this.PDU[this.model].obj.device_id,
                });
              }
            });

            this.fnCallResetView(this.model);
            await this.fnRead("DevicesAttributes");
            var device_before_reload = localStorage.getItem("DeviceDetail");
            if (device_before_reload != null) {
              var device_before_reload_splited = device_before_reload.split("/");
              var device_after_finding = this.PDU["devices"].list.find(e => {
                return e.device_id == device_before_reload_splited[0];
              });

              if (device_after_finding)
                await this.onDeviceDetail({
                  device: device_after_finding,
                  hardware: device_before_reload_splited[1],
                });
            } else {
              await this.onDeviceDetail({
                device: this.PDU["devices"].list[0],
                hardware: 3,
              });
            }
          }
        }
      }
    },
    async onDeleteScenesByChildDevice({
      hardware: hardware,
      device_id: device_id,
    }) {
      var attribute_push = [];
      const searching = (list, key) => list.find(i => Object.keys(i)[0] == key);
      var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);

      scenes.forEach(scene => {
        var data = Object.values(scene)[0].Da;

        if (!this.isEmpty(data)) {
          var output = Object.values(scene)[0].Da.output
            ? Object.values(scene)[0].Da.output
            : [];

          var input = Object.values(scene)[0].Da.input
            ? Object.values(scene)[0].Da.input
            : [];

          for (let [k, v] of Object.entries(output)) {
            if (v.device_id == device_id) {
              attribute_push.push(scene);
              return;
            }
            if (hardware == 6) {
              if ((v["832"] && v["832"].Da) || (v["896"] && v["896"].Da)) {
                var da = (v["832"] && v["832"].Da) || (v["896"] && v["896"].Da);

                var sc = searching(scenes, da);

                if (sc) {
                  //tim duoc scene nhiet do, do am

                  var ip = Object.values(sc)[0].Da.input;

                  for (var j in ip) {
                    if (ip[j] && ip[j].device_id == device_id) {
                      attribute_push.push(scene);
                      return;
                    }
                  }
                }
              }
            }
          }

          if (hardware == 6) {
            for (let [k, v] of Object.entries(input)) {
              if (v.device_id == device_id) {
                attribute_push.push(scene);
                return;
              }
            }
          }
        }
      });

      if (attribute_push.length > 0) {
        for (var i in attribute_push) {
          this.$set(Object.values(attribute_push[i])[0], "Da", {});
        }

        var obj = attribute_push.reduce(function(result, item) {
          var key = Object.keys(item)[0];
          result[key] = item[key];
          return result;
        });

        var attribute_post = Object.assign({}, obj, {
          device_id: this.PDU[this.modul].obj.device_id,
        });

        var attribute = {
          user_name: this.PDU.userSession.username,
          attribute_list: [attribute_post],
        };

        await this.fnIotPost(attribute);
      }
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        console.log(error);
        this.PDU[this.model].obj = { ...this.PDU[this.model].obj, error: "" };
        if (error.name === "NotAllowedError") {
          this.PDU[this.model].obj.error = "Bạn cần cấp quyền truy cập camera";
        } else if (error.name === "NotFoundError") {
          this.PDU[this.model].obj.error = "Thiết bị này không có camera";
        } else if (error.name === "NotSupportedError") {
          this.PDU[this.model].obj.error =
            "Secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.PDU[this.model].obj.error = "The camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.PDU[this.model].obj.error = "Installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.PDU[this.model].obj.error =
            "Vui lòng mở bằng trình duyệt Safari";
        }
      }
    },
    async onDeviceCreateFinished() {
      await pause(3000);
      await this.fnRead(this.model);
      await this.fnRead("DevicesAttributes");
      if (this.PDU[this.model].list.length > 0) {
        var device = this.PDU[this.model].list[0];
        await this.onDeviceDetail({
          device: device,
          hardware: device.hardware,
        });
      } else {
        this.PDU[this.modul].isLoading = true;
      }
    },
    onResetAuditoStatusDecription() {
      for (var i in this.list_audio_status) {
        this.$set(
          Object.values(this.list_audio_status[i])[0],
          "notify_content",
          ""
        );
        this.$set(
          Object.values(this.list_audio_status[i])[0],
          "notify_content1",
          ""
        );
      }
    },

    onResetOutled_Auto({ device_id: device_id, key: key1, status: status }) {
      var list = this.device_outled;
      var device = list.find(e => {
        return e.device_id == device_id;
      });
      for (var i in device.list_outled) {
        var outled = device.list_outled[i];
        var outled = device.list_outled[i];

        var key = Object.keys(outled)[0];

        if (key == key1) {
          this.$set(Object.values(outled)[0], "Auto", status);

          return;
        }
      }
    },
    onResetOutled_Auto_inPopUpSetting() {
      var item = this.fnCopy(this.PDU["DeviceAttribute"].obj);
      var key1 = item != {} && Object.keys(item)[0];
      var device_id = key1 && Object.values(item)[0].device_id;

      var list = this.device_outled;

      if (device_id) {
        var device = list.find(e => {
          return e.device_id == device_id;
        });
        for (var i in device.list_outled) {
          var outled = device.list_outled[i];
          var outled = device.list_outled[i];

          var key = Object.keys(outled)[0];

          if (key == key1) {
            if (
              !Object.values(outled)[0] ||
              (Object.values(outled)[0].list_scene_timer &&
                Object.values(outled)[0].list_scene_timer.length == 0 &&
                Object.values(outled)[0].list_scene_temper &&
                Object.values(outled)[0].list_scene_temper.length == 0)
            ) {
              this.$set(Object.values(outled)[0], "Auto", false);
              return;
            } else {
              this.$set(Object.values(outled)[0], "Auto", true);
              return;
            }
          }
        }
      }
    },

    async onsetAttribute(res) {
      try {
        if (Object.values(res)[0].timeout == false) {
          var avail = this.onCheckchildDeviceOnlineAvailble(
            Object.values(res)[0].device_id
          );
          if (avail) {
            if (this.onCheckRole() == false) {
              this.fnToastNotificationOnly({
                message: "Bạn chưa được cấp quyền truy cập điều khiển!",
                type: "warning",
                reload: false,
              });
              return;
            }

            if (res && Object.values(res)[0].Auto) {
              this.fnToastNotificationOnly({
                message: "Xin vui lòng chuyển sang chế độ Thường để tiếp tục!",
                type: "warning",
                reload: false,
              });
              return;
            }

            var obj = this.fnCopy(res);
            var value = Object.values(obj)[0];
            var id =
              this.fnCopy(Object.values(obj)[0].device_id) ||
              this.PDU[this.modul].obj.device_id;
            value.Da = !value.Da;
            Object.values(obj)[0] = {
              ...Object.values(obj)[0],
              Dt: value.Dt,
              Da: value.Da,
            };

            delete Object.values(obj)[0].Auto;
            delete Object.values(obj)[0].list_scene_temper;
            delete Object.values(obj)[0].list_scene_timer;
            delete Object.values(obj)[0].device_id;
            delete Object.values(obj)[0].attribute_descriptions;
            delete Object.values(obj)[0].Pe;
            delete Object.values(obj)[0].Cl;
            delete Object.values(obj)[0].Re;
            delete Object.values(obj)[0].Un;
            delete Object.values(obj)[0].Na;

            var data = Object.assign({}, obj, { device_id: id });
            var obj1 = {
              user_name: this.PDU.userSession.username,
              attribute_list: [data],
            };

            this.$set(Object.values(res)[0], "timeout", true);
            await this.fnIotPost(obj1);

            if (Object.values(res)[0].timeout == true) {
              await pause(10000);
              if (Object.values(res)[0].timeout == true) {
                // this.fnToast({message: "Thao tác thất bại", type: 'error'})
                this.$set(Object.values(res)[0], "timeout", false);
              }
              return;
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    onCallUpdateView(data) {
      try {
        if (
          (data.active == true || data.active == false) &&
          data.hardware == 4 &&
          !data.hardwareVersion
        ) {
          this.$set(
            this.PDU["PDU_description"].obj,
            "attribute_descriptions",
            data.attribute_descriptions
          );
          this.$set(
            this.PDU["PDU_description"].obj,
            "list_attribute_descriptions",
            data.list_attribute_descriptions
          );
          this.$set(
            this.PDU["PDU_description"].obj,
            "device_id",
            data.device_id
          );
          this.$set(
            this.PDU["PDU_description"].obj,
            "attribute_descriptions_copy",
            data.attribute_descriptions
          );

          this.PDU["PDU_description"].obj.isNameDevice = true;
        } else if (
          (data.active == true || data.active == false) &&
          data.hardwareVersion &&
          data.hardwareVersion["64"]
        ) {
          var attribute = {
            user_device_descriptions:
              data.user_device_descriptions || data.device_id,
          };
          this.PDU[this.model].obj = this.fnCopy(attribute);
          this.PDU[this.model].obj.isNameDevice = true;
        } else {
          this.PDU["DeviceAttribute"].obj = this.fnCopy(data);
          this.PDU["DeviceAttribute"].obj.isNameDevice = true;
        }
      } catch (err) {
        this.fnToast({ message: "Có lỗi xảy ra. Vui lòng thử lại sau!" });
        return;
      }
    },
    async onDeviceUpdate() {
      this.PDU[this.model].user_device_descriptions = this.PDU["NewDevice"].obj.user_device_descriptions ||this.PDU[this.model].obj.user_device_descriptions;

      var index = this.PDU[this.model].list.findIndex(e => { return e.user_device_descriptions === this.PDU[this.model].user_device_descriptions })
      if (index >= 0) {
        this.fnToast({
          message: `${this.PDU[this.model].user_device_descriptions} đã tồn tại`,
          type: "error",
        });
        this.PDU[this.model].obj.isNameDevice = false
        return
      }
      var id = this.PDU[this.modul].obj.device_id;
      var device = this.PDU[this.model].list.find(e => { return e.device_id == id })
      if (device) {
        var attribute = {
          device_list: [
            {
              user_name: this.PDU.userSession.username,
              user_device_descriptions: this.PDU[this.model].obj
                .user_device_descriptions,
              device_id: id,
              user_role: device.user_role,
            },
          ],
        }
        await this.fnUpdate_user_device_des(attribute);
        this.PDU[this.model].obj.isNameDevice = false;
      } else {
        this.fnToast({
          message: "Có lỗi, vui lòng thử lại sau!",
          type: "error",
        })
        this.PDU[this.model].obj.isNameDevice = false
        return
      }
    },
    async onAttributeUpdate(model) {
      var data = {};
      var obj_key = Object.keys(this.PDU[model].obj).find(key => {
        return key != "isNameDevice";
      });
      var obj_value = Object.values(this.PDU[model].obj).find(
        key => typeof key != "boolean"
      );

      var arr = this.PDU[this.modul].obj.child_attribute.filter(e => {
        return obj_value.hardware == e.hardware;
      });
      for (var i in arr) {
        if (arr[i].device_id == obj_value.device_id) {
          data = arr[i].list_attribute_descriptions;

          data[obj_key] = obj_value.attribute_descriptions;
        }
      }

      var attribute = {
        user_name: this.PDU.userSession.username,
        attribute_list: [
          {
            attribute_descriptions: {
              Da: data,
              Dt: "OBJECT",
            },
            device_id:
              obj_value.device_id || this.PDU[this.modul].obj.device_id,
          },
        ],
      };

      await this.fnUpdate_AttributeDescriptions(attribute).then(data => {
        if (data["success"] == true) {
          //set attribute
          try {
            for (var i in this.PDU[this.modul].obj.child_attribute) {
              var item = this.PDU[this.modul].obj.child_attribute[i];

              if (item.list_outled && item.list_outled.length > 0) {
                var idx_outled_nesthouse = item.list_outled.findIndex(e => {
                  return (
                    Object.values(e)[0].device_id == obj_value.device_id &&
                    Object.keys(e)[0] == obj_key
                  );
                });

                if (idx_outled_nesthouse != -1) {
                  this.$set(
                    Object.values(item.list_outled[idx_outled_nesthouse])[0],
                    "attribute_descriptions",
                    obj_value.attribute_descriptions
                  );
                  this.fnToast({
                    message: "Thao tác thành công",
                    type: "success",
                  });
                  return;
                }
              }
              if (
                item.device_id == obj_value.device_id &&
                item.list_temper &&
                item.list_temper.length > 0
              ) {
                var idx_temper_pdu = item.list_temper.findIndex(e => {
                  return Object.keys(e)[0] == obj_key;
                });

                if (idx_temper_pdu != -1) {
                  this.$set(
                    Object.values(item.list_temper[idx_temper_pdu])[0],
                    "attribute_descriptions",
                    obj_value.attribute_descriptions
                  );
                  this.fnToast({
                    message: "Thao tác thành công",
                    type: "success",
                  });
                  return;
                }
              }
            }
            if (
              this.PDU[this.modul].obj.list_outled &&
              this.PDU[this.modul].obj.list_outled.length > 0
            ) {
              var idx_outled_pdu = this.PDU[
                this.modul
              ].obj.list_outled.findIndex(e => {
                return Object.keys(e)[0] == obj_key;
              });

              if (idx_outled_pdu != -1) {
                this.$set(
                  Object.values(
                    this.PDU[this.modul].obj.list_outled[idx_outled_pdu]
                  )[0],
                  "attribute_descriptions",
                  obj_value.attribute_descriptions
                );
                this.fnToast({
                  message: "Thao tác thành công",
                  type: "success",
                });
                return;
              }
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          this.fnToast({
            message: "Có lỗi xảy ra. Vui lòng thử lại sau!",
            type: "error",
          });
          return;
        }
      });
    },
    async onAttributeUpdatePDU(model) {
      var data = new Object();
      var arr = {};
      data["PDU_name"] = this.PDU[
        "PDU_description"
      ].obj.attribute_descriptions_copy;
      var device_id = this.fnCopy(this.PDU["PDU_description"].obj.device_id);
      var description = this.fnCopy(data["PDU_name"]);

      arr = Object.assign(
        {},
        this.PDU[model].obj.list_attribute_descriptions,
        data
      );

      var attribute = {
        user_name: this.PDU.userSession.username,
        attribute_list: [
          {
            attribute_descriptions: {
              Da: arr,
              Dt: "OBJECT",
            },
            device_id:
              this.PDU["PDU_description"].obj.device_id ||
              this.PDU[this.modul].obj.device_id,
          },
        ],
      };

      await this.fnUpdate_AttributeDescriptions(attribute).then(data => {
        if (data["success"] == true) {
          for (var i in this.PDU[this.modul].obj.child_attribute) {
            var item = this.PDU[this.modul].obj.child_attribute[i];

            if (item.device_id == device_id) {
              this.fnToast({ message: "Thao tác thành công", type: "success" });
              this.$set(item, "attribute_descriptions", description);
            }
          }
        }
      });

      return;
    },
    async onOpenListSharing() {
      await this.fnRead("ShareDevice");
      this.BREA.dialogSharing = true;
    },
    async onShareDevice() {
      const { device_id } = this.PDU["ShareDevice"].obj;
      var share_list = [
        {
          shared: this.shared_username,
          device_id: device_id,
          user_role: this.select == "Khách" ? "guest" : "admin",
        },
      ];
      await this.fnIotPut_ShareDevice({ share_list: share_list });
    },
    async onDeleteSharing() {
      this.PDU["ShareDevice"].obj = {
        device_id: this.PDU["ShareDevice"].obj.device_id,
        user_name: this.PDU.userSession.username,
        shared: this.PDU["ShareDevice"].obj.shared,
      };
      await this.fnDelete("ShareDevice");
      await this.onOpenListSharing();
    },

    async onRefreshChildDevice() {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          var data = {
            Dt: "BOOLEAN",
            Da: true,
          };
          var obj = {
            user_name: this.PDU.userSession.username,
            attribute_list: [
              { "960": data, device_id: this.PDU[this.modul].obj.device_id },
            ],
          };
          this.$set(this.PDU[this.modul].obj, "childRefresh", "waiting");

          await this.fnIotPost(obj);

          document.getElementById("timer").innerHTML = 1 + ":" + 30;
          this.onCountDownTimer();
        }
      }
    },

    initialize(data) {
      if (data.timer != "00:00") {
        this.items.push({
          // status: data.status == true ? "BẬT" : "TẮT",
          status: data.status,
          timer: data.timer,
          scene00: data.scene00,
          id: data.id,
          input: data.input,
          output: data.output,
          enb: data.enb,
        });
      }
      this.items_copy.push({
        // status: data.status == true ? "BẬT" : "TẮT",
        status: data.status,
        timer: data.timer,
        scene00: data.scene00,
        id: data.id,
        input: data.input,
        output: data.output,
        enb: data.enb,
      });
    },
    initialize_PDUSensor({ in: data, out: sensor }) {
      if (data.timer != "00:00") {
        sensor.PDUList.push({
          status: data.status,
          timer: data.timer,
          scene00: data.scene00 || false,
          id: data.id,
          input: data.input,
          output: data.output,
          enb: data.enb,
        });
      }
      sensor.PDUList_copy.push({
        status: data.status,
        timer: data.timer,
        scene00: data.scene00 || false,
        id: data.id,
        input: data.input,
        output: data.output,
        enb: data.enb,
      });
    },
    initialize_OutletProtect(data) {
      this.protectItem = {
        MaxCurrent_unit: "mA",
        MinCurrent_unit: "mA",
        notify_content: `Thiết bị [${this.PDU[this.modul].obj
          .user_device_descriptions ||
          this.PDU[this.modul].obj.device_id}]:  ${Object.values(
          this.PDU["DeviceAttribute"].obj
        )[0].attribute_descriptions ||
          Object.values(this.PDU["DeviceAttribute"].obj)[0].Na} có vấn đề`,
      };
      var x = this.fnCopy(data);
      var obj = {};
      if (data.length > 1) {
        if (x[0].GT && x[1].LT) {
          this.protectItem = {
            ...this.protectItem,
            Max_check: true,
            Min_check: true,
            MaxCurrent_unit: x[0].GT.unit ? x[0].GT.unit : "mA",
            MinCurrent_unit: x[1].LT.unit ? x[1].LT.unit : "mA",
            MaxCurrent_content: x[0].GT.data ? x[0].GT.data : null,
            MinCurrent_content: x[1].LT.data ? x[1].LT.data : null,
            notify_content: x[0].GT.content,
            id: x[0].id,
          };
          return;
        } else if (x[1].GT && x[0].LT) {
          this.protectItem = {
            ...this.protectItem,
            Max_check: true,
            Min_check: true,
            MaxCurrent_unit: x[1].GT.unit ? x[1].GT.unit : "mA",
            MinCurrent_unit: x[0].LT.unit ? x[0].LT.unit : "mA",
            MaxCurrent_content: x[1].GT.data ? x[1].GT.data : null,
            MinCurrent_content: x[0].LT.data ? x[0].LT.data : null,
            notify_content: x[1].GT.content,
            id: x[1].id,
          };
          return;
        }
      } else if (data.length == 1) {
        if (x[0].GT) {
          this.protectItem = {
            ...this.protectItem,
            Max_check: true,
            MaxCurrent_unit: x[0].GT.unit ? x[0].GT.unit : "mA",
            MinCurrent_unit: "mA",
            MaxCurrent_content: x[0].GT.data ? x[0].GT.data : null,
            MinCurrent_content: null,
            notify_content: x[0].GT.content,
            id: x[0].id,
          };
          return;
        } else if (x[0].LT) {
          this.protectItem = {
            ...this.protectItem,
            Min_check: true,
            MaxCurrent_unit: "mA",
            MinCurrent_unit: x[0].LT.unit ? x[0].LT.unit : "mA",
            MaxCurrent_content: null,
            MinCurrent_content: x[0].LT.data,
            notify_content: x[0].LT.content,
            id: x[0].id,
          };
          return;
        }
      }
    },
    initialize_TemperControl(arr) {
      if (arr.length) {
        var x = this.fnCopy(arr);
        var obj = {};
        var day = [];
        var timer = "0.0";

        arr.forEach(e => {
          if (e.GT) {
            this.TemperHumiItem.temper = {
              ...this.TemperHumiItem.temper,
              check_high: e.GT,
              wday: e.wday.map(e => this.onConvertDay(e)),
              sta_dmin: e.sta_dmin == "24:00" ? "00:00" : e.sta_dmin,
              sto_dmin: e.sto_dmin == "24:00" ? "23:59" : e.sto_dmin,
              outlet_sceneID: e.outlet_sceneID,
              select: e.select,
              higher: {
                data: e.data,

                status: e.status,
                old_scene: e.old_scene,
                id: e.id,
              },
            };
          }
          if (e.LT) {
            this.TemperHumiItem.temper = {
              ...this.TemperHumiItem.temper,
              check_short: e.LT,
              wday: e.wday.map(e => this.onConvertDay(e)),
              sta_dmin: e.sta_dmin == "24:00" ? "00:00" : e.sta_dmin,
              sto_dmin: e.sto_dmin == "24:00" ? "23:59" : e.sto_dmin,
              outlet_sceneID: e.outlet_sceneID,
              select: e.select,
              shorter: {
                data: e.data,

                status: e.status,
                old_scene: e.old_scene,
                id: e.id,
              },
            };
          }
        });
      }
    },
    initialize_HumiControl(arr) {
      if (arr.length) {
        var x = this.fnCopy(arr);

        var obj = {};
        arr.forEach(e => {
          if (e.GT) {
            this.TemperHumiItem.humi = {
              ...this.TemperHumiItem.humi,
              check_high: true,
              wday: e.wday.map(e => this.onConvertDay(e)),
              sta_dmin: e.sta_dmin == "24:00" ? "00:00" : e.sta_dmin,
              sto_dmin: e.sto_dmin == "24:00" ? "23:59" : e.sto_dmin,
              outlet_sceneID: e.outlet_sceneID,
              select: e.select,
              higher: {
                data: e.data,

                status: e.status,
                old_scene: e.old_scene,
                id: e.id,
              },
            };
          }
          if (e.LT) {
            this.TemperHumiItem.humi = {
              ...this.TemperHumiItem.humi,
              check_short: true,
              wday: e.wday.map(e => this.onConvertDay(e)),
              sta_dmin: e.sta_dmin == "24:00" ? "00:00" : e.sta_dmin,
              sto_dmin: e.sto_dmin == "24:00" ? "23:59" : e.sto_dmin,
              outlet_sceneID: e.outlet_sceneID,
              select: e.select,
              shorter: {
                data: e.data,

                status: e.status,
                old_scene: e.old_scene,
                id: e.id,
              },
            };
          }
        });
      }
    },
    onInitTimerPDUSensor(key) {
      if (
        this.TemperHumiItem[key].higher.id == null &&
        this.TemperHumiItem[key].shorter.id == null
      ) {
        this.fnToastNotificationOnly({
          message: `Vui lòng tạo kịch bản ${
            key == "temper" ? "Nhiệt độ" : "Độ ẩm"
          } trước`,
          type: "warning",
          reload: false,
        });
        return;
      }

      if (key == "temper") {
        this.TemperHumiItem.temper.editedItem.id = null;
        this.TemperHumiItem.temper.editedItem.timer =
          new Date().getHours() + ":" + new Date().getMinutes();
        this.PDU["Sensor"].obj = this.fnCopy(this.TemperHumiItem.temper);
        this.PDU["Sensor"].obj.cata = key;
        this.dialogPDUSensor = true;
      } else if (key == "humi") {
        this.TemperHumiItem.humi.editedItem.id = null;
        this.TemperHumiItem.humi.editedItem.timer =
          new Date().getHours() + ":" + new Date().getMinutes();
        this.PDU["Sensor"].obj = this.fnCopy(this.TemperHumiItem.humi);
        this.PDU["Sensor"].obj.cata = key;
        this.dialogPDUSensor = true;
      }
    },
    initialize_PDUTemper({ obj: data, cata: cata }) {
      if (data.timer != "00:00") {
        var idx = this.TemperHumiItem[cata].PDUList.findIndex(e => {
          return e.id == data.id;
        });
        idx == -1
          ? this.TemperHumiItem[cata].PDUList.push({
              status: data.status,
              timer: data.timer,
              // scene00: data.scene00,
              id: data.id,
              input: data.input,
              output: data.output,
              // enb: data.enb
            })
          : "";
      }
      var idx1 = this.TemperHumiItem[cata].PDUList_copy.findIndex(e => {
        return e.id == data.id;
      });
      idx1 == -1
        ? this.TemperHumiItem[cata].PDUList_copy.push({
            status: data.status,
            timer: data.timer,
            // scene00: data.scene00,
            id: data.id,
            input: data.input,
            output: data.output,
            // enb: data.enb
          })
        : "";

      this.sort_scene_display(this.TemperHumiItem[cata].PDUList);
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },

    onGetScene() {
      var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);
      var arr_day = [];
      this.items = [];
      var obj = {};

      this.PDU["Scene"].list = [];

      scenes.forEach(scene => {
        var data = Object.values(scene)[0].Da;

        var id = Object.keys(scene)[0];

        if (!this.isEmpty(data)) {
          var enb = data.enb;
          var input = data.input ? data.input : [];

          var output = data.output ? data.output : [];

          if (output && output[0]) {
            var device_id = output[0].device_id;

            input.forEach(e => {
              if (
                e.day_min &&
                e.oper &&
                (e.oper == "GE" || e.oper == "IN" || e.oper == "OR")
              ) {
                var time = e.day_min.Da;
                for (let [key, value] of Object.entries(output[0])) {
                  if (
                    device_id == this.PDU.device_id &&
                    key == this.PDU.item_id
                  ) {
                    try {
                      obj = {
                        ...obj,
                        timer: this.onConvertMilestone(time),
                        status: value.Da,
                        scene00: time == 0 ? true : false,
                        id: id,
                        input: input,
                        output: output,
                        enb: enb,
                      };

                      this.PDU["Scene"].list.push(scene);

                      this.initialize(obj);
                    } catch (err) {
                      console.log(err);
                    }
                  }
                }
              }
            });
          }
        }
      });
    },
    onGetScene_outled_by_undosccene({ list: scenes }) {
      // var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);
      var arr_day = [];
      this.items = [];
      var obj = {};

      this.PDU["Scene"].list = [];

      scenes.forEach(scene => {
        var data = Object.values(scene)[0].Da;

        var id = Object.keys(scene)[0];

        if (!this.isEmpty(data)) {
          var enb = data.enb;
          var input = data.input ? data.input : [];

          var output = data.output ? data.output : [];

          if (output && output[0]) {
            var device_id = output[0].device_id;

            input.forEach(e => {
              if (
                e.day_min &&
                e.oper &&
                (e.oper == "GE" || e.oper == "IN" || e.oper == "OR")
              ) {
                var time = e.day_min.Da;
                for (let [key, value] of Object.entries(output[0])) {
                  if (
                    device_id == this.PDU.device_id &&
                    key == this.PDU.item_id
                  ) {
                    try {
                      obj = {
                        ...obj,
                        timer: this.onConvertMilestone(time),
                        status: value.Da,
                        scene00: time == 0 ? true : false,
                        id: id,
                        input: input,
                        output: output,
                        enb: enb,
                      };

                      this.PDU["Scene"].list.push(scene);

                      this.initialize(obj);
                    } catch (err) {
                      console.log(err);
                    }
                  }
                }
              }
            });
          }
        }
      });
    },
    onGetScene_OutletProtect() {
      var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);
      var arr = [];
      this.items = [];
      var obj = {};
      scenes.forEach(scene => {
        var data = Object.values(scene)[0].Da;
        var id = Object.keys(scene)[0];
        if (!this.isEmpty(data)) {
          var input = Object.values(scene)[0].Da.input;
          input.filter(i => {
            if (Object.keys(i)[0] == this.PDU.item_id) {
              if (i.oper == "GT") {
                arr.push({
                  GT: {
                    data: Object.values(i)[0].Da,
                    unit: "mA",
                    content: data.output[0].notify_content.Da,
                  },
                  id: id,
                });
              } else if (i.oper == "LT") {
                arr.push({
                  LT: {
                    data: Object.values(i)[0].Da,
                    unit: "mA",
                    content: data.output[0].notify_content.Da,
                  },
                  id: id,
                });
              }
            }
            // else{
            //   arr = []
            // }
          });
        }
      });
      this.initialize_OutletProtect(arr);
    },
    onGetScene_sensorControl() {
      this.tabs_temper = null;
      this.onContructual_Sensor();
      this.PDU["Scene"].list = [];
      this.onGetScene_TemperControl();
      this.onGetScene_PDUTemper("temper");

      this.onGetScene_HumiControl();
      this.onGetScene_PDUTemper("humi");

      if (
        this.TemperHumiItem.temper.higher.id ||
        this.TemperHumiItem.temper.shorter.id
      ) {
        this.tabs_temper = "tab-0";
        return;
      } else if (
        this.TemperHumiItem.humi.higher.id ||
        this.TemperHumiItem.humi.shorter.id
      ) {
        this.tabs_temper = "tab-1";
        return;
      } else {
        this.tabs_temper = "tab-0";
        return;
      }
    },
    onGetScene_sensor_by_undosccene({
      list: scenes,
      listPDUSensor: listPDUSensor,
    }) {
      this.tabs_temper = null;
      this.onContructual_Sensor();
      this.PDU["Scene"].list = [];
      this.onGetScene_TemperControl_by_undosccene({ list: scenes });
      this.onGetScene_PDUTemper_by_undosccene({
        cata: "temper",
        list: scenes,
        listPDUSensor: listPDUSensor,
      });

      this.onGetScene_HumiControl_by_undosccene({ list: scenes });
      this.onGetScene_PDUTemper_by_undosccene({
        cata: "humi",
        list: scenes,
        listPDUSensor: listPDUSensor,
      });

      if (
        this.TemperHumiItem.temper.higher.id ||
        this.TemperHumiItem.temper.shorter.id
      ) {
        this.tabs_temper = "tab-0";
        return;
      } else if (
        this.TemperHumiItem.humi.higher.id ||
        this.TemperHumiItem.humi.shorter.id
      ) {
        this.tabs_temper = "tab-1";
        return;
      } else {
        this.tabs_temper = "tab-0";
        return;
      }
    },
    onGetScene_TemperControl() {
      var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);

      var arr = [];

      scenes.forEach(scene => {
        var data = Object.values(scene)[0].Da;

        var id = Object.keys(scene)[0];

        if (!this.isEmpty(data)) {
          var wday = data.wday;
          var sta_dmin = data.sta_dmin;
          var sto_dmin = data.sto_dmin;

          var input = Object.values(scene)[0].Da.input
            ? Object.values(scene)[0].Da.input
            : null;

          var output = Object.values(scene)[0].Da.output
            ? Object.values(scene)[0].Da.output[0]
            : null;

          if (input) {
            input.filter(i => {
              if (
                Object.keys(i)[0] == this.PDU.item_id &&
                i.device_id == this.PDU.device_id
              ) {
                var rs = this.onFindOutlet_SceneID(Object.keys(output)[0]);

                if (i.oper == "GT") {
                  arr.push({
                    GT: true,
                    data: Object.values(i)[0].Da,
                    status: Object.values(output)[0].Da,
                    select: {
                      name:
                        Object.values(output)[0].attribute_descriptions ||
                        Object.values(output)[0].Na,
                      id_outlet: {
                        id_outlet: Object.keys(output)[0],
                        device_id: output.device_id,
                      },
                    },
                    wday: wday,
                    sta_dmin: this.onConvertTimer(sta_dmin),
                    sto_dmin: this.onConvertTimer(sto_dmin),
                    old_scene: scene,
                    id: id,
                    outlet_sceneID: rs ? Object.keys(rs)[0] : null,
                  });
                }

                if (i.oper == "LT") {
                  arr.push({
                    LT: true,
                    data: Object.values(i)[0].Da,
                    status: Object.values(output)[0].Da,
                    select: {
                      name:
                        Object.values(output)[0].attribute_descriptions ||
                        Object.values(output)[0].Na,
                      id_outlet: {
                        id_outlet: Object.keys(output)[0],
                        device_id: output.device_id,
                      },
                    },
                    wday: wday,
                    sta_dmin: this.onConvertTimer(sta_dmin),
                    sto_dmin: this.onConvertTimer(sto_dmin),
                    old_scene: scene,
                    id: id,
                    outlet_sceneID: rs ? Object.keys(rs)[0] : null,
                  });
                }

                this.PDU["Scene"].list = [];
              }
            });
          }
        }
      });
      this.initialize_TemperControl(arr);
    },
    onGetScene_TemperControl_by_undosccene({ list: scenes }) {
      var scenes_cmp = this.fnCopy(this.PDU[this.modul].obj.AutoScene);

      var arr = [];

      scenes.forEach(scene => {
        var data = Object.values(scene)[0].Da;

        var id = Object.keys(scene)[0];

        if (!this.isEmpty(data)) {
          var wday = data.wday;
          var sta_dmin = data.sta_dmin;
          var sto_dmin = data.sto_dmin;

          var input = Object.values(scene)[0].Da.input
            ? Object.values(scene)[0].Da.input
            : null;

          var output = Object.values(scene)[0].Da.output
            ? Object.values(scene)[0].Da.output[0]
            : null;

          if (input) {
            input.filter(i => {
              if (
                Object.keys(i)[0] == this.PDU.item_id &&
                i.device_id == this.PDU.device_id
              ) {
                var rs = this.onFindOutlet_SceneID(Object.keys(output)[0]);

                if (i.oper == "GT") {
                  var scene_found = scenes_cmp.find(sc => {
                    return (
                      sc &&
                      Object.keys(sc)[0] == Object.keys(scene)[0] &&
                      Object.values(sc)[0] &&
                      Object.values(sc)[0].Da &&
                      Object.values(sc)[0].Da.input
                    );
                  });

                  arr.push({
                    GT: true,
                    data: Object.values(i)[0].Da,
                    status: Object.values(output)[0].Da,
                    select: {
                      name:
                        Object.values(output)[0].attribute_descriptions ||
                        Object.values(output)[0].Na,
                      id_outlet: {
                        id_outlet: Object.keys(output)[0],
                        device_id: output.device_id,
                      },
                    },
                    wday: wday,
                    sta_dmin: this.onConvertTimer(sta_dmin),
                    sto_dmin: this.onConvertTimer(sto_dmin),
                    old_scene: scene_found,
                    id: id,
                    outlet_sceneID: rs ? Object.keys(rs)[0] : null,
                  });
                }

                if (i.oper == "LT") {
                  var scene_found = scenes_cmp.find(sc => {
                    return (
                      sc &&
                      Object.keys(sc)[0] == Object.keys(scene)[0] &&
                      Object.values(sc)[0] &&
                      Object.values(sc)[0].Da &&
                      Object.values(sc)[0].Da.input
                    );
                  });

                  arr.push({
                    LT: true,
                    data: Object.values(i)[0].Da,
                    status: Object.values(output)[0].Da,
                    select: {
                      name:
                        Object.values(output)[0].attribute_descriptions ||
                        Object.values(output)[0].Na,
                      id_outlet: {
                        id_outlet: Object.keys(output)[0],
                        device_id: output.device_id,
                      },
                    },
                    wday: wday,
                    sta_dmin: this.onConvertTimer(sta_dmin),
                    sto_dmin: this.onConvertTimer(sto_dmin),
                    old_scene: scene_found,
                    id: id,
                    outlet_sceneID: rs ? Object.keys(rs)[0] : null,
                  });
                }

                this.PDU["Scene"].list = [];
              }
            });
          }
        }
      });
      this.initialize_TemperControl(arr);
    },
    onGetScene_HumiControl() {
      var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);
      var arr = [];

      scenes.forEach(scene => {
        var data = Object.values(scene)[0].Da;

        var id = Object.keys(scene)[0];

        if (!this.isEmpty(data)) {
          var wday = data.wday;
          var sta_dmin = data.sta_dmin;
          var sto_dmin = data.sto_dmin;

          var input = Object.values(scene)[0].Da.input
            ? Object.values(scene)[0].Da.input
            : null;

          var output = Object.values(scene)[0].Da.output
            ? Object.values(scene)[0].Da.output[0]
            : null;

          if (input) {
            input.filter(i => {
              if (
                Object.keys(i)[0] == this.PDU.sub_id &&
                i.device_id == this.PDU.device_id
              ) {
                var rs = this.onFindOutlet_SceneID(Object.keys(output)[0]);

                if (i.oper == "GT") {
                  arr.push({
                    GT: true,
                    data: Object.values(i)[0].Da,
                    status: Object.values(output)[0].Da,
                    select: {
                      name:
                        Object.values(output)[0].attribute_descriptions ||
                        Object.values(output)[0].Na,
                      id_outlet: {
                        id_outlet: Object.keys(output)[0],
                        device_id: output.device_id,
                      },
                    },
                    wday: wday,
                    sta_dmin: this.onConvertTimer(sta_dmin),
                    sto_dmin: this.onConvertTimer(sto_dmin),
                    old_scene: scene,
                    id: id,
                    outlet_sceneID: rs ? Object.keys(rs)[0] : null,
                  });
                }
                if (i.oper == "LT") {
                  arr.push({
                    LT: true,
                    data: Object.values(i)[0].Da,
                    status: Object.values(output)[0].Da,
                    select: {
                      name:
                        Object.values(output)[0].attribute_descriptions ||
                        Object.values(output)[0].Na,
                      id_outlet: {
                        id_outlet: Object.keys(output)[0],
                        device_id: output.device_id,
                      },
                    },
                    wday: wday,
                    sta_dmin: this.onConvertTimer(sta_dmin),
                    sto_dmin: this.onConvertTimer(sto_dmin),
                    old_scene: scene,
                    id: id,
                    outlet_sceneID: rs ? Object.keys(rs)[0] : null,
                  });
                }

                this.PDU["Scene"].list = [];
              }
            });
          }
        }
      });
      this.initialize_HumiControl(arr);
    },
    onGetScene_HumiControl_by_undosccene({ list: scenes }) {
      // var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);
      var arr = [];

      scenes.forEach(scene => {
        var data = Object.values(scene)[0].Da;

        var id = Object.keys(scene)[0];

        if (!this.isEmpty(data)) {
          var wday = data.wday;
          var sta_dmin = data.sta_dmin;
          var sto_dmin = data.sto_dmin;

          var input = Object.values(scene)[0].Da.input
            ? Object.values(scene)[0].Da.input
            : null;

          var output = Object.values(scene)[0].Da.output
            ? Object.values(scene)[0].Da.output[0]
            : null;

          if (input) {
            input.filter(i => {
              if (
                Object.keys(i)[0] == this.PDU.sub_id &&
                i.device_id == this.PDU.device_id
              ) {
                var rs = this.onFindOutlet_SceneID(Object.keys(output)[0]);

                if (i.oper == "GT") {
                  arr.push({
                    GT: true,
                    data: Object.values(i)[0].Da,
                    status: Object.values(output)[0].Da,
                    select: {
                      name:
                        Object.values(output)[0].attribute_descriptions ||
                        Object.values(output)[0].Na,
                      id_outlet: {
                        id_outlet: Object.keys(output)[0],
                        device_id: output.device_id,
                      },
                    },
                    wday: wday,
                    sta_dmin: this.onConvertTimer(sta_dmin),
                    sto_dmin: this.onConvertTimer(sto_dmin),
                    old_scene: scene,
                    id: id,
                    outlet_sceneID: rs ? Object.keys(rs)[0] : null,
                  });
                }
                if (i.oper == "LT") {
                  arr.push({
                    LT: true,
                    data: Object.values(i)[0].Da,
                    status: Object.values(output)[0].Da,
                    select: {
                      name:
                        Object.values(output)[0].attribute_descriptions ||
                        Object.values(output)[0].Na,
                      id_outlet: {
                        id_outlet: Object.keys(output)[0],
                        device_id: output.device_id,
                      },
                    },
                    wday: wday,
                    sta_dmin: this.onConvertTimer(sta_dmin),
                    sto_dmin: this.onConvertTimer(sto_dmin),
                    old_scene: scene,
                    id: id,
                    outlet_sceneID: rs ? Object.keys(rs)[0] : null,
                  });
                }

                this.PDU["Scene"].list = [];
              }
            });
          }
        }
      });
      this.initialize_HumiControl(arr);
    },
    onGetScenePDUBySenSor(sensor) {
      return sensor.PDUList_copy;
    },
    onGetScene_PDUTemper(cata) {
      var arr = [];
      var obj = {};
      var kind = null;
      if (cata == "temper") {
        kind = "512";
      } else if (cata == "humi") {
        kind = "448";
      }
      try {
        var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);

        const searching = (list, key) =>
          list.find(i => Object.keys(i)[0] == key);

        scenes.forEach(scene => {
          var data = Object.values(scene)[0].Da;
          var time;
          var status;
          var obj = {};

          var id = Object.keys(scene)[0];

          if (!this.isEmpty(data)) {
            var input = data.input ? data.input : [];

            var output = data.output ? data.output : [];

            output.forEach(out => {
              if (
                Object.keys(out)[0] != "device_id" &&
                (Object.keys(out)[0] == "896" || Object.keys(out)[0] == "832")
              ) {
                if (Object.keys(out)[0] == "896") {
                  status = false;
                } else if (Object.keys(out)[0] == "832") {
                  status = true;
                }

                input.forEach(it => {
                  if (it.oper == "GE") {
                    time = it.day_min.Da;
                  }
                });

                var da = Object.values(out)[0].Da;
                var sc = searching(scenes, da);
                if (sc) {
                  //tim duoc scene nhiet do, do am
                  var ip = Object.values(sc)[0].Da.input;

                  for (var i in ip) {
                    if (
                      Object.keys(ip[i])[0] != "device_id" &&
                      Object.keys(ip[i])[0] != "oper" &&
                      Object.keys(ip[i])[0] == kind
                    ) {
                      if (ip[i].device_id == this.PDU.device_id) {
                        obj = {
                          ...obj,
                          status: status,
                          timer: this.onConvertMilestone(time),
                          id: id,
                          input: input,
                          output: output,
                        };

                        var rxs = this.TemperHumiItem[cata].listScene.findIndex(
                          e => {
                            return Object.keys(e)[0] == id;
                          }
                        );
                        if (rxs == -1) {
                          this.TemperHumiItem[cata].listScene.push(scene);
                        }

                        this.initialize_PDUTemper({ obj: obj, cata: cata });
                      }
                    }
                  }
                }
              }
            });
          }
        });
      } catch (err) {
        console.log("get scene pdu timer error are:= ", err);
      }
    },
    onGetScene_PDUTemper_by_undosccene({
      cata: cata,
      list: scenes,
      listPDUSensor: listPDUSensor,
    }) {
      var arr = [];
      var obj = {};
      var kind = null;
      if (cata == "temper") {
        kind = "512";
      } else if (cata == "humi") {
        kind = "448";
      }
      try {
        // var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);

        const searching = (list, key) =>
          list.find(i => Object.keys(i)[0] == key);

        listPDUSensor.forEach(scene => {
          var data = Object.values(scene)[0].Da;
          var time;
          var status;
          var obj = {};

          var id = Object.keys(scene)[0];

          if (!this.isEmpty(data)) {
            var input = data.input ? data.input : [];

            var output = data.output ? data.output : [];

            output.forEach(out => {
              if (
                Object.keys(out)[0] != "device_id" &&
                (Object.keys(out)[0] == "896" || Object.keys(out)[0] == "832")
              ) {
                if (Object.keys(out)[0] == "896") {
                  status = false;
                } else if (Object.keys(out)[0] == "832") {
                  status = true;
                }

                input.forEach(it => {
                  if (it.oper == "GE") {
                    time = it.day_min.Da;
                  }
                });

                var da = Object.values(out)[0].Da;
                var sc = searching(scenes, da);
                if (sc) {
                  //tim duoc scene nhiet do, do am
                  var ip = Object.values(sc)[0].Da.input;

                  for (var i in ip) {
                    if (
                      Object.keys(ip[i])[0] != "device_id" &&
                      Object.keys(ip[i])[0] != "oper" &&
                      Object.keys(ip[i])[0] == kind
                    ) {
                      if (ip[i].device_id == this.PDU.device_id) {
                        obj = {
                          ...obj,
                          status: status,
                          timer: this.onConvertMilestone(time),
                          id: id,
                          input: input,
                          output: output,
                        };

                        var rxs = this.TemperHumiItem[cata].listScene.findIndex(
                          e => {
                            return Object.keys(e)[0] == id;
                          }
                        );
                        if (rxs == -1) {
                          this.TemperHumiItem[cata].listScene.push(scene);
                        }

                        this.initialize_PDUTemper({ obj: obj, cata: cata });
                      }
                    }
                  }
                }
              }
            });
          }
        });
      } catch (err) {
        console.log("get scene pdu timer error are:= ", err);
      }
    },

    onFindOutlet_SceneID(key) {
      var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);

      const rs = scenes.find(d => {
        var Da = Object.values(d)[0].Da;
        if (!this.isEmpty(Da)) {
          var Op = Da.output ? Da.output : [];
          var Ip = Da.input ? Da.input[0] : [];
          for (let [k, v] of Object.entries(Op[0])) {
            if (k == key) {
              if (Ip.day_min && Ip.oper) {
                return d;
              }
            }
          }
        }
      });

      return rs;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = this.fnCopy(item);
      if (item.status == true) {
        this.$set(this.editedItem, "status", true);
      } else this.$set(this.editedItem, "status", false);
      this.dialog = true;
    },
    editItemPDUSensor({ item: item, cata: cata }) {
      this.TemperHumiItem[cata].editedItem = this.fnCopy(item);

      this.PDU["Sensor"].obj = this.fnCopy(this.TemperHumiItem[cata]);
      this.PDU["Sensor"].obj.cata = cata;

      this.dialogPDUSensor = true;
    },
    isDel_timer(item) {
      this.items.forEach(e => {
        if (e.id == this.PDU["PDUTimer"].obj.id) {
        }
      });

      var index = this.items.findIndex(e => {
        return e.id == this.PDU["PDUTimer"].obj.id;
      });
      var index1 = this.items_copy.findIndex(e => {
        return e.id == this.PDU["PDUTimer"].obj.id;
      });
      this.items.splice(index, 1);
      this.items_copy.splice(index1, 1);

      if (this.items.length == 0) {
        var index2 = this.items_copy.findIndex(e => {
          return (
            e.input &&
            e.input[0] &&
            e.input[0].day_min &&
            e.input[0].day_min.Da == 0
          );
        });
        this.items_copy.splice(index2, 1);
      }

      this.fnCallResetView("PDUTimer");
    },
    async delete(item) {
      this.PDU["Scene"].obj = {};
      var scene = this.PDU[this.modul].obj.AutoScene.find(scene => {
        return Object.keys(scene)[0] == item.id;
      });
      if (scene) {
        var scene_1 = this.fnCopy(scene);
        Object.values(scene_1)[0].Da = {};
        this.PDU["Scene"].obj = Object.assign({}, scene_1, {
          device_id:
            Object.values(scene_1)[0].device_id ||
            this.PDU[this.modul].obj.device_id,
        });
        var attribute = {
          user_name: this.PDU.userSession.username,
          attribute_list: [this.PDU["Scene"].obj],
        };
        await this.fnIotPost(attribute);
      }
    },
    close() {
      this.dialog = false;
    },
    onDeleteItemPDUSensor({ item: data, cata: cata }) {
      this.fnSetMusicStorage({ model: "PDUSensor", item: data });
      this.$set(this.PDU["PDUSensor"].obj, "cata", cata);
    },
    isDel_PDUSensor(cata) {
      var index = this.TemperHumiItem[cata].PDUList.findIndex(e => {
        return e.id == this.PDU["PDUSensor"].obj.id;
      });
      var index1 = this.TemperHumiItem[cata].PDUList_copy.findIndex(e => {
        return e.id == this.PDU["PDUSensor"].obj.id;
      });
      // var index2 = this.TemperHumiItem[cata].listScene.findIndex(e => {
      //   return Object.keys(e)[0] == this.PDU["PDUSensor"].obj.id;
      // });

      this.TemperHumiItem[cata].PDUList.splice(index, 1);
      this.TemperHumiItem[cata].PDUList_copy.splice(index1, 1);
      // this.TemperHumiItem[cata].listScene.splice(index2, 1);

      if (this.TemperHumiItem[cata].PDUList.length == 0) {
        var id00 = null;
        var index3 = this.TemperHumiItem[cata].PDUList_copy.findIndex(e => {
          if (
            e.input &&
            e.input[0] &&
            e.input[0].day_min &&
            e.input[0].day_min.Da == 0
          ) {
            id00 = e.id;
            return e;
          }
        });

        this.TemperHumiItem[cata].PDUList_copy.splice(index3, 1);
      }

      this.fnCallResetView("PDUSensor");
    },

    PreventOOh() {
      var idx = this.items.findIndex(e => {
        return e.timer == this.editedItem.timer;
      });
      var arr = this.editedItem.timer.split(":");
      var time = parseInt(arr[0]) * 60 + parseInt(arr[1]);
      if (time == -1 * new Date().getTimezoneOffset() || time == 0) {
        this.fnToastNotificationOnly({
          message: "Vui lòng chọn giờ khác!",
          type: "error",
          reload: false,
        });
        return;
      }
      this.$refs.dialog1.save(this.editedItem.timer);
    },
    PreventOOh1() {
      var idx = this.NesthouseTimerArr.findIndex(e => {
        return e.timer == this.NesthouseTimerItem.timer;
      });
      var arr = this.NesthouseTimerItem.timer.split(":");
      var time = parseInt(arr[0]) * 60 + parseInt(arr[1]);
      if (time == -1 * new Date().getTimezoneOffset() || time == 0) {
        this.fnToastNotificationOnly({
          message: "Vui lòng chọn giờ khác!",
          type: "error",
          reload: false,
        });
        return;
      }
      this.$refs.dialog.save(this.NesthouseTimerItem.timer);
    },
    PreventOOh2() {
      var arr = this.PDU["Sensor"].obj.editedItem.timer.split(":");
      var time = parseInt(arr[0]) * 60 + parseInt(arr[1]);
      if (time == -1 * new Date().getTimezoneOffset() || time == 0) {
        this.fnToastNotificationOnly({
          message: "Vui lòng chọn giờ khác!",
          type: "error",
          reload: false,
        });
        return;
      }
      this.$refs.dialog2.save(this.PDU["Sensor"].obj.editedItem.timer);
    },
    PreventOOh3() {
      var arr = this.PDU["PDU_automation"].obj.start_time.split(":");
      var time = parseInt(arr[0]) * 60 + parseInt(arr[1]);
      if (time == -1 * new Date().getTimezoneOffset() || time == 0) {
        this.fnToastNotificationOnly({
          message: "Vui lòng chọn giờ khác!",
          type: "error",
          reload: false,
        });
        return;
      }
      this.$refs.dialog3.save(this.PDU["PDU_automation"].obj.start_time);
    },

    PreventOOh4() {
      var arr = this.PDU["PDU_automation"].obj.stop_time.split(":");
      var time = parseInt(arr[0]) * 60 + parseInt(arr[1]);
      if (time == -1 * new Date().getTimezoneOffset() || time == 0) {
        this.fnToastNotificationOnly({
          message: "Vui lòng chọn giờ khác!",
          type: "error",
          reload: false,
        });
        return;
      }
      this.$refs.dialog4.save(this.PDU["PDU_automation"].obj.stop_time);
    },
    PreventOOh5() {
      var arr = this.item_CM_setting.time_start.split(":");
      var time = parseInt(arr[0]) * 60 + parseInt(arr[1]);
      if (time == -1 * new Date().getTimezoneOffset() || time == 0) {
        this.fnToastNotificationOnly({
          message: "Vui lòng chọn giờ khác!",
          type: "error",
          reload: false,
        });
        return;
      }
      this.$refs.dialog5.save(this.item_CM_setting.time_start);
    },
    PreventOOh6() {
      var arr = this.item_CM_setting.time_end.split(":");
      var time = parseInt(arr[0]) * 60 + parseInt(arr[1]);
      if (time == -1 * new Date().getTimezoneOffset() || time == 0) {
        this.fnToastNotificationOnly({
          message: "Vui lòng chọn giờ khác!",
          type: "error",
          reload: false,
        });
        return;
      }
      this.$refs.dialog6.save(this.item_CM_setting.time_end);
    },

    async save_OutletControl() {
      var attribute_push = [],
        attribute_push_1 = [];
      var attribute_delete = [];

      this.PDU["Scene"].isLoading = true;
      try {
        if (this.items.length == 0) {
          var index2 = this.items_copy.findIndex(e => {
            return (
              e.input &&
              e.input[0] &&
              e.input[0].day_min &&
              e.input[0].day_min.Da == 0
            );
          });
          this.items_copy.splice(index2, 1);
        }

        var index = this.items_copy.find(e => {
          return e.scene00 == true;
        });

        if (!index) {
          /// thêm vào kịch bản 00h

          var timezone_scene_00h = this.createScene_timezone_pdu(
            this.items_copy,
            0
          ); // kịch bản 00h

          this.PDU["Scene"].list.push(this.fnCopy(this.PDU["Scene"].obj));

          var idx = this.items_copy.findIndex(e => {
            return e && e.id && e.id == timezone_scene_00h.id;
          });

          if (idx && idx == -1) {
            this.items_copy.push({
              timer: "00:00",
              status: timezone_scene_00h.status,
              id: timezone_scene_00h.id,
              output: timezone_scene_00h.output,
              input: timezone_scene_00h.input,
            });
          }
        }

        this.sort_scene_display_to_setting(this.items_copy);

        await this.sort_scene_timer_setting(this.items_copy);

        await this.PDU["Scene"].list.forEach(async e => {
          var e_copy = this.fnCopy(e);
          // Object.values(e_copy)[0].Da.enb = true

          var idx = this.items_copy.findIndex(d => {
            return d.id == Object.keys(e_copy)[0];
          }); //this.NesthouseTimerArr_copy
          var idx1 = this.items.findIndex(d => {
            return d.id == Object.keys(e_copy)[0];
          });

          if (idx >= 0) {
            if (idx1 >= 0) {
              Object.values(e_copy)[0].Da.enb = this.items[idx1].enb;
            }

            var obj = Object.assign({}, e_copy, {
              device_id: this.PDU[this.modul].obj.device_id,
            });

            if (this.onCheckSceneIsRight(e_copy)) {
              attribute_push.push(obj);
            }
          } else if (idx == -1) {
            var item = { id: Object.keys(e_copy)[0] };

            var scene = this.PDU[this.modul].obj.AutoScene.find(scene => {
              return Object.keys(scene)[0] == item.id;
            });
            if (scene) {
              var scene_1 = this.fnCopy(scene);
              Object.values(scene_1)[0].Da = {};

              var obj_del = Object.assign({}, scene_1, {
                device_id: this.PDU[this.modul].obj.device_id,
              });
              attribute_delete.push(obj_del);
            }
          }
        });

        if (this.undo.list_scene && this.undo.list_scene.length > 0) {
          attribute_push_1 = this.onCheckUndoScene();

          if (attribute_push_1.length > 0) {
            var obj = attribute_push
              .concat(attribute_delete)
              .reduce(function(result, item) {
                var key = Object.keys(item)[0];
                result[key] = item[key];
                return result;
              });

            var attribute_post = Object.assign({}, obj, {
              device_id: this.PDU[this.modul].obj.device_id,
            });

            var attribute = {
              user_name: this.PDU.userSession.username,
              attribute_list: [attribute_post],
            };

            await this.fnIotPost(attribute);

            var device = this.PDU[this.modul].obj.child_attribute.find(
              device => {
                return device.device_id == this.PDU.device_id;
              }
            );

            this.PDU["Scene"].isLoading = false;
            this.dialog_Outlet_settings = false;

            await this.onFindAsynchronous(device.list_outled);
            return;
          }
          return;
        }

        if (attribute_push.length > 0 || attribute_delete.length > 0) {
          var obj = attribute_push
            .concat(attribute_delete)
            .reduce(function(result, item) {
              var key = Object.keys(item)[0];
              result[key] = item[key];
              return result;
            });

          var attribute_post = Object.assign({}, obj, {
            device_id: this.PDU[this.modul].obj.device_id,
          });

          var attribute = {
            user_name: this.PDU.userSession.username,
            attribute_list: [attribute_post],
          };

          await this.fnIotPost(attribute);

          var device = this.PDU[this.modul].obj.child_attribute.find(device => {
            return device.device_id == this.PDU.device_id;
          });

          this.PDU["Scene"].isLoading = false;
          this.dialog_Outlet_settings = false;

          await this.onFindAsynchronous(device.list_outled);
        } else {
          this.PDU["Scene"].isLoading = false;
          this.dialog_Outlet_settings = false;
        }
      } catch (err) {
        console.log(err);
        this.fnToast({
          message: "Có lỗi xảy ra. Vui lòng thử lại sau!",
          type: "error",
        });
        this.PDU["Scene"].isLoading = false;
        this.dialog_Outlet_settings = false;
      }
    },
    async save_OutletProtect() {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          if (this.protectItem.id) {
            await this.delete(this.protectItem);

            var idx = this.PDU[this.modul].obj.AutoScene.findIndex(
              scene => Object.keys(scene)[0] == this.protectItem.id
            );
            this.PDU[this.modul].obj.AutoScene.splice(idx, 1);

            if (!this.protectItem.Max_check && !this.protectItem.Min_check) {
              this.dialog_Outlet_protect = false;
              return;
            }
          }
          await this.onsetScene_OutletProtect();
          this.PDU[this.modul].obj.AutoScene.push(this.PDU["Scene"].obj);
          this.dialog_Outlet_protect = false;
        }
      }
    },
    async save_sensorControl() {
      this.PDU["Sensor"].isLoading = true;

      await this.onsetScene_SensorControl();
    },
    async save_sensorWarning () {
      if (this.onBefore_save_sensorWarning() == true) {
        this.dialog_Temper_Humi_warning = false
        var cHiTempAlarmSetting_key = "2944"
        var cLoTempAlarmSetting_key = "3008"
        var cHiHumiAlarmSetting_key = "3072"
        var cLoHumiAlarmSetting_key = "3136"

        var cHiTempAlarmSetting = {}
        var cLoTempAlarmSetting = {}
        var cHiHumiAlarmSetting = {}
        var cLoHumiAlarmSetting = {}

        cHiTempAlarmSetting[cHiTempAlarmSetting_key] = {
          Dt: "ARRAY",
          Da: [
            this.TemperHumiItemWarning.temper.check_high == true && this.TemperHumiItemWarning.temper.higher.data ? parseInt(this.TemperHumiItemWarning.temper.higher.data) : -1,
            this.TemperHumiItemWarning.temper.check_high == true ? (this.TemperHumiItemWarning.temper.higher.time.value ?
            parseInt(this.TemperHumiItemWarning.temper.higher.time.value) : (this.TemperHumiItemWarning.temper.higher.time ?
            parseInt(this.TemperHumiItemWarning.temper.higher.time) : -1)) : -1,
            this.TemperHumiItemWarning.temper.check_high == true ? this.TemperHumiItemWarning.temper.higher.message : "null"
          ]
        }
        cLoTempAlarmSetting[cLoTempAlarmSetting_key] = {
          Dt: "ARRAY",
          Da: [
            this.TemperHumiItemWarning.temper.check_short == true && this.TemperHumiItemWarning.temper.shorter.data ? parseInt(this.TemperHumiItemWarning.temper.shorter.data) : -1,
            this.TemperHumiItemWarning.temper.check_short == true ? (this.TemperHumiItemWarning.temper.shorter.time.value ?
            parseInt(this.TemperHumiItemWarning.temper.shorter.time.value) : (this.TemperHumiItemWarning.temper.shorter.time ?
            parseInt(this.TemperHumiItemWarning.temper.shorter.time) : -1)) : -1,
            this.TemperHumiItemWarning.temper.check_short == true ? this.TemperHumiItemWarning.temper.shorter.message : "null"
          ]
        }
        cHiHumiAlarmSetting[cHiHumiAlarmSetting_key] = {
          Dt: "ARRAY",
          Da: [
            this.TemperHumiItemWarning.humi.check_high == true && this.TemperHumiItemWarning.humi.higher.data ? parseInt(this.TemperHumiItemWarning.humi.higher.data) : -1,
            this.TemperHumiItemWarning.humi.check_high == true ? (this.TemperHumiItemWarning.humi.higher.time.value ?
            parseInt(this.TemperHumiItemWarning.humi.higher.time.value) : (this.TemperHumiItemWarning.humi.higher.time ?
            parseInt(this.TemperHumiItemWarning.humi.higher.time) : -1)) : -1,
            this.TemperHumiItemWarning.humi.check_high == true ? this.TemperHumiItemWarning.humi.higher.message : "null"
          ]
        }
        cLoHumiAlarmSetting[cLoHumiAlarmSetting_key] = {
          Dt: "ARRAY",
          Da: [
            this.TemperHumiItemWarning.humi.check_short == true && this.TemperHumiItemWarning.humi.shorter.data ? parseInt(this.TemperHumiItemWarning.humi.shorter.data) : -1,
            this.TemperHumiItemWarning.humi.check_short == true ? (this.TemperHumiItemWarning.humi.shorter.time.value ?
            parseInt(this.TemperHumiItemWarning.humi.shorter.time.value) : (this.TemperHumiItemWarning.humi.shorter.time ?
            parseInt(this.TemperHumiItemWarning.humi.shorter.time) : -1)) : -1,
            this.TemperHumiItemWarning.humi.check_short == true ? this.TemperHumiItemWarning.humi.shorter.message : "null"
          ]
        }

        var attribute_tempt = Object.assign({}, cHiTempAlarmSetting, cLoTempAlarmSetting, cHiHumiAlarmSetting, cLoHumiAlarmSetting)
        var attribute_post = Object.assign({}, attribute_tempt, {
          device_id: Object.values(this.PDU["DeviceAttribute"].obj)[0].device_id,
        });
        
        var attribute = {
          user_name: this.PDU.userSession.username,
          attribute_list: [attribute_post],
        };
        await this.fnIotPost(attribute).then(data => {
          if (data && data.success == true) {
            this.fnToast({
              message: `Thao tác thành công`,
              type: "success",
            });
            var index = this.device_temper.findIndex(e => { return e.device_id == this.PDU.device_id })
            if (index >= 0) {
              this.$set(this.device_temper[index].list_temper[0]['512'], 'cHiTempAlarmSetting', cHiTempAlarmSetting[cHiTempAlarmSetting_key].Da)
              this.$set(this.device_temper[index].list_temper[0]['512'], 'cLoTempAlarmSetting', cLoTempAlarmSetting[cLoTempAlarmSetting_key].Da)
              this.$set(this.device_temper[index].list_temper[0]['512'], 'cHiHumiAlarmSetting', cHiHumiAlarmSetting[cHiHumiAlarmSetting_key].Da)
              this.$set(this.device_temper[index].list_temper[0]['512'], 'cLoHumiAlarmSetting', cLoHumiAlarmSetting[cLoHumiAlarmSetting_key].Da)
            }
          } else {
            this.fnToast({
              message: `Thao tác thất bại`,
              type: "error",
            });
          }
        })
      }
    },
    onResetVaribleSensorWarning () {
      this.TemperHumiItemWarning = {
        temper: {
          check_high: "",
          check_short: "",
          higher: {
            data: 35,
            time: { text: "3h", value: 180 },
            message: "Canh bao T > 35 - 3h"
          },
          shorter: {
            data: 20,
            time: { text: "3h", value: 180 },
            message: "Canh bao T < 20 - 3h"
          },
        },
        humi: {
          check_high: "",
          check_short: "",
          higher: {
            data: 90,
            time: { text: "2h", value: 120 },
            message: "Canh bao H > 90 - 2h"
          },
          shorter: {
            data: 80,
            time: { text: "2h", value: 120 },
            message: "Canh bao H < 80 - 2h"
          },
        },
      }
    },
    onBefore_save_sensorWarning () {
      if (this.TemperHumiItemWarning.temper.check_high == true) {
        if (!this.TemperHumiItemWarning.temper.higher.data || this.TemperHumiItemWarning.temper.higher.data <= 0 || this.TemperHumiItemWarning.temper.higher.data >= 100 ||
        this.TemperHumiItemWarning.temper.higher.data == "") {
          this.fnToastNotificationOnly({
            message: `[Nhiệt độ] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        }
        if (!this.TemperHumiItemWarning.temper.higher.time || this.TemperHumiItemWarning.temper.higher.time == "") {
          this.fnToastNotificationOnly({
            message: `[Nhiệt độ] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        } else {
          if (!this.TemperHumiItemWarning.temper.higher.time.value) {
            if (parseInt(this.TemperHumiItemWarning.temper.higher.time) > 600) {
              this.fnToastNotificationOnly({
                message: `[Nhiệt độ] Thời gian không quá 10 giờ (600 phút)`,
                type: "error",
                reload: false,
              });
              return false
            }
          } else {
            if (parseInt(this.TemperHumiItemWarning.temper.higher.time.value) > 600) {
              this.fnToastNotificationOnly({
                message: `[Nhiệt độ] Thời gian không quá 10 giờ (600 phút)`,
                type: "error",
                reload: false,
              });
              return false
            }
          }
        }
        if (!this.TemperHumiItemWarning.temper.higher.message || this.TemperHumiItemWarning.temper.higher.message == "") {
          this.fnToastNotificationOnly({
            message: `[Nhiệt độ] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        }
      }
      if (this.TemperHumiItemWarning.temper.check_short == true) {
        if (!this.TemperHumiItemWarning.temper.shorter.data || this.TemperHumiItemWarning.temper.shorter.data <= 0 || this.TemperHumiItemWarning.temper.shorter.data > 100 ||
        this.TemperHumiItemWarning.temper.shorter.data == "") {
          this.fnToastNotificationOnly({
            message: `[Nhiệt độ] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        }
        if (!this.TemperHumiItemWarning.temper.shorter.time || this.TemperHumiItemWarning.temper.shorter.time == "") {
          this.fnToastNotificationOnly({
            message: `[Nhiệt độ] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        } else {
          if (!this.TemperHumiItemWarning.temper.shorter.time.value) {
            if (parseInt(this.TemperHumiItemWarning.temper.shorter.time) > 600) {
              this.fnToastNotificationOnly({
                message: `[Nhiệt độ] Thời gian không quá 10 giờ (600 phút)`,
                type: "error",
                reload: false,
              });
              return false
            }
          } else {
            if (parseInt(this.TemperHumiItemWarning.temper.shorter.time.value) > 600) {
              this.fnToastNotificationOnly({
                message: `[Nhiệt độ] Thời gian không quá 10 giờ (600 phút)`,
                type: "error",
                reload: false,
              });
              return false
            }
          }
        }
        if (!this.TemperHumiItemWarning.temper.shorter.message || this.TemperHumiItemWarning.temper.shorter.message == "") {
          this.fnToastNotificationOnly({
            message: `[Nhiệt độ] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        }
      }
      if (this.TemperHumiItemWarning.temper.check_high == true) {
        if (!this.TemperHumiItemWarning.temper.higher.data || this.TemperHumiItemWarning.temper.higher.data <= 0 || this.TemperHumiItemWarning.temper.higher.data >= 100 ||
        this.TemperHumiItemWarning.temper.higher.data == "") {
          this.fnToastNotificationOnly({
            message: `[Độ ẩm] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        }
        if (!this.TemperHumiItemWarning.humi.higher.time || this.TemperHumiItemWarning.humi.higher.time == "") {
          this.fnToastNotificationOnly({
            message: `[Độ ẩm] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        } else {
          if (!this.TemperHumiItemWarning.humi.higher.time.value) {
            if (parseInt(this.TemperHumiItemWarning.humi.higher.time) > 600) {
              this.fnToastNotificationOnly({
                message: `[Độ ẩm] Thời gian không quá 10 giờ (600 phút)`,
                type: "error",
                reload: false,
              });
              return false
            }
          } else {
            if (parseInt(this.TemperHumiItemWarning.humi.higher.time.value) > 600) {
              this.fnToastNotificationOnly({
                message: `[Độ ẩm] Thời gian không quá 10 giờ (600 phút)`,
                type: "error",
                reload: false,
              });
              return false
            }
          }
        }
        if (!this.TemperHumiItemWarning.humi.higher.message || this.TemperHumiItemWarning.humi.higher.message == "") {
          this.fnToastNotificationOnly({
            message: `[Độ ẩm] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        }
      }
      if (this.TemperHumiItemWarning.humi.check_short == true) {
        if (!this.TemperHumiItemWarning.humi.shorter.data || this.TemperHumiItemWarning.humi.shorter.data <= 0 || this.TemperHumiItemWarning.humi.shorter.data >= 100 ||
        this.TemperHumiItemWarning.humi.shorter.data == "") {
          this.fnToastNotificationOnly({
            message: `[Độ ẩm] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        }
        if (!this.TemperHumiItemWarning.humi.shorter.time || this.TemperHumiItemWarning.humi.shorter.time == "") {
          this.fnToastNotificationOnly({
            message: `[Độ ẩm] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        } else {
          if (!this.TemperHumiItemWarning.humi.shorter.time.value) {
            if (parseInt(this.TemperHumiItemWarning.humi.shorter.time) > 600) {
              this.fnToastNotificationOnly({
                message: `[Độ ẩm] Thời gian không quá 10 giờ (600 phút)`,
                type: "error",
                reload: false,
              });
              return false
            }
          } else {
            if (parseInt(this.TemperHumiItemWarning.humi.shorter.time.value) > 600) {
              this.fnToastNotificationOnly({
                message: `[Độ ẩm] Thời gian không quá 10 giờ (600 phút)`,
                type: "error",
                reload: false,
              });
              return false
            }
          }
        }
        if (!this.TemperHumiItemWarning.humi.shorter.message || this.TemperHumiItemWarning.humi.shorter.message == "") {
          this.fnToastNotificationOnly({
            message: `[Độ ẩm] ${this.MES.sensorWarningIncorrectFormat}`,
            type: "error",
            reload: false,
          });
          return false
        }
      }
      return true
    },
    async save_notify_content() {
      var arr = [];
      var arr1 = []
      const noti_content_channel = {
        1920: 1792,
        1921: 1792,
        1922: 1793,
        1923: 1793,
        1924: 1794,
        1925: 1794,
        1926: 1795,
        1927: 1795
      }
      for (var i in this.list_audio_status) {
        var item = this.list_audio_status[i];
        var status_key = Object.keys(item)[0]
        arr.push({
          notify_content: Object.values(item)[0].notify_content,
          parent_device: this.PDU[this.modul].obj.device_id,
          status_key: status_key,
          child_device: Object.values(item)[0].device_id,
          scene_id: Object.values(item)[0].scene_id,
          status: Object.values(item)[0].status,
          stt: i,
          active: Object.values(item)[0].active,
          description: Object.values(item)[0].attribute_descriptions,
          musicChannel: noti_content_channel[status_key]
        });
        arr1.push({
          notify_content: Object.values(item)[0].notify_content1,
          parent_device: this.PDU[this.modul].obj.device_id,
          status_key: Object.keys(item)[0],
          child_device: Object.values(item)[0].device_id,
          scene_id: Object.values(item)[0].scene_id1,
          status: Object.values(item)[0].status1,
          stt: i,
          active: Object.values(item)[0].active1,
          description: Object.values(item)[0].attribute_descriptions,
          musicChannel: noti_content_channel[status_key]
        });
      }

      var attribute = this.onsetSceneNotifyContent(arr, arr1);
      var attribute1 = this.onsetSceneNotifyContent1(arr, arr1);
      var attribute2 = this.onMapDataIfDaUnderfined({ attribute, attribute1 })

      if (attribute2 && attribute2.length > 0) {
        var obj = attribute2.reduce(function(result, item) {
          var key = Object.keys(item)[0];
          result[key] = item[key];
          return result;
        });
        // var obj1 = attribute1.reduce(function(result1, item1) {
        //   var key1 = Object.keys(item1)[0];
        //   result1[key1] = item1[key1];
        //   return result1;
        // });

        var attribute_post = Object.assign({}, obj, {
          device_id: this.PDU[this.modul].obj.device_id,
        });

        var attribute = {
          user_name: this.PDU.userSession.username,
          attribute_list: [attribute_post],
        };
        await this.fnIotPost(attribute).then(data => {});
        this.onResetAuditoStatusDecription();
      }
    },
    onMapDataIfDaUnderfined ({ attribute, attribute1 }) {
      for (var i in attribute) {
        var scene = attribute[i]
        var output = Object.values(scene)[0].Da && Object.values(scene)[0].Da.output
        var input = Object.values(scene)[0].Da && Object.values(scene)[0].Da.input
        var enb = Object.values(scene)[0].Da && Object.values(scene)[0].Da.enb
        var status_key = null
        for (var j in input) {
          if (Object.keys(input[j])[0] !== 'device_id' && Object.keys(input[j])[0] !== 'oper' && Object.keys(input[j])[0] >= 1920) {
            status_key = Object.keys(input[j])[0]
          }
        }
        for (var j in output) {
          if (output[j]['832'] && output[j]['832'].Da === 'undefined') {
            var index = this.list_sceneID_Notify_Content_ON.findIndex(e => { return e.status_key === status_key })
            if (index >= 0) {
              this.$set(output[j]['832'], 'Da', `${this.list_sceneID_Notify_Content_ON[index].id}`)
            }
            this.$set(Object.values(scene)[0].Da, 'enb', true)
          }
        }
      }
      for (var i in attribute1) {
        var scene = attribute1[i]
        var output = Object.values(scene)[0].Da && Object.values(scene)[0].Da.output
        var input = Object.values(scene)[0].Da && Object.values(scene)[0].Da.input
        var status_key = null
        for (var j in input) {
          if (Object.keys(input[j])[0] !== 'device_id' && Object.keys(input[j])[0] !== 'oper' && Object.keys(input[j])[0] >= 1920) {
            status_key = Object.keys(input[j])[0]
          }
        }
        for (var j in output) {
          if (output[j]['832'] && output[j]['832'].Da === 'undefined') {
            var index = this.list_sceneID_Notify_Content_ON.findIndex(e => { return e.status_key === status_key })
            if (index >= 0) {
              this.$set(output[j]['832'], 'Da', `${this.list_sceneID_Notify_Content_OFF[index].id}`)
            }
            this.$set(Object.values(scene)[0].Da, 'enb', false)
          }
        }
      }
      return attribute && attribute.length > 0 ? attribute.concat(attribute1) : (attribute1 && attribute1.length > 0 ? attribute1.concat(attribute) : [])
    },
    onCopy(item) {
      this.PDU["DeviceAttribute"].obj = this.fnCopy(item);
      this.PDU.Na =
        Object.values(this.PDU["DeviceAttribute"].obj)[0]
          .attribute_descriptions ||
        Object.values(this.PDU["DeviceAttribute"].obj)[0].Na;
      this.PDU.item_id = Object.keys(item)[0];
      this.PDU.device_id =
        Object.values(item)[0].device_id || this.PDU[this.modul].obj.device_id;
      if (Object.values(item)[0].Humi) {
        this.PDU.sub_id = Object.keys(Object.values(item)[0].Humi)[0];
      }
      if (Object.values(item)[0].volume) {
        this.PDU.sub_id = Object.keys(Object.values(item)[0].volume)[0];
      }
    },
    onCheckAutoSceneID() {
      var ID = this.onCheckAutoSceneID1(64);
      return ID || null;
    },
    onCheckAutoSceneID1(n) {
      var MAX = 320 + 64;
      var MIN = 320;
      var arr = [];

      // arr = this.fnCopy(this.PDU['DeviceDetail'].obj.list_sceneID)

      // if (this.PDU[this.modul].obj.list_sceneID.length == 127) {
      //     this.fnToastNotificationOnly({
      //       message: "Số kịch bản đã tới giới hạn, vui lòng kiểm tra lại!",
      //       type: "error",reload:false
      //     });
      //     return null;
      // }
      if (n == 0) {
        var ID = this.onCheckAutoSceneID2(64);
        return ID || null;
      }

      if (this.editedItem.id) {
        return this.editedItem.id;
      } else if (this.NesthouseTimerItem.id) {
        return this.NesthouseTimerItem.id;
      } else {
        var id = Math.floor(Math.random() * (MAX - MIN)) + MIN;

        var idx = this.PDU[this.modul].obj.list_sceneID.findIndex(ids => {
          return ids == id;
        });

        if (idx == -1) {
          return id;
        } else {
          return this.onCheckAutoSceneID1(n - 1);
        }
      }

      return null;
    },
    onCheckAutoSceneID2(n) {
      var MIN1 = 2176;
      var MAX1 = 2176 + 64;

      // var arr = this.fnCopy(this.PDU['DeviceDetail'].obj.list_sceneID)

      if (this.PDU[this.modul].obj.list_sceneID.length == 127 || n == 0) {
        this.fnToastNotificationOnly({
          message: "Số cài đặt đã tới giới hạn, vui lòng kiểm tra lại!",
          type: "error",
          reload: false,
        });
        return null;
      }

      if (this.editedItem.id) {
        return this.editedItem.id;
      } else if (this.NesthouseTimerItem.id) {
        return this.NesthouseTimerItem.id;
      } else {
        var id1 = Math.floor(Math.random() * (MAX1 - MIN1)) + MIN1;

        var idx1 = this.PDU[this.modul].obj.list_sceneID.findIndex(ids => {
          return ids == id1;
        });

        if (idx1 == -1) {
          return id1;
        } else {
          return this.onCheckAutoSceneID2(n - 1);
        }
      }

      return null;
    },
    onCheckSceneIsRight(scene) {
      return Object.values(scene)[0].Da &&
        Object.values(scene)[0].Da.input &&
        Object.values(scene)[0].Da.input.length == 2 &&
        Object.values(scene)[0].Da.output &&
        Object.values(scene)[0].Da.output.length > 0
        ? true
        : false;
    },
    onCreate() {
      var d = new Date();
      this.PDU.week_min = [];
      this.PDU.week_min_LT = [];
      var obj = {};
      var child_id = this.fnCopy(
        Object.values(this.PDU["DeviceAttribute"].obj)[0].device_id ||
          this.PDU["DeviceDetail"].obj.device_id
      );
      var iCopy = this.fnCopy(this.PDU["DeviceAttribute"].obj);
      this.data.id = this.onCheckAutoSceneID();
      if (this.data.id == null) {
        return;
      }
      this.PDU[this.modul].obj.list_sceneID.push(this.data.id);

      //input

      var arr = this.editedItem.timer.split(":");
      var timeGE =
        parseInt(arr[0]) * 60 + parseInt(arr[1]) + d.getTimezoneOffset();
      timeGE < 0 ? (timeGE = timeGE + 1440) : (timeGE = timeGE);

      //input
      this.PDU["Scene"].input = [
        {
          day_min: {
            Da: timeGE,
            Dt: "UINT32",
          },
          device_id: this.PDU.select || this.PDU[this.modul].obj.device_id,
          oper: "GE",
        },
      ];
      //output

      Object.values(iCopy)[0].Da = this.editedItem.status;

      delete Object.values(iCopy)[0].current;
      delete Object.values(iCopy)[0].list_scene;
      delete Object.values(iCopy)[0].Auto;
      delete Object.values(iCopy)[0].device_id;
      delete Object.values(iCopy)[0].list_scene_temper;
      delete Object.values(iCopy)[0].list_scene_timer;
      delete Object.values(iCopy)[0].list_scene_timer_1;
      delete Object.values(iCopy)[0].attribute_descriptions;
      delete Object.values(iCopy)[0].Pe;
      delete Object.values(iCopy)[0].Cl;
      delete Object.values(iCopy)[0].Re;
      delete Object.values(iCopy)[0].Un;
      delete Object.values(iCopy)[0].Na;
      delete Object.values(iCopy)[0].hardware;
      delete Object.values(iCopy)[0].timeout;

      this.PDU["Scene"].output = Object.assign({}, iCopy, {
        device_id: child_id,
      });

      obj[this.data.id] = {
        Da: {
          enb: this.editedItem.enb || true,
          wday: [0, 1, 2, 3, 4, 5, 6],
          sta_dmin: 0,
          sto_dmin: 1440,
          cond: "AND",
          input: this.PDU["Scene"].input,
          output: [this.PDU["Scene"].output],
        },
        Dt: "OBJECT",
      };

      this.PDU["Scene"].obj = Object.assign({}, obj, {
        device_id: this.PDU[this.modul].obj.device_id,
      });

      var result = {
        timer: this.editedItem.timer,
        enb: this.editedItem.enb || true,
        status: this.editedItem.status,
        input: this.PDU["Scene"].input,
        output: [this.PDU["Scene"].output],
        id: this.data.id,
      };

      return result;
    },
    onCreate_PDUSensor(sensor) {
      var d = new Date();
      this.PDU.week_min = [];
      this.PDU.week_min_LT = [];
      var obj = {};

      this.data.id = this.onCheckAutoSceneID();
      if (this.data.id == null) {
        return;
      }
      this.PDU[this.modul].obj.list_sceneID.push(this.data.id);

      //input

      var arr = sensor.editedItem.timer.split(":");
      var timeGE =
        parseInt(arr[0]) * 60 + parseInt(arr[1]) + d.getTimezoneOffset();
      timeGE < 0 ? (timeGE = timeGE + 1440) : (timeGE = timeGE);

      //input
      var input = [
        {
          day_min: { Da: timeGE, Dt: "UINT32" },
          device_id: this.PDU[this.modul].obj.device_id,
          oper: "GE",
        },
      ];
      //output
      var output = [];
      if (sensor.select && sensor.select.id_outlet) {
        var iCopy = this.fnCopy(
          this.getOutletofTemperControl(sensor.select.id_outlet)
        );
        var outlet_id = this.fnCopy(Object.values(iCopy)[0].device_id);

        if (sensor.editedItem.status == true) {
          if (sensor.check_high == true) {
            output.push({
              832: {
                Da: `${sensor.higher.id}`,
                Dt: "STRING",
              },
              device_id: this.PDU[this.modul].obj.device_id,
            });
          }
          if (sensor.check_short == true) {
            output.push({
              832: {
                Da: `${sensor.shorter.id}`,
                Dt: "STRING",
              },
              device_id: this.PDU[this.modul].obj.device_id,
            });
          }
        } else if (sensor.editedItem.status == false) {
          if (sensor.check_high == true) {
            output.push({
              896: {
                Da: `${sensor.higher.id}`,
                Dt: "STRING",
              },
              device_id: this.PDU[this.modul].obj.device_id,
            });
          }
          if (sensor.check_short == true) {
            output.push({
              896: {
                Da: `${sensor.shorter.id}`,
                Dt: "STRING",
              },
              device_id: this.PDU[this.modul].obj.device_id,
            });
          }

          Object.values(iCopy)[0].Da = sensor.editedItem.status;

          delete Object.values(iCopy)[0].current;
          delete Object.values(iCopy)[0].list_scene;
          delete Object.values(iCopy)[0].Auto;
          delete Object.values(iCopy)[0].device_id;
          delete Object.values(iCopy)[0].list_scene_temper;
          delete Object.values(iCopy)[0].list_scene_timer;
          delete Object.values(iCopy)[0].attribute_descriptions;
          delete Object.values(iCopy)[0].Pe;
          delete Object.values(iCopy)[0].Cl;
          delete Object.values(iCopy)[0].Re;
          delete Object.values(iCopy)[0].Un;
          delete Object.values(iCopy)[0].Na;
          delete Object.values(iCopy)[0].hardware;
          delete Object.values(iCopy)[0].timeout;

          var xx = Object.assign({}, iCopy, { device_id: outlet_id });
          output.push(xx);
        }

        // var arr1 = new Object();

        obj[this.data.id] = {
          Da: {
            enb: true,
            wday: [0, 1, 2, 3, 4, 5, 6],
            sta_dmin: 0,
            sto_dmin: 1440,
            cond: "AND",
            input: input,
            output: output,
          },
          Dt: "OBJECT",
        };

        this.PDU["Scene"].obj = Object.assign({}, obj, {
          device_id: this.PDU[this.modul].obj.device_id,
        });

        var result = {
          timer: sensor.editedItem.timer,
          enb: true,
          status: sensor.editedItem.status,
          input: input,
          output: output,
          id: `${this.data.id}`,
        };

        return result;
      } else {
        this.fnToastNotificationOnly({
          message: "Ổ cắm chưa được chọn. Vui lòng kiểm tra lại",
          type: "error",
          reload: false,
        });
        this.data.id = null;
        return;
      }
    },

    onOpenSceneHandling(cata) {
      if (this.TemperHumiItem[cata].PDUList.length == 0) {
        var index2 = this.TemperHumiItem[cata].PDUList_copy.filter(e => {
          return (
            e.input &&
            e.input[0] &&
            e.input[0].day_min &&
            e.input[0].day_min.Da == 0
          );
        });
        if (index2 && index2.length >= 2) {
          // co nhiu hon 2 kich ban 00 => xoa het kich ban 00
          var arr = this.fnCopy(this.TemperHumiItem[cata].PDUList_copy);

          for (var i in this.TemperHumiItem[cata].PDUList_copy) {
            var e = this.TemperHumiItem[cata].PDUList_copy[i];
            if (
              e.input &&
              e.input[0] &&
              e.input[0].day_min &&
              e.input[0].day_min.Da == 0
            ) {
              arr.splice(i, 1);
            }
          }
          this.TemperHumiItem[cata].PDUList_copy = arr;
        } else {
          // chi co 1 kich ban 00 => xoa 1 kich ban 00
          var index3 = this.TemperHumiItem[cata].PDUList_copy.findIndex(e => {
            return (
              e.input &&
              e.input[0] &&
              e.input[0].day_min &&
              e.input[0].day_min.Da == 0
            );
          });

          this.TemperHumiItem[cata].PDUList_copy.splice(index3, 1);
        }
      }

      var index = this.TemperHumiItem[cata].PDUList_copy.find(e => {
        return (
          e.input &&
          e.input[0] &&
          e.input[0].day_min &&
          e.input[0].day_min.Da == 0
        );
      });

      if (!index) {
        this.sort_scene_display_to_setting(
          this.TemperHumiItem[cata].PDUList_copy
        );

        var scene_00h = this.create_Open_Scene_PDU(
          this.TemperHumiItem[cata].PDUList_copy,
          0
        ); // kịch bản 00h

        this.TemperHumiItem[cata].listScene.push(
          this.fnCopy(this.PDU["Scene"].obj)
        ); // ...

        var idx = this.TemperHumiItem[cata].PDUList_copy.findIndex(e => {
          return e && e.id && e.id == scene_00h.id;
        });

        if (idx == -1) {
          this.TemperHumiItem[cata].PDUList_copy.push({
            timer: "00:00",
            // status: timezone_scene_00h.status,
            id: scene_00h.id,
            output: scene_00h.output,
            input: scene_00h.input,
          });
        }
      }
    },

    async onsetScene_SensorControl() {
      var attribute_1 = [],
        attribute_2 = [],
        attribute_3 = [],
        attribute_4 = [],
        attribute_5 = [],
        attribute_6 = [],
        attribute_7 = [],
        attribute_8 = [],
        attribute_9 = [],
        attribute_10 = [],
        attribute_11 = [],
        attribute_12 = [],
        attribute_push_1 = [],
        attribute_push_2 = []
      var d = new Date()
      try {
        var tempt = this.fnCopy(this.TemperHumiItem)

        var temper = this.fnCopy(this.PDU["DeviceAttribute"].obj)
        var humi = this.fnCopy(Object.values(this.PDU["DeviceAttribute"].obj)[0].Humi)

        if ((tempt.temper.check_high || tempt.temper.check_short) && (tempt.humi.check_high || tempt.humi.check_short)) {
          if (tempt.temper.select &&
            tempt.temper.select.id_outlet &&
            tempt.humi.select &&
            tempt.humi.select.id_outlet &&
            tempt.temper.select.id_outlet.id_outlet === tempt.humi.select.id_outlet.id_outlet) {
            this.fnToastNotificationOnly({
              message: "Ổ cắm của cài đặt nhiệt độ và độ ẩm phải khác nhau",
              type: "warning",
              reload: false,
            });
            this.PDU["Sensor"].isLoading = false;
            return;
          }
        }

        //Buoc 1: tao kich ban sensor
        attribute_1 = this.onAddSceneTemperControl(tempt.temper, temper) || []; //truyền vào parent chứa high and short

        attribute_2 = this.onAddSceneTemperControl(tempt.humi, humi) || []; //truyền vào parent chứa high and short

        if (attribute_1 === "null" || attribute_2 === "null") {
          this.PDU["Sensor"].isLoading = false;
          return;
        }
        /////////////////////////////////////////////////////XU LY KICH BAN PDU////////////////////////////////////////

        //Buoc 4: Xu ly kich ban 00
        if (
          this.TemperHumiItem.temper.PDUList_copy &&
          this.TemperHumiItem.temper.PDUList_copy.length > 0 ) {
          this.onOpenSceneHandling("temper");
        }
        if (
          this.TemperHumiItem.humi.PDUList_copy &&
          this.TemperHumiItem.humi.PDUList_copy.length > 0) {
          this.onOpenSceneHandling("humi");
        }

        //Buoc 2: Get kich ban PDU (neu co)
        attribute_3 = this.onGetScenePDUBySenSor(this.TemperHumiItem.temper);
        attribute_4 = this.onGetScenePDUBySenSor(this.TemperHumiItem.humi);

        //Buoc 3: Sap xep cac kich ban PDU tang dan theo thoi gian
        this.sort_scene_display_to_setting(attribute_3);
        this.sort_scene_display_to_setting(attribute_4);

        //Buoc 5: Tao vong lap cac kich ban PDU
        attribute_5 = this.globalsort_scene_timer_setting({
          list: attribute_3,
          cata: "temper",
        });
        await pause(1000)
        attribute_6 = this.globalsort_scene_timer_setting({
          list: attribute_4,
          cata: "humi",
        });

        this.onSumaryCheckPDUSensor({ list: attribute_5, cata: "temper" });
        this.onSumaryCheckPDUSensor({ list: attribute_6, cata: "humi" });

        attribute_7 = this.onSumaryCheckPDUSensor1({
          attribute_1: attribute_1,
          list: attribute_5,
          sensor: this.TemperHumiItem.temper,
        })
        attribute_8 = this.onSumaryCheckPDUSensor1({
          attribute_1: attribute_2,
          list: attribute_6,
          sensor: this.TemperHumiItem.humi,
        })

        this.onSumaryCheckPDUSensor2({ list: attribute_7, cata: "temper" });
        this.onSumaryCheckPDUSensor2({ list: attribute_8, cata: "humi" });

        attribute_9 = this.onSumaryCheckPDUSensor3({
          list: attribute_1,
          cata: "temper",
        });
        attribute_10 = this.onSumaryCheckPDUSensor3({
          list: attribute_2,
          cata: "humi",
        })

        if (
          attribute_1.length > 0 ||
          attribute_2.length > 0 ||
          attribute_7.length > 0 ||
          attribute_8.length > 0
        ) {
          var obj = attribute_1
            .concat(attribute_2)
            .concat(attribute_7)
            .concat(attribute_8)
            .concat(attribute_9)
            .concat(attribute_10)
            .reduce(function(result, item) {
              var key = Object.keys(item)[0];
              result[key] = item[key];
              return result;
            })

          var attribute_post = Object.assign({}, obj, {
            device_id: this.PDU[this.modul].obj.device_id,
          })

          var attribute = {
            user_name: this.PDU.userSession.username,
            attribute_list: [attribute_post],
          }

          await this.fnIotPost(attribute);
          this.PDU["Sensor"].isLoading = false;
          this.dialog_Sensor_settings = false;

          var device = this.PDU[this.modul].obj.child_attribute.find(device => {
            return device.device_id == this.PDU.device_id;
          })
          await this.onFindAsynchronous(device.list_temper);
        }
        this.PDU["Sensor"].isLoading = false;
      } catch (err) {
        console.log(err);
        this.fnToast({
          message: "Có lỗi xảy ra. Vui lòng thử lại sau!",
          type: "error",
        });
        this.PDU["Sensor"].isLoading = false;
        this.dialog_Sensor_settings = false;
      }
    },
    onSetSceneOutOfTimerPDU(item) {
      var obj = {
        sta_dmin: item.sta_dmin,
        sto_dmin: item.sto_dmin,
        select: item.select,
      };
      var scene = {};
      var d = new Date();

      //tìm outlet phù hợp
      var outlet = this.fnCopy(
        this.list_outlet1.find(outlet => {
          return Object.keys(outlet)[0] == obj.select.id_outlet;
        }) || this.getOutletofTemperControl(obj.select.id_outlet)
      );

      var outlet_id = this.fnCopy(Object.values(outlet)[0].device_id);

      delete Object.values(outlet)[0].Na;
      delete Object.values(outlet)[0].Re;
      delete Object.values(outlet)[0].Pe;
      delete Object.values(outlet)[0].Cl;
      delete Object.values(outlet)[0].Un;
      delete Object.values(outlet)[0].attribute_descriptions;
      delete Object.values(outlet)[0].device_id;
      delete Object.values(outlet)[0].hardware;
      delete Object.values(outlet)[0].timeout;
      delete Object.values(outlet)[0].list_scene_temper;
      delete Object.values(outlet)[0].list_scene_timer;
      delete Object.values(outlet)[0].Auto;

      this.$set(Object.values(outlet)[0], "Da", false);

      //output
      var output = Object.assign({}, outlet, { device_id: outlet_id });

      //input: nhỏ hơn sta_dmin hoặc lớn hơn sto_day_min
      var timeLT = this.onConvertNumber(obj.sta_dmin) + d.getTimezoneOffset();
      timeLT < 0 ? (timeLT = timeLT + 1440) : (timeLT = timeLT);

      var timeGE = this.onConvertNumber(obj.sto_dmin) + d.getTimezoneOffset();
      timeGE < 0 ? (timeGE = timeGE + 1440) : (timeGE = timeGE);

      timeGE == 1439 ? (timeGE = timeGE + 1) : "";

      ////////////////////
      var input = [
        {
          day_min: {
            Da: timeLT,
            Dt: "UINT32",
          },
          device_id: this.PDU[this.modul].obj.device_id,
          oper: "LT",
        },
        {
          day_min: {
            Da: timeGE,
            Dt: "UINT32",
          },
          device_id: this.PDU[this.modul].obj.device_id,
          oper: "GE",
        },
      ];

      // tạo kịch bản
      this.data.id = item.outlet_sceneID || this.onCheckAutoSceneID();
      if (this.data.id == null) {
        return;
      }
      scene[this.data.id] = {
        Da: {
          enb: true,
          wday: this.onConvertDay_Number(item.wday),
          sta_dmin: 0,
          sto_dmin: 1440,
          cond: timeGE > timeLT ? "OR" : "AND",
          input: input,
          output: [output],
        },
        Dt: "OBJECT",
      };

      return scene;
    },
    async onsetScene_OutletProtect() {
      var obj = {};
      this.data.id = this.onCheckAutoSceneID();
      var current = Object.values(this.PDU["DeviceAttribute"].obj)[0].current;
      var current_max = this.fnCopy(current);
      var current_min = this.fnCopy(current);
      if (
        this.protectItem.Max_check &&
        this.protectItem.MaxCurrent_content &&
        this.protectItem.MinCurrent_content &&
        this.protectItem.Min_check
      ) {
        Object.values(current_max)[0].Da =
          this.protectItem.MaxCurrent_unit == "mA"
            ? this.protectItem.MaxCurrent_content
            : this.protectItem.MaxCurrent_content * 1000;
        Object.values(current_min)[0].Da =
          this.protectItem.MinCurrent_unit == "mA"
            ? this.protectItem.MinCurrent_content
            : this.protectItem.MinCurrent_content * 1000;
        this.PDU["Scene"].input = [
          Object.assign(
            {},
            current_max,
            {
              device_id:
                Object.values(this.PDU["DeviceAttribute"].obj)[0].device_id ||
                this.PDU["DeviceDetail"].obj.device_id,
            },
            { oper: "GT" }
          ),
          Object.assign(
            {},
            current_min,
            { device_id: this.PDU["DeviceDetail"].obj.device_id },
            { oper: "LT" }
          ),
        ];
      } else if (
        this.protectItem.Max_check &&
        this.protectItem.MaxCurrent_content
      ) {
        Object.values(current_max)[0].Da =
          this.protectItem.MaxCurrent_unit == "mA"
            ? this.protectItem.MaxCurrent_content
            : this.protectItem.MaxCurrent_content * 1000;
        this.PDU["Scene"].input = [
          Object.assign(
            {},
            current_max,
            {
              device_id:
                Object.values(this.PDU["DeviceAttribute"].obj)[0].device_id ||
                this.PDU["DeviceDetail"].obj.device_id,
            },
            { oper: "GT" }
          ),
        ];
      } else if (
        this.protectItem.Min_check &&
        this.protectItem.MinCurrent_content
      ) {
        Object.values(current_min)[0].Da =
          this.protectItem.MinCurrent_unit == "mA"
            ? this.protectItem.MinCurrent_content
            : this.protectItem.MinCurrent_content * 1000;
        this.PDU["Scene"].input = [
          Object.assign(
            {},
            current_min,
            {
              device_id:
                Object.values(this.PDU["DeviceAttribute"].obj)[0].device_id ||
                this.PDU["DeviceDetail"].obj.device_id,
            },
            { oper: "LT" }
          ),
        ];
      }

      this.PDU["Scene"].output = [
        {
          notify_device: {
            Da: this.PDU[this.modul].obj.device_id,
            Dt: "STRING",
          },
          notify_content: {
            Da: this.protectItem.notify_content,
            Dt: "STRING",
          },
          device_id: "application",
        },
      ];

      obj[this.data.id] = {
        Da: {
          enb: true,
          wday: [0, 1, 2, 3, 4, 5, 6],
          sta_dmin: 0,
          sto_dmin: 1440,
          cond: "OR",
          input: this.PDU["Scene"].input,
          output: this.PDU["Scene"].output,
        },
        Dt: "OBJECT",
      };
      this.PDU["Scene"].obj = Object.assign({}, obj, {
        device_id: this.PDU[this.modul].obj.device_id,
      });

      var attribute = {
        user_name: this.PDU.userSession.username,
        attribute_list: [this.PDU["Scene"].obj],
      };

      await this.fnIotPost(attribute);
    },
    onsetSceneNotifyContent(arr, arr1) {
      var result = [];
      this.list_sceneID_Notify_Content_OFF = []

      for (var i in arr) {
        var item = arr[i];

        // if(item.notify_content && item.notify_content != ''){ // nhung kenh co noi dung thong bao
        var status = {};
        var id;
        var enb = item.status;
        status[item.status_key] = { Dt: "BOOLEAN", Da: false };

        var objInput1 = Object.assign({}, status, {
          device_id: item.child_device,
          oper: "EQ",
        })

        var status1 = {}
        status1[item.musicChannel] = {
          Dt: 'STRING',
          Da: 'No setting'
        }

        var objInput2 = Object.assign({}, status1, {
          device_id: this.PDU[this.modul].obj.device_id,
          oper: "NE",
        })

        var input = [
          objInput1,
          objInput2
        ];

        if (item.scene_id) {
          id = item.scene_id;
        } else {
          id = this.onCheckAutoSceneID();
          if (id == null) {
            return;
          }
          this.PDU[this.modul].obj.list_sceneID.push(id);
        }
        // push sceneID để map vào scene nếu Da trong attr 832 bị underfined
        this.list_sceneID_Notify_Content_OFF.push({ status_key: item.status_key, id })
        //find SceneID has notification when AudioStatus On
        var index = arr1.findIndex(e => { return e.status_key === item.status_key })
        var output = [
          {
            device_id: "telegram",
            notify_content: {
              Dt: "STRING",
              Da: !item.active ? 'null' : (item.notify_content || `Kênh ${item.description || item.stt} tăt`),
            },
            notify_device: { Dt: "STRING", Da: item.parent_device }
          },
          {
            "832": {
              Da: `${index >= 0 ? arr1[index].scene_id : ''}`,
              Dt: 'STRING'
            },
            "896": {
              Da: `${id}`,
              Dt: 'STRING'
            },
            device_id: this.PDU[this.modul].obj.device_id
          }
        ];

        var obj = {};

        obj[id] = {
          Da: {
            enb: enb,
            wday: [0, 1, 2, 3, 4, 5, 6],
            sta_dmin: 0,
            sto_dmin: 1440,
            cond: "AND",
            input: input,
            output: output,
          },
          Dt: "OBJECT",
        };

        result.push(obj);
      }
      return result;
    },
    onsetSceneNotifyContent1(arr, arr1) {
      var result = [];
      this.list_sceneID_Notify_Content_ON = []

      for (var i in arr1) {
        var item = arr1[i];

        // if(item.notify_content && item.notify_content != ''){ // nhung kenh co noi dung thong bao
        var status = {};
        var id;
        var enb = item.status;
        status[item.status_key] = { Dt: "BOOLEAN", Da: true };

        var objInput1 = Object.assign({}, status, {
          device_id: item.child_device,
          oper: "EQ",
        })

        var status1 = {}
        status1[item.musicChannel] = {
          Dt: 'STRING',
          Da: 'No setting'
        }

        var objInput2 = Object.assign({}, status1, {
          device_id: this.PDU[this.modul].obj.device_id,
          oper: "NE",
        })
        var input = [
          objInput1,
          objInput2
        ];

        if (item.scene_id) {
          id = item.scene_id;
        } else {
          id = this.onCheckAutoSceneID();
          if (id == null) {
            return;
          }
          this.PDU[this.modul].obj.list_sceneID.push(id);
        }
        // push sceneID để map vào scene nếu Da trong attr 832 bị underfined
        this.list_sceneID_Notify_Content_ON.push({ status_key: item.status_key, id })
        //find SceneID has notification when AudioStatus On
        var index = arr.findIndex(e => { return e.status_key === item.status_key })
        var output = [
          {
            device_id: "telegram",
            notify_content: {
              Dt: "STRING",
              Da: !item.active ? 'null' : (item.notify_content || `Kênh ${item.description || item.stt } bật`),
            },
            notify_device: { Dt: "STRING", Da: item.parent_device },
          },
          {
            "832": {
              Da: `${index >= 0 ? arr[index].scene_id : ''}`,
              Dt: 'STRING'
            },
            "896": {
              Da: `${id}`,
              Dt: 'STRING'
            },
            device_id: this.PDU[this.modul].obj.device_id
          }
        ];


        var obj = {};

        obj[id] = {
          Da: {
            enb: enb,
            wday: [0, 1, 2, 3, 4, 5, 6],
            sta_dmin: 0,
            sto_dmin: 1440,
            cond: "AND",
            input: input,
            output: output,
          },
          Dt: "OBJECT",
        };

        result.push(obj);
      }
      return result;
    },
    async onUpload_ChannelMonitorSettings() {
      try {
        var data = this.fnCopy(this.PDU["AudioTimeOff"].obj);
        var timer =
          this.PDU["AudioTimeOff"].obj.timer == "Tắt thông báo"
            ? 0
            : parseInt(this.PDU["AudioTimeOff"].obj.timer, 10) * 3600;
        delete data.timer;
        delete data.isForm;
        delete data.name;
        this.$set(Object.values(data)[0], "Da", timer);

        var data_1 = Object.assign({}, data, {
          device_id: this.PDU[this.modul].obj.device_id,
        });

        var attribute = {
          user_name: this.PDU.userSession.username,
          attribute_list: [data_1],
        };

        await this.fnIotPost(attribute);
        await this.onFindAsynchronous(this.list_channel_monitor_AudioTimeOff);
      } catch (err) {
        console.log(err);
      }
    },
    async onUpload_ChannelMonitorSettings1() {
      try {
        // convert time start and time end to timestamp
        if (this.item_CM_setting.mode_allway_on === true) {
          this.$set(this.item_CM_setting, 'time_start', '00:00:00')
          this.$set(this.item_CM_setting, 'time_end', '23:59:59')
        }
        var time_start = this.onConvertTimeToTimestamp(this.item_CM_setting.time_start)
        var time_end = this.onConvertTimeToTimestamp(this.item_CM_setting.time_end)
        if (time_end < time_start) {
          time_end = time_end + 86400
        }
        var obj_copy = this.fnCopy(this.PDU["AudioTimeOff"].obj);
        var data = {
          En: time_start,
          Ds: time_end,
          // Me: message_error,
          // Mo: message_normal
        }
        this.$set(Object.values(obj_copy)[0], "Da", data);
        var data_1 = Object.assign({}, obj_copy, {
          device_id: this.PDU[this.modul].obj.device_id,
        });

        var attribute = {
          user_name: this.PDU.userSession.username,
          attribute_list: [data_1],
        };

        await this.fnIotPost(attribute);
        await this.onWrapNotiContentChannelMonitor()
        await this.onFindAsynchronous(this.list_channel_monitor_AudioTimerHealthy);
      } catch (err) {
        console.log(err);
      }
    },
    async onWrapNotiContentChannelMonitor () {
      var message_error = this.item_CM_setting.message_error
      var message_normal = this.item_CM_setting.message_normal
      var noti_content_key = this.item_CM_setting.noti_content_key
      if (noti_content_key !== null && noti_content_key !== undefined) {
        var noti_content_copy = this.fnCopy(this.PDU[this.modul].obj.noti_content)
        noti_content_copy[noti_content_key] = [
          message_normal,
          message_error
        ]
        var key = 'noti_content'
        var data = {}
        data[key] = {
          Da: noti_content_copy,
          Dt: 'Object'
        }
        var data_1 = Object.assign({}, data, {
          device_id: this.PDU[this.modul].obj.device_id,
        });
        var attribute = {
          user_name: this.PDU.userSession.username,
          attribute_list: [data_1],
        };

        await this.fnUpdate_NotiContent(attribute).then(data => {
          if (data["success"]) {
            this.fnToast({
              message: "Cài đặt nội dung thông báo thành công",
              type: "success",
            });
            this.$set(this.PDU[this.modul].obj, 'noti_content', noti_content_copy)
          }
        });
      }
    },
    onSumaryCheckPDUSensor({ list: list, cata: cata }) {
      // var list = this.PDU['Scene'].list
      var item = this.TemperHumiItem;

      list.forEach(scene => {
        // var output = scene.output

        var output = Object.values(scene)[0].Da.output;

        if (item[cata].check_high == true) {
          var key;
          var out = output.find(e => {
            if (
              Object.keys(e)[0] != "device_id" &&
              (Object.keys(e)[0] == 896 || Object.keys(e)[0] == 832)
            ) {
              key = Object.keys(e)[0];
            }
            return (
              Object.keys(e)[0] != "device_id" &&
              Object.values(e)[0].Da == item[cata].higher.id
            );
          });
          //todo
          if (!out) {
            var x = new Object();
            x[key] = { Da: `${item[cata].higher.id}`, Dt: "STRING" };
            x = Object.assign({}, x, {
              device_id: this.PDU[this.modul].obj.device_id,
            });
            output.push(x);
          }
        } else if (item[cata].check_high == false) {
          var key;
          var out = output.findIndex(e => {
            if (
              Object.keys(e)[0] != "device_id" &&
              (Object.keys(e)[0] == 896 || Object.keys(e)[0] == 832)
            ) {
              key = Object.keys(e)[0];
            }
            return (
              Object.keys(e)[0] != "device_id" &&
              Object.values(e)[0].Da == item[cata].higher.id
            );
          });
          //todo
          if (out >= 0) {
            output.splice(out, 1);
          }
        }

        if (item[cata].check_short == true) {
          var key;
          var out = output.find(e => {
            if (
              Object.keys(e)[0] != "device_id" &&
              (Object.keys(e)[0] == 896 || Object.keys(e)[0] == 832)
            ) {
              key = Object.keys(e)[0];
            }
            return (
              Object.keys(e)[0] != "device_id" &&
              Object.values(e)[0].Da == item[cata].shorter.id
            );
          });
          //todo
          if (!out) {
            var x = new Object();
            x[key] = { Da: `${item[cata].shorter.id}`, Dt: "STRING" };
            x = Object.assign({}, x, {
              device_id: this.PDU[this.modul].obj.device_id,
            });
            output.push(x);
          }
        } else if (item[cata].check_short == false) {
          var key;
          var out = output.findIndex(e => {
            if (
              Object.keys(e)[0] != "device_id" &&
              (Object.keys(e)[0] == 896 || Object.keys(e)[0] == 832)
            ) {
              key = Object.keys(e)[0];
            }
            return (
              Object.keys(e)[0] != "device_id" &&
              Object.values(e)[0].Da == item[cata].shorter.id
            );
          });
          //todo
          if (out >= 0) {
            output.splice(out, 1);
          }
        }
      });
      return list;
    },
    onSumaryCheckPDUSensor1({
      attribute_1: attribute_1,
      list: list,
      sensor: sensor,
    }) {
      // var list = this.PDU['Scene'].list
      if (sensor.select && sensor.select.id_outlet) {
        var temper_selectID = sensor.select.id_outlet;

        var temper_outlet = this.fnCopy(
          this.getOutletofTemperControl(temper_selectID)
        );

        var outlet_id1 = this.fnCopy(Object.values(temper_outlet)[0].device_id);
        Object.values(temper_outlet)[0].Da = false;

        delete Object.values(temper_outlet)[0].list_scene_temper;
        delete Object.values(temper_outlet)[0].list_scene_timer;
        delete Object.values(temper_outlet)[0].Auto;
        delete Object.values(temper_outlet)[0].device_id;
        delete Object.values(temper_outlet)[0].attribute_descriptions;
        delete Object.values(temper_outlet)[0].Pe;
        delete Object.values(temper_outlet)[0].Cl;
        delete Object.values(temper_outlet)[0].Re;
        delete Object.values(temper_outlet)[0].Un;
        delete Object.values(temper_outlet)[0].Na;
        delete Object.values(temper_outlet)[0].hardware;
        delete Object.values(temper_outlet)[0].timeout;
        delete Object.values(temper_outlet)[0].list_scene_timer_1;

        var temper_outlet1 = Object.assign({}, temper_outlet, {
          device_id: outlet_id1,
        });

        if (list.length > 0) {
          list.forEach(scene => {
            var output = Object.values(scene)[0].Da.output;
            // var output = scene.output
            var output1 = this.fnCopy(output);

            var out = output1.forEach(e => {
              if (
                Object.keys(e)[0] != "device_id" &&
                Object.keys(e)[0] == 896
              ) {
                var data = Object.values(e)[0].Da;

                var stype = attribute_1.find(d => {
                  return Object.keys(d)[0] == data;
                });

                if (stype) {
                  var idx = output.findIndex(ot => {
                    return (
                      Object.keys(ot)[0] >= 640 && Object.keys(ot)[0] <= 648
                    );
                  });

                  if (idx >= 0) {
                    output.splice(idx, 1);
                  }
                  output.push(temper_outlet1);
                }
              }
            });
          });
        }
      }
      return this.fnCopy(list);
    },
    onSumaryCheckPDUSensor2({ list: list, cata: cata }) {
      for (var i in list) {
        var scene = list[i];
        if (
          !this.TemperHumiItem[cata].check_high &&
          !this.TemperHumiItem[cata].check_short
        ) {
          this.$set(Object.values(scene)[0], "Da", {});
        }
        if (!scene.device_id) {
          scene = Object.assign(scene, {
            device_id: this.PDU[this.modul].obj.device_id,
          });
        }

        var id =
          Object.keys(scene)[0] != "device_id" ? Object.keys(scene)[0] : null;

        var idx = this.TemperHumiItem[cata].PDUList_copy.findIndex(e => {
          return e.id == id;
        });

        if (idx == -1) {
          this.$set(Object.values(scene)[0], "Da", {});
        }
      }
    },

    onSumaryCheckPDUSensor3({ list: list, cata: cata }) {
      if (this.TemperHumiItem[cata].PDUList.length == 0) {
        var arr = [];
        var higherID = this.TemperHumiItem[cata].higher.id;
        var shorterID = this.TemperHumiItem[cata].shorter.id;
        var list_scene = this.PDU[this.modul].obj.AutoScene;

        var HigherScene =
          list.find(e => {
            return Object.keys(e)[0] == higherID;
          }) ||
          list_scene.find(e => {
            return Object.keys(e)[0] == higherID;
          });

        var ShorterScene =
          list.find(e => {
            return Object.keys(e)[0] == shorterID;
          }) ||
          list_scene.find(e => {
            return Object.keys(e)[0] == shorterID;
          });

        if (HigherScene) {
          if (this.TemperHumiItem[cata].check_high == true) {
            var scene1 = this.fnCopy(HigherScene);
            this.$set(Object.values(scene1)[0].Da, "enb", true);
            arr.push(scene1);
          }
        }
        if (ShorterScene) {
          if (this.TemperHumiItem[cata].check_short == true) {
            var scene2 = this.fnCopy(ShorterScene);
            this.$set(Object.values(scene2)[0].Da, "enb", true);
            arr.push(scene2);
          }
        }

        return arr;
      } else return [];
    },
    onDeleteSceneOutOfTimerPDU(item) {
      var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);
      var arr = [];
      if (item.outlet_sceneID) {
        var scene = scenes.filter(scene => {
          return Object.keys(scene)[0] == item.outlet_sceneID;
        });
        for (var i in scene) {
          var scene1 = this.fnCopy(scene[i]);

          this.$set(Object.values(scene1)[0], "Da", {});

          arr.push(scene1);
        }
        return arr;
      } else {
        return [];
      }
    },
    conCompareScene(data1, data2) {
      if (data1.id != data2.id) {
        return false;
      }
      if (data1.enb != data2.enb) {
        return false;
      }
      if (data1.wday.length != data2.wday.length) {
        return false;
      }
      for (var i = 0; i < data1.wday.length; i++) {
        if (data1.wday[i] != data2.wday[i]) {
          return false;
        }
      }
      if (data1.sta_dmin != data2.sta_dmin) {
        return false;
      }
      if (data1.sto_dmin != data2.sto_dmin) {
        return false;
      }
      var input1 = data1.input[0];
      var input2 = data2.input[0];
      var item1;
      var item2;
      for (let [k, v] of Object.entries(input1)) {
        if (k != "oper" && k != "device_id") {
          item1 = { id: k, value: v };
        }
      }

      for (let [k, v] of Object.entries(input2)) {
        if (k != "oper" && k != "device_id") {
          item2 = { id: k, value: v };
        }
      }
      if (item1.id != item2.id) {
        return false;
      }
      if (item1.value.Da != item2.value.Da) {
        return false;
      }

      var output1 = data1.output[0];
      var output2 = data2.output[0];
      var outlet1 = {};
      var outlet2 = {};

      for (let [k, v] of Object.entries(output1)) {
        if (k != "device_id") {
          outlet1 = { id: k, value: v };
        } else {
          outlet1 = Object.assign({}, outlet1, { device_id: v });
        }
      }

      for (let [k, v] of Object.entries(output2)) {
        if (k != "device_id") {
          outlet2 = { id: k, value: v };
        } else {
          outlet2 = Object.assign({}, outlet2, { device_id: v });
        }
      }

      if (outlet1.id != outlet2.id) {
        return false;
      }
      if (outlet1.value.Da != outlet2.value.Da) {
        return false;
      }
      if (outlet1.device_id != outlet2.device_id) {
        return false;
      }

      return true;
    },
    async onOutletDetail(i) {
      if (
        Object.values(i)[0] &&
        Object.values(i)[0].list_scene_temper &&
        Object.values(i)[0].list_scene_temper.length > 0
      ) {
        this.fnToastNotificationOnly({
          message: `${Object.values(i)[0].attribute_descriptions ||
            Object.values(i)[0].Na} đang được tự động ở thiết bị khác.`,
          type: "warning",
          reload: false,
        });
        return;
      } else {
        if (Object.values(i)[0] && Object.values(i)[0].timeout == false) {
          this.items = [];
          this.items_copy = [];
          this.onCopy(i);
          this.undo = {};
          await this.onGetScene();
          await this.sort_scene_timer_display(this.items);

          this.dialog_Outlet_settings = true;
        } else {
          this.fnToastNotificationOnly({
            message: "Đang đợi phản hồi từ thiết bị",
            type: "warning",
            reload: false,
          });
        }
      }
    },
    async onTHDetail(i) {
      this.onCopy(i);
      this.onFindID_Temper();
    },
    async onSensorDetail({ i, item }) {
      if (this.onCheckRole == false) {
        this.fnToastNotificationOnly({
          message: "Xin lỗi, bạn chưa được cấp quyền truy cập điều khiển!",
          type: "warning",
          reload: false,
        });
        return;
      }
      var avail = this.onCheckchildDeviceOnlineAvailble(item.device_id);
      if (avail) {
        var list_outlet = this.list_outlet;
        if (!list_outlet || list_outlet.length == 0) {
          this.fnToastNotificationOnly({
            message: "Không thể cài đặt (thiếu thiết bị Điều khiển nguồn)",
            type: "warning",
            reload: false,
          });
          return;
        }

        if (Object.values(i)[0] && Object.values(i)[0].timeout == false) {
          this.onCopy(i);
          await this.onGetScene_sensorControl();
          this.dialog_Sensor_settings = true;
        } else {
          this.fnToastNotificationOnly({
            message: "Đang đợi phản hồi từ thiết bị",
            type: "warning",
            reload: false,
          });
        }
      }
    },
    async onSensorWarningDetail({ i, temperature }) {
      this.onResetVaribleSensorWarning()
      if (this.onCheckRole == false) {
        this.fnToastNotificationOnly({
          message: "Xin lỗi, bạn chưa được cấp quyền truy cập điều khiển!",
          type: "warning",
          reload: false,
        });
        return;
      }
      var avail = this.onCheckchildDeviceOnlineAvailble(temperature.device_id);
      if (avail) {
        // if (!this.PDU[this.modul].obj.cLoTempAlarmSetting || this.PDU[this.modul].obj.cLoTempAlarmSetting.length === 0 ||
        // !this.PDU[this.modul].obj.cHiTempAlarmSetting || this.PDU[this.modul].obj.cHiTempAlarmSetting.length === 0 ||
        // !this.PDU[this.modul].obj.cLoHumAlarmSetting || this.PDU[this.modul].obj.cLoHumAlarmSetting.length === 0 ||
        // !this.PDU[this.modul].obj.cHiHumAlarmSetting || this.PDU[this.modul].obj.cHiHumAlarmSetting.length === 0) {
        //   this.fnToastNotificationOnly({
        //     message: "Tính năng chưa được hỗ trợ",
        //     type: "warning",
        //     reload: false
        //   });
        //   return
        // }
        this.onCopy(i)
        this.TemperWarning = [
          { text: '15p', value: 15 },
          { text: '30p', value: 30 },
          { text: '1h', value: 60 },
          { text: '2h', value: 120 },
          { text: '3h', value: 180 },
          { text: '4h', value: 240 },
          { text: '5h', value: 300 },
          { text: '6h', value: 360 },
          { text: '7h', value: 420 },
          { text: '8h', value: 480 },
          { text: '9h', value: 540 },
          { text: '10h', value: 600 }
        ]
        this.$set(this.TemperHumiItemWarning.temper.higher, 'data', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting[0] > 0 && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting[0] || this.TemperHumiItemWarning.temper.higher.data)
        this.$set(this.TemperHumiItemWarning.temper.higher, 'time', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting[1] > 0 && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting[1]  || this.TemperHumiItemWarning.temper.higher.time)
        this.$set(this.TemperHumiItemWarning.temper.higher, 'message', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting[2] !== null && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting[2] !== 'null' && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting[2] || this.TemperHumiItemWarning.temper.higher.message)

        this.$set(this.TemperHumiItemWarning.temper.shorter, 'data', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting[0] > 0 && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting[0] || this.TemperHumiItemWarning.temper.shorter.data)
        this.$set(this.TemperHumiItemWarning.temper.shorter, 'time', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting[1] > 0 && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting[1] || this.TemperHumiItemWarning.temper.shorter.time)
        this.$set(this.TemperHumiItemWarning.temper.shorter, 'message', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting[2] !== null && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting[2] !== 'null' && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting[2] || this.TemperHumiItemWarning.temper.shorter.message)

        this.$set(this.TemperHumiItemWarning.humi.higher, 'data', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting[0] > 0 && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting[0] || this.TemperHumiItemWarning.humi.higher.data)
        this.$set(this.TemperHumiItemWarning.humi.higher, 'time', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting[1] > 0 && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting[1] || this.TemperHumiItemWarning.humi.higher.time)
        this.$set(this.TemperHumiItemWarning.humi.higher, 'message', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting[2] !== null && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting[2] !== 'null' && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting[2] || this.TemperHumiItemWarning.humi.higher.message)

        this.$set(this.TemperHumiItemWarning.humi.shorter, 'data', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting[0] > 0 && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting[0] || this.TemperHumiItemWarning.humi.shorter.data)
        this.$set(this.TemperHumiItemWarning.humi.shorter, 'time', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting[1] > 0 && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting[1] || this.TemperHumiItemWarning.humi.shorter.time)
        this.$set(this.TemperHumiItemWarning.humi.shorter, 'message', this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting[2] !== null && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting[2] !== 'null' && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting[2] || this.TemperHumiItemWarning.humi.shorter.message)

        if (this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiHumiAlarmSetting[0] !== -1) {
          this.TemperHumiItemWarning.humi.check_high = true
          this.tabs_warning = 'tab-1'
        }
        if (this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoHumiAlarmSetting[0] !== -1) {
          this.TemperHumiItemWarning.humi.check_short = true
          this.tabs_warning = 'tab-1'
        }
        if (this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cHiTempAlarmSetting[0] !== -1) {
          this.TemperHumiItemWarning.temper.check_high = true
          this.tabs_warning = 'tab-0'
        }
        if (this.PDU["DeviceAttribute"].obj && Object.values(this.PDU["DeviceAttribute"].obj)[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting[0] && Object.values(this.PDU["DeviceAttribute"].obj)[0].cLoTempAlarmSetting[0] !== -1) {
          this.TemperHumiItemWarning.temper.check_short = true
          this.tabs_warning = 'tab-0'
        }
        this.dialog_Temper_Humi_warning = true
      }
    },
    // onChannelMonitorDetail(status, timeoff) {
    //   if (this.onCheckRole == false) {
    //     this.fnToast({
    //       message: "Xin lỗi, bạn chưa được cấp quyền truy cập điều khiển!",
    //       type: "warning",
    //     });
    //     return;
    //   }
    //   var avail = this.onCheckDeviceOnlineAvailble();
    //   if (avail) {
    //     if (
    //       Object.values(timeoff)[0] &&
    //       Object.values(timeoff)[0].timeout == false
    //     ) {
    //       this.onCopy(timeoff);
    //       var data = Object.values(this.PDU["AudioTimeOff"].obj)[0].Da;
    //       var time =
    //         data > 0 ? `${Math.floor(data / 3600)} h` : "Tắt thông báo";

    //       this.$set(this.PDU["AudioTimeOff"].obj, "timer", time);
    //       this.$set(
    //         this.PDU["AudioTimeOff"].obj,
    //         "name",
    //         Object.values(status)[0].attribute_descriptions ||
    //           Object.values(status)[0].Na
    //       );

    //       this.timerOff = [];
    //       this.timerOff.push(`Tắt thông báo`);
    //       for (var i = 1; i < 25; i++) {
    //         this.timerOff.push(`${i} h`);
    //       }

    //       this.dialog_ChannelMonitor_settings = true;
    //     } else {
    //       this.fnToast({
    //         message: "Đang đợi phản hồi từ thiết bị",
    //         type: "warning",
    //       });
    //     }
    //   }
    // },
    onResetitem_CM_setting () {
      this.item_CM_setting = {
        picker_start: false,
        picker_end: false,
        time_start: null,
        time_end: null,
        mode_allway_on: true,
        message_error: null,
        message_normal: null,
        message_error_default: null,
        message_normal_default: null,
        channel_description: null,
        status_key: null,
        noti_content_key: null
      }
    },
    onChannelMonitorDetail(status, timehealthy) {
      if (this.onCheckRole == false) {
        this.fnToast({
          message: "Xin lỗi, bạn chưa được cấp quyền truy cập điều khiển!",
          type: "warning",
        });
        return;
      }
      var avail = this.onCheckDeviceOnlineAvailble();
      if (avail) {
        if (this.onCheckManualReboot() === false) {
          if (timehealthy === null || timehealthy === undefined || (Object.values(timehealthy)[0].Da.En === null || Object.values(timehealthy)[0].Da.En === '') || (Object.values(timehealthy)[0].Da.Ds === null || Object.values(timehealthy)[0].Da.Ds === '')) {
            this.fnToast({
              message: "Sự cố cài đặt kênh",
              type: "warning",
            });
            return
          }
          const noti_content_channel = [
            { 1920: 1984 },
            { 1921: 1985 },
            { 1922: 1986 },
            { 1923: 1987 },
            { 1924: 1988 },
            { 1925: 1989 },
            { 1926: 1990 },
            { 1927: 1991 },
            { 1928: 1992 },
            { 1929: 1993 },
            { 1930: 1994 },
            { 1931: 1995 },
            { 1932: 1996 },
            { 1933: 1997 },
            { 1934: 1998 },
            { 1935: 1999 }
          ]
          var message_error = null
          var message_normal = null
          var noti_content_key = null
          var status_key = Object.keys(status)[0]
          var index = noti_content_channel.findIndex(e => { return Object.keys(e)[0] === status_key })
          if (index >= 0) {
            noti_content_key = Object.values(noti_content_channel[index])[0]
            var noti_content_value = this.PDU[this.modul].obj.noti_content[noti_content_key]
            if (noti_content_value && noti_content_value.length > 0) {
              message_normal = noti_content_value[0]
              message_error = noti_content_value[1]
            }
          }
          this.onResetitem_CM_setting()
          var time_start = Object.values(timehealthy)[0].Da.En
          var time_end = Object.values(timehealthy)[0].Da.Ds
          var time_start1 = this.onGetTimeSettingCM(time_start)
          var time_end1 = this.onGetTimeSettingCM(time_end)
          var channel_description = Object.values(status)[0].attribute_descriptions || Object.values(status)[0].attribute_descriptions_copy
          var message_error_default = `đã tắt`
          var message_normal_default = `đã bật`

          var mode_allway_on = true
          if (time_start1 === '00:00' && (time_end1 === '23:59' || Math.floor(time_end / 60) === 1439)) {
            mode_allway_on = true
          } else {
            mode_allway_on = false
          }
          this.$set(this.item_CM_setting, 'time_start', mode_allway_on === true ? null : time_start1)
          this.$set(this.item_CM_setting, 'time_end', mode_allway_on === true ? null : time_end1)
          this.$set(this.item_CM_setting, 'mode_allway_on', mode_allway_on)
          this.$set(this.item_CM_setting, 'message_error_default', message_error_default)
          this.$set(this.item_CM_setting, 'message_normal_default', message_normal_default)
          this.$set(this.item_CM_setting, 'message_error', message_error || this.item_CM_setting.message_error_default)
          this.$set(this.item_CM_setting, 'message_normal', message_normal || this.item_CM_setting.message_normal_default)
          this.$set(this.item_CM_setting, 'channel_description', channel_description)
          this.$set(this.item_CM_setting, 'status_key', status_key)
          this.$set(this.item_CM_setting, 'noti_content_key', noti_content_key)
          this.dialog_ChannelMonitor_settings = true
        }
      }
    },
    onConvertDay(data) {
      return data ? `${data > 0 ? `Thứ ${data + 1}` : `Chủ nhật`}` : "Chủ nhật";
    },
    onConvertSecondToTimer (sec) {
      var minute = Math.floor(sec / 60)
      var second = sec % 60
      var minute1 = minute < 10 ? '0' + minute : minute
      var second1 = second < 10 ? '0' + second : second
      return minute1 + ':' + second1
    },
    onGetTimeSettingCM (data) {
      var d = new Date(data*1000)
      var hour = parseInt(d.getHours())
      var minute = parseInt(d.getMinutes())
      return data
        ? `${hour < 10 ? `0${hour}` : `${hour}`}:${
            minute < 10 ? `0${minute}` : `${minute}`
          }`
        : "00:00";
    },
    onConvertTimeToTimestamp (data) {
      var d = new Date()
      var month = d.getMonth()
      var date = d.getDate()
      var year = d.getFullYear()
      var time = data.split(':')
      var x =  Date.UTC(
        year,
        month,
        date,
        parseInt(time[0]),
        parseInt(time[1]),
        time[2] && parseInt(time[2]) || '00'
      )
      var y = Math.floor(x / 1000) + d.getTimezoneOffset() * 60
      return y
    },
    onConvertMilestone(data) {
      var d = new Date();
      var hour =
        Math.floor((data - d.getTimezoneOffset()) / 60) < 24
          ? Math.floor((data - d.getTimezoneOffset()) / 60)
          : Math.floor((data - d.getTimezoneOffset() - 1440) / 60);
      var minute = Math.floor((data - d.getTimezoneOffset()) % 60);
      return data
        ? `${hour < 10 ? `0${hour}` : `${hour}`}:${
            minute < 10 ? `0${minute}` : `${minute}`
          }`
        : "00:00";
    },
    onConvertNumber(data) {
      var arr1 = data.split(":");
      var time = parseInt(arr1[0]) * 60 + parseInt(arr1[1]);
      return time;
    },
    onConvertTimer(data) {
      var d = new Date();
      var data1 = data - d.getTimezoneOffset();
      var hour = Math.floor((data1 > 1440 ? data1 - 1440 : data1) / 60);
      var minute = Math.floor(data1 % 60);
      return data
        ? `${hour < 10 ? `0${hour}` : `${hour}`}:${
            minute < 10 ? `0${minute}` : `${minute}`
          }`
        : "00:00";
    },
    onConvertTimer_without_timzone(data) {
      var data1 = data;
      var hour = Math.floor((data1 > 1440 ? data1 - 1440 : data1) / 60);
      var minute = Math.floor(data1 % 60);
      return data
        ? `${hour < 10 ? `0${hour}` : `${hour}`}:${
            minute < 10 ? `0${minute}` : `${minute}`
          }`
        : "00:00";
    },
    onConvertMilestone_pdu(data) {
      var d = new Date();
      var day = Math.floor((data - d.getTimezoneOffset()) / 1440);
      var hour = Math.floor((data - d.getTimezoneOffset() - day * 1440) / 60);
      var minuter = data - d.getTimezoneOffset() - day * 1440 - hour * 60;
      return data
        ? `${hour < 10 ? `0${hour}` : `${hour}`}:${
            minuter < 10 ? `0${minuter}` : `${minuter}`
          }`
        : "00:00";
    },
    onConvertVolume(data) {
      return data ? `${data / 100}` : 0;
    },
    onConvertDay_Number(data) {
      var arr = [];
      data.forEach(e => {
        switch (e) {
          case "Chủ nhật": {
            arr.push(0);
            break;
          }
          case "Thứ 2": {
            arr.push(1);
            break;
          }
          case "Thứ 3": {
            arr.push(2);
            break;
          }
          case "Thứ 4": {
            arr.push(3);
            break;
          }
          case "Thứ 5": {
            arr.push(4);
            break;
          }
          case "Thứ 6": {
            arr.push(5);
            break;
          }
          case "Thứ 7": {
            arr.push(6);
            break;
          }
        }
      });
      return arr;
    },

    onCaculator(e) {
      if (e) {
        this.protectItem.MaxCurrent_unit == "mA"
          ? (this.protectItem.MaxCurrent_content =
              this.protectItem.MaxCurrent_content * 1000)
          : (this.protectItem.MaxCurrent_content =
              this.protectItem.MaxCurrent_content / 1000);
      } else {
        this.protectItem.MinCurrent_unit == "mA"
          ? (this.protectItem.MinCurrent_content =
              this.protectItem.MinCurrent_content * 1000)
          : (this.protectItem.MinCurrent_content =
              this.protectItem.MinCurrent_content / 1000);
      }
    },
    onCheckAvalable() {
      if (
        (this.protectItem.Max_check && !this.protectItem.MaxCurrent_content) ||
        (this.protectItem.Min_check && !this.protectItem.MinCurrent_content)
      ) {
        return false;
      } else {
        return true;
      }
    },
    onGetScenefromStorage({ parent_device_id, device_id, key }) {
      var sceneStorage = localStorage.getItem(
        parent_device_id + "/" + device_id
      );
      if (sceneStorage) {
        var arr = JSON.parse(sceneStorage);

        var outlet = arr.find(e => {
          return e && Object.keys(e)[0] == key;
        });

        return outlet;
      } else {
        return null;
      }
    },
    onCheckUndoScene() {
      var arr = [];
      var list = this.undo.list_scene;
      for (var i in list) {
        var item = list[i];
        var key = Object.keys(item)[0];
        var value = Object.values(item)[0];

        var scene = this.PDU[this.modul].obj.AutoScene.find(e => {
          return e && Object.keys(e)[0] == key;
        });

        if (scene) {
          var data = Object.values(scene)[0].Da;

          if (data && data.output && data.input) {
            var id = this.onCheckAutoSceneID();
            var obj = new Object();
            obj[id] = value;

            arr.push(obj);
          } else {
            arr.push(item);
          }
        }
      }
      return arr;
    },
    async onBeforePostUndoScene({ list: list }) {
      var arr = [];

      arr = this.onCheckUndoScene({ list: list });

      if (arr.length > 0) {
        var obj = arr.reduce(function(result, item) {
          var key = Object.keys(item)[0];
          result[key] = item[key];
          return result;
        });

        var attribute_post = Object.assign({}, obj, {
          device_id: this.PDU[this.modul].obj.device_id,
        });

        var attribute = {
          user_name: this.PDU.userSession.username,
          attribute_list: [attribute_post],
        };

        await this.fnIotPost(attribute);

        var device = this.PDU[this.modul].obj.child_attribute.find(device => {
          return device.device_id == this.undo.device_id;
        });

        await this.onFindAsynchronous(device.list_outled);
      } else {
      }
    },
    async disableScenes({ item: item }) {
      var attribute_push = [];
      var attribute_setting = [];

      if (Object.values(item)[0] && Object.values(item)[0].timeout == false) {
        if (
          Object.values(item)[0] &&
          Object.values(item)[0].Auto == true &&
          Object.values(item)[0].list_scene_temper.length == 0 &&
          Object.values(item)[0].list_scene_timer.length == 0
        ) {
          var outlet = this.onGetScenefromStorage({
            parent_device_id: this.PDU[this.modul].obj.device_id,
            device_id: Object.values(item)[0].device_id,
            key: Object.keys(item)[0],
          });

          if (
            outlet &&
            Object.values(outlet)[0] &&
            Object.values(outlet)[0].list_scene_timer &&
            Object.values(outlet)[0].list_scene_timer.length > 0
          ) {
            this.items = [];
            this.items_copy = [];

            this.undo.list_scene = Object.values(outlet)[0].list_scene_timer;
            var list_scene_timer = this.onCheckUndoScene();
            this.$set(
              Object.values(outlet)[0],
              "list_scene_timer",
              list_scene_timer
            );

            Object.values(outlet)[0].list_scene_timer;

            this.onCopy(item);
            await this.onGetScene_outled_by_undosccene({
              list: Object.values(outlet)[0].list_scene_timer,
            });
            await this.sort_scene_timer_display(this.items);

            this.dialog_Outlet_settings = true;
          } else {
            this.onOutletDetail(item);
          }
        } else {
          this.item_outlet = item;
          this.dialog_confirm_changeto_normal = true;
        }

        // if ( Object.values(item)[0] && Object.values(item)[0].Auto == true && Object.values(item)[0].list_scene_temper.length == 0 && Object.values(item)[0].list_scene_timer.length == 0) {
        //   var outlet = this.onGetScenefromStorage({
        //     parent_device_id: this.PDU[this.modul].obj.device_id,
        //     device_id: Object.values(item)[0].device_id,
        //     key: Object.keys(item)[0],
        //   });
        //   this.undo = {};
        // if (
        //   outlet &&
        //   Object.values(outlet)[0] &&
        //   Object.values(outlet)[0].list_scene_temper &&
        //   Object.values(outlet)[0].list_scene_temper.length > 0
        // ) {

        //   var list = Object.values(outlet)[0].list_scene_temper
        //   var listPDUSensor = Object.values(outlet)[0].listPDUSensor
        //   var device_id = list[0] && Object.values(list[0])[0] && Object.values(list[0])[0].Da
        //   && Object.values(list[0])[0].Da.input && Object.values(list[0])[0].Da.input[0]
        //   && Object.values(list[0])[0].Da.input[0].device_id

        //   var device = this.PDU[this.modul].obj.child_attribute &&
        //   this.PDU[this.modul].obj.child_attribute.find(e => {
        //     return e.device_id == device_id
        //   })

        //   if(device){
        //     var list_temper = device.list_temper
        //     var temper = list_temper[0]

        //     this.undo.list_scene = list

        //     this.onCopy(temper);
        //     await this.onGetScene_sensor_by_undosccene({list: list, listPDUSensor: listPDUSensor});
        //     this.dialog_Sensor_settings = true;
        //   }

        // } else
        // if (
        //   outlet &&
        //   Object.values(outlet)[0] &&
        //   Object.values(outlet)[0].list_scene_timer &&
        //   Object.values(outlet)[0].list_scene_timer.length > 0
        // ) {

        //   this.items = [];
        //   this.items_copy = [];

        //   this.undo.list_scene = Object.values(outlet)[0].list_scene_timer
        //   this.onCopy(item);
        //   await this.onGetScene_outled_by_undosccene({list: Object.values(outlet)[0].list_scene_timer})
        //   await this.sort_scene_timer_display(this.items);

        //   this.dialog_Outlet_settings = true;
        // } else {
        //   this.onOutletDetail(item)

        // }
        // } else {
        //   this.item_outlet = item;
        //   this.dialog_confirm_changeto_normal = true;
        // }
      } else {
        // this.fnToastNotificationOnly({
        //     message: "Đang đợi phản hồi từ thiết bị",
        //     type: "warning",
        //     reload: false,
        //   });
        this.dialog_noti_with_reset_auto_manual = true;
      }
    },
    async disableScenes_1() {
      var item = this.item_outlet;
      var sonnt = this.fnCopy(this.item_outlet)
      var attribute_push = [];
      var attribute_setting = [];

      const searching = (list, key) =>
          list.find(i => Object.keys(i)[0] == key);

      if (Object.values(item)[0] && Object.values(item)[0].Auto == false) {
        if (this.onCheckRole() == false) {
          this.fnToast({
            message: "Bạn chưa được cấp quyền truy cập điều khiển!",
            type: "warning",
          });
          return;
        }

        var list_scene_timer =
          Object.values(item)[0] && Object.values(item)[0].list_scene_timer_1
            ? Object.values(item)[0].list_scene_timer_1
            : [];
        var list_scene_temper =
          Object.values(item)[0] && Object.values(item)[0].list_scene_temper
            ? Object.values(item)[0].list_scene_temper
            : [];

        if (list_scene_timer && list_scene_timer.length > 0) {
          list_scene_timer.forEach(scene => {
            attribute_push.push(scene);
          });
        }

        //find scene timer in sensor

        for (var i in list_scene_temper) {
          var it = list_scene_temper[i];
          var id = Object.keys(it)[0];

          this.PDU[this.modul].obj.AutoScene.forEach(scene => {
            var data = Object.values(scene)[0].Da;

            if (!this.isEmpty(data)) {
              var output = data.output ? data.output : [];

              output.forEach(out => {
                if (
                  Object.keys(out)[0] != "device_id" &&
                  (Object.keys(out)[0] == "896" || Object.keys(out)[0] == "832")
                ) {
                  if (Object.values(out)[0].Da === id) {
                    var index = attribute_push.findIndex(e => { return Object.keys(e)[0] == Object.keys(scene)[0] })
                    index < 0 ? attribute_push.push(scene) : ''
                    return
                  }
                }
              });
            }
          });
        }

        // get scene pduSensor have output are scene Sensor

        var list_scene_pdusensor = []
        this.PDU[this.modul].obj.AutoScene.forEach(scene => {

          var data = Object.values(scene)[0].Da;
          var id = Object.keys(scene)[0];

          if (!this.isEmpty(data)) {
            var input = data.input ? data.input : [];

            var output = data.output ? data.output : [];

            output.forEach(out => {

              if ( Object.keys(out)[0] != "device_id" && (Object.keys(out)[0] == "896" || Object.keys(out)[0] == "832")) {

                var da = Object.values(out)[0].Da;

                var sc = searching(list_scene_temper, da);
                if (sc) {
                  var idx = list_scene_pdusensor.findIndex(n=>{return n && Object.keys(n)[0] == id})
                  if (idx < 0) { list_scene_pdusensor.push(scene); attribute_push.push(scene) }
                }
              }
            })

          }

        })

        if (list_scene_temper && list_scene_temper.length > 0) {
          list_scene_temper.forEach(scene => {
            attribute_push.push(scene);
          });
        }

        if (attribute_push.length > 0) {
          attribute_setting = this.onsetAttributePDU(
            this.fnCopy(attribute_push)
          );

          var obj = attribute_setting.reduce(function(result, item) {
            var key = Object.keys(item)[0];
            result[key] = item[key];
            return result;
          });

          var attribute_post = Object.assign({}, obj, {
            device_id: this.PDU[this.modul].obj.device_id,
          });

          var attribute = {
            user_name: this.PDU.userSession.username,
            attribute_list: [attribute_post],
          };

          await this.fnIotPost(attribute);

          var device = this.PDU[this.modul].obj.child_attribute.find(device => {
            return device.device_id == Object.values(item)[0].device_id;
          });

          // this.$set(Object.values(item)[0], 'listPDUSensor', list_scene_pdusensor)

          await this.fnSetScenetoLocalStorage({ device: device, item: item });

          await this.onFindAsynchronous(device.list_outled);
        }
      }
    },
    onsetAttributePDU(attribute_push) {
      var attribute = [];
      attribute_push.forEach(e => {
        Object.values(e)[0].Da = {};
        attribute.push(e);
      });
      return attribute;
    },
    getOutletofTemperControl(id_outlet_obj) {
      var result = {};
      for (var i in this.PDU[this.modul].obj.child_attribute) {
        if (
          this.PDU[this.modul].obj.child_attribute[i].list_outled &&
          this.PDU[this.modul].obj.child_attribute[i].list_outled.length > 0
        ) {
          var result = this.PDU[this.modul].obj.child_attribute[
            i
          ].list_outled.find(outlet => {
            return (
              Object.keys(outlet)[0] == id_outlet_obj.id_outlet &&
              Object.values(outlet)[0].device_id == id_outlet_obj.device_id
            );
          });

          if (result) {
            return result;
          }
        }
      }

      return result;
    },
    onFindID_Temper() {
      var item = this.PDU["DeviceAttribute"].obj;
      var list = Object.values(item)[0].list_scene_temper
        ? Object.values(item)[0].list_scene_temper
        : null;

      var child_attribute = this.fnCopy(
        this.PDU[this.modul].obj.child_attribute
      );
      var found = 0;
      if (list.length > 0) {
        for (var obj of list) {
          if (obj) {
            var value = Object.values(obj)[0];
            var input = value.Da.input ? value.Da.input[0] : {};

            var device_id = input.device_id;
            var item_id_temper =
              Object.keys(input)[0] != "device_id" &&
              Object.keys(input)[0] != "oper"
                ? Object.keys(input)[0]
                : "";
            var item_id_humi =
              Object.keys(input)[0] != "device_id" &&
              Object.keys(input)[0] != "oper"
                ? Object.keys(input)[0]
                : "";

            child_attribute.forEach(device => {
              if (
                device.device_id == device_id &&
                device.list_temper &&
                device.list_temper.length > 0
              ) {
                var item = this.fnCopy(device.list_temper[0]);
                if (
                  Object.keys(item)[0] == item_id_temper ||
                  Object.keys(Object.values(item)[0].Humi)[0] == item_id_humi
                ) {
                  if (device.active) {
                    found = 1;
                    this.onSensorDetail(item, item);
                    return;
                  }
                }
              }
            });
          }
        }
      } else {
        this.fnToastNotificationOnly({
          message: ` ${Object.values(item)[0].attribute_descriptions ||
            Object.values(item)[0].Na} chưa được cài đặt nhiệt độ, độ ẩm`,
          type: "warning",
          reload: false,
        });
        return;
      }

      if (found == 0) {
        this.fnToastNotificationOnly({
          message: "Thiết bị [Nhiệt độ, độ ẩm] không tồn tại",
          type: "warning",
          reload: false,
        });
        return;
      }
    },

    sort_scene_timer_display(list) {
      var d = new Date();
      list.sort(function(a, b) {
        var arr = a.timer.split(":");
        var time =
          parseInt(arr[0]) * 60 + parseInt(arr[1]) + d.getTimezoneOffset();
        var arr1 = b.timer.split(":");
        var time1 =
          parseInt(arr1[0]) * 60 + parseInt(arr1[1]) + d.getTimezoneOffset();
        return time - time1;
      });
    },
    async sort_scene_timer_setting(list) {
      var timeLT = null;
      var len = list.length;

      list.forEach(e => {
        //khi các kịch bản đã có 2 input
        if (e.input && e.input.length == 2) {
          var idx = e.input.findIndex(d => {
            d.oper && d.oper == "LT";
          });

          e.input.splice(idx, 1);
        }
      });

      var index = list.find(e => {
        return e.input[0].day_min.Da == 0;
      });
      ////

      if (index && index != -1) {
        index.output = list[len - 1].output;

        var scene1 = this.PDU["Scene"].list.find(e => {
          return Object.keys(e)[0] == index.id;
        });

        if (scene1) {
          this.$set(Object.values(scene1)[0].Da, "output", index.output);
        }
      }

      ////

      for (var i = 0; i < list.length; i++) {
        var item = list[i];

        if (
          item.input &&
          item.input.length == 1 &&
          !list[i + 1] &&
          this.items.length > 0
        ) {
          // thêm LT 1440 cho kịch bản cuối cùng có input length = 1

          var scene = this.PDU["Scene"].list.find(e => {
            return Object.keys(e)[0] == item.id;
          });

          var value = Object.values(scene)[0].Da;

          var input = value.input;

          input.push({
            day_min: { Da: 1440, Dt: "UINT32" },
            device_id: this.PDU[this.modul].obj.device_id,
            oper: "LT",
          });
        }

        if (item.input && item.input.length == 1 && list[i + 1]) {
          // chỉ thêm LT cho những kịch bản có input = 1

          var scene = this.PDU["Scene"].list.find(e => {
            return Object.keys(e)[0] == item.id;
          });

          if (scene) {
            var key = Object.keys(scene)[0];
            var value = Object.values(scene)[0].Da;

            var input = value.input;

            var ip = list[i + 1] ? list[i + 1].input : [];

            for (var j in ip) {
              if (ip[j] && ip[j].oper && ip[j].oper == "GE") {
                timeLT = ip[j] && ip[j].day_min ? ip[j].day_min.Da : null;

                timeLT != null
                  ? input.push({
                      day_min: { Da: timeLT, Dt: "UINT32" },
                      device_id: this.PDU[this.modul].obj.device_id,
                      oper: "LT",
                    })
                  : "";
              }
            }
          }
        }
      }
    },
    globalsort_scene_timer_setting({ list: list, cata: cata }) {
      var timeLT = null;

      var len = list.length;

      list.forEach(e => {
        //khi các kịch bản đã có 2 input
        if (e.input && e.input.length == 2) {
          var idx = e.input.findIndex(d => {
            d.oper && d.oper == "LT";
          });

          e.input.splice(idx, 1);
        }
      });

      var index = list.find(e => {
        return e.input[0].day_min.Da == 0;
      });
      //// tim kich ban 00 va set output cho kich ban do

      if (index) {
        index.output = list[len - 1].output;

        var scene1 = this.TemperHumiItem[cata].listScene.find(e => {
          return Object.keys(e)[0] == index.id;
        });

        if (scene1) {
          this.$set(Object.values(scene1)[0].Da, "output", index.output);
        }
      }

      ////

      for (var i = 0; i < list.length; i++) {
        var item = list[i];

        if (
          item.input &&
          item.input.length == 1 &&
          !list[i + 1] &&
          this.TemperHumiItem[cata].PDUList.length > 0
        ) {
          // error here
          // thêm LT 1440 cho kịch bản cuối cùng có input length = 1

          var scene = this.TemperHumiItem[cata].listScene.find(e => {
            return Object.keys(e)[0] == item.id;
          });

          var value = Object.values(scene)[0].Da;

          var input = value.input;

          input.push({
            day_min: { Da: 1440, Dt: "UINT32" },
            device_id: this.PDU[this.modul].obj.device_id,
            oper: "LT",
          });
        }

        if (item.input && item.input.length == 1 && list[i + 1]) {
          // chỉ thêm LT cho những kịch bản có input = 1

          var scene = this.TemperHumiItem[cata].listScene.find(e => {
            return Object.keys(e)[0] == item.id;
          });

          if (scene) {
            var key = Object.keys(scene)[0];
            var value = Object.values(scene)[0].Da;

            var input = value.input;

            var ip = list[i + 1] ? list[i + 1].input : [];

            for (var j in ip) {
              if (ip[j] && ip[j].oper && ip[j].oper == "GE") {
                timeLT = ip[j] && ip[j].day_min ? ip[j].day_min.Da : null;

                timeLT != null
                  ? input.push({
                      day_min: { Da: timeLT, Dt: "UINT32" },
                      device_id: this.PDU[this.modul].obj.device_id,
                      oper: "LT",
                    })
                  : "";
              }
            }
          }
        }
      }

      var arr = this.fnCopy(this.TemperHumiItem[cata].listScene);
      return arr;
    },

    onRenderSceneID() {
      var tempt = this.TemperHumiItem;
      if (tempt.temper.check_high == true) {
        if (tempt.temper.higher.id == null) {
          tempt.temper.higher.id = this.onCheckAutoSceneID();
          if (tempt.temper.higher.id == null) {
            return;
          }

          this.PDU[this.modul].obj.list_sceneID.push(tempt.temper.higher.id);
          return;
        }
      }

      if (tempt.temper.check_short == true) {
        if (tempt.temper.shorter.id == null) {
          tempt.temper.shorter.id = this.onCheckAutoSceneID();
          if (tempt.temper.shorter.id == null) {
            return;
          }

          this.PDU[this.modul].obj.list_sceneID.push(tempt.temper.shorter.id);
          return;
        }
      }
      if (tempt.humi.check_high == true) {
        if (tempt.humi.higher.id == null) {
          tempt.humi.higher.id = this.onCheckAutoSceneID();
          if (tempt.humi.higher.id == null) {
            return;
          }

          this.PDU[this.modul].obj.list_sceneID.push(tempt.humi.higher.id);
          return;
        }
      }

      if (tempt.humi.check_short == true) {
        if (tempt.humi.shorter.id == null) {
          tempt.humi.shorter.id = this.onCheckAutoSceneID();
          if (tempt.humi.shorter.id == null) {
            return;
          }

          this.PDU[this.modul].obj.list_sceneID.push(tempt.humi.shorter.id);
          return;
        }
      }
    },

    onAddScene_PDUSensor(cata) {
      var sensor;

      if (cata == "temper") {
        sensor = this.fnCopy(this.PDU["Sensor"].obj);
      } else if (cata == "humi") {
        sensor = this.fnCopy(this.PDU["Sensor"].obj);
      } else {
        return;
      }

      if (sensor.editedItem.id == null) {
        // tao moi
        var idx = this.TemperHumiItem[cata].PDUList.findIndex(e => {
          return e.timer == this.PDU["Sensor"].obj.editedItem.timer;
        });

        if (idx != -1) {
          this.fnToastNotificationOnly({
            message: "Vui lòng chọn giờ khác!",
            type: "error",
            reload: false,
          });
          return;
        }
        //tao moi 1 kich ban pdu
        var onCr1 = this.onCreate_PDUSensor(sensor);

        if (this.data.id == null) {
          return;
        }

        var result = this.fnCopy(onCr1);

        this.initialize_PDUSensor({
          in: result,
          out: this.TemperHumiItem[cata],
        });

        this.TemperHumiItem[cata].listScene.push(
          this.fnCopy(this.PDU["Scene"].obj)
        );
      } else {
        // something is wrong
        // sua kich ban

        var index = this.TemperHumiItem[cata].PDUList.findIndex(e => {
          return e.id && e.id == sensor.editedItem.id;
        });

        var index1 = this.TemperHumiItem[cata].PDUList_copy.findIndex(e => {
          return e.id && e.id == sensor.editedItem.id;
        });

        // xoa kich ban cu
        this.TemperHumiItem[cata].PDUList.splice(index, 1);
        this.TemperHumiItem[cata].PDUList_copy.splice(index1, 1);

        var onCr2 = this.onCreate_PDUSensor(sensor);
        if (this.data.id == null) {
          return;
        }

        var result = this.fnCopy(onCr2);

        this.initialize_PDUSensor({
          in: result,
          out: this.TemperHumiItem[cata],
        });

        // var idx = this.PDU["Scene"].list.findIndex(e => {
        //   return Object.keys(e)[0] == sensor.editedItem.id;
        // });
        // this.PDU["Scene"].list.splice(idx, 1);
        // this.PDU["Scene"].list.push(this.fnCopy(this.PDU["Scene"].obj)); //push kich ban moi

        // var idx = this.TemperHumiItem[cata].listScene.findIndex(e => {
        //   return Object.keys(e)[0] == sensor.editedItem.id;
        // });
        // this.TemperHumiItem[cata].listScene.splice(idx, 1);

        this.TemperHumiItem[cata].listScene.push(
          this.fnCopy(this.PDU["Scene"].obj)
        );
      }

      this.sort_scene_display(this.TemperHumiItem[cata].PDUList);
    },
    onAddScene_OutletControl() {
      if (this.editedItem.id == null) {
        //tao moi
        var idx = this.items.findIndex(e => {
          return e && e.timer == this.editedItem.timer;
        });

        if (idx != -1) {
          this.fnToastNotificationOnly({
            message: "Vui lòng chọn giờ khác!",
            type: "error",
            reload: false,
          });
          return;
        }

        var onCr1 = this.onCreate();

        if (this.data.id == null) {
          this.dialog = false;
          return;
        }

        var result = this.fnCopy(onCr1);

        this.initialize(result);

        this.PDU["Scene"].list.push(this.fnCopy(this.PDU["Scene"].obj));
      } else {
        //edit

        var index = this.items.findIndex(e => {
          return e.id && e.id == this.editedItem.id;
        });
        var index1 = this.items_copy.findIndex(e => {
          return e.id && e.id == this.editedItem.id;
        });

        // xoa kich ban cu
        this.items.splice(index, 1);
        this.items_copy.splice(index1, 1);

        var onCr2 = this.onCreate();

        if (this.data.id == null) {
          this.dialog = false;
          return;
        }

        var result = this.fnCopy(onCr2);

        this.initialize(result);

        var idx = this.PDU["Scene"].list.findIndex(e => {
          return Object.keys(e)[0] == this.editedItem.id;
        });

        this.PDU["Scene"].list.splice(idx, 1);
        this.PDU["Scene"].list.push(this.fnCopy(this.PDU["Scene"].obj));
      }
      this.dialog = false;
      this.sort_scene_timer_display(this.items);
    },
    onAddSceneTemperControl(TemperHumiItem, temper) {
      var tempt = this.fnCopy(TemperHumiItem);
      var device_id =
        Object.values(this.PDU["DeviceAttribute"].obj)[0].device_id ||
        this.PDU["DeviceDetail"].obj.device_id;
      var obj = {};
      var list_attribute = [];
      var input;
      var output;
      var obj_data;
      var obj1 = {};
      var d = new Date();

      //////////////////////////////////////////////////////////////////////
      if (tempt.higher.id || tempt.check_high) {
        var item = this.fnCopy(temper);
        var data = tempt.higher;
        //output
        if (!tempt.select || !tempt.select.id_outlet.id_outlet) {
          this.fnToastNotificationOnly({
            message: "Vui lòng chọn ổ cắm",
            type: "error",
            reload: false,
          });
          return "null";
        }

        var outlet = this.fnCopy(
          this.list_outlet1.find(outlet => {
            return Object.keys(outlet)[0] == tempt.select.id_outlet;
          }) || this.getOutletofTemperControl(tempt.select.id_outlet)
        );

        var outlet_id = this.fnCopy(Object.values(outlet)[0].device_id);
        Object.values(outlet)[0].Da = data.status;

        delete Object.values(outlet)[0].list_scene_temper;
        delete Object.values(outlet)[0].list_scene_timer;
        delete Object.values(outlet)[0].Auto;
        delete Object.values(outlet)[0].device_id;
        delete Object.values(outlet)[0].attribute_descriptions;
        delete Object.values(outlet)[0].Pe;
        delete Object.values(outlet)[0].Cl;
        delete Object.values(outlet)[0].Re;
        delete Object.values(outlet)[0].Un;
        delete Object.values(outlet)[0].Na;
        delete Object.values(outlet)[0].hardware;
        delete Object.values(outlet)[0].timeout;
        delete Object.values(outlet)[0].list_scene_timer_1;

        //input

        delete Object.values(item)[0].list_scene_temper;
        delete Object.values(item)[0].Humi;
        delete Object.values(item)[0].device_id;
        delete Object.values(item)[0].attribute_descriptions;
        delete Object.values(item)[0].Pe;
        delete Object.values(item)[0].Cl;
        delete Object.values(item)[0].Re;
        delete Object.values(item)[0].Un;
        delete Object.values(item)[0].Na;
        delete Object.values(item)[0].hardware;
        delete Object.values(item)[0].temper_outlet_status;
        delete Object.values(item)[0].humi_outlet_status;
        delete Object.values(item)[0].timeout;
        delete Object.values(item)[0].list_scene_timer_1;

        if (data.data != "" && data.data >= 0 && data.data <= 100) {
          // this.$set(Object.values(item)[0], 'Da', parseFloat(parseFloat(data.data).toFixed(1)))
          Object.values(item)[0].Da = parseFloat(
            parseFloat(data.data).toFixed(1)
          );
        } else {
          this.fnToast({
            message: "Có lỗi xảy ra, vui lòng kiểm tra lại",
            type: "error",
          });
          return null;
        }

        input = Object.assign({}, item, { oper: "GT", device_id: device_id });

        output = Object.assign({}, outlet, {
          device_id:
            outlet_id ||
            Object.values(this.PDU["DeviceAttribute"].obj)[0].device_id ||
            this.PDU["DeviceDetail"].obj.device_id,
        });
        this.data.id = null;
        this.data.id = tempt.higher.id || this.onCheckAutoSceneID();
        if (this.data.id == null) {
          return;
        }
        this.PDU[this.modul].obj.list_sceneID.push(this.data.id);

        var st = this.onConvertNumber(tempt.sto_dmin) + d.getTimezoneOffset();
        st < 0 ? (st = st + 1440) : (st = st);

        var st1 = this.onConvertNumber(tempt.sta_dmin) + d.getTimezoneOffset();
        st1 < 0 ? (st1 = st1 + 1440) : (st1 = st1);

        obj[this.data.id] = {
          Da: {
            enb: true,
            wday: [0, 1, 2, 3, 4, 5, 6],
            // this.onConvertDay_Number(tempt.wday)
            // sta_dmin: st1,
            // sto_dmin: st == 1439? st = st +1: st,
            sta_dmin: 0,
            sto_dmin: 1440,
            cond: "AND",
            input: [input],
            output: [output],
          },
          Dt: "OBJECT",
        };
        if (tempt.check_high == false) {
          this.$set(Object.values(obj)[0], "Da", {});
        }

        //===========================================================//
        if (tempt.higher.old_scene) {
          var cmp = this.conCompareScene(
            Object.values(obj)[0].Da,
            Object.values(tempt.higher.old_scene)[0].Da
          );
        }
        //===========================================================//
        if (!cmp) {
          list_attribute.push(obj);
        }
      }

      //////////////////////////////////////////////////////////////
      if (tempt.shorter.id || tempt.check_short) {
        var item = this.fnCopy(temper);
        var data1 = tempt.shorter;
        //output
        if (!tempt.select || !tempt.select.id_outlet.id_outlet) {
          this.fnToastNotificationOnly({
            message: "Vui lòng chọn ổ cắm",
            type: "error",
            reload: false,
          });
          return "null";
        }

        var outlet1 = this.fnCopy(
          this.list_outlet1.find(outlet => {
            return Object.keys(outlet)[0] == tempt.select.id_outlet;
          }) || this.getOutletofTemperControl(tempt.select.id_outlet)
        );

        var outlet_id = this.fnCopy(Object.values(outlet1)[0].device_id);

        Object.values(outlet1)[0].Da = data1.status;

        delete Object.values(outlet1)[0].list_scene_temper;
        delete Object.values(outlet1)[0].list_scene_timer;
        delete Object.values(outlet1)[0].Auto;
        delete Object.values(outlet1)[0].device_id;
        delete Object.values(outlet1)[0].attribute_descriptions;
        delete Object.values(outlet1)[0].Pe;
        delete Object.values(outlet1)[0].Cl;
        delete Object.values(outlet1)[0].Re;
        delete Object.values(outlet1)[0].Un;
        delete Object.values(outlet1)[0].Na;
        delete Object.values(outlet1)[0].hardware;
        delete Object.values(outlet1)[0].timeout;
        delete Object.values(outlet1)[0].list_scene_timer_1;

        //input
        delete Object.values(item)[0].list_scene_temper;
        delete Object.values(item)[0].Humi;
        delete Object.values(item)[0].device_id;
        delete Object.values(item)[0].Pe;
        delete Object.values(item)[0].Cl;
        delete Object.values(item)[0].Re;
        delete Object.values(item)[0].Un;
        delete Object.values(item)[0].Na;
        delete Object.values(item)[0].hardware;
        delete Object.values(item)[0].attribute_descriptions;
        delete Object.values(item)[0].timeout;
        delete Object.values(item)[0].temper_outlet_status;
        delete Object.values(item)[0].humi_outlet_status;
        delete Object.values(item)[0].list_scene_timer_1;

        if (data1.data != "" && data1.data >= 0 && data1.data <= 100) {
          Object.values(item)[0].Da = parseFloat(
            parseFloat(data1.data).toFixed(1)
          );
        } else {
          this.fnToast({
            message: "Có lỗi xảy ra, vui lòng kiểm tra lại",
            type: "error",
          });
          return;
        }

        var input = Object.assign({}, item, {
          oper: "LT",
          device_id: device_id,
        });

        var output = Object.assign({}, outlet1, {
          device_id:
            outlet_id ||
            Object.values(this.PDU["DeviceAttribute"].obj)[0].device_id ||
            this.PDU["DeviceDetail"].obj.device_id,
        });
        this.data.id = null;
        this.data.id = tempt.shorter.id || this.onCheckAutoSceneID();
        if (this.data.id == null) {
          return;
        }
        this.PDU[this.modul].obj.list_sceneID.push(this.data.id);

        var st = this.onConvertNumber(tempt.sto_dmin) + d.getTimezoneOffset();
        st < 0 ? (st = st + 1440) : (st = st);

        var st1 = this.onConvertNumber(tempt.sta_dmin) + d.getTimezoneOffset();
        st1 < 0 ? (st1 = st1 + 1440) : (st1 = st1);

        obj1[this.data.id] = {
          Da: {
            enb: true,
            wday: [0, 1, 2, 3, 4, 5, 6],
            // this.onConvertDay_Number(tempt.wday)
            // sta_dmin: st1,
            // sto_dmin: st == 1439? st = st +1: st,
            sta_dmin: 0,
            sto_dmin: 1440,
            cond: "AND",
            input: [input],
            output: [output],
          },
          Dt: "OBJECT",
        };
        if (tempt.check_short == false) {
          this.$set(Object.values(obj1)[0], "Da", {});
        }

        if (tempt.shorter.old_scene) {
          var cmp1 = this.conCompareScene(
            Object.values(obj1)[0].Da,
            Object.values(tempt.shorter.old_scene)[0].Da
          );
        }

        if (!cmp1) {
          list_attribute.push(obj1);
        }
      }

      return list_attribute;
    },

    onActiveFlatsit() {
      for (var j in this.PDU["DeviceDetail"].obj.child_attribute) {
        var item = this.PDU["DeviceDetail"].obj.child_attribute[j];
        if (item.list_outled && item.list_outled.length > 0) {
          var outlet = item.list_outled.find(e => {
            return Object.keys(e)[0] == this.PDU.item_id;
          });
          if (outlet) {
            this.$set(
              Object.values(outlet)[0],
              "list_scene_timer",
              this.PDU["Scene"].list
            );
          }
        }
      }
    },
    createScene_timezone_pdu(list, timeGE) {
      var obj = {};
      this.sort_scene_display_to_setting(list);

      var timeLT = { time: null, input: [], output: [] };

      var len = list.length;

      var timer = list.find(e => {
        //tìm kịch bản có thời gian lớn hơn 0 đầu tiên
        if (
          e.input &&
          e.input[0] &&
          e.input[0].day_min &&
          e.input[0].day_min.Da
        ) {
          var data = e.input[0].day_min.Da;
          return data > timeGE;
        }
      });

      if (timer) {
        timeLT.time = this.onConvertMilestonetoTimer(timer.timer);
        timeLT.output = list[len - 1].output;
        timeLT.input = timer.input;
      }

      this.data.id = this.onCheckAutoSceneID(); // not sure
      if (this.data.id == null) {
        return;
      }
      this.PDU[this.modul].obj.list_sceneID.push(this.data.id);

      var input = [
        {
          day_min: { Da: timeGE, Dt: "UINT32" },
          device_id: this.PDU[this.modul].obj.device_id,
          oper: "GE",
        },
      ];

      var output = timeLT.output;

      var status =
        timeLT.output[0] && Object.keys(timeLT.output[0])[0] != "device_id"
          ? Object.values(timeLT.output[0])[0].Da
          : null;

      var result = {
        music_name: "",
        input: input,
        output: output,
        timer: this.onConvertMilestone(timeGE),
        music_volume: 0,
        status: status,
        id: this.data.id,
      };

      obj[this.data.id] = {
        Da: {
          enb: true,
          wday: [0, 1, 2, 3, 4, 5, 6],
          sta_dmin: 0,
          sto_dmin: 1440,
          cond: "AND",
          input: input,
          output: output,
        },
        Dt: "OBJECT",
      };

      this.PDU["Scene"].obj = Object.assign({}, obj);

      return result;
    },
    create_Open_Scene_PDU(list, timeGE) {
      var obj = {};
      this.sort_scene_display_to_setting(list);

      var timeLT = { time: null, input: [], output: [] };

      var len = list.length;

      var timer = list.find(e => {
        //tìm kịch bản có thời gian lớn hơn 0 đầu tiên
        if (
          e.input &&
          e.input[0] &&
          e.input[0].day_min &&
          e.input[0].day_min.Da
        ) {
          var data = e.input[0].day_min.Da;
          return data > timeGE;
        }
      });

      if (timer) {
        timeLT.time = this.onConvertMilestonetoTimer(timer.timer);
        timeLT.output = list[len - 1].output;
        timeLT.input = timer.input;
      }

      this.data.id = this.onCheckAutoSceneID(); // not sure
      if (this.data.id == null) {
        return;
      }
      this.PDU[this.modul].obj.list_sceneID.push(this.data.id);

      var input = [
        {
          day_min: { Da: timeGE, Dt: "UINT32" },
          device_id: this.PDU[this.modul].obj.device_id,
          oper: "GE",
        },
      ];

      var output = timeLT.output;

      // var status =
      //   timeLT.output[0] && Object.keys(timeLT.output[0])[0] != "device_id"
      //     ? Object.values(timeLT.output[0])[0].Da
      //     : null;

      var result = {
        music_name: "",
        input: input,
        output: output,
        timer: this.onConvertMilestone(timeGE),
        music_volume: 0,
        // status: status,
        id: this.data.id,
      };

      obj[this.data.id] = {
        Da: {
          enb: true,
          wday: [0, 1, 2, 3, 4, 5, 6],
          sta_dmin: 0,
          sto_dmin: 1440,
          cond: "AND",
          input: input,
          output: output,
        },
        Dt: "OBJECT",
      };

      this.PDU["Scene"].obj = Object.assign({}, obj, {
        device_id: this.PDU[this.modul].obj.device_id,
      });

      return result;
    },
    onGeneratorPDUSensor({ attribute: attribute, cata: cata }) {
      var list_scene = [];

      for (var j in this.TemperHumiItem[cata].listScene) {
        var it = this.TemperHumiItem[cata].listScene[j];
        var item = attribute.find(e => {
          return e.id == Object.keys(it)[0];
        });
        if (item) {
          var obj = new Object();
          var out;
          obj[item.id] = {
            Da: {
              enb: true,
              wday: [0, 1, 2, 3, 4, 5, 6],
              sta_dmin: 0,
              sto_dmin: 1440,
              cond: "AND",
              input: item.input,
              output: item.output,
            },
            Dt: "OBJECT",
          };

          out = Object.assign({}, obj, {
            device_id: this.PDU[this.modul].obj.device_id,
          });
          list_scene.push(out);
        } else {
          var out1;
          this.$set(Object.values(it)[0], "Da", {});
          out1 = Object.assign({}, it, {
            device_id: this.PDU[this.modul].obj.device_id,
          });
          list_scene.push(out1);
        }
      }

      // for(var i in attribute){

      //     var item = attribute[i];var obj = new Object();var out;

      //     obj[item.id] = {
      //       Da: {
      //         enb: true,
      //         wday: [0, 1, 2, 3, 4, 5, 6],
      //         sta_dmin: 0,
      //         sto_dmin: 1440,
      //         cond: "AND",
      //         input: item.input,
      //         output: item.output
      //       },
      //       Dt: "OBJECT",
      //     };

      //     out = Object.assign({}, obj, { device_id: this.PDU[this.modul].obj.device_id});
      //     list_scene.push(out)
      // }
      return list_scene;
    },
    async onBeforeSubmitAutomation() {
      this.AutomationLoading = false;
      this.$set(
        this.PDU["PDU_automation"].obj,
        "start_state",
        this.start_state
      );

      // this.fnToastNotificationOnly({
      //     message: "Tính năng đang được phát triển!",
      //     type: "warning", reload: false
      //   });

      this.$set(
        this.PDU["PDU_automation"].obj,
        "start_time_1",
        this.onConvertNumber(this.PDU["PDU_automation"].obj.start_time)
      );
      this.$set(
        this.PDU["PDU_automation"].obj,
        "stop_time_1",
        this.onConvertNumber(this.PDU["PDU_automation"].obj.stop_time)
      );

      var time_number = Math.floor(
        (this.PDU["PDU_automation"].obj.stop_time_1 -
          this.PDU["PDU_automation"].obj.start_time_1) /
          60
      );

      var time_on = this.PDU["PDU_automation"].obj.time_on;
      var time_off = this.PDU["PDU_automation"].obj.time_off;
      var start_time = this.PDU["PDU_automation"].obj.start_time_1;
      var stop_time = this.PDU["PDU_automation"].obj.stop_time_1;
      var start_state = this.PDU["PDU_automation"].obj.start_state;

      var t1 = start_time;
      var t2 = start_time;
      var t3 = [];
      if (start_state == true) {
        t3.push({ data: t1, status: "on" });
      } else if (start_state == false) {
        t3.push({ data: t1, status: "off" });
      }

      for (var i = 1; i <= 60; i++) {
        if (start_state == true) {
          t1 = t2 + time_on;
          if (t1 > stop_time) {
          } else {
            t3.push({ data: t1, status: "off" });
          }

          t2 = t1 + time_off;
          if (t2 > stop_time) {
          } else {
            t3.push({ data: t2, status: "on" });
          }
        } else {
          t1 = t2 + time_on;
          if (t1 > stop_time) {
          } else {
            t3.push({ data: t1, status: "on" });
          }

          t2 = t1 + time_off;
          if (t2 > stop_time) {
          } else {
            t3.push({ data: t2, status: "off" });
          }
        }
      }

      //////////////////////////////////testing add scene like pdu
      // this.editedItem.id = null;

      // this.editedItem.timer = this.onConvertTimer_without_timzone(t3[0].data);

      // this.editedItem.status = t3[0].status == 'on' && true || false;

      // this.onAddScene_OutletControl();

      //////////////////////////////////////////////////////////////////////
      try {
        await pause(500);
        for (var i in t3) {
          var item = t3[i];

          this.editedItem.id = null;

          this.editedItem.timer = this.onConvertTimer_without_timzone(
            item.data
          );

          this.editedItem.status = (item.status == "on" && true) || false;

          this.onAddScene_OutletControl();
        }

        var len = this.items.length;
        var len_1 = t3.length;

        if (len != len_1) {
          await this.fnResetListSceneID();

          this.dialog_Outlet_settings = false;
        }
        this.AutomationLoading = true;
        this.dialog_setting_PDU_automation = false;
      } catch (err) {
        console.log("error in scene", err);
        await this.fnResetListSceneID();

        this.dialog_Outlet_settings = false;

        this.AutomationLoading = true;
        this.dialog_setting_PDU_automation = false;
      }
      return;
    },

    onCountDownTimer() {
      try {
        var presentTime = document.getElementById("timer").innerHTML;
        var timeArray = presentTime.split(/[:]+/);
        var m = timeArray[0];
        var s = this.checkSecond(timeArray[1] - 1);
        if (s == 59) {
          m = m - 1;
        }

        if (m < 0) {
          if (this.PDU["DeviceDetail"].obj.childRefresh == "waiting") {
            this.$set(this.PDU[this.modul].obj, "childRefresh", false);
            this.fnToast({
              message: `Tìm kiếm thiết bị con không thành công`,
              type: "error",
            });
            return;
          }
          return;
        }

        document.getElementById("timer").innerHTML = m + ":" + s;

        setTimeout(this.onCountDownTimer, 1000);
      } catch (err) {
        console.log(err);
      }
    },
    onCountDownTimerUSBIn() {
      try {
        var presentTime = document.getElementById("timerUSBin").innerHTML;
        var timeArray = presentTime.split(/[:]+/);
        var m = timeArray[0];
        var s = this.checkSecond(timeArray[1] - 1);
        if (s == 59) {
          m = m - 1;
        }

        if (m < 0) {
          if (this.PDU["DeviceDetail"].obj.MusicUSBIn == "waiting") {
            this.$set(this.PDU[this.modul].obj, "MusicUSBIn", false);
            this.fnToast({
              message: `Sao chép âm không thành công`,
              type: "error",
            });
            return;
          }
          return;
        }

        document.getElementById("timerUSBin").innerHTML = m + ":" + s;

        setTimeout(this.onCountDownTimerUSBIn, 1000);
      } catch (err) {
        console.log(err);
      }
    },
    checkSecond(sec) {
      if (sec < 10 && sec >= 0) {
        sec = "0" + sec;
      } // add zero in front of numbers < 10
      if (sec < 0) {
        sec = "59";
      }
      return sec;
    },
    onCountUpTimer() {
      try {
      if (this.PDU[this.modul].obj.musicProgress == 100) {
        this.fnToast({
          message: `Tải âm thành công`,
          type: "success",
        });
        return;
      } else if (this.PDU[this.modul].obj.musicProgress == -1) {
        this.fnToast({
          message: `Tải âm không thành công!`,
          type: "error",
        });
        return;
      }

      var presentTime = document.getElementById("timer_up").innerHTML;
      var timeArray = presentTime.split(/[:]+/);
      var m = timeArray[0];
      var s = timeArray[1];
      ++s;
      if (s < 10 && s >= 0) {
        s = "0" + s;
      }
      if (s > 59) {
        s = 0;
        m = parseInt(m) + 1;
      }
      document.getElementById("timer_up").innerHTML = m + ":" + s;
      setTimeout(this.onCountUpTimer, 1000);
      } catch (err) {
        console.log(err)
      }
    },
    onselected(item) {},
    onReload() {
      window.location.reload();
    },
    /////////////////////////////////////////////////////NESTHOUSE/////////////////////////////////////////////////////////
    async onChannelDetail(channel) {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          this.onRemoveDeviceIDOfLocal()
          if (Object.values(channel)[0] && Object.values(channel)[0].timeout == false) {
            this.NesthouseTimerArr = [];
            this.NesthouseTimerArr_copy = [];
            this.NesthouseList = [];
            this.onCopy(channel);
            await this.onGetScene_NesthouseControl();
            await this.sort_scene_display(this.NesthouseTimerArr);
            this.PDU["Scene"].isLoading = false;
            this.dialogMusicTimer = true;
          } else {
            this.fnToast({
              message: "Đang đợi phản hồi từ thiết bị",
              type: "warning",
            });
          }
        }
      }
    },
    onRemoveDeviceIDOfLocal () {
      var arrREB = []
      var arrManualReboot = localStorage.getItem('REB')
      arrREB = arrManualReboot ? JSON.parse(arrManualReboot) : []
      if (arrREB && arrREB.length > 0) {
        var index = arrREB.findIndex(e => { return e.device_id === this.PDU[this.modul].obj.device_id })
        if (index >= 0) {
          arrREB.splice(index, 1)
          if (arrREB.length > 0) {
            localStorage.setItem('REB', JSON.stringify(arrREB))
          } else {
            localStorage.removeItem('REB')
          }
        }
      }
    },
    onSelectAllDetail() {
      for (var i in this.list_channel_monitor_AudioUsed) {
        var item = this.list_channel_monitor_AudioUsed[i];
        if (Object.values(item)[0].Da_copy == false) {
          this.ChMoUsedItem = false;
          return;
        }
      }
      this.ChMoUsedItem = true;
    },
    onResetlist_channel_monitor_AudioUsed () {
      // for (var i in this.list_channel_monitor_AudioUsed) {
      //   var item = this.list_channel_monitor_AudioUsed[i];
      //   // if (Object.values(item)[0].Da_copy == false) {
      //   //   this.ChMoUsedItem = false;
      //   //   return;
      //   // }
      //   this.$set(Object.values(item)[0], 'Da_copy', false)
      // }
      this.list_channel_monitor_AudioUsed.forEach(e => {
        this.$set(Object.values(e)[0], 'Da_copy', Object.values(e)[0].Da)
      })
    },
    onUpload(event) {
      var obj = {};
      var test = /^[a-z\u00E0-\u00FC]+$/i;
      var file_list = [];
      let file = event.srcElement.files;
      this.PDU["Music"].obj.size_sum = 0;
      for (var i in file) {
        try {
          if (file[i].name && file[i].size) {
            this.PDU["Music"].obj.size_sum =
              this.PDU["Music"].obj.size_sum + file[i].size;
            file_list.push(file[i]);

            this.PDU["Music"].obj = {
              ...this.PDU["Music"].obj,
              file_list: file_list,
            };
          }
        } catch (err) {console.log(err);}
      }
      var USBRemain = Object.values(this.PDU[this.modul].obj.USBRemain)[0].Da;
      if (
        Math.floor(this.PDU["Music"].obj.size_sum / 1000 / 1000) >=
        Math.floor(USBRemain / 1000)
      ) {
        this.PDU["Music"].obj.isValid = false;

        this.fnToastNotificationOnly({
          message:
            "Bộ nhớ thiết bị không đủ để tải âm này",
          type: "error",
          reload: false,
        });

        return;
      }
      this.PDU["Music"].obj.isValid = true;
    },
    onContructual() {
      var hour = new Date().getHours();
      var minute = new Date().getMinutes();

      this.tabs_setting_mucsicChannel = "tab-0";

      this.NesthouseTimerItem = {
        music_name: null,
        music_volume: 0.5,
        timer:
          (hour < 10 ? `0${hour}` : `${hour}`) +
          ":" +
          (minute < 10 ? `0${minute}` : `${minute}`),
        status: true,
        id: null,
      };
      this.editedItem = {
        timer:
          (hour < 10 ? `0${hour}` : `${hour}`) +
          ":" +
          (minute < 10 ? `0${minute}` : `${minute}`),
        status: true,
        id: null,
      };
    },
    onContructual_Sensor() {
      var list_outlet = this.list_outlet;
      if (list_outlet && list_outlet.length > 0) {
        this.TemperHumiItem = {
          temper: {
            check_high: "",
            check_short: "",
            wday: [
              "Thứ 2",
              "Thứ 3",
              "Thứ 4",
              "Thứ 5",
              "Thứ 6",
              "Thứ 7",
              "Chủ nhật",
            ],
            sta_dmin: "00:00",
            sto_dmin: "23:59",
            select: list_outlet.filter(e => {
              return e.disable != true;
            })[0],
            editedItem: {
              id: null,
              timer: new Date().getHours() + ":" + new Date().getMinutes(),
              dialog: false,
              dialog_time: false,
              status: false,
            },
            PDUList: [],
            PDUList_copy: [],
            listScene: [],
            higher: {
              dialog: false,
              picker_start_GT: false,
              picker_stop_GT: false,
              data: 30,
              status: true,
              day: [],
              id: null,
            },
            shorter: {
              dialog: false,
              picker_start_LT: false,
              picker_stop_LT: false,
              data: 20,

              status: false,
              day: [],
              id: null,
            },
          },

          humi: {
            check_high: "",
            check_short: "",
            wday: [
              "Thứ 2",
              "Thứ 3",
              "Thứ 4",
              "Thứ 5",
              "Thứ 6",
              "Thứ 7",
              "Chủ nhật",
            ],
            sta_dmin: "00:00",
            sto_dmin: "23:59",
            select: list_outlet.filter(e => {
              return e.disable != true;
            })[0],
            editedItem: {
              id: null,
              timer: new Date().getHours() + ":" + new Date().getMinutes(),
              dialog: false,
              dialog_time: false,
              status: false,
            },
            PDUList: [],
            PDUList_copy: [],
            listScene: [],
            higher: {
              dialog: false,
              picker_start_GT: false,
              picker_stop_GT: false,
              data: 80,

              status: true,
              day: [],
              id: null,
            },
            shorter: {
              dialog: false,
              picker_stop_LT: false,
              picker_stop_LT: false,
              data: 50,
              status: false,
              day: [],
              id: null,
            },
          },
        };
      }
    },
    initialize_NesthouseControl(data) {
      if (data) {
        var idx = this.NesthouseTimerArr.findIndex(e => {
          return e.id && e.id == data.id;
        });

        if (idx == -1 && data.timer != "00:00") {
          this.NesthouseTimerArr.push({
            music_name:
              data.music_name == "No setting" ? "Im lặng" : data.music_name,
            music_volume: data.music_volume,
            timer: data.timer,
            status: data.status,
            scene00: data.scene00,
            id: data.id,
            output: data.output,
            input: data.input,
          });
        }
        this.NesthouseTimerArr_copy.push({
          music_name:
            data.music_name == "No setting" ? "Im lặng" : data.music_name,
          music_volume: data.music_volume,
          timer: data.timer,
          status: data.status,
          scene00: data.scene00,
          id: data.id,
          output: data.output,
          input: data.input,
        });
      }
    },
    async onDelMusic() {
      var obj = {};
      obj["1664"] = {
        Da: [this.PDU["Music"].obj.music],
        Dt: "ARRAY",
      };
      var attribute = {
        user_name: this.PDU.userSession.username,
        attribute_list: [
          Object.assign({}, obj, {
            device_id: this.PDU.select || this.PDU[this.modul].obj.device_id,
          }),
        ],
      };

      await this.fnIotPost(attribute);
      await this.fnRead("DevicesAttributes");
    },
    editSceneNesthouse(item) {
      this.editedNesthouseIndex = this.NesthouseTimerArr.indexOf(item);

      this.NesthouseTimerItem = Object.assign({}, item);

      switch (this.NesthouseTimerItem.status) {
        case true:
          {
            this.tabs_setting_mucsicChannel = "tab-0";
          }
          break;
        case false:
          {
            this.tabs_setting_mucsicChannel = "tab-1";
          }
          break;
        default: {
          this.tabs_setting_mucsicChannel = "tab-0";
        }
      }

      this.NesthouseTimerItem = {
        ...this.NesthouseTimerItem,
        music_name: decodeURIComponent(this.NesthouseTimerItem.music_name),
      };

      this.dialog_updateSceneNesthouse = true;
    },
    isDel() {
      var index = this.NesthouseTimerArr.findIndex(e => {
        return e.id == this.PDU["MusicTimer"].obj.id;
      });
      var index1 = this.NesthouseTimerArr_copy.findIndex(e => {
        return e.id == this.PDU["MusicTimer"].obj.id;
      });
      this.NesthouseTimerArr.splice(index, 1);
      this.NesthouseTimerArr_copy.splice(index1, 1);

      if (this.NesthouseTimerArr.length == 0) {
        var arr = this.fnCopy(this.NesthouseTimerArr_copy);

        for (var i in arr) {
          var index2 = this.NesthouseTimerArr_copy.findIndex(e => {
            return (
              e.input &&
              e.input[0] &&
              e.input[0].day_min &&
              e.input[0].day_min.Da == 0
            );
          });
          this.NesthouseTimerArr_copy.splice(index2, 1);
        }
      }

      // var index3 = this.NesthouseList.findIndex(e=>{
      //   return Object.keys(e)[0] == this.PDU["MusicTimer"].obj.id;
      // })
      // this.NesthouseList.splice(index3, 1);
      this.fnCallResetView("MusicTimer");
    },
    onGetScene_NesthouseControl() {
      var list = this.fnCopy(this.PDU[this.modul].obj.AutoScene);
      var arr_day = [];
      this.items = [];
      var obj = {};
      this.PDU.scene_sort = [];
      this.PDU.scene_number = [];
      this.PDU["Scene"].list = [];

      list.forEach(scene => {
        var data = Object.values(scene)[0].Da;

        var id = Object.keys(scene)[0];

        if (!this.isEmpty(data)) {
          var input = data.input;

          var output = data.output ? data.output : null;

          if (output[0]) {
            var device_id = output[0].device_id;

            input.forEach(e => {
              if (e.day_min && e.oper && (e.oper == "GE" || e.oper == "IN")) {
                var time = e.day_min.Da;

                for (let [key, value] of Object.entries(output[0])) {
                  if (
                    key == this.PDU.item_id &&
                    device_id == this.PDU.device_id
                  ) {
                    try {
                      obj = {
                        ...obj,
                        music_name: value.Da,
                        timer: this.onConvertMilestone(time),
                        status: value.Da == "No setting" ? false : true,
                        scene00: time == 0 ? true : false,
                        device_id: e.device_id,
                        id: id,
                      };
                    } catch (err) {console.log(err);}
                    // this.PDU["Scene"].list.push(scene);

                    var rxs = this.NesthouseList.findIndex(e => {
                      return Object.keys(e)[0] == id;
                    });
                    if (rxs == -1) {
                      this.NesthouseList.push(scene);
                    }
                  }
                  if (
                    key == this.PDU.sub_id &&
                    device_id == this.PDU.device_id
                  ) {
                    obj = {
                      ...obj,
                      music_volume: value.Da || 0,
                      output: output,
                      input: input,
                    };

                    this.initialize_NesthouseControl(obj);

                    break;
                  }
                }
              }
            });
          }
        }
      });
    },
    onAddScene_NesthouseControl() {
      try {
        if (this.NesthouseTimerItem.id == null) {
          //tao moi
          var idx = this.NesthouseTimerArr.findIndex(e => {
            return e.timer == this.NesthouseTimerItem.timer;
          });
          if (idx != -1) {
            this.fnToastNotificationOnly({
              message: "Vui lòng chọn giờ khác!",
              type: "error",
              reload: false,
            });
            return;
          }
          var onCr1 = this.createScene();
          if (this.data.id == null) {
            return;
          }
          var result = this.fnCopy(onCr1);

          this.initialize_NesthouseControl(result);

          this.NesthouseList.push(this.fnCopy(this.PDU["Scene"].obj));
        } else {
          //edit

          var index = this.NesthouseTimerArr.findIndex(e => {
            return e.id && e.id == this.NesthouseTimerItem.id;
          });
          var index1 = this.NesthouseTimerArr_copy.findIndex(e => {
            return e.id && e.id == this.NesthouseTimerItem.id;
          });

          // xoa kich ban cu
          this.NesthouseTimerArr.splice(index, 1);
          this.NesthouseTimerArr_copy.splice(index1, 1);

          var onCr2 = this.createScene();
          if (this.data.id == null) {
            return;
          }
          var result = this.fnCopy(onCr2);

          this.initialize_NesthouseControl(result);
          var idx2 = this.NesthouseList.findIndex(e => {
            return Object.keys(e)[0] == result.id;
          });
          if (idx2 >= 0) {
            this.NesthouseList.splice(idx2, 1);
          }
          this.NesthouseList.push(this.fnCopy(this.PDU["Scene"].obj));

          this.NesthouseTimerItem.id = null;
        }
      } catch (err) {
        console.log(err);
        this.fnToast({
          message: "có lỗi xảy ra, vui lòng thử lại sau",
          type: "error",
        });
      }

      this.sort_scene_display(this.NesthouseTimerArr);
      this.dialog_updateSceneNesthouse = false;
    },
    onFindScene_GE0_LT_0(list) {
      if (list && list.length > 0) {
        for (var i in list) {
          var item = list[i].input;
          var arr_0 = item[0] || null;
          var arr_1 = item[1] || null;
          if (arr_0 != null && arr_1 != null) {
            if (arr_0.day_min.Da == 0 && arr_1.day_min.Da == 0) {
              list.splice(i, 1);
            }
          }
        }
      }
    },
    onOpenSceneHandling_NesthouseControl() {
      if (this.NesthouseList.length == 0) {
        var index2 = this.NesthouseTimerArr_copy.filter(e => {
          return (
            e.input &&
            e.input[0] &&
            e.input[0].day_min &&
            e.input[0].day_min.Da == 0
          );
        });

        if (index2 && index2.length >= 2) {
          // co nhiu hon 2 kich ban 00 => xoa het kich ban 00
          var arr = this.fnCopy(this.NesthouseTimerArr_copy);

          for (var i in this.NesthouseTimerArr_copy) {
            var e = this.NesthouseTimerArr_copy[i];
            if (
              e.input &&
              e.input[0] &&
              e.input[0].day_min &&
              e.input[0].day_min.Da == 0
            ) {
              arr.splice(i, 1);
            }
          }
          this.NesthouseTimerArr_copy = arr;
        } else {
          // chi co 1 kich ban 00 => xoa 1 kich ban 00
          var index3 = this.NesthouseTimerArr_copy.findIndex(e => {
            return (
              e.input &&
              e.input[0] &&
              e.input[0].day_min &&
              e.input[0].day_min.Da == 0
            );
          });

          this.NesthouseTimerArr_copy.splice(index3, 1);
        }
      }

      var index = this.NesthouseTimerArr_copy.find(e => {
        return (
          e.input &&
          e.input[0] &&
          e.input[0].day_min &&
          e.input[0].day_min.Da == 0
        );
      });

      if (!index && this.NesthouseTimerArr.length > 0) {
        this.sort_scene_display_to_setting(this.NesthouseTimerArr_copy);

        // var scene_00h = this.create_Open_Scene_PDU( this.TemperHumiItem[cata].PDUList_copy, 0 ); // kịch bản 00h

        var scene_00h = this.createScene_timezone(
          this.NesthouseTimerArr_copy,
          0
        );

        this.NesthouseList.push(this.fnCopy(this.PDU["Scene"].obj)); // ...

        var idx = this.NesthouseTimerArr_copy.findIndex(e => {
          return e && e.id && e.id == scene_00h.id;
        });

        if (idx == -1) {
          // this.TemperHumiItem[cata].PDUList_copy.push({
          //   timer: "00:00",
          //   // status: timezone_scene_00h.status,
          //   id: scene_00h.id,
          //   output: scene_00h.output,
          //   input: scene_00h.input
          // });

          this.NesthouseTimerArr_copy.push({
            music_name:
              scene_00h.music_name == "No setting"
                ? "Im lặng"
                : scene_00h.music_name,
            music_volume: scene_00h.music_volume,
            timer: "00:00",
            status: scene_00h.status,
            id: scene_00h.id,
            output: scene_00h.output,
            input: scene_00h.input,
          });
        }
      }
    },
    onSumaryCheckNestHouse({ attribute: list }) {
      for (var i in list) {
        var scene = list[i];

        var id =
          Object.keys(scene)[0] != "device_id" ? Object.keys(scene)[0] : null;

        var idx = this.NesthouseTimerArr_copy.findIndex(e => {
          return e.id == id;
        });
        if (idx == -1) {
          this.$set(Object.values(scene)[0], "Da", {});
        }
        if (this.NesthouseTimerArr.length == 0) {
          this.$set(Object.values(scene)[0], "Da", {});
        }
      }
      return list;
    },
    async onSaveScene_NesthouseControl() {
      var attribute_setting = [],
        attribute_5 = [],
        attribute_6 = [],
        attribute_post_2 = [],
        attribute_post_2_1 = [],
        attribute_post_3 = [];

      this.PDU["Scene"].isLoading = true;

      try {
        this.onOpenSceneHandling_NesthouseControl();

        this.sort_scene_display_to_setting(this.NesthouseTimerArr_copy);

        await pause(1000);
        attribute_5 = this.sort_scene_setting(this.NesthouseTimerArr_copy);

        attribute_6 = this.onSumaryCheckNestHouse({ attribute: attribute_5 });

        if (attribute_6.length > 0) {
          if (this.NesthouseTimerArr.length == 0) {
            attribute_setting = this.onsetAttributeNesthouse();
          }

          var obj = attribute_6
            .concat(attribute_setting)
            .reduce(function(result, item) {
              var key = Object.keys(item)[0];
              result[key] = item[key];
              return result;
            });
          //nếu xóa hết kịch bản của kênh thì bắn thêm disable scene thông báo của 2 scene tương ứng

          // if (attribute_setting.length > 0) {
          //   var tem = this.PDU["DeviceAttribute"].obj;
          //   var channel_key = this.fnCopy(Object.keys(tem)[0]);
          //   attribute_post_2 = this.createStateSceneNotification_audioStatus({
          //     state: false,
          //     key: channel_key,
          //   });
          //   attribute_post_2_1 = this.createStateSceneNotification_audioStatus1({
          //     state: false,
          //     key: channel_key,
          //   });
          // }
          ///////////////////
          var attribute_post = [];
          var attribute_post_1 = Object.assign({}, obj, {
            device_id: this.PDU[this.modul].obj.device_id,
          });

          attribute_post.push(attribute_post_1);

          // if (attribute_post_2.length == 1) {
          //   attribute_post.push(attribute_post_2[0]);
          // } else if (attribute_post_2.length == 2) {
          //   attribute_post.push(attribute_post_2[0]);
          //   attribute_post.push(attribute_post_2[1]);
          // } else {
          // }

          // if (attribute_post_2_1.length == 1) {
          //   attribute_post.push(attribute_post_2_1[0]);
          // } else if (attribute_post_2_1.length == 2) {
          //   attribute_post.push(attribute_post_2_1[0]);
          //   attribute_post.push(attribute_post_2_1[1]);
          // } else {
          // }

          var attribute = {
            user_name: this.PDU.userSession.username,
            attribute_list: attribute_post,
          };

          await this.fnIotPost(attribute);
          this.PDU["Scene"].isLoading = false;
          this.dialogMusicTimer = false;
          await this.onFindAsynchronous(
            this.PDU[this.modul].obj.list_MusicChannel
          );
        } else {
          this.PDU["Scene"].isLoading = false;
          this.dialogMusicTimer = false;
        }
      } catch (err) {
        console.log(err);
        this.fnToast({
          message: "Có lỗi xảy ra. Vui lòng thử lại sau!",
          type: "error",
        });

        this.PDU["Scene"].isLoading = false;

        return;
      }
    },
    async onFindAsynchronous(task) {
      var arr = task || [];
      var item_outlet = this.fnCopy(this.PDU["DeviceAttribute"].obj);

      try {
        for (var i in arr) {
          var item = arr[i];

          if (Object.keys(item)[0] == Object.keys(item_outlet)[0]) {
            this.$set(Object.values(item)[0], "timeout", true);
          }
        }

        await pause(10000);

        for (var i in arr) {
          var item = arr[i];
          if (Object.values(item)[0].timeout == true) {
            // this.fnToast({message: "Thao tác thất bại", type: 'error'})
            this.$set(Object.values(item)[0], "timeout", false);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    onsetScene_NesthouseControl(contentGE, contentLT) {
      this.PDU["Scene"].obj = {};
      var d = new Date();
      this.PDU.week_min = [];
      var obj = {};
      this.data.id = contentGE.id || this.onCheckAutoSceneID();

      this.PDU["Scene"].input = [
        {
          day_min: {
            Da: contentGE.timer,
            Dt: "UINT32",
          },
          device_id: this.PDU.select || this.PDU[this.modul].obj.device_id,
          oper: "GE",
        },
      ];
      if (contentLT) {
        this.PDU["Scene"].input.push({
          day_min: {
            Da: contentLT.timer,
            Dt: "UINT32",
          },
          device_id: this.PDU.select || this.PDU[this.modul].obj.device_id,
          oper: "LT",
        });
      }

      //output
      // this.PDU['Scene'].output = Object.assign({}, {device_id: this.PDU[this.modul].obj.device_id},x,y)
      this.PDU["Scene"].output = contentGE.output;

      obj[this.data.id] = {
        Da: {
          enb: true,
          wday: [0, 1, 2, 3, 4, 5, 6],
          sta_dmin: 0,
          sto_dmin: 1440,
          cond: "AND",
          input: this.PDU["Scene"].input,
          output: this.PDU["Scene"].output,
        },
        Dt: "OBJECT",
      };
      return obj;
    },
    createScene() {
      var d = new Date();
      this.PDU.week_min = [];
      var obj = {};
      var timeGE_1;
      this.data.id = this.onCheckAutoSceneID();

      if (this.data.id == null) {
        return;
      }
      this.PDU[this.modul].obj.list_sceneID.push(this.data.id);

      var arr = this.NesthouseTimerItem.timer.split(":");
      var timeGE =
        parseInt(arr[0]) * 60 + parseInt(arr[1]) + d.getTimezoneOffset();
      timeGE < 0 ? (timeGE = timeGE + 1440) : (timeGE = timeGE);

      var x = this.fnCopy(this.PDU["DeviceAttribute"].obj);
      delete Object.values(x)[0].volume;
      delete Object.values(x)[0].attribute_descriptions;
      delete Object.values(x)[0].attribute_descriptions_copy;
      delete Object.values(x)[0].attribute_descriptions_left;
      delete Object.values(x)[0].attribute_descriptions_right;
      delete Object.values(x)[0].list_timer;
      delete Object.values(x)[0].list_scene_timer;
      delete Object.values(x)[0].Pe;
      delete Object.values(x)[0].Cl;
      delete Object.values(x)[0].Re;
      delete Object.values(x)[0].Un;
      delete Object.values(x)[0].Na;
      delete Object.values(x)[0].timeout;

      var y = Object.values(this.PDU["DeviceAttribute"].obj)[0].volume;
      var list_music = this.PDU[this.modul].obj.list_ListMusic["1728"].Da;
      Object.values(x)[0].Da = encodeURIComponent(
        this.NesthouseTimerItem.music_name
      );

      for (var l in list_music) {
        if (this.NesthouseTimerItem.music_name == list_music[l]) {
          Object.values(x)[0].Da = this.NesthouseTimerItem.music_name;
        }
      }

      // Object.values(x)[0].Da = this.NesthouseTimerItem.music_name;
      Object.values(y)[0].Da = this.NesthouseTimerItem.music_volume;
      delete Object.values(y)[0].Pe;
      delete Object.values(y)[0].Cl;
      delete Object.values(y)[0].Re;
      delete Object.values(y)[0].Un;
      delete Object.values(y)[0].Na;
      delete Object.values(y)[0].timeout;
      //input
      //output
      if (this.NesthouseTimerItem.status) {
        //enable scene notification of audio status
        // var sumary = []; var sumary1 = []
        // sumary = this.createStateSceneNotification_audioStatus({
        //   state: true,
        //   key: Object.keys(x)[0],
        // });
        // sumary1 = this.createStateSceneNotification_audioStatus1({
        //   state: true,
        //   key: Object.keys(x)[0],
        // });

        // var len = sumary.length;
        // var len1 = sumary1.length;
        ///////////////////////////////////////////
        this.PDU["Scene"].input = [
          {
            day_min: {
              Da: timeGE,
              Dt: "UINT32",
            },
            device_id: this.PDU.select || this.PDU[this.modul].obj.device_id,
            oper: "GE",
          },
        ];

        this.PDU["Scene"].output = [];

        // if (len == 1) {
        //   var data_tempt = Object.assign(
        //     {},
        //     { device_id: this.PDU[this.modul].obj.device_id },
        //     x,
        //     y
        //   );

        //   this.PDU["Scene"].output.push(data_tempt);

        //   this.PDU["Scene"].output.push(sumary[0]);
        //   this.PDU["Scene"].output.push(sumary1[0]);
        // } else if (len == 2) {
        //   var data_tempt = Object.assign(
        //     {},
        //     { device_id: this.PDU[this.modul].obj.device_id },
        //     x,
        //     y
        //   );

        //   this.PDU["Scene"].output.push(data_tempt);

        //   this.PDU["Scene"].output.push(sumary[0]);
        //   this.PDU["Scene"].output.push(sumary[1]);
        //   this.PDU["Scene"].output.push(sumary1[0]);
        //   this.PDU["Scene"].output.push(sumary1[1]);
        // } else {
        //   var data_tempt = Object.assign(
        //     {},
        //     { device_id: this.PDU[this.modul].obj.device_id },
        //     x,
        //     y
        //   );

        //   this.PDU["Scene"].output.push(data_tempt);

        //   // this.PDU["Scene"].output = Object.assign({},{ device_id: this.PDU[this.modul].obj.device_id },x,y);
        // }
        var data_tempt = Object.assign({}, { device_id: this.PDU[this.modul].obj.device_id }, x, y);
        this.PDU["Scene"].output.push(data_tempt);
      } else {
        //disable scene notification of audio status
        this.PDU["Scene"].input = [
          {
            day_min: {
              Da: timeGE,
              Dt: "UINT32",
            },
            device_id: this.PDU.select || this.PDU[this.modul].obj.device_id,
            oper: "GE",
          },
        ];
        Object.values(x)[0].Da = "No setting";
        Object.values(y)[0].Da = 0;
        // output

        // var sumary = [];
        // sumary = this.createStateSceneNotification_audioStatus({
        //   state: false,
        //   key: Object.keys(x)[0],
        // });
        // var sumary1 = [];
        // sumary1 = this.createStateSceneNotification_audioStatus1({
        //   state: false,
        //   key: Object.keys(x)[0],
        // });

        // var len = sumary.length;
        // var len1 = sumary1.length;

        this.PDU["Scene"].output = [];

        // if (len == 1) {
        //   var data_tempt = Object.assign(
        //     {},
        //     { device_id: this.PDU[this.modul].obj.device_id },
        //     x,
        //     y
        //   );

        //   this.PDU["Scene"].output.push(data_tempt);

        //   this.PDU["Scene"].output.push(sumary[0]);
        //   this.PDU["Scene"].output.push(sumary1[0]);

        //   // this.PDU["Scene"].output = Object.assign({},{ device_id: this.PDU[this.modul].obj.device_id },x,y)
        // } else if (len == 2) {
        //   var data_tempt = Object.assign(
        //     {},
        //     { device_id: this.PDU[this.modul].obj.device_id },
        //     x,
        //     y
        //   );

        //   this.PDU["Scene"].output.push(data_tempt);

        //   this.PDU["Scene"].output.push(sumary[0]);
        //   this.PDU["Scene"].output.push(sumary[1]);
        //   this.PDU["Scene"].output.push(sumary1[0]);
        //   this.PDU["Scene"].output.push(sumary1[1]);

        //   // this.PDU["Scene"].output = Object.assign({},{ device_id: this.PDU[this.modul].obj.device_id },x,y)
        // } else {
        //   var data_tempt = Object.assign(
        //     {},
        //     { device_id: this.PDU[this.modul].obj.device_id },
        //     x,
        //     y
        //   );

        //   this.PDU["Scene"].output.push(data_tempt);

        //   // this.PDU["Scene"].output = Object.assign({},{ device_id: this.PDU[this.modul].obj.device_id },x,y)
        // }
        var data_tempt = Object.assign({}, { device_id: this.PDU[this.modul].obj.device_id }, x, y);
        this.PDU["Scene"].output.push(data_tempt);
      }

      obj[this.data.id] = {
        Da: {
          enb: true,
          wday: [0, 1, 2, 3, 4, 5, 6],
          sta_dmin: 0,
          sto_dmin: 1440,
          cond: "AND",
          input: this.PDU["Scene"].input,
          output: this.PDU["Scene"].output,
        },
        Dt: "OBJECT",
      };
      this.PDU["Scene"].obj = Object.assign({}, obj);

      if (this.NesthouseTimerItem.status) {
        var result = {
          music_name: encodeURIComponent(this.NesthouseTimerItem.music_name),
          input: this.PDU["Scene"].input,
          output: this.PDU["Scene"].output,
          timer: this.onConvertMilestone(timeGE),
          music_volume: this.NesthouseTimerItem.music_volume,
          status: true,
          id: this.data.id,
        };
        return result;
      } else {
        var result = {
          music_name: "Im lặng",
          input: this.PDU["Scene"].input,
          output: this.PDU["Scene"].output,
          timer: this.onConvertMilestone(timeGE),
          music_volume: 0,
          status: false,
          id: this.data.id,
        };
        return result;
      }
    },
    onCheckDeviceID({device_id: device_id}){
      const child_device = this.PDU['DeviceDetail'].obj.childDevice
      return child_device.findIndex(e=>{return e == device_id}) 
    },
    createStateSceneNotification_audioStatus({ state, key }) {
      const define = [
        { 1792: 1920 },
        { 1792: 1921 },
        { 1793: 1922 },
        { 1793: 1923 },
        { 1794: 1924 },
        { 1794: 1925 },
        { 1795: 1926 },
        { 1795: 1927 },
      ];
      var list = this.PDU[this.modul].obj.AutoScene;
      var suma = [];

      var arr_key = define.filter(e => {
        return Object.keys(e)[0] == key;
      });

      for (var i in arr_key) {
        // arr_key.forEach(obj => {
        var obj = arr_key[i];

        var rsx = list.find(scene => {
          var id = Object.keys(scene)[0];
          var data = Object.values(scene)[0].Da;

          if (!this.isEmpty(data)) {
            var input = Object.values(scene)[0].Da.input
              ? Object.values(scene)[0].Da.input[0]
              : null;

            var output = Object.values(scene)[0].Da.output
              ? Object.values(scene)[0].Da.output[0]
              : null;

            if (output && (output.device_id == "zalo" || output.device_id == "telegram")) {
              var status_key =
                input &&
                Object.keys(input)[0] != "oper" &&
                Object.keys(input)[0] != "device_id" &&
                Object.keys(input)[0];
              if (input && input[status_key] && input[status_key].Da === false) {

                var uart_device_id = input && input.device_id
                
                var rsx_id = this.onCheckDeviceID({device_id: uart_device_id})

                if (rsx_id != -1 && status_key == Object.values(obj)[0]) {
                  return id;
                }
              }
            }
          }
        });

        if (rsx && state) {
          var enable_scene = new Object();
          var rsx_key = this.fnCopy(Object.keys(rsx)[0]);

          enable_scene["832"] = { Da: rsx_key, Dt: "STRING" };

          enable_scene = Object.assign({}, enable_scene, {
            device_id: this.PDU[this.modul].obj.device_id,
          });
          suma.push(enable_scene);
        } else if (rsx && !state) {
          var rsx_key = this.fnCopy(Object.keys(rsx)[0]);
          var disable_scene = new Object();

          disable_scene["896"] = { Da: rsx_key, Dt: "STRING" };

          disable_scene = Object.assign({}, disable_scene, {
            device_id: this.PDU[this.modul].obj.device_id,
          });
          suma.push(disable_scene);
        }
      }

      return suma;
    },
    createStateSceneNotification_audioStatus1({ state, key }) {
      const define = [
        { 1792: 1920 },
        { 1792: 1921 },
        { 1793: 1922 },
        { 1793: 1923 },
        { 1794: 1924 },
        { 1794: 1925 },
        { 1795: 1926 },
        { 1795: 1927 },
      ];
      var list = this.PDU[this.modul].obj.AutoScene;
      var suma = [];

      var arr_key = define.filter(e => {
        return Object.keys(e)[0] == key;
      });

      for (var i in arr_key) {
        // arr_key.forEach(obj => {
        var obj = arr_key[i];

        var rsx = list.find(scene => {
          var id = Object.keys(scene)[0];
          var data = Object.values(scene)[0].Da;

          if (!this.isEmpty(data)) {
            var input = Object.values(scene)[0].Da.input
              ? Object.values(scene)[0].Da.input[0]
              : null;

            var output = Object.values(scene)[0].Da.output
              ? Object.values(scene)[0].Da.output[0]
              : null;

            if (output && (output.device_id == "zalo" || output.device_id == "telegram")) {
              var status_key =
                input &&
                Object.keys(input)[0] != "oper" &&
                Object.keys(input)[0] != "device_id" &&
                Object.keys(input)[0];
              if (input && input[status_key] && input[status_key].Da === true) {

                var uart_device_id = input && input.device_id
                
              
                var rsx_id = this.onCheckDeviceID({device_id: uart_device_id})

                if (rsx_id != -1 && status_key == Object.values(obj)[0]) {
                  return id;
                }
              }
            }
          }
        });

        if (rsx && state) {
          var enable_scene = new Object();
          var rsx_key = this.fnCopy(Object.keys(rsx)[0]);

          enable_scene["832"] = { Da: rsx_key, Dt: "STRING" };

          enable_scene = Object.assign({}, enable_scene, {
            device_id: this.PDU[this.modul].obj.device_id,
          });
          suma.push(enable_scene);
        } else if (rsx && !state) {
          var rsx_key = this.fnCopy(Object.keys(rsx)[0]);
          var disable_scene = new Object();

          disable_scene["896"] = { Da: rsx_key, Dt: "STRING" };

          disable_scene = Object.assign({}, disable_scene, {
            device_id: this.PDU[this.modul].obj.device_id,
          });
          suma.push(disable_scene);
        }
      }

      return suma;
    },
    createScene_timezone(list, timeGE) {
      var obj = {};
      this.sort_scene_display_to_setting(list);

      var timeLT = { time: null, input: [], output: [] };

      var len = list.length;

      var timer = list.find(e => {
        //tìm kịch bản có thời gian lớn hơn 0 đầu tiên
        if (
          e.input &&
          e.input[0] &&
          e.input[0].day_min &&
          e.input[0].day_min.Da
        ) {
          var data = e.input[0].day_min.Da;
          return data > timeGE;
        }
      });

      if (timer) {
        timeLT.time = this.onConvertMilestonetoTimer(timer.timer);
        timeLT.output = list[len - 1].output;
        timeLT.input = timer.input;
      }

      this.data.id = this.onCheckAutoSceneID();
      if (this.data.id == null) {
        return;
      }
      this.PDU[this.modul].obj.list_sceneID.push(this.data.id);

      var input = [
        {
          day_min: {
            Da: timeGE,
            Dt: "UINT32",
          },
          device_id: this.PDU.select || this.PDU[this.modul].obj.device_id,
          oper: "GE",
        },
      ];

      var output = timeLT.output;

      var result = {
        music_name: "",
        input: input,
        output: output,
        timer: this.onConvertMilestone(timeGE),
        music_volume: 0,
        status: true,
        id: this.data.id,
      };

      obj[this.data.id] = {
        Da: {
          enb: true,
          wday: [0, 1, 2, 3, 4, 5, 6],
          sta_dmin: 0,
          sto_dmin: 1440,
          cond: "AND",
          input: input,
          output: output,
        },
        Dt: "OBJECT",
      };

      this.PDU["Scene"].obj = Object.assign({}, obj);

      return result;
    },
    async onUploadMusic() {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          this.PDU["Music"].isLoading = true;
          // Set danh sách bài hát vào localstorage
          var storeFileList = []
          var fileList = this.PDU["Music"].obj.file_list
          var fileListLength = fileList.length
          for (var i in fileList) {
            storeFileList.push(fileList[i].name)
          }
          localStorage.setItem(
            "MusicUploading",
            JSON.stringify(storeFileList)
          );
          await this.fnUpload("Music");
          this.dialogMusic = false; //danh sach nhac
          await pause(3000);
          if (
            this.AudioMasterHardwareVersionAvailble == true &&
            (this.PDU[this.modul].obj.musicProgress >= 0 &&
              this.PDU[this.modul].obj.musicProgress != 100)
          ) {
            document.getElementById("timer_up").innerHTML = "00:03";
            this.onCountUpTimer();
          }
        }
      }
    },
    async onsetScene_NesthouseFormatUSB() {
      this.PDU["Scene"].obj = {
        device_id: this.PDU.select || this.PDU[this.modul].obj.device_id,
        "1344": {
          Da: true,
          Dt: "BOOLEAN",
        },
      };
      var attribute = {
        user_name: this.PDU.userSession.username,
        attribute_list: [this.PDU["Scene"].obj],
      };

      if (!this.PDU[this.modul].obj.active) {
        return;
      } else {
        await this.fnIotPost(attribute);
        this.$set(this.PDU[this.modul].obj, "USBFormat", "waiting");
      }
    },
    async onsetScene_NesthouseMusicUSBIn () {
      this.PDU["Scene"].obj = {
        device_id: this.PDU[this.modul].obj.device_id,
        "2752": {
          Da: true,
          Dt: "BOOLEAN",
        },
      };
      var attribute = {
        user_name: this.PDU.userSession.username,
        attribute_list: [this.PDU["Scene"].obj],
      };

      if (!this.PDU[this.modul].obj.active) {
        return;
      } else {
        await this.fnIotPost(attribute);
        this.$set(this.PDU[this.modul].obj, "MusicUSBIn", "waiting");
      }
    },
    async onFormatUSB() {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          await this.onsetScene_NesthouseFormatUSB();
          this.fnCallResetView("UsbFormat");
          this.dialogMusic = false;
          await pause(300000);
          if (this.PDU["DeviceDetail"].obj.USBFormat == "waiting") {
            this.$set(this.PDU[this.modul].obj, "USBFormat", false);
            this.fnToast({
              message: `Định dạng bộ nhớ trong không thành công`,
              type: "error",
            });
          }
        }
      }
    },
    async onCopyMusicToSDcard () {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          await this.onsetScene_NesthouseMusicUSBIn();
          this.fnCallResetView("MusicUSBIn");
          document.getElementById("timerUSBin").innerHTML = 5 + ":" + 0;
          this.onCountDownTimerUSBIn();
        }
      }
    },
    async onsetScene_NesthouseUpdateFirmwareURL() {
      this.PDU["Scene"].obj = {
        device_id: this.PDU.select || this.PDU[this.modul].obj.device_id,
        "1152": {
          Da: true,
          Dt: "BOOLEAN",
        },
        "1088": {
          Da: this.PDU[this.modul].obj.firmwareURL,
          Dt: "STRING",
        },
      };
      var attribute = {
        user_name: this.PDU.userSession.username,
        attribute_list: [this.PDU["Scene"].obj],
      };

      if (!this.PDU[this.modul].obj.active) {
        return;
      } else {
        await this.fnIotPost(attribute);
      }
    },
    async onUpdateFirmware() {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          if (this.onCheckRole() == false) {
            this.fnToastNotificationOnly({
              message: "Bạn chưa được cấp quyền truy cập điều khiển!",
              type: "error",
              reload: false,
            });
            return;
          }

          await this.onsetScene_NesthouseUpdateFirmwareURL();
          this.$set(this.PDU[this.modul].obj, "firmwareUpdateStatus", "waiting");
          this.dialog_update_firmware = false;
          this.dialog_device_info = false;
          this.BREA.dialog_device_info = false
          await pause(100000);

          if (this.PDU[this.modul].obj.firmwareUpdateStatus == "waiting") {
            this.$set(this.PDU[this.modul].obj, "firmwareUpdateStatus", false);
            this.fnToastNotificationOnly({
              message: "Có lỗi xảy ra. Vui lòng thử lại sau!",
              type: "error",
              reload: false,
            });
          }
        }
      }
      this.BREA.dialog_device_info = false
      this.dialog_update_firmware = false;
      this.dialog_device_info = false;
    },
    async onAttributeUpdate_Nesthouse() {
      var data = {};
      var data1 = {};
      for (let [key, value] of Object.entries(this.list_MusicChannel)) {
        delete value.volume;
        data[Object.keys(value)[0]] =
          Object.values(value)[0].attribute_descriptions_copy ||
          `Âm ly ${parseInt(key, 10) + 1}`;
      }

      for (let [key, value] of Object.entries(this.list_audio_status)) {
        data1[Object.keys(value)[0]] =
          Object.values(value)[0].attribute_descriptions_copy ||
          this.fnRename(Object.values(value)[0].Na);
      }
      var attribute_list = []
      attribute_list.push({
        attribute_descriptions: {
          Da: data,
          Dt: "Object",
        },
        device_id: this.PDU.select || this.PDU[this.modul].obj.device_id,
      })
      if (this.list_audio_status && this.list_audio_status.length > 0) {
        attribute_list.push({
          attribute_descriptions: {
            Da: data1,
            Dt: "Object",
          },
          device_id:
            Object.values(this.list_audio_status[0])[0].device_id ||
            this.PDU[this.modul].obj.device_id,
        })
      }
      var attribute = {
        user_name: this.PDU.userSession.username,
        attribute_list,
      };

      await this.fnUpdate_AttributeDescriptions(attribute).then(data => {
        if (data["success"]) {
          this.fnToast({
            message: "Thao tác thành công",
            type: "success",
          });
          this.list_MusicChannel.map(e =>
            this.$set(
              Object.values(e)[0],
              "attribute_descriptions",
              Object.values(e)[0].attribute_descriptions_copy
            )
          );

          this.list_audio_status.map(e =>
            this.$set(
              Object.values(e)[0],
              "attribute_descriptions",
              Object.values(e)[0].attribute_descriptions_copy ||
                this.fnRename(Object.values(e)[0].Na)
            )
          );
        }
      });
      var attribute_list1 = {}
      for (var i in this.list_audio_status) {
        let audio_status = this.list_audio_status[i]
        let new_audio_status_description = Object.values(audio_status)[0].attribute_descriptions
        let new_music_channel_description = Object.values(this.list_MusicChannel[Math.floor(i/2)])[0].attribute_descriptions || `Âm ly ${Math.floor(i/2) + 1}`

        var scene_on, scene_off

        let index_scene_id = this.PDU[this.modul].obj.AutoScene.findIndex(scene => {
          return Object.keys(scene)[0] === Object.values(audio_status)[0].scene_id
        })
        scene_on = index_scene_id >= 0 ? this.fnCopy(this.PDU[this.modul].obj.AutoScene[index_scene_id]) : null

        let index_scene_id1 = this.PDU[this.modul].obj.AutoScene.findIndex(scene => {
          return Object.keys(scene)[0] === Object.values(audio_status)[0].scene_id1
        })
        scene_off = index_scene_id1 >= 0 ? this.fnCopy(this.PDU[this.modul].obj.AutoScene[index_scene_id1]) : null

        if (scene_on) {
          let output = Object.values(scene_on)[0].Da.output

          if (output[0].notify_content) {
            output[0].notify_content.Da = output[0].notify_content.Da !== 'null' ? `${new_music_channel_description} - ${new_audio_status_description} - tắt` : 'null'
          } else {
            output[1].notify_content.Da = output[1].notify_content.Da !== 'null' ? `${new_music_channel_description} - ${new_audio_status_description} - tằt` : 'null'
          }
          Object.assign(attribute_list1, scene_on)
        }
        if (scene_off) {
          let output = Object.values(scene_off)[0].Da.output

          if (output[0].notify_content) {
            output[0].notify_content.Da = output[0].notify_content.Da !== 'null' ? `${new_music_channel_description} - ${new_audio_status_description} - bật` : 'null'
          } else {
            output[1].notify_content.Da = output[1].notify_content.Da !== 'null' ? `${new_music_channel_description} - ${new_audio_status_description} - bật` : 'null'
          }
          Object.assign(attribute_list1, scene_off)
        }
      }
      var attribute_post = Object.assign({}, attribute_list1, {
        device_id: this.PDU[this.modul].obj.device_id,
      });
      var attribute1 = {
        user_name: this.PDU.userSession.username,
        attribute_list: [attribute_post],
      };
      await this.fnIotPost(attribute1);
    },
    async onAttributeUpdate_ChannelMonitor() {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          var data = {};
          var data1 = {};
          for (let [key, value] of Object.entries(
            this.list_channel_monitor_AudioStatus
          )) {
            data[Object.keys(value)[0]] =
              Object.values(value)[0].attribute_descriptions_copy ||
              `Kênh ${key}`;
          }

          var attribute = {
            user_name: this.PDU.userSession.username,
            attribute_list: [
              {
                attribute_descriptions: {
                  Da: data,
                  Dt: "Object",
                },
                device_id: this.PDU[this.modul].obj.device_id,
              },
            ],
          };

          await this.fnUpdate_AttributeDescriptions(attribute).then(data => {
            if (data["success"]) {
              this.fnToast({
                message: "Cài đặt tên thành công",
                type: "success",
              });

              this.list_channel_monitor_AudioStatus.map(e =>
                this.$set(
                  Object.values(e)[0],
                  "attribute_descriptions",
                  Object.values(e)[0].attribute_descriptions_copy
                )
              );
            }
          });

          var audioUsed_1 = [];
          var audioUsed = this.fnCopy(this.list_channel_monitor_AudioUsed);
          var audioUsedLen = this.list_channel_monitor_AudioUsed.length;

          for (var i = 0; i < audioUsedLen; i++) {
            var Da_copy = this.fnCopy(Object.values(audioUsed[i])[0].Da_copy);

            if (Object.values(audioUsed[i])[0].Da != Da_copy) {
              delete Object.values(audioUsed[i])[0].Na;
              delete Object.values(audioUsed[i])[0].Re;
              delete Object.values(audioUsed[i])[0].Pe;
              delete Object.values(audioUsed[i])[0].Cl;
              delete Object.values(audioUsed[i])[0].Un;
              delete Object.values(audioUsed[i])[0].Da_copy;

              this.$set(Object.values(audioUsed[i])[0], "Da", Da_copy);

              audioUsed_1.push(audioUsed[i]);
            } else {
            }
          }
          var obj = audioUsed_1.reduce(function(result, item) {
            var key = Object.keys(item)[0];
            result[key] = item[key];
            return result;
          });

          var attribute_post = Object.assign({}, obj, {
            device_id: this.PDU[this.modul].obj.device_id,
          });

          var attribute_1 = {
            user_name: this.PDU.userSession.username,
            attribute_list: [attribute_post],
          };
          await this.fnIotPost(attribute_1);
        }
      }
    },
    onInitChMoItem(e) {
      if (e == true) {
        for (var i in this.list_channel_monitor_AudioUsed) {
          var item = this.list_channel_monitor_AudioUsed[i];

          this.$set(Object.values(item)[0], "Da_copy", true);
        }
      } else if (e == false) {
        for (var i in this.list_channel_monitor_AudioUsed) {
          var item = this.list_channel_monitor_AudioUsed[i];

          this.$set(Object.values(item)[0], "Da_copy", false);
        }
      }
    },
    onInitChMoItem_1(e) {
      if (e == false) {
        this.ChMoUsedItem = false;
        return;
      } else {
        for (var i in this.list_channel_monitor_AudioUsed) {
          var item = this.list_channel_monitor_AudioUsed[i];

          if (Object.values(item)[0].Da_copy == false) {
            this.ChMoUsedItem = false;
            return;
          }
        }
        this.ChMoUsedItem = true;
        return;
      }
    },
    onsetAttributeNesthouse() {
      var attribute = [];
      var ojj = this.fnCopy(this.PDU["DeviceAttribute"].obj);
      delete Object.values(ojj)[0].volume;
      delete Object.values(ojj)[0].attribute_descriptions;
      delete Object.values(ojj)[0].attribute_descriptions_copy;
      delete Object.values(ojj)[0].attribute_descriptions_left;
      delete Object.values(ojj)[0].attribute_descriptions_right;
      delete Object.values(ojj)[0].list_timer;
      delete Object.values(ojj)[0].list_scene_timer;

      Object.values(ojj)[0].Da = "No setting";

      // var data_ojj = Object.assign({}, ojj, {device_id:Object.values(ojj)[0].device_id || this.PDU[this.modul].obj.device_id});

      var obb = this.fnCopy(this.PDU["DeviceAttribute"].obj);
      var volume = this.fnCopy(Object.values(obb)[0].volume);
      Object.values(volume)[0].Da = 0.0;

      attribute.push(ojj);
      attribute.push(volume);

      return attribute;
    },
    list_playing() {
      var list = this.fnCopy(this.PDU[this.modul].obj.list_MusicChannel);
      var arr = [];
      list.forEach(e => {
        var data = Object.values(e)[0].Da;
        if (data != "No setting") {
          arr.push(data);
        }
      });
      return arr;
    },
    onCheckDelMusic(music) {
      var mus = { music: music };
      var list = this.list_playing();
      var idx = list.findIndex(e => {
        return e == mus.music;
      });
      if (idx != -1) {
        this.fnToast({
          message: `Âm đang được phát! Vui lòng không xóa!`,
          type: "error",
        });
        return;
      } else {
        this.fnSetMusicStorage({ model: "Music", item: mus });
      }
    },

    sort_scene_display_to_setting(list) {
      var d = new Date();
      list.sort(function(a, b) {
        var time =
          a.input && a.input[0] && a.input[0].day_min && a.input[0].day_min.Da
            ? a.input[0].day_min.Da
            : 0;
        var time1 =
          b.input && b.input[0] && b.input[0].day_min && b.input[0].day_min.Da
            ? b.input[0].day_min.Da
            : 0;
        return time - time1;
      });
    },
    sort_scene_display(list) {
      try {
        list.sort(function(a, b) {
          if (a.timer) {
            var arr = a.timer.split(":");
            if (arr && arr.length > 0) {
              var time = parseInt(arr[0]) * 60 + parseInt(arr[1]);
              var arr1 = b.timer.split(":");
              var time1 = parseInt(arr1[0]) * 60 + parseInt(arr1[1]);
              return time - time1;
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    sort_scene_setting(list) {
      var timeLT = null;
      var len = list.length;

      list.forEach(e => {
        if (e.input && e.input.length >= 2) {
          for (var i = 0; i < e.input.length; i++) {
            var idx = e.input.findIndex(d => {
              d.oper && d.oper == "LT";
            });

            e.input.splice(idx, 1);
          }
        }
      });

      var index = list.find(e => {
        return e.input[0].day_min.Da == 0;
      });

      if (index) {
        index.output = list[len - 1].output;
        var scene1 = this.NesthouseList.find(e => {
          return Object.keys(e)[0] == index.id;
        });
        if (scene1)
          this.$set(Object.values(scene1)[0].Da, "output", index.output);
      }

      for (var i = 0; i < list.length; i++) {
        var item = list[i];

        // var index = list.find(e => {
        //   return e.input[0].day_min.data == 0;
        // });

        if (
          item.input &&
          item.input.length == 1 &&
          !list[i + 1] &&
          this.NesthouseTimerArr.length > 0
        ) {
          var scene = this.NesthouseList.find(e => {
            return Object.keys(e)[0] == item.id;
          });

          var value = Object.values(scene)[0].Da;

          var input = value.input;

          input.push({
            day_min: {
              Da: 1440,
              Dt: "UINT32",
            },
            device_id: this.PDU[this.modul].obj.device_id,
            oper: "LT",
          });
        }

        if (item.input && item.input.length == 1 && list[i + 1]) {
          var scene = this.NesthouseList.find(e => {
            return Object.keys(e)[0] == item.id;
          });

          if (scene) {
            var key = Object.keys(scene)[0];
            var value = Object.values(scene)[0].Da;

            var input = value.input;

            var ip = list[i + 1] ? list[i + 1].input : [];

            for (var j in ip) {
              if (ip[j] && ip[j].oper && ip[j].oper == "GE") {
                timeLT = ip[j] && ip[j].day_min ? ip[j].day_min.Da : null;

                timeLT != null
                  ? input.push({
                      day_min: {
                        Da: timeLT,
                        Dt: "UINT32",
                      },
                      device_id: this.PDU[this.modul].obj.device_id,
                      oper: "LT",
                    })
                  : "";
              }
            }
          }
        }
      }

      var arr = this.fnCopy(this.NesthouseList);
      return arr;
    },

    onConvertMilestonetoTimer(data) {
      var d = new Date();
      var arr1 = data.split(":");
      var time =
        parseInt(arr1[0]) * 60 + parseInt(arr1[1]) + d.getTimezoneOffset();
      return time;
    },
    onCaculator_timestamp(data) {
      // var hour1 = new Date().valueOf() / 1000;
      // var result = Math.floor(Math.floor(hour1 - data) / 60);

      // if(result<0){result = result * (-1)}
      // return result == 0
      //   ? Math.floor(hour1 - data) + " giây"
      //   : result + " phút";
      var hour1 = new Date().valueOf() / 1000;
      var second = Math.floor(hour1 - data);
      var minute = Math.floor(second / 60);
      var hour = Math.floor(minute / 60);
      var day = Math.floor(hour / 24);
      var month = Math.floor(day / 30);
      var year = Math.floor(month / 12);

      if (minute < 0) {
        minute = minute * -1;
      }
      return second >= 0 && second <= 60
        ? second + " giây trước"
        : minute >= 1 && minute <= 60
          ? minute + " phút trước"
          : hour <= 24
            ? hour + " giờ trước"
            : day >= 1 && day <= 30
              ? day + " ngày trước"
              : month ? month + " tháng trước" : " không xác định";
    },

    async onCreateGroup() {
      var index = this.PDU['group'].group_list.findIndex(e => { return e.name === this.GROUP.group.name })
      if (index >= 0) {
        this.fnToast({
          message: `${this.GROUP.group.name} đã tồn tại`,
          type: "error",
        })
        this.GROUP.dialog_creategroup = false
        this.GROUP.dialog_add_group = false
        return
      }
      var device_id = [];
      this.GROUP.checkbox_creategroup.forEach(device => {
        device_id.push(device);
      });
      var data = {
        user_name: this.PDU.userSession.username,
        group_descriptions: this.GROUP.group.name,
        device_ids: device_id,
        group_id: new Date().getTime().toString(),
      };

      await this.fnIotPut_Group({ group_list: [data] }).then(data => {});

      this.GROUP.dialog_creategroup = false
      this.GROUP.dialog_add_group = false
      await pause(2000);
      await this.fnRead("group");
    },
    async onDeleteGroup() {
      await this.fnDelete("group");

      await this.fnRead("group");
    },
    async onEditGroup() {
      var index = this.PDU['group'].group_list.findIndex(e => { return e.group_id != this.PDU["group"].obj.group_id && e.name === this.PDU["group"].obj.name })
      if (index >= 0) {
        this.fnToast({
          message: `${this.PDU["group"].obj.name} đã tồn tại`,
          type: "error",
        })
        this.GROUP.dialog_edit_group_3 = false;
        this.GROUP.dialog_edit_group_2 = false;
        this.GROUP.dialog_edit_group_1 = false;
        return
      }
      var device_id = [];
      if (this.GROUP.checkbox_creategroup.length >= 1) {
        this.GROUP.checkbox_creategroup.forEach(device => {
          // var index = this.PDU['group'].obj.children && this.PDU['group'].obj.children.findIndex(e=>{return e && e.id == device  && e.name && !e.name.includes('[Không tìm thấy]')})
          // if(index>=0){ device_id.push(device) }

          device_id.push(device);
        });
      } else {
        this.fnToast({
          message: `Có lỗi xảy ra trong quá trình sửa nhóm thiết bị [${
            this.group.name
          }]. Vui lòng thử lại!`,
          type: "error",
        });
        this.GROUP.dialog_edit_group_3 = false;
        this.GROUP.dialog_edit_group_2 = false;
        this.GROUP.dialog_edit_group_1 = false;
        return;
      }
      //create object IOT post
      var data = {
        user_name: this.PDU.userSession.username,
        group_descriptions: this.PDU["group"].obj.name,
        device_ids: device_id,
        group_id: this.PDU["group"].obj.group_id,
      };
      var obj = new Object();
      obj.group_list = [data];

      await this.fnIotPost_Group(obj);

      this.GROUP.dialog_edit_group_3 = false;
      this.GROUP.dialog_edit_group_2 = false;
      this.GROUP.dialog_edit_group_1 = false;

      // await this.fnRead('group')
    },
    onFilterGroupSelected(item) {
      this.GROUP.dialog_edit_group_2 = true;
      this.PDU["group"].obj = this.fnCopy(item);

      for (var i in item.children) {
        var it = item.children[i];

        if (it.name && !it.name.includes("[Không tìm thấy]")) {
          this.GROUP.checkbox_creategroup.push(it.id);
        }
      }
      this.GROUP.group.name = this.fnCopy(item.name);
    },
  },
  computed: {
    AudioMasterHardwareAvailble1 () {
      if (this.PDU[this.modul].obj.hardware_1 && 
        ( this.PDU[this.modul].obj.hardware_1 == 3 || 
          this.PDU[this.modul].obj.hardware_1 == 10 || 
          this.PDU[this.modul].obj.hardware_1 == 20 || 
          this.PDU[this.modul].obj.hardware_1 == 11 )) {
        return true
      } else { return false }
    },
    AudioMasterHardwareVersionAvailble () {
      if (this.PDU[this.modul].obj.hardwareVersion && 
        Object.values(this.PDU[this.modul].obj.hardwareVersion)[0] && 
        ( Object.values(this.PDU[this.modul].obj.hardwareVersion)[0].Da == 3 || 
          Object.values(this.PDU[this.modul].obj.hardwareVersion)[0].Da == 10 || 
          Object.values(this.PDU[this.modul].obj.hardwareVersion)[0].Da == 20 ||
          Object.values(this.PDU[this.modul].obj.hardwareVersion)[0].Da == 11)) {
        return true
      } else { return false }
    },
    AudioMasterHardwareAvailble () {
      if (this.PDU[this.modul].obj.hardware && 
        ( this.PDU[this.modul].obj.hardware == 3 || 
          this.PDU[this.modul].obj.hardware == 10 || 
          this.PDU[this.modul].obj.hardware == 20 || 
          this.PDU[this.modul].obj.hardware == 11)) {
        return true
      } else { return false }
    },
    usbremain () {
      return this.PDU[this.modul].obj && this.PDU[this.modul].obj.USBRemain &&
      Object.values(this.PDU[this.modul].obj.USBRemain)[0] &&
      Object.values(this.PDU[this.modul].obj.USBRemain)[0].Da &&
      Object.values(this.PDU[this.modul].obj.USBRemain)[0].Da/1000/1000
    },
    devicelist() {
      return this.PDU["devices"].list || [];
    },
    devices() {
      return this.PDU["devices"].list || [];
    },
    device() {
      return this.PDU["devices"].obj || {};
    },
    output() {
      return this.PDU.output || [];
    },
    list_outlet() {
      var x = Object.assign({}, this.PDU[this.modul].obj.list_outled || []);
      var list = [];
      if (!x.length) {
        for (var i in this.PDU[this.modul].obj.child_attribute) {
          x = this.PDU[this.modul].obj.child_attribute[i]
            ? this.PDU[this.modul].obj.child_attribute[i].list_outled
            : [];
          if (x && x.length > 0) {
            var list_outlet = this.fnCopy(x);

            for (var j in list_outlet) {
              if (
                (Object.values(list_outlet[j])[0] &&
                  (Object.values(list_outlet[j])[0].list_scene_temper &&
                    Object.values(list_outlet[j])[0].list_scene_temper.length >
                      0)) ||
                (Object.values(list_outlet[j])[0].list_scene_timer &&
                  Object.values(list_outlet[j])[0].list_scene_timer.length >
                    0 &&
                  Object.values(list_outlet[j])[0].Auto == true)
              ) {
                list.push({
                  name:
                    (Object.values(list_outlet[j])[0].attribute_descriptions ||
                      Object.values(list_outlet[j])[0].Na) + " (bận)",
                  id_outlet: {
                    id_outlet: Object.keys(list_outlet[j])[0],
                    device_id: Object.values(list_outlet[j])[0].device_id,
                  },
                  disable: true,
                  device_id: Object.values(list_outlet[j])[0].device_id,
                });
              } else {
                list.push({
                  name:
                    Object.values(list_outlet[j])[0].attribute_descriptions ||
                    Object.values(list_outlet[j])[0].Na,
                  id_outlet: {
                    id_outlet: Object.keys(list_outlet[j])[0],
                    device_id: Object.values(list_outlet[j])[0].device_id,
                  },
                  device_id: Object.values(list_outlet[j])[0].device_id,
                });
              }
            }
          }
        }
      }
      if(list.length > 8) {
        var PDU_index = 1
        let index = 0
        list = list.map(pdu => {
          if (index > 0 && index % 8 === 0) {
            PDU_index += 1
          }
          index += 1
          return {
            ...pdu,
            name: `[${PDU_index}] ${pdu.name}`
          }
        })
      }

      return list;
    },
    list_outlet1() {
      var list_outlet = this.fnCopy(this.PDU[this.modul].obj.list_outled);
      return list_outlet;
    },
    list_temper() {
      return this.PDU["devices"].obj.list_temper;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Tạo mới kịch bản" : "Sửa kịch bản";
    },
    isDelTitle({ message }) {
      return message;
    },
    addchildDevice() {
      // this.childDevice = []
      // if(Object.values(this.PDU[this.modul].obj.hardwareVersion)[0].Da = 3){
      //   var idx = this.childDevice.findIndex(e=> {return e && e.name && e.name == 'Audio'})
      //   if(idx == -1) {this.childDevice = [{name: 'Audio'}]}
      // }
      // for(var i in this.PDU['DeviceDetail'].obj.child_attribute){
      //   var item = this.PDU['DeviceDetail'].obj.child_attribute[i]
      //   if(item.isOutlet && item.list_outled && item.list_outled.length>0){
      //     if(this.childDevice.length <= 4){
      //       var idx = this.childDevice.findIndex(e=> {return e && e.name && e.name == 'PDU'})
      //       if(idx==-1){this.childDevice.push({name: 'PDU'})}
      //     }
      //   }
      //   if(item.isSensor && item.list_temper && item.list_temper.length>0){
      //     if(this.childDevice.length <= 4){
      //       var idx = this.childDevice.findIndex(e=> {return e && e.name && e.name == 'Sensor'})
      //       if(idx==-1){this.childDevice.push({name: 'Sensor'})}
      //     }
      //   }
      // }
      return this.childDevice;
    },
    outlet_availble_setting() {},

    device_outled() {
      var device = this.PDU[this.modul].obj.child_attribute
        ? this.PDU[this.modul].obj.child_attribute.filter(e => {
            return e.list_outled && e.list_outled.length > 0 &&
            e.device_id === this.PDU[this.modul].obj.child_device_id;
          })
        : [];
      if (device && device.length > 0) {
        device.sort((a, b) => {
          return a.device_id < b.device_id ? 1 : -1;
        });

        return device;
      } else {
        return [];
      }
    },
    device_temper() {
      try {
        var device = this.PDU[this.modul].obj.child_attribute
          ? this.PDU[this.modul].obj.child_attribute.filter(e => {
              return e.list_temper && e.list_temper.length > 0;
            })
          : [];

        device.sort((a, b) => {
          return a.device_id < b.device_id ? 1 : -1;
        });

        return device;
      } catch (err) {console.log(err);}
    },
    list_device_to_deleting() {
      var arr = [];
      if (this.PDU[this.modul].obj.child_attribute) {
        this.PDU[this.modul].obj.child_attribute.forEach(e => {
          arr.push(e.device_id);
        });
        arr.push(this.PDU[this.modul].obj.device_id);
      }
      return arr;
    },
    ///////////////////////////////////////////////////////////NESTHOUSE///////////////////////////////////////////////////////
    list_MusicChannel() {
      return this.PDU[this.modul].obj.list_MusicChannel || [];
    },
    list_ListMusic() {
      return this.PDU[this.modul].obj.list_ListMusic &&
        Object.values(this.PDU[this.modul].obj.list_ListMusic)[0] &&
        Object.values(this.PDU[this.modul].obj.list_ListMusic)[0].Da
        ? Object.values(this.PDU[this.modul].obj.list_ListMusic)[0].Da
        : [];
    },
    list_musicSelect() {
      return this.PDU["Music"].obj.file;
    },
    devicesDownloadMusic() {
      return this.PDU["Music"].obj.progress_data;
    },
    sortLE() {
      var x = this.PDU.scene_sort;
      var result = 0;
      if (x.length == 0) return result;
      else if (x.length == 1) return x[0];
      else {
        return x.sort(function(a, b) {
          return a - b;
        });
      }
    },
    list_audio_status() {
      var attribute = null
      attribute = this.PDU[this.modul].obj.child_attribute
        ? this.PDU[this.modul].obj.child_attribute.find(e => {
            return (
              e.device_id &&
              e.list_audio_status &&
              e.list_audio_status.length > 0
            );
          })
        : [];
      if (!attribute || (attribute && attribute.length === 0)) {
        return [];
      } else {
        return attribute.list_audio_status || [];
      }
    },
    list_channel_monitor_AudioStatus() {
      return this.PDU[this.modul].obj.list_CMAudioStatus || [];
    },
    list_channel_monitor_AudioHealthy() {
      return this.PDU[this.modul].obj.list_CMAudioHealth || [];
    },
    list_channel_monitor_AudioTimeOff() {
      return this.PDU[this.modul].obj.list_CMAudioTimeOff || [];
    },
    list_channel_monitor_AudioTimerHealthy() {
      return this.PDU[this.modul].obj.list_CMAudioTimerHealthy || [];
    },
    list_channel_monitor_AudioUsed() {
      return this.PDU[this.modul].obj.list_CMAudioUsed || [];
    },
    checkChMoIsVisible() {
      for (var i in this.PDU[this.modul].obj.list_CMAudioUsed) {
        var item = this.PDU[this.modul].obj.list_CMAudioUsed[i];
        if (Object.values(item)[0].Da == true) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  }
};
</script>

<style>
.slider-edit-musicchanel .v-input__control .v-input__slot .v-slider .v-slider__track-container{
  height: 30px !important
}
.slider-edit-musicchanel .v-input__control .v-input__slot .v-slider .v-slider__thumb-container .v-slider__thumb {
  display: none !important
} 
.slider-scene-musicchanel .v-input__control .v-input__slot .v-slider .v-slider__thumb-container .v-slider__thumb {
  display: none !important
} 
.sen-war-combb .v-input__control .v-text-field__details{
  display: none;
}
.bg_gray {
  background-color: #f9f9f9
}
.recover_btn_add {
  margin-left: 15px;
}
.gr-he .v-list-group__header {
  max-width: 30%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
}
.gr-he .v-list-group__header .v-list-item__icon {
  opacity: 1;
}

.v-slider__thumb {
  height: 15px !important;
  width: 15px !important;
}
.v-slider--horizontal .v-slider__track-container {
  height: 10px !important;
}
.theme--light.v-select .v-select__selection--disabled {
  color: #00897b !important;
  caret-color: #00897b !important;
}
.theme--light.v-select .v-select__selection--comma {
  color: #00897b !important;
  caret-color: #00897b !important;
}

.ac-tab {
  color: #fc9003 !important;
  text-transform: initial !important;
}
.v-tab {
  text-transform: initial !important;
}
.status_color {
  color: #1bb5a6;
}

.color_off {
  color: #b2b2b2;
}
.color_on {
  color: #fc9003;
}
.color_on_green {
  color: #009688;
}
.v-application .v-list-item--active {
  color: #aeaeae !important;
  caret-color: #aeaeae !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 44px !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}
.v-application .primary.lighten-3 {
  background-color: #aeaeae !important;
  border-color: #aeaeae !important;
}
.Rectangle-35 {
  background-color: rgba(196, 196, 196, 0.2) !important;
  height: 44px;
  width: 100%;
}

.v-slider__thumb-container--active .v-slider__thumb:before {
  transform: scale(1.5) !important;
}
.zalo_custom {
  width: 90px !important;
  height: 25px !important;
}

.br-crum {
  height: 56px;
  background-color: hsl(0, 0%, 90%);
}
.padding_style_none .v-list__tile {
  padding: 0 !important;
}
.v-text-field .v-input__prepend-inner {
  padding-right: 10px;
}
.font_badge {
  font-family: SFProText;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ef1e02;
}
.Rectangle-24:fullscreen {
  width: 100%;
  min-height: 900px;
  border-radius: 4px;
  background-color: rgba(196, 196, 196, 0.2);
  padding: 30px;
}
.Rectangle-25,
.Rectangle-28 {
  width: 297px;
  height: 359px;
  border-radius: 20px !important;
  border: solid 1px #ffffff;
  background-image: linear-gradient(
    to top,
    rgba(223, 223, 223, 0.5),
    rgba(223, 223, 223, 0.1)
  ) !important;
  margin: 30px;
  padding: 12px;
}
.Rectangle-28 {
  border: none;
  background-color: rgba(11, 10, 10, 0.1) !important;
}
.Rectangle-25_1 {
  width: 297px;
  height: 347px;
  border-radius: 20px !important;
  background-image: linear-gradient(
    to top,
    rgba(223, 223, 223, 0.5),
    rgba(223, 223, 223, 0.1)
  ) !important;
}
.Rectangle-26 {
  width: 297px;
  height: 347px;
  border-radius: 20px !important;
  background-color: rgba(11, 10, 10, 0.2) !important;
}

.H-thng-camera {
  margin: 12px auto;
  text-align: center;

  height: 19px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #797979;
}

.Ellipse-23 {
  width: 123.5px !important;
  height: 123.5px !important;
  box-shadow: inset 25px 50px 25px 50px rgba(196, 196, 196, 0.7),
    inset 0 0 12px 0 rgba(196, 196, 196, 0.1),
    inset 0 -4px 12px 0 rgba(255, 255, 255, 0.25),
    inset -4px 0 12px 0 rgba(196, 196, 196, 0.1) !important;
  border-style: solid;
  border-width: 6px;
  border-image-source: radial-gradient(
    circle at 0 0,
    rgba(223, 223, 223, 0.1),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
  background-color: rgba(196, 196, 196, 0.2) !important;
}

.Ellipse-23_1 {
  width: 123.5px !important;
  height: 123.5px !important;
  border-style: solid;
  border-width: 6px;
  border-image-source: radial-gradient(
    circle at 0 0,
    rgba(223, 223, 223, 0.1),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
  box-shadow: inset 25px 50px 25px 50px #0d9fa1, 0 0 15px 0 #00c2ff,
    inset 0 -4px 12px 0 rgba(255, 255, 255, 0.25),
    inset -4px 0 12px 0 rgba(38, 166, 154, 0.26) !important;
}
.Ellipse-23_2 {
  width: 30px !important;
  height: 30px !important;
  box-shadow: inset 25px 50px 25px 50px rgba(196, 196, 196, 0.7),
    inset 0 0 12px 0 rgba(196, 196, 196, 0.1),
    inset 0 -4px 12px 0 rgba(255, 255, 255, 0.25),
    inset -4px 0 12px 0 rgba(196, 196, 196, 0.1) !important;
  border-style: solid;
  border-width: 6px;
  border-image-source: radial-gradient(
    circle at 0 0,
    rgba(223, 223, 223, 0.1),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
  background-color: rgba(196, 196, 196, 0.2) !important;
}
.Ellipse-23_3 {
  width: 30px !important;
  height: 30px !important;
  border-style: solid;
  border-width: 6px;
  border-image-source: radial-gradient(
    circle at 0 0,
    rgba(223, 223, 223, 0.1),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
  box-shadow: inset 25px 50px 25px 50px #0d9fa1, 0 0 15px 0 #00c2ff,
    inset 0 -4px 12px 0 rgba(255, 255, 255, 0.25),
    inset -4px 0 12px 0 rgba(38, 166, 154, 0.26) !important;
}
.Thng {
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #797979;
}
.Thngs {
  color: #797979;
}
.Thng_3 {
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0d9fa1;
}
.Thng_2 {
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fc9003;
}
.Thng_1 {
  font-size: 30px;
  font-weight: 450;
}
.Cnh-bo {
  width: 60px;

  height: 16px;

  font-family: Roboto;

  font-size: 14px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: normal;

  color: #797979;
}
.Rectangle-29 {
  width: 44px;
  height: 44px !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 4px 0 rgba(174, 174, 174, 0.5), -4px 0 4px 0 #ffffff,
    0 4px 4px 0 rgba(128, 128, 128, 0.25) !important;
  border: solid 0.5px #fcfcfc;
  background-color: #ffffff !important;
  padding: 0 !important;
}
.Rectangle-29_1 {
  width: 44px;
  height: 44px !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 4px 0 rgba(38, 166, 154, 0.4), -4px 0 4px 0 #ffffff !important;
  border: solid 0.5px rgba(38, 166, 154, 0.26);
  background-color: #ffffff !important;
  padding: 0 !important;
}

.Rectangle-29_2 {
  width: 100px;

  height: 80px;

  border-radius: 10px !important;

  /* box-shadow: 0 4px 4px 0 rgba(174, 174, 174, 0.5), -4px 0 4px 0 #ffffff, 0 4px 4px 0 rgba(128, 128, 128, 0.25) !important; */

  background-color: transparent !important;
}

.Rectangle-29_4 {
  width: 80px;

  height: 80px;

  border-radius: 10px !important;

  box-shadow: 1px 3px 4px 2px rgba(38, 166, 154, 0.4), -4px 0 4px 0 #ffffff !important;

  border: solid 0.5px rgba(38, 166, 154, 0.26);

  background-color: #ffffff;
}
.Rectangle-29_5 {
  width: 100px;
  height: 100px;
  border-radius: 10px !important;
  /* box-shadow: 0 2px 2px 0 rgba(174, 174, 174, 0.5), -2px 0 4px 0 #ffffff, 0 4px 4px 0 rgba(128, 128, 128, 0.25); */
  background-color: rgba(11, 10, 10, 0) !important;
}

.Rectangle-18 {
  width: 100%;

  height: auto;

  background-color: hsl(0deg 0% 64% / 10%);
}
.Rectangle-18_1 {
  width: 100%;

  height: auto;

  background-color: rgba(11, 10, 10, 0);
}
.Rectangle-18:hover,
.Rectangle-18_1:hover {
  box-shadow: 0 4px 4px 0 rgba(38, 166, 154, 0.12),
    0 4px 4px 0 rgba(38, 166, 154, 0.12);
  /* background-image: linear-gradient(to top, #f9f9f9, rgba(255, 255, 255, 0)), linear-gradient(to top, #f9f9f9, rgba(255, 255, 255, 0)), linear-gradient(to bottom, #f9f9f9, #f9f9f9); */
}

.layout_1 .v-btn {
  min-width: 44px;
}
.color_text {
  color: #26a69a;
}

.offline_color {
  color: gray;
}
.volume_up_color {
  color: #fc9003;
}
.volume_off_color {
  color: #ef1e02;
}
.music_name_color {
  color: #424242;
  margin: 0;
}
.stylish {
  width: 10px;
  text-align: center;
}
.ca-te-moblie-style {
  padding: 0 4px !important;
}

@media only screen and (max-width: 600px) {
  
  .ca-te-moblie-style {
    padding: 0 !important;
  }
  .recover_btn_add {
    margin-left: 0;
  }
  .mobile-padd {
    padding-bottom: 100px;
    padding-left:0;
  }
  .gr-he .v-list-group__header {
    max-width: 100%;
  }
  .padding_bread {
    padding: 0 !important;
  }
  .li-it-pa {
    padding: 0 !important;
  }
  .cha-pass {
    width: 100% !important;
  }
  .zalo_custom {
    right: 0 !important;
    bottom: -3px !important;
  }
  .da-nh {
    align-items: start !important;
  }
  .notification {
    margin-right: 0 !important;
  }
  .Rectangle-12 {
    width: auto !important;
    height: 56px !important;
    padding: 4px 15px !important;
  }
  .scalefont {
    font-family: Roboto;

    font-size: 12px !important;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: normal !important;

    color: #424242 !important;
  }
  .margin_sm {
    margin: 10px 16px 0 0;
  }
  .Rectangle-25 {
    margin: 15px 0;
  }
  .Rectangle-24 {
    /* padding: 15px; */
  }
  .no_padding_margin {
    margin: 0;
    padding: 0;
  }
  .font_size {
    font-size: 14px;
  }

  .scalesize {
    transform: scale(0.8);
    font-size: 12px;
  }
  .scalesize_1 {
    transform: scale(0.9);
    font-size: 13px;
  }
}

.Vector {
  width: 49px;
  height: 13.8px;
  margin: 20px 16px 0 26px !important;
}
.opacity_hide {
  opacity: 0;
}

.fontstyle {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
}
.fontstyle:hover {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #009688;
}
.avartahover::after {
  content: url("../assets/edit.svg");
}
.avartahover_1::after {
  content: url("../assets/close.svg");
}
.avartahover_2::after {
  content: url("../assets/share.svg");
}
.avartahover_3::after {
  content: url("../assets/refresh.svg");
}
.avartahover_4::after {
  content: url("../assets/library.svg");
}
.avartahover_5::after {
  content: url("../assets/report.svg");
}
.avartahover_6::after {
  content: url("../assets/notification_1.svg");
}
.avartahover_7::after {
  content: url("../assets/notishare.svg");
}

.fontstyle:hover .avartahover::after {
  content: url("../assets/edit-color.svg");
  color: #009688 !important;
}
.fontstyle:hover .avartahover_1::after {
  content: url("../assets/close_color.svg");
  color: #009688;
}
.fontstyle:hover .avartahover_2::after {
  content: url("../assets/share_color.svg");
  color: #009688;
}
.fontstyle:hover .avartahover_3::after {
  content: url("../assets/refresh_color.svg");
  color: #009688;
}
.fontstyle:hover .avartahover_4::after {
  content: url("../assets/library_color.svg");
  color: #009688;
}
.fontstyle:hover .avartahover_5::after {
  content: url("../assets/report_color.svg");
  color: #009688;
}
.fontstyle:hover .avartahover_6::after {
  content: url("../assets/notification_1_color.svg");
  color: #009688;
}
.fontstyle:hover .avartahover_7::after {
  content: url("../assets/notishare_color.svg");
  color: #009688;
}

.padding_style {
  padding: 0 33px;
}
.margin_style {
  margin: 5px 0 0 8px;
}

.flashit {
  color: #fc9003;
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}
@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
</style>
