

<template>
  <v-app >
    <div id="app" style="z-index: 1000;">
      <!--toast notification-->
      <vue-snotify></vue-snotify>
    </div>

    <span  v-if="PDU.userSession" style="position: relative">
      <!--menu sidebar-->
      <sidebar></sidebar>
      <!--appbar in the top of website-->
      <appbar></appbar>
    

    </span>
    <!--another page rendered by vue-router-->
    <v-content class="main" style="background-color: #efefef;">
      <v-container fluid fill-height grid-list-md pt-0 px-1 pb-0>
        <router-view ></router-view>
      </v-container>
    </v-content>

    

    <footerbar></footerbar>
    
  </v-app>
</template>

<script>
import footerbar from "./components/Footer.vue";
import sidebar from "./components/SideBar.vue";
import appbar from "./components/AppBar.vue";
export default {
  data: () => ({ // declare local varaibles
    overlay: false,
  }),
  components: { // declare local varaibles
    footerbar,
    sidebar,
    appbar
  },
  props: {
    source: String,
  },
  computed: {},
  methods: {
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 3000);
    },
  },
};
</script>


<style>
.btn_gr_on {
  width: 14px !important;
  height: 14px !important;
  background-color: rgb(0, 194, 255) !important;
}
.btn_gr_off {
  width: 14px !important;
  height: 14px !important;
  background-color: rgba(174, 174, 174, 0.5) !important;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 6px !important;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #aeaeae !important ;
}
.device_hub_active {
  color: rgb(0, 194, 255) !important;
}
.v-list-group__header {
  padding: 0 !important;
}
.v-messages__message {
  margin-top: 10px;
}
.main {
  padding-top: 57px !important;
  padding-bottom: 30px !important;
  /* padding-left: 56px !important; */
}
.toolbar_padding_left {
  padding-left: 14px !important;
}
.layout_flex {
  flex: 0 1 auto !important;
}
.layout_flex_1 {
  flex: 1 1 auto !important;
}
.v-badge__badge {
  top: 0px;
  right: -5px;
}

.theme--dark.v-input:not(.v-input--is-disabled) input {
  color: #424242;
}
.NestHouse {
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.v-toolbar__content {
  padding: 0 10px 0 0 !important;
  width: 100% !important;
}
.Rectangle-12 {
  width: 300px;
  height: 64px;
  background-color: #009688;
  align-items: center;
  padding: 8px 15px;
}

.theme--dark.v-list .v-list__group--active:after,
.theme--dark.v-list .v-list__group--active:before {
  background: unset;
}
.v-badge--overlap .v-badge__badge {
  top: -4px;
  right: -16px;
}
.v-badge__badge {
  background-color: unset !important;
  /* color: #ef1e02; */
}

.v-list__group__header--active .v-list__group__header__append-icon .v-icon {
  color: #fff !important;
}
.arrow_down_toolbar_color {
  color: #424242 !important;
}
.theme--dark.v-icon {
  color: #aeaeae;
}
.size_avatar {
  width: 24px;
}
.v-list__group,
.v-list__group__items {
  position: unset;
}

.Thit-b,
.Thit-b_1 {
  font-family: Roboto;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal;
  color: #00ffe7 !important;
}
.Thit-b_1 {
  color: #fff !important;
}
.Thit-b_2 {
  color: #aeaeae !important;
}
.theme--dark.v-icon {
  color: #aeaeae !important;
}

.Thit-b_1 .v-list-group__header__append-icon .mdi-chevron-down {
  color: #fff !important;
}

.bg_nav {
  background-color: rgb(50, 50, 50) !important;
  transform: translateX(-300%);
}
.v-list__group__items--no-action .v-list__tile {
  padding: 0 16px;
}
.Nh-yn-A,
.Nh-yn-A_1 {
  font-family: Roboto;

  font-size: 14px !important;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: normal !important;

  color: #aeaeae !important;
}
.Nh-yn-A_1 {
  color: #fff !important;
}
.iu-khin-m-ly {
  font-family: Roboto;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal;
  color: #ffffff;
}

.tile_1 {
  color: #fff;
  background-color: rgba(196, 196, 196, 0.1);
  padding: 0 !important;
}
.tile_2 {
  color: #aeaeae;
  padding: 0 !important;
}
.tile {
  color: #fff;
  background-color: rgba(196, 196, 196, 0.1);
  padding: 12px 0 12px 28px !important;
}
.tile_hover {
  color: #fff;
  padding: 12px 0 12px 28px !important;
}

.tile_hover:hover {
  color: #fff;
  background-color: rgba(196, 196, 196, 0.1);
}

#create .v-speed-dial {
  position: absolute;
}
@-webkit-keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }
  10.5% {
    left: 0;
    top: 0;
  }
  12.5% {
    left: 32px;
    top: 0;
  }
  23% {
    left: 32px;
    top: 0;
  }
  25% {
    left: 64px;
    top: 0;
  }
  35.5% {
    left: 64px;
    top: 0;
  }
  37.5% {
    left: 64px;
    top: 32px;
  }
  48% {
    left: 64px;
    top: 32px;
  }
  50% {
    left: 32px;
    top: 32px;
  }
  60.5% {
    left: 32px;
    top: 32px;
  }
  62.5% {
    left: 32px;
    top: 64px;
  }
  73% {
    left: 32px;
    top: 64px;
  }
  75% {
    left: 0;
    top: 64px;
  }
  85.5% {
    left: 0;
    top: 64px;
  }
  87.5% {
    left: 0;
    top: 32px;
  }
  98% {
    left: 0;
    top: 32px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }
  10.5% {
    left: 0;
    top: 0;
  }
  12.5% {
    left: 32px;
    top: 0;
  }
  23% {
    left: 32px;
    top: 0;
  }
  25% {
    left: 64px;
    top: 0;
  }
  35.5% {
    left: 64px;
    top: 0;
  }
  37.5% {
    left: 64px;
    top: 32px;
  }
  48% {
    left: 64px;
    top: 32px;
  }
  50% {
    left: 32px;
    top: 32px;
  }
  60.5% {
    left: 32px;
    top: 32px;
  }
  62.5% {
    left: 32px;
    top: 64px;
  }
  73% {
    left: 32px;
    top: 64px;
  }
  75% {
    left: 0;
    top: 64px;
  }
  85.5% {
    left: 0;
    top: 64px;
  }
  87.5% {
    left: 0;
    top: 32px;
  }
  98% {
    left: 0;
    top: 32px;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-webkit-keyframes hue-rotate {
  0% {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}
@keyframes hue-rotate {
  0% {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  100% {
    -webkit-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}

.loading {
  position: relative;
  width: 96px;
  height: 96px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: hue-rotate 10s linear infinite both;
  animation: hue-rotate 10s linear infinite both;
}

.loading__square {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  margin: 2px;
  border-radius: 2px;
  background: #07a;
  /* background-image: linear-gradient(45deg, #fa0 40%, #0c9 60%); */
  /* background-image: -moz-linear-gradient(#fa0, #fa0); */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  -webkit-animation: square-animation 10s ease-in-out infinite both;
  animation: square-animation 10s ease-in-out infinite both;
}
.loading__square:nth-of-type(0) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.loading__square:nth-of-type(1) {
  -webkit-animation-delay: -1.4285714286s;
  animation-delay: -1.4285714286s;
}
.loading__square:nth-of-type(2) {
  -webkit-animation-delay: -2.8571428571s;
  animation-delay: -2.8571428571s;
}
.loading__square:nth-of-type(3) {
  -webkit-animation-delay: -4.2857142857s;
  animation-delay: -4.2857142857s;
}
.loading__square:nth-of-type(4) {
  -webkit-animation-delay: -5.7142857143s;
  animation-delay: -5.7142857143s;
}
.loading__square:nth-of-type(5) {
  -webkit-animation-delay: -7.1428571429s;
  animation-delay: -7.1428571429s;
}
.loading__square:nth-of-type(6) {
  -webkit-animation-delay: -8.5714285714s;
  animation-delay: -8.5714285714s;
}
.loading__square:nth-of-type(7) {
  -webkit-animation-delay: -10s;
  animation-delay: -10s;
}
</style>


