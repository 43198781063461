<template>
    <v-container fluid class="no_padding_margin py-0 br-crum align-center pt-1">
            <v-list-item class="no_padding_margin padding_style_none px-0">
              
                <v-breadcrumbs :items="PDU.breadcrumbs || []" divider=">>" class="layout_flex_1 padding_bread px-0">
                      
                      <template v-slot:item="{ item }">
                          <v-breadcrumbs-item :disabled="item.disable">
                            <span class="Nhp-mt-khu">{{item.text}}</span>
                          </v-breadcrumbs-item>
                      </template>
                      
                </v-breadcrumbs>
                
            
              <v-menu  bottom left offset-y min-width="250" style="width:250px" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <v-btn :class="onCheckRole() == false ? '':(PDU[modul].obj.firmwareAvailable ? 'flashit': '')" icon v-on="on"><v-icon color="#fc9003">more_vert</v-icon></v-btn>
                  <p class="mb-0 volume_up_color hidden-sm-and-down">Quản lý thiết bị</p>
                </template>
                <v-layout column>
                  <v-card>
                    <v-list-item class="fontstyle" @click="onCallUpdateView(PDU[modul].obj)">
                        
                        <v-avatar class="mr-1 avartahover"></v-avatar>
                        <p class="mb-0 fontstyle">Đổi tên thiết bị</p>
                    </v-list-item>
                    <v-divider :class="onCheckRole() == false? 'd-none':''"/>
                    <v-list dense class="py-0">
                      <v-list-group>
                        <template v-slot:activator>
                          
                            <v-avatar class="mr-1 ml-4 avartahover_1"></v-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <p class="mb-0 fontstyle">Xóa thiết bị</p>
                              </v-list-item-title>
                            </v-list-item-content>
                          
                        </template>
                        
                          <v-list dense class="py-0"  v-for="(item,k) in device_delete" :key="k">
                            
                            <!--dieu khien am ly-->
                            <v-list-item class="fontstyle" v-if="CheckHardwareAvailble({ data: item }) || item.hardware === 7" @click="fnCallDeleteView({ model, item: item })">
                              
                                <v-icon class="ml-3 mr-4" @click="fnCallDeleteView({ model, item: item })" style="opacity: 0">delete_outline</v-icon>
                                <p class="mb-0 fontstyle">{{item.title}}</p>
                                <v-spacer/>
                            </v-list-item>

                            <!--dieu khien nguon-->
                            <v-list-item :class="onCheckRole() == false? 'd-none':'fontstyle'" v-if="item.hardware == 4" >
                              
                                
                                <v-list-group no-action style="width:100%">
                                  <template v-slot:activator>
                                      <v-icon class="ml-3 mr-4" style="opacity:0">delete_outline</v-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <p class="mb-0 fontstyle">{{item.title}}</p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                  </template>

                                  <v-list dense class="py-0 " v-for="(item1, j) in item.list_child" :key="j" >
                                      <v-list-item class="fontstyle px-0 pr-0" @click="fnCallDeleteView({ model, item: item1 })">
                                          <v-icon class="ml-8 mr-4" @click="fnCallDeleteView({ model, item: item1 })" style="opacity: 0">delete_outline</v-icon>
                                          <v-list-item-content><v-list-item-title><p class="mb-0 fontstyle">{{item1.title}}</p></v-list-item-title></v-list-item-content>
                                      </v-list-item>
                                  </v-list>

                                </v-list-group>
                              
                            </v-list-item>
                            
                            <!--dieu khien sensor-->
                            <v-list-item v-else-if="item.hardware == 6" :class="onCheckRole() == false? 'd-none':''">
                              <v-list-group no-action style="width:100%">
                                <template v-slot:activator>
                                  
                                    <v-icon class="ml-3 mr-4" style="opacity:0">delete_outline</v-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <p class="mb-0 fontstyle">{{item.title}}</p>
                                      </v-list-item-title>
                                    </v-list-item-content>
                                    
                                  
                                </template>

                                <v-list dense class="py-0" v-for="(item1, j) in item.list_child" :key="j" >
                                      <v-list-item class="fontstyle px-0" @click="fnCallDeleteView({ model, item: item1 })">
                                          <v-icon class="ml-8 mr-4" @click="fnCallDeleteView({ model, item: item1 })" style="opacity: 0">delete_outline</v-icon>
                                          <v-list-item-content><p class="mb-0 fontstyle">{{item1.title}}</p> </v-list-item-content>
                                      </v-list-item>
                                </v-list>
                              </v-list-group>
                            </v-list-item>
                            
                        </v-list>
                      </v-list-group>
                    </v-list>
                    <v-divider :class="onCheckRole() == false? 'd-none':''"/>
                    <!--/////////////////////////////////////////////////////-->

                    
                    <v-list-item :class="PDU[modul].obj.user_role != 'owner'?'d-none':'fontstyle'" @click="onOpenListSharing()">

                      <v-avatar class="mr-1 avartahover_2"></v-avatar>
                      <p class="mb-0 fontstyle">Chia sẻ quyền truy cập</p>
                    </v-list-item>
                    
                    <v-divider :class="PDU[modul].obj.user_role != 'owner'?'d-none':''"/>

                    <v-list-item :class="onCheckRole() == false || AudioMasterHardwareAvailble == true ? 'fontstyle':'d-none'" @click="onConfirmRefresh();">

                      <v-avatar class="mr-1 avartahover_3"></v-avatar>
                      <p class="mb-0 fontstyle">Làm mới thiết bị con</p>
                    </v-list-item>

                    <v-divider :class="onCheckRole() == false || AudioMasterHardwareAvailble == true ? '':'d-none'"/>

                    <v-list-item :class="onCheckRole() == false || AudioMasterHardwareAvailble == true || PDU[modul].obj.hardware_1 == 7? 'fontstyle':'d-none'" @click="onOpenRebootSettingDetail()">

                      <v-icon class="ml-3 mr-4 icon_hover_style">mdi-backup-restore</v-icon>
                      <p class="mb-0 fontstyle">Cài đặt reset</p>
                    </v-list-item>
                    
                    <v-divider :class="onCheckRole() == false || AudioMasterHardwareAvailble == true ||  PDU[modul].obj.hardware_1 == 7? '':'d-none'"/>

                    <v-list dense :class="AudioMasterHardwareAvailble == true ? 'fontstyle py-0':'d-none'">
                      <v-list-group>
                        <template v-slot:activator>
                          <v-avatar class="mr-1 ml-4 avartahover_4"></v-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              <p class="mb-0 fontstyle">Quản lý âm</p>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <v-list-item class="fontstyle" @click="onOpenListMusicInUSB()">
                            <v-icon class="ml-3 mr-4" style="opacity: 0">delete_outline</v-icon>
                            <p class="mb-0 fontstyle">Danh sách âm</p>
                            <v-spacer/>
                        </v-list-item>
                        <v-list-item :class="onCheckRole() == false? 'd-none' : 'fontstyle'" @click="onOpenConfirmFormatUSB()">
                            <v-icon class="ml-3 mr-4" style="opacity: 0">delete_outline</v-icon>
                            <p class="mb-0 fontstyle">Định dạng bộ nhớ trong</p>
                            <v-spacer/>
                        </v-list-item>
                        <v-list-item :class="onCheckRole() == false? 'd-none' : 'fontstyle'" @click="onOpenDialogAddMusic()">
                            <v-icon class="ml-3 mr-4" style="opacity: 0">delete_outline</v-icon>
                            <p class="mb-0 fontstyle">Thêm âm mới</p>
                            <v-spacer/>
                        </v-list-item>
                        <v-list-item :class="!PDU[modul].obj.hardwareVersion || Object.values(PDU[modul].obj.hardwareVersion)[0].Da !== 10 || onCheckRole() == false? 'd-none' : 'fontstyle'" @click="fnCallCreateView('MusicUSBIn')">
                            <v-icon class="ml-3 mr-4" style="opacity: 0">delete_outline</v-icon>
                            <p class="mb-0 fontstyle">Sao chép âm</p>
                            <v-spacer/>
                        </v-list-item>

                      </v-list-group>
                    </v-list>

                    <v-divider :class="onCheckRole() == false || AudioMasterHardwareAvailble == true ? '':'d-none'"/>

                    <v-list-item :class="onCheckRole() == false || AudioMasterHardwareAvailble == true ? 'fontstyle':'d-none'" @click="onOpenNotiContentDetail(true)">
                      <v-avatar class="mr-1 avartahover_6"></v-avatar>
                      <p class="mb-0 fontstyle">Cài đặt thông báo</p>
                    </v-list-item>

                    <v-divider :class="onCheckRole() == false || AudioMasterHardwareAvailble == true ? '':'d-none'"/>

                    <v-list-item :class="PDU[modul].obj.user_role != 'owner'?'d-none':'fontstyle'" @click="onOpenNotiShareDetail()">
                      <v-avatar class="mr-1 avartahover_7"></v-avatar>
                      <p class="mb-0 fontstyle">Chia sẻ thông báo</p>
                    </v-list-item>

                    <v-divider :class="PDU[modul].obj.user_role != 'owner'?'d-none' : ''"/>

                    <v-list-item @click="BREA.dialog_device_info= true" class="fontstyle">
                      <v-avatar class="mr-1 avartahover_5"></v-avatar>
                      <p class="mb-0 fontstyle">Thông tin thiết bị</p>
                    </v-list-item>
                  </v-card>
                </v-layout>
              </v-menu>
            </v-list-item>
          <!--dialog danh sách lịch sử chia sẻ thông báo-->
          <v-dialog max-width="500" v-model="NOTI.ShareDialog" persistent>
            <v-toolbar dense class="toolbar_padding_left white--text" :color="PDU.teal_color">Danh sách chia sẻ thông báo
              <v-menu dark offset-y style="width:150px" top open-on-hover>
                <template v-slot:activator="{ on }">
                  <!-- <span v-on="on" style="font-size: 22px; color: #fff; margin-left: 4px">(i)</span> -->
                  <v-icon light v-on="on" class="ml-2">info</v-icon>
                </template>
                <div style="min-height: 20px;color:#fff;width:250px; background-color:#4a4a4a;padding:10px">
                  <v-list-item-content style="padding: 0; min-height: 10px">+ Chia sẻ cho tối đa 3 người</v-list-item-content>
                  <v-list-item-content style="padding: 0; min-height: 10px">+ Tên gợi nhớ phải không dấu</v-list-item-content>
                  <v-list-item-content style="padding: 0; min-height: 10px">+ Nhấn nút Sao chép Liên Kết, sau đó chia sẻ tới người nhận</v-list-item-content>
                  <v-list-item-content style="padding: 0; min-height: 10px">+ Chia sẻ thành công khi người nhận xác nhận đăng ký thông báo từ liên kết được chia sẻ</v-list-item-content>
                </div>
              </v-menu>
              <v-spacer/>
            </v-toolbar>
            <v-card  >
              <v-list v-if="PDU['NotiShare'].list.length">
                <v-list-item v-for="(item,i) in PDU['NotiShare'].list" :key="i" :class="$vuetify.breakpoint.name == 'xs'? 'px-1': 'pr-1'" >
                  <v-btn icon small :class="$vuetify.breakpoint.name == 'xs'? '': 'mr-2'"><v-icon color="red" @click="fnCallDeleteView({ model:'NotiShare', item: item })">delete</v-icon></v-btn>
                  <v-flex xs7 md8>{{item.telegram_share_name}} {{item.isNew && `(đang chờ)`}}</v-flex>
                  <v-flex xs5 md4>
                    <v-btn style="text-transform: none;width: 100%" :color="item.isCopy ? 'secondary' : ''" small @click="CopyToNotiShareToClipBoard(item)">
                      {{item.isCopy ? 'Đã sao chép' : 'Sao chép liên kết'}}</v-btn>
                  </v-flex>
                </v-list-item>
              </v-list>
              <v-list-item-content v-else>
                  <p class="px-4" style="text-align:center">Thiết bị chưa được chia sẻ thông báo</p>
              </v-list-item-content>
              <v-card-actions>
                <v-text-field clearable :color="PDU.teal_color" dense outlined :class="'ip_select_sty'" style="height:36px; margin-right:8px" label="Tên gợi nhớ" v-model="sharedName"></v-text-field>
                <v-btn style='height:40px' @click="onOpenDialogAddNotiShare()" class="white--text" :color="PDU.teal_color">Thêm mới</v-btn>
                <v-btn style='height:40px' color="secondary" @click="NOTI.ShareDialog = false">Hủy</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- Chia Se Thong bao-->
          <!-- <v-form v-model="PDU['NotiShare'].isValid" @submit.prevent v-if="PDU['NotiShare'].isUpdate">
            <v-dialog persistent v-model="PDU['NotiShare'].obj.isForm" scrollable max-width="500">
              <v-form @submit.prevent="onAddNotiShare();fnCallResetView('NotiShare')">
                <v-card>
                  <v-toolbar class="toolbar_padding_left white--text" :color="PDU.teal_color" flat  dense>Chia sẻ Thông Báo</v-toolbar>
                  <v-card-text class="pt-4">
                    <v-list-item>
                        <v-text-field class="Rectangle-5 Nhp-mt-khu mt-0 px-3" :color="PDU.teal_color" label="Tên người được nhận thông báo" v-model="sharedName" required :rules="[PDU.rules.required]"></v-text-field>
                    </v-list-item>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :color="PDU.teal_color" class="white--text" :disabled="!PDU['NotiShare'].isValid || !sharedName" type="submit">Đồng Ý</v-btn>
                    <v-btn color="secondary" @click="fnCallResetView('NotiShare')">Hủy</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-form> -->
          <!-- Xoa chia se thong bao -->
          <v-dialog persistent v-model="PDU['NotiShare'].isDelete" scrollable max-width="290">
            <v-card>
              <v-toolbar dense flat>
                  <v-spacer/>
                  <v-btn icon @click="fnCallResetView('NotiShare')"><v-icon>close</v-icon></v-btn>
              </v-toolbar>
              <v-card-text class="pt-0">
                <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
                <v-list-item>
                  <v-layout row justify-center align-center align-content-center>
                    <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Bạn có chắc muốn xóa người nhận này?</p>
                  </v-layout>
                </v-list-item>
              </v-card-text>
              <v-card-actions>
                <v-list-item style="justify-content:center">
                  <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :color="PDU.teal_color" dark  @click="onDeleteNotiShare();fnCallResetView('NotiShare')">Đồng ý</v-btn>
                </v-list-item>
              </v-card-actions>
            </v-card> 
          </v-dialog>
          <!-- Cai dat reboot dinh ki -->
          <v-dialog persistent v-model="REB.dialogReboot" scrollable max-width="350">
              <v-card>
                <v-toolbar :color="PDU.teal_color" flat  dense class="toolbar_padding_left white--text">Cài đặt Reset thiết bị</v-toolbar>
                <v-tabs icons-and-text :color="PDU.teal_color" center-active active-class="ac-tab" fixed-tabs v-model="isAutoReboot">
                  <v-tabs-slider color="#fc9003"></v-tabs-slider>
                  <v-tab href="#tab-0">Tự động<v-icon>mdi-restart</v-icon></v-tab>
                  <v-tab href="#tab-1">Điều khiển tay<v-icon>mdi-hand-pointing-up</v-icon></v-tab>
                  <v-tab-item value='tab-0'>
                    <v-card-text class="pa-0" style="min-height:250px">
                      <v-list-item style="min-height: 0px; justify-content: center; padding: 0 8px 0px 20px; background-color: #f6f6f6">
                        <v-spacer/>
                        <span>Cài đặt Reset</span><v-switch :color="PDU.teal_color" inset style="margin-left: 15px" v-model="REB.switchManualReboot"></v-switch>
                      </v-list-item>
                      <div style="width:1px; height:20px"></div>
                      <div v-if="REB.switchManualReboot === true">
                        <span class="ml-2 mb-4" style="text-decoration:underline">Khởi động hằng ngày vào:</span>
                        <v-list-item style="justify-content:center">
                          <v-dialog ref="dialog" width="290px" persistent v-model="REB.dialogTimer" :return-value.sync="REB.timer">
                            <template v-slot:activator="{ on }">
                              <v-text-field class="Rectangle-5 Nhp-mt-khu mt-4 px-3 mr-1" v-on="on" v-model="REB.timer" label="Thời Gian Reset" readonly required :rules="[PDU.rules.required]" :color="PDU.teal_color"></v-text-field>
                            </template>
                            <v-time-picker v-if="REB.dialogTimer" :color="PDU.teal_color" format="24hr" v-model="REB.timer">
                              <v-spacer></v-spacer>
                              <v-btn :color="PDU.teal_color" dark @click="PreventOOh()">Đồng Ý</v-btn>
                              <v-btn color="secondary" @click="REB.dialogTimer = false">Hủy</v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-list-item>
                      </div>
                      <p style="text-align:center;font-weight: bold;font-size: 16px;" v-if="REB.switchManualReboot === false">Cài đặt Reset tắt</p>
                      <div style="width:1px; height:10px"></div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn :disabled="REB.timer === 'Not set'" :class="onCheckRole() == false? 'd-none':''" :color="PDU.teal_color" @click="onSaveCrontab()" class="white--text">Đồng Ý</v-btn>
                      <v-btn color="secondary" @click="REB.dialogReboot = false">Hủy</v-btn>
                    </v-card-actions>
                  </v-tab-item>
                  <v-tab-item value='tab-1'>
                    <v-card-text class="pa-0" style="min-height:250px">
                      <div style="width:1px;height:40px"></div>
                      <v-layout column>
                        <v-layout row align-center justify-center class="my-2">
                          <v-btn :loading="manualRebootLoading" fab :class="PDU[modul].obj.active ? 'Ellipse-23_1 white--text' : 'Ellipse-23 white--text'" @click="onOpendialogConfirmRebootManual()">
                            <v-avatar>
                              <img style="width: 25px"  :src="image_power">
                            </v-avatar>
                          </v-btn>
                        </v-layout>
                        <v-list-item style="min-height: 15px; justify-content:center" v-for="(text, i) in manualRebootMessage" :key="i">
                          <span class="mb-1 mx-4">{{text.text}}</span>
                        </v-list-item>
                      </v-layout>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" @click="REB.dialogReboot = false">Thoát</v-btn>
                    </v-card-actions>
                  </v-tab-item>
                </v-tabs>
              </v-card>
          </v-dialog>
          <!--Xác nhận Reboot bằng tay-->
          <v-dialog persistent v-model="dialogConfirmRebootManual" scrollable max-width="290">
            <v-card>
              <v-toolbar dense flat>
                  <v-spacer/>
                  <v-btn icon @click="dialogConfirmRebootManual = false"><v-icon>close</v-icon></v-btn>
              </v-toolbar>
              <v-card-text class="pt-0">
                <v-list-item-content class="pt-0"><img style="width: 70px; height: 70px" :src="image_err_yellow"/></v-list-item-content>
                <v-list-item>
                  <v-layout row justify-center align-center align-content-center>
                    <p class="mb-0" style="text-align: center; font-size: 18px; line-height: 2;">Bạn có chắc muốn Reset thiết bị?</p>
                  </v-layout>
                </v-list-item>
              </v-card-text>
              <v-card-actions>
                <v-list-item style="justify-content:center">
                  <v-btn :class="onCheckRole() == false? 'd-none':'white--text'" :color="PDU.teal_color" dark  @click="dialogConfirmRebootManual = false;onSetHttpManualReboot();">Đồng ý</v-btn>
                </v-list-item>
              </v-card-actions>
            </v-card> 
          </v-dialog>
        </v-container>
</template>
<script>
// import VueTimepicker from 'vue2-timepicker'
// import 'vue2-timepicker/dist/VueTimepicker.css'
import { setInterval, clearTimeout, clearInterval } from 'timers';
const pause = ms => new Promise(resolve => setTimeout(resolve, ms));
export default {
  data() {
    return {
      modul: "DeviceDetail",
      model: "devices",
      sharedName: null,
      image_err_yellow: require("@/assets/err_yellow.svg"),
      isAutoReboot: 'tab-0',
      image_power: require("@/assets/power.svg"),
      manualRebootLoading: false,
      manualRebootMessage: [],
      dialogConfirmRebootManual: false,
      MaxTimeReboot: 150,
    };
  },
  components: {
    // VueTimepicker
  },
  methods: {
    onCheckDeviceOnlineAvailble() {
      if (
        !this.PDU[this.modul].obj.active ||
        this.PDU[this.modul].obj.USBFormat == "waiting" ||
        this.PDU[this.modul].obj.firmwareUpdateStatus == "waiting" ||
        ((Object.values(this.PDU[this.modul].obj.hardwareVersion)[0].Da == 3 || 
          Object.values(this.PDU[this.modul].obj.hardwareVersion)[0].Da == 10 || 
          Object.values(this.PDU[this.modul].obj.hardwareVersion)[0].Da == 11) &&
          (this.PDU[this.modul].obj.musicProgress >= 0 && 
          this.PDU[this.modul].obj.musicProgress != 100)) ||
        this.PDU[this.modul].obj.childRefresh == "waiting"
      ) {
        this.fnToastNotificationOnly({
          message: this.MES.deviceAvailble,
          type: "error",
          reload: false,
        });

        return false;
      } else {
        return true;
      }
    },
    onCheckManualReboot () {
      var arrREB = []
      var arrManualReboot = localStorage.getItem('REB')
      arrREB = arrManualReboot ? JSON.parse(arrManualReboot) : []
      if (arrREB && arrREB.length > 0) {
        var index = arrREB.findIndex(e => { return e.device_id === this.PDU[this.modul].obj.device_id })
        if (index >= 0) {
          this.$set(this.PDU[this.modul].obj, 'isManualReboot', true)
          this.fnToastNotificationOnly({
            message: "Thiết bị đang khởi động lại",
            type: "error",
            reload: false
          });
          return true
        }
      }
      return false
    },
    onConfirmRefresh () {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          this.BREA.dialog_children_refresh = true
        }
      }
    },
    onOpenListMusicInUSB () {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          this.BREA.dialogMusic=true
        }
      }
    },
    onOpenConfirmFormatUSB () {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          this.fnCallCreateView('UsbFormat')
        }
      }
    },
    onOpenDialogAddMusic () {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          this.fnCallCreateView('Music')
        }
      }
    },
    onCheckRole() {
      if (
        this.PDU[this.modul].obj.user_role &&
        this.PDU[this.modul].obj.user_role == "guest"
      ) {
        return false;
      } else return true;
    },
    onCallUpdateView(data) {
      try {
        if (
          (data.active == true || data.active == false) &&
          data.hardware == 4 &&
          !data.hardwareVersion
        ) {
          this.$set(
            this.PDU["PDU_description"].obj,
            "attribute_descriptions",
            data.attribute_descriptions
          );
          this.$set(
            this.PDU["PDU_description"].obj,
            "list_attribute_descriptions",
            data.list_attribute_descriptions
          );
          this.$set(
            this.PDU["PDU_description"].obj,
            "device_id",
            data.device_id
          );
          this.$set(
            this.PDU["PDU_description"].obj,
            "attribute_descriptions_copy",
            data.attribute_descriptions
          );

          this.PDU["PDU_description"].obj.isNameDevice = true;
        } else if (
          (data.active == true || data.active == false) &&
          data.hardwareVersion &&
          data.hardwareVersion["64"]
        ) {
          var attribute = {
            user_device_descriptions:
              data.user_device_descriptions || data.device_id,
          };
          this.PDU[this.model].obj = this.fnCopy(attribute);
          this.PDU[this.model].obj.isNameDevice = true;
        } else {
          this.PDU["DeviceAttribute"].obj = this.fnCopy(data);
          this.PDU["DeviceAttribute"].obj.isNameDevice = true;
        }
      } catch (err) {
        console.log(err);
        this.fnToast({ message: "Có lỗi xảy ra. Vui lòng thử lại sau!" });
        return;
      }
    },
    async onOpenListSharing() {
      await this.fnRead("ShareDevice");
      this.BREA.dialogSharing = true;
    },
    onCheckDeviceID({device_id: device_id}){
      const child_device = this.PDU['DeviceDetail'].obj.childDevice
      return child_device.findIndex(e=>{return e == device_id}) 
    },
    onOpenNotiContentDetail (isManualCheck) {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          this.onNotiContentDetail(isManualCheck)
        }
      }
    },
    onNotiContentDetail (isManualCheck) {
      this.$set(this.NOTI, 'data', [])
      if (this.PDU[this.modul].obj.active === true && this.onCheckRole() === true) {
        var interval = setInterval(async () => {
          if (this.PDU[this.modul].obj && this.PDU[this.modul].obj.AutoScene && this.PDU[this.modul].obj.AutoScene.length > 0) {
            clearInterval(interval)
            await this.onGetScene_Noti_Content()
            if ((isManualCheck === true || (this.NOTI.data && this.NOTI.data.length === 0) || (this.NOTI.data && this.NOTI.data.length % 16 !== 0)) && this.PDU[this.modul].obj.active === true && this.onCheckRole() === true) {
              this.BREA.tabNoti = 'tab-0'
              this.BREA.dialog_NesthouseUpdateNotification = true
            }
          }
        }, 100)
      }
    },
    onGetScene_Noti_Content() {
      
      var scenes = this.fnCopy(this.PDU[this.modul].obj.AutoScene);
      var arr = [];
      var arr1 = []

      var istempt = scenes.filter(scene => {
        var data = Object.values(scene)[0].Da;

        var id = Object.keys(scene)[0];

        if (!this.isEmpty(data)) {
          var wday = data.wday;
          var sta_dmin = data.sta_dmin;
          var sto_dmin = data.sto_dmin;
          var status = data.enb;

          var input = Object.values(scene)[0].Da.input
            ? Object.values(scene)[0].Da.input[0]
            : null;

          var output = Object.values(scene)[0].Da.output
            ? Object.values(scene)[0].Da.output[0]
            : null;

          if (output && (output.device_id == "zalo" || output.device_id == 'telegram')) {
            var status_key =
              input &&
              Object.keys(input)[0] != "oper" &&
              Object.keys(input)[0] != "device_id" &&
              Object.keys(input)[0] >= 1920 ?
              Object.keys(input)[0] : (
                Object.keys(input)[1] != "oper" &&
                Object.keys(input)[1] != "device_id" &&
                Object.keys(input)[1] >= 1920 &&
                Object.keys(input)[1]
              );
            if (input && input[status_key] && input[status_key].Da === false) {
              var uart_device_id = input && input.device_id

              var rsx_id = this.onCheckDeviceID({device_id: uart_device_id})
              if(rsx_id != -1){
                arr.push({
                  parent_device: output.notify_device.Da,
                  notify_content: output.notify_content.Da,
                  child_device: input.device_id,
                  status_key: status_key,
                  scene_id: id,
                  status: status
                });
                return scene
              }
            } else if (input && input[status_key] && input[status_key].Da === true) {
              var uart_device_id1 = input && input.device_id
              var rsx_id1 = this.onCheckDeviceID({device_id: uart_device_id1})
              if(rsx_id1 != -1){
                arr1.push({
                  parent_device: output.notify_device.Da,
                  notify_content: output.notify_content.Da,
                  child_device: input.device_id,
                  status_key: status_key,
                  scene_id: id,
                  status: status
                });
                return scene
              }
            }
          }
        }
      });
      var arr2 = arr && arr.length > 0 ? arr.concat(arr1) : (arr1 && arr1.length > 0 ? arr1.concat(arr) : [])
      this.$set(this.NOTI, 'data', arr2)
      this.initialize_noti_content(arr, arr1);
    },
    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },
    initialize_noti_content(data, data1) {
      for (var i in this.list_audio_status) {
        this.$set(Object.values(this.list_audio_status[i])[0], "status", false);
        this.$set(Object.values(this.list_audio_status[i])[0], "notify_content", `Kênh ${Object.values(this.list_audio_status[i])[0].attribute_descriptions || 'Kênh ' + (i + 1) } tắt`);
        this.$set(Object.values(this.list_audio_status[i])[0], "active", true);

        this.$set(Object.values(this.list_audio_status[i])[0], "status1", true);
        this.$set(Object.values(this.list_audio_status[i])[0], "notify_content1", `Kênh ${Object.values(this.list_audio_status[i])[0].attribute_descriptions || 'Kênh ' + (i + 1) } bật`);
        this.$set(Object.values(this.list_audio_status[i])[0], "active1", true);
      }

      if (data && data.length > 0) {
        for (var i in data) {
          // console.log(data)
          var status = this.list_audio_status.find(e => {
            return (
              e &&
              Object.keys(e)[0] == data[i].status_key &&
              Object.values(e)[0] &&
              Object.values(e)[0].device_id == data[i].child_device
            );
          });
          if (status) {
            this.$set(Object.values(status)[0], "notify_content", data[i].notify_content !== 'null' ? data[i].notify_content : '');
            this.$set(Object.values(status)[0], "scene_id", data[i].scene_id);
            this.$set(Object.values(status)[0], "status", data[i].status);
            this.$set(Object.values(status)[0], "active", data[i].notify_content !== 'null' && data[i].notify_content !== '' ? true : false);
          }
        }
        for (var j in data1) {
          var status1 = this.list_audio_status.find(e => {
            return (
              e &&
              Object.keys(e)[0] == data1[j].status_key &&
              Object.values(e)[0] &&
              Object.values(e)[0].device_id == data1[j].child_device
            );
          });
          if (status1) {
            this.$set(Object.values(status1)[0], "notify_content1", data1[j].notify_content !== 'null' ? data1[j].notify_content : '');
            this.$set(Object.values(status1)[0], "scene_id1", data1[j].scene_id);
            this.$set(Object.values(status1)[0], "status1", data1[j].status);
            this.$set(Object.values(status1)[0], "active1", data1[j].notify_content !== 'null' && data1[j].notify_content !== '' ? true : false);
          }
        }
      }
    },
    async onOpenNotiShareDetail () {
      await this.fnRead('NotiShare')
      this.$set(this.NOTI, 'ShareDialog', true)
    },
    CopyToNotiShareToClipBoard (item) {
      try {
        if (item.telegram_share_name && item.telegram_share_name !== '') {
          var item1 = `101;${item.device_id};${item.telegram_share_name}`
          var str = this.encodeBase64(item1)
          var str1 = this.PDU.host_tele + '?start=' + str
          // window.navigator.clipboard.writeText(str1)
          navigator.clipboard.writeText(str1).then(() => {
          })

          var index = this.PDU['NotiShare'].list.findIndex(e => { return e.telegram_share_name === item.telegram_share_name && (parseInt(e.ts) === parseInt(item.ts) || e.telegram_chat_id === item.telegram_chat_id) })
          if (index >= 0) {
            this.$set(this.PDU['NotiShare'].list[index], 'isCopy', true)
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    encodeBase64 (str) {
      try {
        return window.btoa(str)
      } catch (err) {
        console.log(err);
        return null
      }
    },
    onOpenDialogAddNotiShare () {
      if (this.sharedName && this.sharedName !== '') {
        if (this.PDU['NotiShare'].list && this.PDU['NotiShare'].list.length >= 3) {
          this.fnToastNotificationOnly({
            message: "Tối đa 3 người được nhận thông báo trên mỗi thiết bị!",
            type: "error",
            reload: false
          });
          return
        }
        var item1 = `101;${this.PDU[this.modul].obj.device_id};${this.sharedName}`
        var str = this.encodeBase64(item1)
        if ((str && str.length > 64) || str === null || str === '') {
          this.fnToast({
            message: "Tên gợi nhớ quá dài hoặc có dấu",
            type: "error"
          })
          return
        }

        this.PDU['NotiShare'].list.push({
          ts: new Date().getTime(),
          device_id: this.PDU[this.modul].obj.device_id,
          user_name: this.PDU.userSession.username,
          cmd: 101,
          isCopy: false,
          telegram_share_name: this.sharedName,
          isNew: true
        })
        this.sharedName = null
      } else {
        this.fnToast({
          message: "Tên gợi nhớ trống",
          type: "error"
        })
      }
    },
    async onDeleteNotiShare () {
      if (this.PDU['NotiShare'].obj && this.PDU['NotiShare'].obj.telegram_chat_id) {
        this.PDU["NotiShare"].obj = {
          device_id: this.PDU["NotiShare"].obj.device_id,
          user_name: this.PDU.userSession.username,
          telegram_chat_id: this.PDU["NotiShare"].obj.telegram_chat_id,
        };
        await this.fnDelete("NotiShare")
        var idx = this.PDU['NotiShare'].list.findIndex(e => { return e.telegram_chat_id === this.PDU['NotiShare'].obj.telegram_chat_id })
        this.PDU['NotiShare'].list.splice(idx, 1)
      } else {
        var index = this.PDU['NotiShare'].list.findIndex(e => { return e.ts === this.PDU['NotiShare'].obj.ts })
        this.PDU['NotiShare'].list.splice(index, 1)
      }
    },
    onOpenRebootSettingDetail () {
      // var availble = this.onCheckDeviceOnlineAvailble();
      // if (availble) {
        if (!this.PDU[this.modul].obj.active) {
          this.fnToastNotificationOnly({
            message: this.MES.deviceAvailble,
            type: "error",
            reload: false,
          });
          return
        }
        if (this.onCheckManualReboot() === false) {
          if (this.PDU[this.modul].obj.RebootScheduler === null || this.PDU[this.modul].obj.RebootScheduler === undefined) {
            this.fnToastNotificationOnly({
              message: "Tính năng chưa được hỗ trợ",
              type: "warning",
              reload: false
            });
            return
          }
          // this.REB.dayLoop[0].select = false
          this.REB.timer = '00:00'
          this.isAutoReboot = true
          this.manualRebootLoading = false
          this.manualRebootMessage = []

          var RebootScheduler = this.fnCopy(this.PDU[this.modul].obj.RebootScheduler)
          var RebootSchedulerData = Object.values(RebootScheduler)[0].Da

          if (RebootSchedulerData !== null && RebootSchedulerData !== -1 && RebootSchedulerData >= 0) {
            // var arrTimer = RebootSchedulerData.split(' ')
            // var timer1 = arrTimer[1] + ':' + arrTimer[0]
            var timer1 = RebootSchedulerData
            // var timer2 = this.onConvertNumber(timer1)
            var timer3 = this.onConvertTimer1(timer1)
            // get timer
            this.REB.timer = timer3
            // this.REB.dayLoop[0].select = arrTimer[4] === '*' ? true : false
            this.REB.switchManualReboot = true
            this.REB.dialogReboot = true
          } else {
            this.REB.switchManualReboot = false
            this.REB.dialogReboot = true
          }
        }
      // }
    },
    onConvertNumber(data) {
      var arr1 = data.split(":");
      var time = parseInt(arr1[0]) * 60 + parseInt(arr1[1]);
      return time;
    },
    onConvertTimer(data) {
      var d = new Date();
      var data1 = data + d.getTimezoneOffset();
      data1 < 0 ? data1 = data1 + 1440 : data1 = data1
      return data1
      // var hour = Math.floor((data1 < 0 ? data1 + 1440 : (data1 > 1440 ? data1 - 1440 : data1)) / 60);
      // var minute = Math.floor(data1 % 60);
      // return data
      //   ? `${hour < 10 ? `0${hour}` : `${hour}`}:${
      //       minute < 10 ? `0${minute}` : `${minute}`
      //     }`
      //   : "00:00";
    },
    onConvertTimer1(data) {
      var d = new Date();
      var data1 = data - d.getTimezoneOffset();
      var hour = Math.floor((data1 > 1440 ? data1 - 1440 : (data1 < 0 ? data1 + 1440 : data1)) / 60);
      var minute = Math.floor(data1 % 60);
      return data >= 0 && data1 !== 1440
        ? `${hour < 10 ? `0${hour}` : `${hour}`}:${
            minute < 10 ? `0${minute}` : `${minute}`
          }`
        : "00:00";
    },
    async onSaveCrontab () {
      var availble = this.onCheckDeviceOnlineAvailble();
      if (availble) {
        if (this.onCheckManualReboot() === false) {
          var RebootScheduler = {}
          // TH not set
          if (this.REB.switchManualReboot === false) {
            RebootScheduler = this.fnCopy(this.PDU[this.modul].obj.RebootScheduler)
            this.$set(Object.values(RebootScheduler)[0], 'Da', -1)
          } else {
            // TH seting
            // var rebootData = ['0', '0', '*', '*', '*'] // default reboot vào lúc 00h hàng ngày
            // set timer
            var timer = this.REB.timer.split(':')

            if (this.REB.timer === '' || this.REB.timer === null || this.REB.timer === undefined || timer[0] === null || timer[1] === null || timer[0] === undefined || timer[1] === undefined) {
              this.fnToast({ message: "Vui lòng chọn giờ reset!", type: 'error' });
              return
            }

            var newTimer = this.onConvertNumber(this.REB.timer)
            var newTimer1 = this.onConvertTimer(newTimer)
            // var timer1 = newTimer1.split(':')
            
            // rebootData[1] = timer1[0]
            // rebootData[0] = timer1[1]
            
            RebootScheduler = this.fnCopy(this.PDU[this.modul].obj.RebootScheduler)
            this.$set(Object.values(RebootScheduler)[0], 'Da', parseInt(newTimer1))
          }
          
          var attribute_post = Object.assign({}, RebootScheduler, {
            device_id: this.PDU[this.modul].obj.device_id,
          });
          
          var attribute = {
            user_name: this.PDU.userSession.username,
            attribute_list: [attribute_post],
          };
          console.log('attribute', attribute)
          await this.fnIotPost(attribute).then(data => {})
          this.REB.dialogReboot = false
        }
      }
    },
    onOpendialogConfirmRebootManual() {
      if (!this.PDU[this.modul].obj.active) {
        this.fnToastNotificationOnly({
          message: this.MES.deviceAvailble,
          type: "error",
          reload: false,
        });
        return
      }
      if (this.onCheckManualReboot() === false) {
        this.dialogConfirmRebootManual = true
      }
    },
    async onSetHttpManualReboot () {
      if (!this.PDU[this.modul].obj.active) {
        this.fnToastNotificationOnly({
          message: this.MES.deviceAvailble,
          type: "error",
          reload: false,
        });
        return
      }
        if (this.onCheckManualReboot() === false) {
          this.manualRebootLoading = true
          var ManualRebootScheduler = {}
          ManualRebootScheduler['command'] = { Da: 'reboot', Dt: 'STRING' }
          var attribute_post = Object.assign({}, ManualRebootScheduler, { device_id: this.PDU[this.modul].obj.device_id,});

          var attribute = {
            user_name: this.PDU.userSession.username,
            attribute_list: [attribute_post]
          };
          await this.fnIotPost(attribute).then(async data => {
            if (data.success === true) {
              var id = this.fnCopy(this.PDU[this.modul].obj.device_id)
              this.$set(this.PDU[this.modul].obj, 'isManualReboot', true) // set flag thiết bị đang reboot
              var arrREB1 = localStorage.getItem('REB') || []
              var arrREB = arrREB1 && arrREB1.length > 0 ? JSON.parse(arrREB1) : []
              var index = arrREB.findIndex(e => { return e.device_id === this.PDU[this.modul].obj.device_id })
              if (index === -1) {
                arrREB.push({ device_id: this.PDU[this.modul].obj.device_id })
                localStorage.setItem('REB', JSON.stringify(arrREB))
              }

              this.manualRebootMessage.push({ text: 'Thiết bị đang khởi động lại. Vui lòng đợi ...', id: 3 })
              this.PDU[this.modul].obj.countUpReboot = 0
              var Offline = false

              var interval1 = setInterval(() => {
                this.PDU[this.modul].obj.countUpReboot = this.PDU[this.modul].obj.countUpReboot + 1
                var arrREB2 = localStorage.getItem('REB') || []
                var arrREB3 = arrREB2 && arrREB2.length > 0 ? JSON.parse(arrREB2) : []
                var index1 = arrREB3.findIndex(e => { return e.device_id === this.PDU[this.modul].obj.device_id })
                if (index1 >= 0) {
                  this.$set(arrREB3[index1], 'countUpReboot', this.PDU[this.modul].obj.countUpReboot)
                  localStorage.setItem('REB', JSON.stringify(arrREB3))
                }
                if (this.PDU[this.modul].obj.device_id !== id) {
                  this.$set(this.PDU[this.modul].obj, 'countUpReboot', 0)
                  clearInterval(interval1)
                }
                if (this.PDU[this.modul].obj.active === false) {
                  Offline = true
                }
              }, 1000)

              var interval = setInterval(() => {
                if ((this.PDU[this.modul].obj.active === true && Offline === true) || (this.PDU[this.modul].obj.countUpReboot >= this.MaxTimeReboot) || this.PDU[this.modul].obj.device_id !== id) {
                  if (this.PDU[this.modul].obj.countUpReboot >= this.MaxTimeReboot) {
                    this.fnToast({ message: this.REB.timeUpMessage, type: 'error' });
                  }
                  this.REB.dialogReboot = false
                  Offline = false
                  this.manualRebootLoading = false
                  this.$set(this.PDU[this.modul].obj, 'countUpReboot', 0)
                  this.$set(this.PDU[this.modul].obj, 'isManualReboot', false)
                  this.fnRemoveDeviceIDOfLocalREB({ device_id: this.PDU[this.modul].obj.device_id })
                  clearInterval(interval1)
                  clearInterval(interval)
                }
              }, 1000)
            }
          })
        }
    },
    onBackCountUpReboot () {
      var id = this.PDU[this.modul].obj.device_id && this.fnCopy(this.PDU[this.modul].obj.device_id)
      var arrREB1 = localStorage.getItem('REB') || []
      var arrREB = arrREB1 && arrREB1.length > 0 ? JSON.parse(arrREB1) : []
      var index = arrREB.findIndex(e => { return e.device_id === this.PDU[this.modul].obj.device_id })
      if (index >= 0) {
        var Offline = false
        var time = arrREB[index].countUpReboot
        var interval1 = setInterval(() => {
          if (this.PDU[this.modul].obj.active === false) { Offline = true }
          this.PDU[this.modul].obj.countUpReboot = time + 1
          this.$set(arrREB[index], 'countUpReboot', this.PDU[this.modul].obj.countUpReboot)
          localStorage.setItem('REB', JSON.stringify(arrREB))
          if (this.PDU[this.modul].obj.device_id !== id || (this.PDU[this.modul].obj.countUpReboot >= this.MaxTimeReboot) || (this.PDU[this.modul].obj.active === true && Offline === true)) {
            if (this.PDU[this.modul].obj.countUpReboot >= this.MaxTimeReboot) {
              this.fnToast({ message: this.REB.timeUpMessage, type: 'error' });
            }
            this.$set(this.PDU[this.modul].obj, 'isManualReboot', false)
            this.$set(this.PDU[this.modul].obj, 'countUpReboot', 0)
            this.fnRemoveDeviceIDOfLocalREB({ device_id: this.PDU[this.modul].obj.device_id })
            clearInterval(interval1)
          }
          time = this.PDU[this.modul].obj.countUpReboot
        }, 1000)
      }
    },
    PreventOOh() {
      var arr = this.REB.timer.split(":");
      var time = parseInt(arr[0]) * 60 + parseInt(arr[1]);
      // if (time == -1 * new Date().getTimezoneOffset() || time == 0) {
      //   this.fnToastNotificationOnly({
      //     message: "Vui lòng chọn giờ khác!",
      //     type: "error",
      //     reload: false,
      //   });
      //   return;
      // }
      this.$refs.dialog.save(this.REB.timer);
    },
    CheckHardwareAvailble ({ data }) {
      if (data.hardware && 
      (data.hardware == 3 || 
       data.hardware == 10 || 
       data.hardware == 20 || 
       data.hardware == 11)) {
        return true
      } else { return false }
    }
  },
  computed: {
    AudioMasterHardwareAvailble () {
      if (this.PDU[this.modul].obj.hardware_1 && 
      (this.PDU[this.modul].obj.hardware_1 == 3 || 
      this.PDU[this.modul].obj.hardware_1 == 10 || 
      this.PDU[this.modul].obj.hardware_1 == 20 || 
      this.PDU[this.modul].obj.hardware_1 == 11)) {
        return true
      } else { return false }
    },
    device_delete() {
      try {
        var arr = [];

        this.PDU["devices"].list.forEach(e => {
          if (e.device_id == this.PDU[this.modul].obj.device_id) {
            if (this.CheckHardwareAvailble({ data: e })) {
              arr.push(
                {
                  title: "Điều khiển âm ly",
                  hardware: e.hardware,
                  device_id: this.PDU[this.modul].obj.device_id,
                  parent_device_id: e.device_id,
                  list_child: [],
                },
                {
                  title: "Điều khiển nguồn",
                  hardware: 4,
                  parent_device_id: e.device_id,
                  list_child: [],
                },
                {
                  title: "Nhiệt độ, độ ẩm",
                  hardware: 6,
                  parent_device_id: e.device_id,
                  list_child: [],
                }
              );
            } else if (e.hardware == 7) {
              arr.push({
                ischild: false,
                value: false,
                device_id: e.device_id,
                user_role: e.user_role,
                online: e.active,
                action: "device_hub",
                hardware: 7,
                title: e.user_device_descriptions || e.device_id,
                parent_device_id: e.device_id,
              });
            }
            return arr;
          }
        });

        if (this.PDU[this.modul].obj.hardware != 7) {
          var ul = this.PDU[this.modul].obj.child_attribute;

          var device_outled = this.PDU[this.modul].obj.child_attribute
            ? this.PDU[this.modul].obj.child_attribute.filter(e => {
                return e.list_outled && e.list_outled.length > 0;
              })
            : [];

          if (device_outled && device_outled.length > 0) {
            device_outled.sort((a, b) => {
              return a.device_id < b.device_id ? -1 : 1;
            });

            for (var i = 0; i < device_outled.length; i++) {
              var item = device_outled[i];
              var temper = arr.find(e => {
                return e.hardware == item.hardware;
              });
              var j = i + 1;
              // var description = item.attribute_descriptions || 'Điều khiển nguồn ' + j;
              var description;
              if (device_outled.length == 1) {
                description = "Điều khiển nguồn";
              } else {
                if (i == 0) {
                  description = "Nguồn 1";
                }
                if (i == 1) {
                  description = "Nguồn 2";
                }
              }

              temper.list_child.push({
                device_id: item.device_id,
                hardware: item.hardware,
                parent_id: item.parent_id,
                title: description,
              });
            }
          }

          var device_temper = this.PDU[this.modul].obj.child_attribute
            ? this.PDU[this.modul].obj.child_attribute.filter(e => {
                return e.list_temper && e.list_temper.length > 0;
              })
            : [];

          device_temper.sort((a, b) => {
            return a.device_id < b.device_id ? 1 : -1;
          });

          for (var i in device_temper) {
            var item = device_temper[i];

            var temper1 = arr.find(e => {
              return e.hardware == item.hardware;
            });

            var description =
              item.list_temper &&
              item.list_temper[0] &&
              Object.values(item.list_temper[0])[0]
                ? Object.values(item.list_temper[0])[0]
                    .attribute_descriptions ||
                  Object.values(item.list_temper[0])[0].Na
                : "chưa xác định";

            temper1.list_child.push({
              device_id: item.device_id,
              hardware: item.hardware,
              parent_id: item.parent_id,
              title: description,
            });
          }
        }

        return arr;
      } catch (err) {
        console.log(err);
      }
    },
    list_audio_status() {
      var attribute = this.PDU[this.modul].obj.child_attribute
        ? this.PDU[this.modul].obj.child_attribute.find(e => {
            return (
              e.device_id &&
              e.list_audio_status &&
              e.list_audio_status.length > 0
            );
          })
        : [];
      if (attribute) {
        return attribute.list_audio_status || [];
      } else return [];
    }
  },
  async mounted () {
    this.$set(this.NOTI, 'data', [])
    await this.onNotiContentDetail()
    await this.onBackCountUpReboot()
  }
};
</script>
<style>
.vue__time-picker-dropdown, .vue__time-picker .dropdown {
  left: 30px !important;
  top: 40px !important;
}
:focus {
  outline: none !important;
}
.vue__time-picker input {
  border-radius: 40px;
  background-color: #00897b;
  justify-content: center;
  align-content: center;
  border: none !important;
  color: #ffffff;
  padding: 0 35% !important;
  height: 50px !important;
  width: 200px !important;
  text-align: center !important;
  font-size: 22px !important;
}
.fontstyle:hover .icon_hover_style {
  color: #009688 !important
}
.ip_select_sty fieldset{
  border: 1px solid #009688 !important;
}
/* .ip_select_sty:hover fieldset{
  border: 1px solid #009688 !important;
} */
.ip_select_sty .v-text-field__details, .ip_select_sty1 .v-text-field__details{
  display: none;
}
</style>
