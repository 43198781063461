import Vue from "vue";

import { mapState, mapActions } from "vuex";
import { fnCopy, fnSlug, fnRename, fnRename_temperature } from "./utils";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../public/registerServiceWorker";
import Amplify from "aws-amplify";
import VueMoment from "vue-moment";
import VueQrcodeReader from "vue-qrcode-reader";
import Snotify, { SnotifyPosition } from "vue-snotify";
import VueTelInput from "vue-tel-input";
import TelInputField from "./components/tel-input-field.vue";
import VueFormGenerator from "vue-form-generator";
import "vue-snotify/styles/material.css";
// import VueGoogleCharts from 'vue-google-charts'

Vue.component("field-tel-input", TelInputField);
Vue.config.productionTip = false;

const options = {
  toast: {
    position: SnotifyPosition.rightBottom,
  },
};

Vue.use(VueMoment);
Vue.use(VueQrcodeReader);
Vue.use(Snotify, options);
Vue.use(VueTelInput);
Vue.use(VueFormGenerator);
// Vue.use(Snotify)
// Vue.use(VueGoogleCharts);

Amplify.configure({
  Auth: {
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_4n0TnB5yt",
    userPoolWebClientId: "4dt0uq66empvcoieumjaftc389",
    identityPoolId: "ap-southeast-1:b54ffbde-9572-43b8-91c2-8d13411eca07",
  },
  Analytics: {
    disabled: true,
  },
  Storage: {
    bucket: "nh-musics",
    region: "ap-southeast-1",
  },
});
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "ap-southeast-1",
    aws_pubsub_endpoint:
      "wss://a36j9zqtwkxh63-ats.iot.ap-southeast-1.amazonaws.com/mqtt",
  })
);

Vue.mixin({ // declare state (global varailables), action (global methods)
  computed: mapState(["PDU", "SKL", "SEA", "APP", "GROUP", "BREA", "NOTI", "REB", "MES", "COLO"]),
  methods: {
    fnCopy,
    fnSlug,
    fnRename,
    fnRename_temperature,
    ...mapActions([
      "fnSetUserSession",
      "fnRegistry",
      "fnLogin",
      "fnAttachPrincipalPolicy",
      "fnRead",
      "fnCheckToken",
      "fnIotSubscribe",
      "fnResetFlagView",
      "fnCallCreateView",
      "fnLoadData",
      "fnDeviceDetail",
      "fnCallResetView",
      "fnisDialogOpen",
      "fnonFind",
      "fnsetManualScene",
      "fnstartManualScene",
      "fnIotPublish",
      "fnRemoveUserSession",
      "fnsetManualOutput",
      "fnsplit",
      "fncallCreateScene",
      "fnIotPost",
      "fncompare_object",
      "fnIotPut_Group",
      "fnGroupDetail",
      "fnCallDeleteView",
      "fnDelete",
      "fnIotPost_Group",
      "fnIotPut_ShareDevice",
      "fnCallCreateDialog",
      "fnCallDeleteDialog",
      "fnUpdate_AttributeDescriptions",
      "fnCallUpdateView",
      "fnCallCreateSceneView",
      "fnUpdate_SceneDescriptions",
      "fnUpload",
      "fnrequest",
      "fnForgotPassword",
      "onDeviceDetail",
      "fnSetMusicStorage",
      "fnCallDeleteView_group",
      "fnlistfullDeviceAttributes",
      "fnCreate",
      "fnSet_listSceneTimer_to_MusicChannel",
      "fnChangePassword",
      "fnUpdate_user_device_des",
      "fnZaloCenter",
      "fnDataZaloCenter",
      "fnToast",
      "fnToastNotificationOnly",
      "fnReSendSMS",
      "fnResetListSceneID",
      "fnCheckUserName",
      "fnDrawChart",
      "fnSetScenetoLocalStorage",
      "fnUnsetScenefromLocalStorage",
      "fnUpdate_NotiContent",
      "fnRemoveDeviceIDOfLocalREB"
    ]),
  },
});

router.beforeEach(async (to, from, next) => { // before enter any page
  localStorage.removeItem('REB')
  var user = await store.dispatch("fnGetUserSession");
  var auth =
    to.matched[0].props.default && to.matched[0].props.default.auth
      ? to.matched[0].props.default.auth
      : false;
  if (!user && auth) {
    next("/login");
  } else {
    next();
    store.state.SKL.loading = false;
    store.state.SKL.devices_loading = false; 
  }
});

var vm = new Vue({ // render App.js file
  router,
  store,
  vuetify,
  // beforeCreate() {
  //   Vue.$snotify = this.$snotify;
  // },
  render: (h) => h(App),
}).$mount("#app");

global.vm = vm;
