import Vue from "vue";
import Vuex, { Store } from "vuex";
import {
  fnCopy,
  fnSlug,
  fnRefixName,
  fnRename,
  fnRename_temperature,
} from "./utils";
// import Amplify from 'aws-amplify'
import Amplify, { Storage } from "aws-amplify";
import router from "./router";
import axios from "axios";
import * as jwt from "jsonwebtoken";
import moment, { fn } from "moment";
import Snotify, { SnotifyPosition } from "vue-snotify";

const options = {
  toast: {
    position: SnotifyPosition.rightBottom,
  },
};

Vue.use(Vuex);
Vue.use(Snotify, options);

const opt = {
  count: 0,
  list: [],
  group_list: [],
  list_ListMusic: [],
  list1: [],
  list_copy: [],
  list_scene: [],
  ObjIflist: [],
  ObjThenlist: [],
  listtempt: [],
  ObjNAlist: [],
  tempt: [],
  listifdevice: [],
  listthendevice: [],
  autoscene_output: [],
  autoscene_input: [],
  obj: {},
  params: {},
  isDialogOpen: false,
  isCreate: false,
  isUpdate: false,
  isDelete: false,
  isLoading: false,
  selected: [],
  attribute: {},
  token: [],
  user_device_descriptions: "",
  isFinish: false,
};
const mainhost =
  "https://b3c65wbl6d.execute-api.ap-southeast-1.amazonaws.com/prod";
const host = "https://78zna7yq6g.execute-api.ap-southeast-1.amazonaws.com/prod";
const host_bucket = "https://s3-ap-southeast-1.amazonaws.com/nh-musics/public";
const host_tele = "https://t.me/NestHouse_Noti_bot"
const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
var flag = false;

export default new Vuex.Store({ // declare states (likely global varaible)
  state: {
    PDU: {
      host: host,
      host_bucket: host_bucket,
      host_tele: host_tele,
      userSession: null,
      http: axios.create({
        baseURL: host,
        headers: {
          Accept: "application/json",
        },
      }),
      userPoolWebClientID: "4dt0uq66empvcoieumjaftc389",
      devices: {
        ...fnCopy(opt),
      },
      DeviceAttributes: {
        ...fnCopy(opt),
      },
      DeviceAttribute: {
        ...fnCopy(opt),
      },
      DevicesAttributes: {
        ...fnCopy(opt),
      },
      NewDevice: {
        ...fnCopy(opt),
      },
      ChildDevice: {
        ...fnCopy(opt),
      },
      DeviceDetail: {
        ...fnCopy(opt),
      },
      ShareDevice: {
        ...fnCopy(opt),
      },
      Registry: {
        ...fnCopy(opt),
      },
      Login: {
        ...fnCopy(opt),
      },
      ForgotPass: {
        ...fnCopy(opt),
      },
      Scene: {
        ...fnCopy(opt),
      },
      ManualScene: {
        ...fnCopy(opt),
      },
      AutoScene: {
        ...fnCopy(opt),
      },
      SceneDetail: {
        ...fnCopy(opt),
      },
      Protect: {
        ...fnCopy(opt),
      },
      PDUTimer: {
        ...fnCopy(opt),
      },
      Music: {
        ...fnCopy(opt),
      },
      AudioTimer: {
        ...fnCopy(opt),
      },
      AudioTimeOff: {
        ...fnCopy(opt),
      },
      MusicTimer: {
        ...fnCopy(opt),
      },
      UsbFormat: {
        ...fnCopy(opt),
      },
      Sensor: {
        ...fnCopy(opt),
      },
      PDUSensor: {
        ...fnCopy(opt),
      },
      firmware: {
        ...fnCopy(opt),
      },
      group: {
        ...fnCopy(opt),
      },
      ChangePass: {
        ...fnCopy(opt),
      },
      MQTTConnect: {
        ...fnCopy(opt),
      },
      PDU_automation: {
        ...fnCopy(opt),
      },
      PDU_description: {
        ...fnCopy(opt),
      },
      MusicUSBIn: {
        ...fnCopy(opt),
      },
      NotiShare: {
        ...fnCopy(opt),
      },
      snotification: {
        timeout: 3000,
        progressbar: true,
        closeOnClick: true,
        img: require("./assets/nest.png"),
      },
      notification: {
        message: "",
        dialog_notification_only: false,
        type: "",
        reload: false,
      },
      rules: {
        required: (v) => !!v || "Trường này bắt buộc",
        required_number: (v) =>
          (parseInt(v, 10) >= 0 && parseInt(v, 10) <= 100) || "0 - 100",
        IDrequired: (v) => {
          for (i in PDU["devices"].list) {
            v == PDU["devices"].list[i].device_id;
          }
        },
        phone: (v) =>
          (!!v && v.length && /^\+/.test(v)) ||
          "Số điện thoại không hợp lệ: (+84)123456789",
        email: (v) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "E-mail không hợp lệ";
        },
      },
      Select: "",
      token: [],
      teal_color: "teal darken-1",
      light_grey: "#f6f6f6",
      childDevice: [
        {
          name: "Audio",
        },
      ],
      sub: "",
      subcribe: [],
      breadcrumbs: [],
    },
    SKL: {
      loading: false,
      transition: "scale-transition",
      devices_loading: false,
    },
    SEA: {
      content: [],
      content_copy: [],
      select: {},
    },
    APP: {
      drawer: true,
      drawer_tab: '1',
    },
    GROUP: {
      dialog_group: false,
      dialog_add_group: false,
      dialog_edit_group_1: false,
      dialog_edit_group_2: false,
      dialog_edit_group_3: false,
      checkbox_creategroup: [],
      group: { checkbox_creategroup: [], name: "", id: null },
      group_choosen: [
        { text: "Thêm nhóm mới", icon: "add", key: 1 },
        { text: "Xóa nhóm thiết bị", icon: "delete", key: 2 },
        { text: "Sửa nhóm thiết bị", icon: "edit", key: 3 },
      ],
    },
    BREA: {
      dialogSharing: false,
      dialog_children_refresh: false,
      dialogMusic: false,
      dialog_device_info: false,
      dialog_NesthouseUpdateNotification: false,
      tabNoti: 'tab-0'
    },
    NOTI: {
      data: [],
      ShareData: [],
      ShareDialog: false
    },
    REB: {
      dialogReboot: false,
      rebootData: '*****',
      dayLoop: [
        { text: 'Hàng ngày', select: false, value: '*' }
        // { text: 'Mọi thứ 2', select: false, value: 'MON' },
        // { text: 'Mọi thứ 3', select: false, value: 'TUE' },
        // { text: 'Mọi thứ 4', select: false, value: 'WED' },
        // { text: 'Mọi thứ 5', select: false, value: 'THU' },
        // { text: 'Mọi thứ 6', select: false, value: 'FRI' },
        // { text: 'Mọi thứ 7', select: false, value: 'THU' },
        // { text: 'Mọi chủ nhật', select: false, value: 'SUN' }
      ],
      timer: '00:00',
      switchManualReboot: false,
      dialogTimer: false,
      countUp: 0,
      timeUpMessage: 'Thiết bị không phản hồi!'
    },
    MES: {
      deviceAvailble : 'Thiết bị đang bận hoặc mất kết nối, vui lòng kiểm tra lại',
      sensorWarningIncorrectFormat: 'Dữ liệu chưa đúng, vui lòng kiểm tra lại'
    },
    COLO: {
      LightOrange: "#FC9003",
      DarkGreen: "#009688"
    }
  },
  actions: {
    fnSetUserSession: async ({ dispatch, state }, user) => { // function set user session
      try {
        state.PDU.userSession = user;
        localStorage.setItem(
          "userSession",
          JSON.stringify(state.PDU.userSession)
        );
        state.PDU["devices"].params = {
          user_name: user.username,
        };

        dispatch("fnCallResetView", "devices");
        dispatch("fnCallResetView", "DeviceDetail");
        await dispatch("fnAttachPrincipalPolicy");
        state.SKL.loading = true;
        state.SKL.devices_loading = true;

        await dispatch("fnRead", "devices");

        var device_before_reload = localStorage.getItem("DeviceDetail");

        state.SKL.loading = true;
        state.SKL.devices_loading = true;
        if (state.PDU["devices"].list.length > 0) {
          await dispatch("fnRead", "DevicesAttributes");
          if (device_before_reload != null) {
            var device_before_reload_splited = device_before_reload.split("/");
            var device_after_finding = state.PDU["devices"].list.find((e) => {
              return e.device_id == device_before_reload_splited[0];
            });

            if (device_after_finding)
              await dispatch("onDeviceDetail", {
                device: device_after_finding,
                hardware: device_before_reload_splited[1],
                child_device_id: device_before_reload_splited[2] || null,
              });
          } else {
            await dispatch("onDeviceDetail", {
              device: state.PDU["devices"].list[0],
              hardware: 3,
            });
          }
          state.SKL.loading = false;
          state.SKL.devices_loading = false;
        } else {
          state.SKL.loading = false;
          state.SKL.devices_loading = false;
        }
        state.SKL.loading = false;
        await dispatch("fnRead", "group");
      } catch (error) {
        state.SKL.loading = false;
        state.SKL.devices_loading = false;
        dispatch("fnRemoveUserSession");
      }
    },
    onDeviceDetail: async ( // function get detail device information
      { dispatch, state },
      { device, hardware, child_device_id }
    ) => {
      try {
        const searching = (list, key) =>
          list.find((i) => {
            return Object.keys(i)[0] == key;
          });
        Vue.set(state.PDU["DeviceDetail"].obj, 'AutoScene', [])
        Vue.set(state.PDU["DeviceDetail"].obj, 'firmwareSlaveVersion', null)

        await dispatch("fnDeviceDetail", {
          device,
          hardware,
          child_device_id,
        });
        if (!state.PDU["DeviceDetail"].obj.active && !state.PDU["DeviceDetail"].obj.IPaddr && !state.PDU["DeviceDetail"].obj.device_id) {
          state.SKL.devices_loading = false
          state.SKL.loading = false
          return
        }
        await dispatch("fnLoadData", "firmware");

        if (
          state.PDU["DeviceDetail"].obj.childDevice &&
          state.PDU["DeviceDetail"].obj.childDevice.length > 0
        ) {
          var childDevice = state.PDU["DeviceDetail"].obj.childDevice

          var i = 11;
          Vue.set(state.PDU["DeviceDetail"].obj, "child_attribute", [])

          state.PDU.list_child.forEach((e) => {
            var device_id = searching(e, "device_id")
            var child = childDevice.find((child) => {
              return child == device_id.device_id;
            });
            if (child) {
              var device = {
                device_id: child,
                attribute: e,
              };
              dispatch("getChildDevice", {
                device,
                hardware,
              });
            }
          });
        } else {
          Vue.set(state.PDU["DeviceDetail"].obj, "child_attribute", [])
          state.SKL.loading = false;
        }

        state.SKL.loading = false;

        localStorage.setItem(
          "DeviceDetail",
          state.PDU["DeviceDetail"].obj.device_id +
            "/" +
            state.PDU["DeviceDetail"].obj.hardware +
            "/" +
            child_device_id
        );

        if (state.PDU["DeviceDetail"].obj.isSubcribe == false) {
          dispatch("fnSubOption");
        }
        Vue.set(state.PDU["DeviceDetail"].obj, "isSubcribe", true);
      } catch (err) {
        console.log(err);
        state.SKL.loading = false;
        return;
      }
    },
    fnSubOption: ({ state, dispatch }) => { // function custom subcribe
      const searching = (list, key) =>
        list.find((i) => {
          return Object.keys(i)[0] == key;
        });

      state.PDU["devices"].list.forEach((device) => {
        dispatch("fnIotSubscribe", {
          device_id: device.device_id,
          unSub: false,
        });
      });

      if (state.PDU["DeviceDetail"].obj.childDevice) {
        var childDevice = state.PDU["DeviceDetail"].obj.childDevice;
        state.PDU.list_child.forEach((e) => {
          var device_id = searching(e, "device_id");
          var child = childDevice.find((child) => {
            return child == device_id.device_id;
          });
          if (child) {
            var device = {
              device_id: child,
              attribute: e,
            };

            dispatch("fnIotSubscribe", {
              device_id: device.device_id,
              unSub: false,
            });
          }
        });
      }
    },
    fnRefreshMQTTConnection: async ({ state, dispatch }) => { // function refresh mqtt connection
      state.PDU["MQTTConnect"].isLoading = true;

      state.PDU["devices"].list.forEach((device) => {
        dispatch("fnIotSubscribe", {
          device_id: device.device_id,
          unSub: true,
        });
      });
      if (state.PDU["DeviceDetail"].obj.childDevice) {
        await pause(1000);
        state.PDU["DeviceDetail"].obj.child_attribute.forEach((device) => {
          dispatch("fnIotSubscribe", {
            device_id: device.device_id,
            unSub: true,
          });
        });
      }

      state.PDU["devices"].list.forEach((device) => {
        dispatch("fnIotSubscribe", {
          device_id: device.device_id,
          unSub: false,
        });
      });

      if (state.PDU["DeviceDetail"].obj.childDevice) {
        await pause(1000);
        state.PDU["DeviceDetail"].obj.child_attribute.forEach((device) => {
          dispatch("fnIotSubscribe", {
            device_id: device.device_id,
            unSub: false,
          });
        });
      }

      state.PDU["MQTTConnect"].count++;

      state.PDU["MQTTConnect"].isLoading = false;
      state.PDU.notification.dialog_notification_only = false;
    },
    getChildDevice: async ({ dispatch, state }, { device, hardware }) => { // function get detail child device information from attribute 192
      dispatch("detail", {
        device,
        hardware,
      });

      state.SKL.loading = false;
    },
    detail: async ({ dispatch, state }, { device, hardware }) => { // function get detail child device information from attribute 192
      const seach_default = (list, key) =>
        list.find((i) => Object.keys(i)[0] == key);
      const seaching = (list, token) =>
        list.filter(
          (i) =>
            i &&
            Object.values(i)[0] &&
            Object.values(i)[0].Na &&
            Object.values(i)[0].Na.includes(token)
        );

      var attributes = device.attribute;
      var pdu_name;

      var obj = attributes.find((attribute) => {
        return Object.keys(attribute)[0] == "attribute_descriptions";
      });
      if (obj) {
        var obj_data = Object.values(obj)[0].Da;
        if (obj_data) {
          state.PDU["DeviceDetail"].attribute_descriptions = fnCopy(obj_data);
          var des = fnCopy(state.PDU["DeviceDetail"].attribute_descriptions);
          for (let [obj_data_key, obj_data_value] of Object.entries(obj_data)) {
            attributes.forEach((attribute) => {
              if (Object.keys(attribute)[0] == obj_data_key) {
                Vue.set(
                  Object.values(attribute)[0],
                  "attribute_descriptions",
                  obj_data_value
                );
              }
            });

            if (obj_data_key == "PDU_name") {
              pdu_name = obj_data_value;
            }
          }
        }
      }

      // if (Object.values(state.PDU['DeviceDetail'].obj.hardwareVersion)[0].Da == 3) {
      var hardware_1 = (await seach_default(attributes, "64"))
        ? Object.values(seach_default(attributes, "64"))[0].Da
        : null;
      if (hardware_1 == 6) {
        device.isSensor = true;
        var index = state.PDU.childDevice.findIndex((e) => {
          return e.name == "Sensor";
        });
        if (index == -1) {
          state.PDU.childDevice.push({
            name: "Sensor",
            device_temper: device,
          });
        }
      }
      if (hardware_1 == 4) {
        device.isOutlet = true;
        var index = state.PDU.childDevice.findIndex((e) => {
          return e.name == "PDU";
        });
        if (index == -1) {
          state.PDU.childDevice.push({
            name: "PDU",
            device_outlet: device,
          });
        }
      }
      if (hardware_1 == 5) {
        var firmwareSlaveVersion = await seach_default(attributes, "128")
        var firmwareSlaveVersion = firmwareSlaveVersion ? Object.values(firmwareSlaveVersion)[0].Da : null;
        Vue.set(state.PDU['DeviceDetail'].obj, 'firmwareSlaveVersion', firmwareSlaveVersion)
      }

      var active = seach_default(attributes, "384")
        ? Object.values(seach_default(attributes, "384"))[0].Da
        : null;
      var x = seaching(attributes, "Outlet");
      var y = seaching(attributes, "Temper");
      var z = seaching(attributes, "Humi");
      var w = seaching(attributes, "Status");

      if (x.length) {
        var idx = state.PDU["DeviceDetail"].obj.child_attribute.findIndex(
          (e) => {
            return e.device_id && e.device_id == device.device_id;
          }
        );
        if (idx == -1) {
          state.PDU["DeviceDetail"].obj.child_attribute.push({
            device_id: device.device_id,
            active: active,
            list_outled: x || [],
            isSensor: device.isSensor,
            isOutlet: device.isOutlet,
            hardware: 4,
            parent_id: state.PDU["DeviceDetail"].obj.device_id,
            list_attribute_descriptions: des || {},
            attribute_descriptions: pdu_name,
          });
        }
      }
      if (y.length) {
        var idx = state.PDU["DeviceDetail"].obj.child_attribute.findIndex(
          (e) => {
            return e.device_id && e.device_id == device.device_id;
          }
        );
        if (idx == -1) {
          state.PDU["DeviceDetail"].obj.child_attribute.push({
            device_id: device.device_id,
            active: active,
            list_temper: y || [],
            list_humi: z || [],
            isSensor: device.isSensor,
            isOutlet: device.isOutlet,
            hardware: 6,
            parent_id: state.PDU["DeviceDetail"].obj.device_id,
            list_attribute_descriptions: des || {},
          });
        }
      }
      if (w.length) {
        var idx = state.PDU["DeviceDetail"].obj.child_attribute.findIndex(
          (e) => {
            return e.device_id && e.device_id == device.device_id;
          }
        );
        if (idx == -1) {
          state.PDU["DeviceDetail"].obj.child_attribute.push({
            device_id: device.device_id,
            list_audio_status: w || [],
            hardware: 5,
            parent_id: state.PDU["DeviceDetail"].obj.device_id,
            list_attribute_descriptions: des || {},
          });
        }
      }

      var device_1 = state.PDU["DeviceDetail"].obj.child_attribute.filter(
        (e) => {
          return e.list_outled && e.list_outled.length > 0;
        }
      );
      var device_2 = state.PDU["DeviceDetail"].obj.child_attribute.filter(
        (e) => {
          return e.list_temper && e.list_temper.length > 0;
        }
      );
      var device_3 = state.PDU["DeviceDetail"].obj.child_attribute.filter(
        (e) => {
          return e.list_humi && e.list_humi.length > 0;
        }
      );
      var device_4 = state.PDU["DeviceDetail"].obj.child_attribute.find((e) => {
        return e.list_audio_status && e.list_audio_status.length > 0;
      });

      if (device_2) {
        device_2.sort(function(a, b) {
          // var t1 = a.list_humi[0] && Object.values(a.list_humi[0])[0].Da
          // var t2 = b.list_humi[0] && Object.values(b.list_humi[0])[0].Da
          // return t1-t2
          var t1 = a.list_temper[0] && Object.values(a.list_temper[0])[0].Na;
          var t2 = b.list_temper[0] && Object.values(b.list_temper[0])[0].Na;

          var t3 = t1.match(/(\d+)/)[0];
          var t4 = t2.match(/(\d+)/)[0];
          return t3 - t4;
        });
      }

      if (device_1) {
        for (var i in device_1) {
          var de = device_1[i];

          for (var j in de.list_outled) {
            Vue.set(
              Object.values(de.list_outled[j])[0],
              "device_id",
              de.device_id
            );
            Vue.set(Object.values(de.list_outled[j])[0], "hardware", 4);
            Vue.set(Object.values(de.list_outled[j])[0], "timeout", false);
            if (Object.values(de.list_outled[j])[0].attribute_descriptions) {
            } else {
              var name = Object.values(de.list_outled[j])[0].Na;

              Vue.set(
                Object.values(de.list_outled[j])[0],
                "attribute_descriptions",
                fnRename(name)
              );
            }
          }
          // dispatch('onCheckAutoManual', de.list_outled)
          dispatch("fnSet_listSceneTimer_pdu", de.list_outled);
          dispatch("onAddList_scene_temper_to_pdu", {
            list_outlet: de.list_outled,
            device: de,
          });
          dispatch("onCheckEnableforSwitch", de.list_outled);
        }
      }

      if (device_2) {
        for (var i in device_2) {
          var des2 = device_2[i].list_temper;
          var des2_1 = device_2[i].list_humi;
          for (var j in des2) {
            var cHiTempAlarmSetting = seach_default(attributes, "2944")
            var cLoTempAlarmSetting = seach_default(attributes, "3008")
            var cHiHumiAlarmSetting = seach_default(attributes, "3072")
            var cLoHumiAlarmSetting = seach_default(attributes, "3136")

            Vue.set(Object.values(des2[j])[0], "device_id", device_2[i].device_id);
            Vue.set(Object.values(des2[j])[0], "hardware", 6);
            Vue.set(Object.values(des2[j])[0], "Humi", des2_1[j]);
            Vue.set(Object.values(des2[j])[0], "timeout", false);
            if (Object.values(des2[j])[0].attribute_descriptions) {
            } else {
              var name = Object.values(des2[j])[0].Na;
              Vue.set(
                Object.values(des2[j])[0],
                "attribute_descriptions",
                fnRename_temperature(name)
              );
            }
            if (cHiTempAlarmSetting && Object.keys(cHiTempAlarmSetting)[0]) {
              Vue.set(Object.values(des2[j])[0], "cHiTempAlarmSetting", Object.values(cHiTempAlarmSetting)[0].Da);
            } else {
              // Vue.set(Object.values(des2[j])[0], "cHiTempAlarmSetting", []);
            }
            if (cLoTempAlarmSetting && Object.keys(cLoTempAlarmSetting)[0]) {
              Vue.set(Object.values(des2[j])[0], "cLoTempAlarmSetting", Object.values(cLoTempAlarmSetting)[0].Da);
            } else {
              // Vue.set(Object.values(des2[j])[0], "cLoTempAlarmSetting", []);
            }
            if (cHiHumiAlarmSetting && Object.keys(cHiHumiAlarmSetting)[0]) {
              Vue.set(Object.values(des2[j])[0], "cHiHumiAlarmSetting", Object.values(cHiHumiAlarmSetting)[0].Da);
            } else {
              // Vue.set(Object.values(des2[j])[0], "cHiHumiAlarmSetting", []);
            }
            if (cLoHumiAlarmSetting && Object.keys(cLoHumiAlarmSetting)[0]) {
              Vue.set(Object.values(des2[j])[0], "cLoHumiAlarmSetting", Object.values(cLoHumiAlarmSetting)[0].Da);
            } else {
              // Vue.set(Object.values(des2[j])[0], "cLoHumiAlarmSetting", []);
            }
          }
          dispatch("onAddList_scene_temper_to_temper", {
            list_temper: des2,
            device: device_2[i],
            list_outlet:
              device_1 &&
              device_1.list_outled &&
              device_1.list_outled.length > 0
                ? device_1.list_outled
                : [],
          });
        }
      }
      if (device_3) {
        for (var i in device_3) {
          var des3 = device_3[i].list_humi;
          for (var j in des3) {
            Vue.set(
              Object.values(des3[j])[0],
              "device_id",
              device_3[i].device_id
            );
            Vue.set(Object.values(des3[j])[0], "hardware", 6);
          }
        }
      }
      if (device_4) {
        for (var i in device_4.list_audio_status) {
          Vue.set(
            Object.values(device_4.list_audio_status[i])[0],
            "device_id",
            device_4.device_id
          );
          if (
            Object.values(device_4.list_audio_status[i])[0]
              .attribute_descriptions
          ) {
            var name = Object.values(device_4.list_audio_status[i])[0]
              .attribute_descriptions;

            Vue.set(
              Object.values(device_4.list_audio_status[i])[0],
              "attribute_descriptions_copy",
              name
            );
          } else {
            var name =
              Object.values(device_4.list_audio_status[i])[0]
                .attribute_descriptions ||
              Object.values(device_4.list_audio_status[i])[0].Na;

            Vue.set(
              Object.values(device_4.list_audio_status[i])[0],
              "attribute_descriptions",
              fnRename(name)
            );
          }
        }
      }

      // }
    },
    fnSetScenetoLocalStorage: ({ dispatch, state }, { device, item }) => { // function set scene to local storage
      var parent_device_id = device.parent_id || device.parent_device_id;
      var device_id = device.device_id;
      var key = Object.keys(item)[0];
      var list_scene_timer = Object.values(item)[0].list_scene_timer_1;
      // var list_scene_temper = Object.values(item)[0].list_scene_temper
      // var listPDUSensor = Object.values(item)[0].listPDUSensor

      if (device_id) {
        var obj = [];

        var device =
          state.PDU["DeviceDetail"].obj.child_attribute &&
          state.PDU["DeviceDetail"].obj.child_attribute.find((e) => {
            return e.device_id == device_id;
          });

        if (device && device.list_outled) {
          var data_storage = localStorage.getItem(
            parent_device_id + "/" + device_id
          );
          if (data_storage) {
            var data_storage_parse = JSON.parse(data_storage);

            var outlet = data_storage_parse.find((e) => {
              return e && Object.keys(e)[0] == key;
            });

            Object.values(outlet)[0].list_scene_timer = list_scene_timer;
            // Object.values(outlet)[0].list_scene_temper = list_scene_temper
            // Object.values(outlet)[0].listPDUSensor = listPDUSensor

            localStorage.setItem(
              parent_device_id + "/" + device_id,
              JSON.stringify(data_storage_parse)
            );
          } else {
            for (var i in device.list_outled) {
              var outled = device.list_outled[i];

              var key = Object.keys(outled)[0];

              var list_scene_timer = Object.values(outled)[0]
                .list_scene_timer_1;
              // var list_scene_temper = Object.values(outled)[0].list_scene_temper
              // var listPDUSensor = Object.values(outled)[0].listPDUSensor

              var obj_1 = new Object();

              obj_1[key] = {
                list_scene_timer: list_scene_timer,
                // list_scene_temper: list_scene_temper,
                // listPDUSensor: listPDUSensor
              };

              obj.push(obj_1);
            }
            localStorage.setItem(
              parent_device_id + "/" + device_id,
              JSON.stringify(obj)
            );
          }
        }
      }
    },
    fnUnsetScenefromLocalStorage: ({ state }, { device }) => { // function remove scene out of local storage
      state.PDU["DeviceDetail"].obj &&
        state.PDU["DeviceDetail"].obj.child_attribute &&
        state.PDU["DeviceDetail"].obj.child_attribute.forEach((e) => {
          if (e.hardware == 4 && e.parent_id == device.device_id) {
            localStorage.removeItem(e.parent_id + "/" + e.device_id);
          }
        });
    },
    onCheckAutoManual: ({ dispatch, state }, list_outlet) => { // function get list scene timer for pdu device
      list_outlet.forEach((e) => {
        var arr = state.PDU["DeviceDetail"].obj.AutoScene.filter((scene) => {
          var key = Object.keys(scene)[0];
          var value = Object.values(scene)[0];
          if (value.Da) {
            var output = value.Da.output ? value.Da.output[0] : null;
            var input = value.Da.input ? value.Da.input[0] : null;
            if (output) {
              for (let [k, v] of Object.entries(output)) {
                return k == Object.keys(e)[0] && input && input.day_min;
              }
            }
          }
        });
        Vue.set(Object.values(e)[0], "list_scene_timer", arr);
      });
    },
    onCheckEnableforSwitch: ({ dispatch, state }, list_outlet) => { // function check and set switch element in pdu device
      list_outlet.forEach((e) => {
        var idx = false;
        var list_scene_timer = Object.values(e)[0].list_scene_timer;
        var list_scene_temper = Object.values(e)[0].list_scene_temper;

        Vue.set(Object.values(e)[0], "Auto", idx);
        if (list_scene_timer && list_scene_timer.length > 0) {
          for (var i in list_scene_timer) {
            if (Object.values(list_scene_timer[i])[0].Da) {
              Vue.set(Object.values(e)[0], "Auto", true);
              return;
            } else {
              Vue.set(Object.values(e)[0], "Auto", false);
            }
          }
        }
        if (list_scene_temper && list_scene_temper.length > 0) {
          for (var i in list_scene_temper) {
            if (Object.values(list_scene_temper[i])[0].Da) {
              Vue.set(Object.values(e)[0], "Auto", true);
              return;
            } else {
              Vue.set(Object.values(e)[0], "Auto", false);
            }
          }
        }
      });
    },
    onCheckInputofTemperScene: ({ dispatch, state }) => { // function get list huminity scene or temperator scene
      return state.PDU["DeviceDetail"].obj.AutoScene.filter((scene) => {
        var key = Object.keys(scene)[0];
        var value = Object.values(scene)[0];
        if (value.Da) {
          var output = value.Da.output ? value.Da.output[0] : null;
          var input = value.Da.input ? value.Da.input[0] : null;
          if (input) {
            for (let [k, v] of Object.entries(input)) {
              return (
                v && v.Na && (v.Na.includes("Temper") || v.Na.includes("Humi"))
              ); // tra ve danh sach kich ban temper cua thiet bi
            }
          }
        }
      });
    },
    onAddList_scene_temper: ({ dispatch, state }) => { // function assign list scene huminity, temperator for pdu device
      dispatch("onCheckInputofTemperScene").then(function(list) {
        for (var j in state.PDU["DeviceDetail"].obj.child_attribute) {
          var item = state.PDU["DeviceDetail"].obj.child_attribute[j];
          var tem_x = item.list_outled;
          var tem_y = item.list_temper;
          var tem_z = item.list_humi;
          if (tem_x) {
            tem_x.forEach((e) => {
              Vue.set(Object.values(e)[0], "list_scene_temper", []);
              if (list.length > 0) {
                var scene = list.forEach((scene) => {
                  var value = Object.values(scene)[0];
                  var output = value.Da.output ? value.Da.output[0] : {};
                  for (let [k, v] of Object.entries(output)) {
                    if (Object.keys(e)[0] == k) {
                      Object.values(e)[0].list_scene_temper.push(scene);
                    }
                  }
                });
              }
            });
          }
        }
      });
    },
    onAddList_scene_temper_to_temper: ( // function assign list scene huminity, temperator for sensor device
      { dispatch, state },
      { list_temper, device, list_outlet }
    ) => {
      var list_device_outlet = [];

      list_device_outlet = state.PDU["DeviceDetail"].obj.child_attribute.filter(
        (e) => {
          return e.list_outled && e.list_outled.length > 0;
        }
      );

      var list = state.PDU["DeviceDetail"].obj.AutoScene;

      list_temper.forEach((e) => {
        Vue.set(Object.values(e)[0], "list_scene_temper", []);
        Vue.set(Object.values(e)[0], "temper_outlet_status", {
          Da: null,
          Na: null,
        });
        Vue.set(Object.values(e)[0], "humi_outlet_status", {
          Da: null,
          Na: null,
        });
        var arr = [];
        arr = list.filter((scene) => {
          var value = Object.values(scene)[0];
          var input = value.Da.input ? value.Da.input[0] : {};
          var output = value.Da.output ? value.Da.output[0] : {};

          var key =
            Object.keys(input)[0] != "device_id" &&
            Object.keys(input)[0] != "oper" &&
            Object.keys(input)[0] != "day_min"
              ? Object.keys(input)[0]
              : null;
          var key_output_outled =
            Object.keys(output)[0] != "device_id"
              ? Object.keys(output)[0]
              : null;
          if (
            key &&
            input.device_id == device.device_id &&
            (Object.keys(e)[0] == key ||
              Object.keys(Object.values(e)[0].Humi)[0] == key)
          ) {
            Vue.set(Object.values(e)[0], "timeout", false);

            ////////////////////////////////////////////////////////////////////////
            if (key_output_outled != null) {
              for (var i in list_device_outlet) {
                var de = list_device_outlet[i];
                for (var j in de.list_outled) {
                  var list_outlet_obj = de.list_outled[j];

                  if (
                    Object.keys(list_outlet_obj)[0] == key_output_outled &&
                    de.device_id == output.device_id
                  ) {
                    var Da = Object.values(list_outlet_obj)[0].Da;
                    var Na =
                      Object.values(list_outlet_obj)[0]
                        .attribute_descriptions ||
                      Object.values(list_outlet_obj)[0].Na;

                    if (key == "512") {
                      Vue.set(Object.values(e)[0], "temper_outlet_status", {
                        Da: Da,
                        Na: Na,
                      });
                    } else if (key == "448") {
                      Vue.set(Object.values(e)[0], "humi_outlet_status", {
                        Da: Da,
                        Na: Na,
                      });
                    }
                  }
                }
              }
            }

            ////////////////////////////////////////////////////////////////////////
            return scene;
          }
        });

        Vue.set(Object.values(e)[0], "list_scene_temper", arr);
        Vue.set(Object.values(e)[0], "timeout", false);
      });
    },
    onAddList_scene_temper_to_pdu: ( // function assign list scene huminity, temperator for direct device
      { dispatch, state },
      { list_outlet, device }
    ) => {
      var list = state.PDU["DeviceDetail"].obj.AutoScene;
      try {
        list_outlet.forEach((e) => {
          Vue.set(Object.values(e)[0], "list_scene_temper", []);
          var arr = [];
          arr = list.filter((scene) => {
            var value = Object.values(scene)[0];

            if (value.Da && value.Da.output && value.Da.output.length >= 0) {
              var output = value.Da.output ? value.Da.output[0] : {};
              var input = value.Da.input ? value.Da.input[0] : {};

              var key =
                Object.keys(output)[0] != "device_id" && !input.day_min
                  ? Object.keys(output)[0]
                  : null;

              return (
                key &&
                output.device_id == device.device_id &&
                Object.keys(e)[0] == key
              );
            }
          });

          Vue.set(Object.values(e)[0], "list_scene_temper", arr);
        });
      } catch (err) {
        console.log(err);
      }
    },
    fnGetUserSession: async ({ dispatch, state }) => { // function get user session
      if (!state.PDU.userSession && localStorage.getItem("userSession")) {
        await dispatch(
          "fnSetUserSession",
          JSON.parse(localStorage.getItem("userSession"))
        );
      }
      return state.PDU.userSession;
    },
    // function check user name is correct
    fnCheckUserName: ({ state, dispatch }, model) =>
      new Promise(async (resolve) => {
        var str = fnSlug(state.PDU[model].obj.username);
        if (str == state.PDU[model].obj.username) {
          return resolve(true);
        } else {
          dispatch("fnToastNotificationOnly", {
            message: "Tên đăng nhập không chứa khoảng trắng và không dấu!",
            type: "error",
            reload: false,
          });
        }
        return resolve(false);
      }),
    fnHandleError: ({ state, dispatch }, { model, error }) => { // function handle error and toast message
      state.PDU[model].isLoading = false;
      if (error.message && error.message.includes("Network")) {
        dispatch("fnToastNotificationOnly", {
          message: "Mạng gặp sự cố",
          type: "error",
          reload: false,
        });
        dispatch("fnRemoveUserSession");
      } else if (
        error.message &&
        error.message.includes("User does not exist")
      ) {
        dispatch("fnToastNotificationOnly", {
          message: "Tài khoản không tồn tại",
          type: "error",
          reload: false,
        });
        dispatch("fnRemoveUserSession");
      } else if (
        error.message &&
        error.message.includes("Incorrect username or password")
      ) {
        dispatch("fnToastNotificationOnly", {
          message: "Mật khẩu không đúng",
          type: "error",
          reload: false,
        });
        dispatch("fnRemoveUserSession");
      } else if (error.message && error.message.includes("limit exceeded")) {
        dispatch("fnToastNotificationOnly", {
          message: "Truy cập quá giới hạn. Vui lòng thử lại sau!",
          type: "error",
          reload: false,
        });
      } else if (error.message && error.message.includes("Device exist")) {
        dispatch("fnToastNotificationOnly", {
          message: "Thiết bị đã tồn tại",
          type: "error",
          reload: false,
        });
      } else if (error.message && error.message.includes("User exist")) {
        dispatch("fnToastNotificationOnly", {
          message: "Thiết bị đã có trong tài khoản",
          type: "error",
          reload: false,
        });
      } else {
        console.log(model);
        dispatch("fnToastNotificationOnly", {
          message: error.message,
          type: "error",
          reload: false,
        });
      }
    },
    fnToast: async ({ dispatch, state }, { message, type }) => { // function toast message
      if (typeof message === "string") {
        try {
          if (typeof type === "string") {
            vm.$snotify[type](message, {
              timeout: state.PDU.snotification.timeout,
              showProgressBar: state.PDU.snotification.progressbar,
              closeOnClick: state.PDU.snotification.closeOnClick,
            });
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        if (
          message.error &&
          !message.error.errorCode &&
          message.error.includes("Disconnected")
        ) {
          // dispatch('fnToastNotificationOnly',{message: 'Kết nối bị gián đoạn. Đang kết nối lại!', type: 'warning', reload: 'refresh'})
          state.PDU["MQTTConnect"].isLoading = true;
          await pause(1000);
          dispatch("fnRefreshMQTTConnection");
        } else if (
          message.error &&
          message.error &&
          message.error.errorMessage.includes("undefined")
        ) {
          dispatch("fnToastNotificationOnly", {
            message: "Kết nối mạng không ổn định. Vui lòng tải lại trang!",
            type: "error",
            reload: true,
          });
          await pause(2000);

          // localStorage.setItem('DeviceDetail', state.PDU['DeviceDetail'].obj.device_id +"/"+ state.PDU['DeviceDetail'].obj.hardware)
          // window.location.reload();
        }
      }
    },
    fnToastNotificationOnly: ( // function toast notification popup
      { dispatch, state },
      { message, type, reload }
    ) => {
      state.PDU.notification.message = message;
      state.PDU.notification.reload = reload;
      switch (type) {
        case "error": {
          state.PDU.notification.type = require("./assets/err.svg");
          break;
        }
        case "warning": {
          state.PDU.notification.type = require("./assets/err_yellow.svg");
          break;
        }
      }

      state.PDU.notification.dialog_notification_only = true;
    },
    fnRegistry: async ({ dispatch, state }, model) => { // function user register
      state.PDU[model].isLoading = true;
      try {
        let { username, email, password, phone_number, code } = state.PDU[
          model
        ].obj;

        if (!state.PDU[model].obj.sendCode) {
          if (email) {
            await Amplify.Auth.signUp({
              username,
              password,
              attributes: {
                email,
                phone_number: phone_number,
              },
            })
              .then((data) => {
                state.PDU[model].obj.message =
                  "Mã xác thực đang được gửi tới số điện thoại của bạn! ";
                state.PDU[model].obj.sendCode = true;
              })
              .catch((error) => {
                console.log("register error: ", error);
                state.PDU[model].obj.message = null;
                state.PDU[model].obj.sendCode = false;
                if (error.code === "UsernameExistsException") {
                  error = {
                    ...error,
                    message: "Tên tài khoản đã tồn tại. Vui lòng thử lại!",
                  };
                }
                if (error.code === "InvalidParameterException") {
                  error = {
                    ...error,
                    message: "Địa chỉ email không hợp lệ",
                  };
                }
                dispatch("fnHandleError", {
                  model,
                  error,
                });
              });
          } else {
            await Amplify.Auth.signUp({
              username,
              password,
              attributes: {
                phone_number: phone_number,
              },
            })
              .then((data) => {
                state.PDU[model].obj.message =
                  "Mã xác thực đang được gửi tới số điện thoại của bạn";
                state.PDU[model].obj.sendCode = true;
              })
              .catch((error) => {
                console.log("register error in no email: ", error);
                state.PDU[model].obj.message = null;
                state.PDU[model].obj.sendCode = false;
                if (error.code === "UsernameExistsException") {
                  error = {
                    ...error,
                    message: "Tên tài khoản đã tồn tại. Vui lòng thử lại!",
                  };
                }
                if (error.code === "InvalidParameterException") {
                  error = {
                    ...error,
                    message: "Địa chỉ email hoặc số điện thoại không hợp lệ",
                  };
                }
                dispatch("fnHandleError", {
                  model,
                  error,
                });
              });
          }

          state.PDU[model].isLoading = false;
        } else {
          await Amplify.Auth.confirmSignUp(username, code);
          dispatch("fnCallResetView", model);
          router.push("/login");
        }
      } catch (error) {
        if (error.code === "UsernameExistsException") {
          error = {
            ...error,
            message: "Tài khoản đã tồn tại",
          };
        }
        dispatch("fnHandleError", {
          model,
          error,
        });
      }
    },
    fnLogin: async ({ dispatch, state }, model) => { // function user login
      state.PDU[model].isLoading = true;
      try {
        let data = await Amplify.Auth.signIn(
          state.PDU[model].obj.username,
          state.PDU[model].obj.password
        );
        let user = await Amplify.Auth.currentUserInfo();
        user.token = data.signInUserSession.idToken.jwtToken;
        user.username = user.attributes.sub;
        user.name = data.username;

        dispatch("fnSetUserSession", user);
        dispatch("fnCallResetView", model);

        router.push("/");
      } catch (error) {
        if (error.code === "UserNotConfirmedException") {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
          error = {
            ...error,
            message: "Tài khoản chưa được xác thực",
          };
        } else if (error.code === "PasswordResetRequiredException") {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
          error = {
            ...error,
            message:
              "Tài khoản đã được reset, vui lòng chuyển đến Quên Mật Khẩu",
          };
        } else if (error.code === "NotAuthorizedException") {
          // The error happens when the incorrect password is provided
          error = {
            ...error,
            message: "Mật khẩu không đúng",
          };
        } else if (error.code === "UserNotFoundException") {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          error = {
            ...error,
            message: "Tài khoản chưa được đăng ký, vui lòng chuyển đến Đăng Ký",
          };
        } else {
          console.log("error message:=", error);
          dispatch("fnToastNotificationOnly", {
            message: "Có lỗi xảy ra. Vui lòng thử lại sau!",
            type: "error",
            reload: false,
          });
          return;
        }

        dispatch("fnHandleError", {
          model,
          error,
        });
      }
    },
    // function resend sms in register and forgot password pages
    fnReSendSMS: async ({ dispatch, state }, attribute_list) =>
      new Promise(async (resolve) => {
        // await dispatch('fnCheckToken')

        axios({
          method: "POST",
          url: mainhost + "/open/verify/",
          data: attribute_list,
          headers: {
            "content-type": "application/json",
          },
        })
          .then((success) => {
            flag = true;

            resolve(success["data"]);
          })
          .catch((err) => {
            console.log("err");
            dispatch("fnHandleError", {
              model: "DeviceAttribute",
              error: err,
            });
          });
      }),
    fnForgotPassword: async ({ dispatch, state }, model) => { // function forgot password
      state.PDU[model].isLoading = true;
      try {
        if (!state.PDU[model].obj.sendCode) {
          await Amplify.Auth.forgotPassword(state.PDU[model].obj.username);
          state.PDU[model].obj = {
            ...state.PDU[model].obj,
            message: "Mã xác thực được gửi tới số điện thoại của bạn",
            sendCode: true,
          };
          state.PDU[model].isLoading = false;
        } else {
          await Amplify.Auth.forgotPasswordSubmit(
            state.PDU[model].obj.username,
            state.PDU[model].obj.code,
            state.PDU[model].obj.password
          );
          dispatch("fnCallResetView", model);
          router.push("/login");
        }
      } catch (error) {
        console.log(error);
        if (error.code === "UserNotFoundException") {
          error = {
            ...error,
            message: "Tài khoản không tồn tại",
          };
        } else if (error.code === "InvalidParameterException") {
          error = {
            ...error,
            message: "Tài khoản không khả dụng",
          };
        } else if (error.code === "CodeMismatchException") {
          error = {
            ...error,
            message: "Mã xác nhận không chính xác",
          };
        }
        dispatch("fnHandleError", {
          model,
          error,
        });
      }
    },
    fnRemoveUserSession: ({ dispatch, state }) => { // function remove user session
      Amplify.Auth.signOut().then();
      state.PDU.userSession = null;
      localStorage.clear();

      //unSubcribe topics
      var arr = [];
      state.PDU["devices"].list.forEach((device) => {
        arr.push(device.device_id);
      });
      if (
        state.PDU["DeviceDetail"].obj &&
        state.PDU["DeviceDetail"].obj.childDevice
      ) {
        state.PDU["DeviceDetail"].obj.childDevice.forEach((device_id) => {
          arr.push(device_id);
        });
      }

      arr.forEach((a) => {
        dispatch("fnIotSubscribe", {
          device_id: a,
          unSub: true,
        });
      });

      router.push("/login");
    },
    fnAttachPrincipalPolicy: ({ state }) => { // function get policy
      Amplify.Auth.currentCredentials().then((credentials) => {
        state.PDU.http = axios.create({
          baseURL: mainhost,
          headers: {
            Accept: "application/json",
          },
        });

        var params = {
          identity_id: credentials.identityId,
        };

        state.PDU.http.get("/identity", {
          headers: {
            Authorization: state.PDU.userSession.token,
          },
          params: params,
        });
      });
    },
    // function check and refresh token
    fnCheckToken: ({ dispatch, state }) =>
      new Promise(async (resolve) => {
        try {
          let session = await Amplify.Auth.currentSession();
          let token = session.idToken.jwtToken;
          let timeout = jwt.decode(token).exp - moment().unix();
          if (timeout >= 600) {
            state.PDU.userSession.token = token;
            localStorage.setItem(
              "userSession",
              JSON.stringify(state.PDU.userSession)
            );
            return resolve();
          }
        } catch (error) {
          dispatch("fnHandleError", {
            model: "DeviceAttribute",
            error,
          });
        }

        let user;
        try {
          user = await Amplify.Auth.currentAuthenticatedUser();
        } catch (err) {
          return dispatch("fnRemoveUserSession");
        }
        user.refreshSession(
          user.signInUserSession.refreshToken,
          (err, session) => {
            if (err) return dispatch("fnRemoveUserSession");
            state.PDU.userSession.token = session.idToken.jwtToken;
            localStorage.setItem(
              "userSession",
              JSON.stringify(state.PDU.userSession)
            );
            return resolve();
          }
        );
      }),
    // function get api
    fnRead: ({ dispatch, state }, model) =>
      new Promise(async (resolve) => {
        switch (model) {
          case "devices":
            {
              flag = true;
              var hosting = mainhost;
              state.PDU.http = axios.create({
                baseURL: hosting,
                headers: {
                  Accept: "application/json",
                },
              });
              state.PDU[model].params = {
                user_name: state.PDU.userSession.username,
              };
              await dispatch("fnCheckToken");
              //lấy danh sách thiết bị
              await state.PDU.http
                .get("/user/devices/", {
                  headers: {
                    Authorization: state.PDU.userSession.token,
                  },
                  params: state.PDU[model].params,
                })
                .then((success1) => {
                  state.PDU[model].list = success1["data"].device_list;

                  resolve(success1["data"]);
                })
                .catch((error) =>{
                  Vue.set(state.PDU[model], 'list', [])
                  dispatch("fnHandleError", { model, error})
                });
            }
            break;
          case "DeviceAttributes":
            {
              flag = true;
              state.PDU.http = axios.create({
                baseURL: mainhost,
                headers: {
                  Accept: "application/json",
                },
              });
              state.PDU[model].params = {
                user_name: state.PDU.userSession.username,
                device_id: state.PDU["DeviceDetail"].obj.device_id,
              };
              await dispatch("fnCheckToken");
              //lấy danh sách thiết bị
              state.PDU.http
                .get("/device/attributes/", {
                  headers: {
                    Authorization: state.PDU.userSession.token,
                  },
                  params: state.PDU[model].params,
                })
                .then((success) => {
                  state.PDU[model].list = success["data"].attribute_list
                  dispatch('fnSkeletonDataOne', model)

                  resolve(success["data"]);
                  state.PDU[model].isLoading = false;
                })
                .catch((error) =>
                  dispatch("fnHandleError", {
                    model,
                    error,
                  })
                );
            }
            break;
          case "DeviceAttribute":
            {
              flag = true;
              state.PDU.http = axios.create({
                baseURL: mainhost,
                headers: {
                  Accept: "application/json",
                },
              });
              state.PDU[model].params = {
                user_name: state.PDU.userSession.username,
                device_id: state.PDU[model].obj.device_id,
                attribute_id: "",
              };
              state.PDU[model].isLoading = true;
              await dispatch("fnCheckToken");
              //lấy danh sách thiết bị
              state.PDU.http
                .get("/device/attribute/", {
                  headers: {
                    Authorization: state.PDU.userSession.token,
                  },
                  params: state.PDU[model].params,
                })
                .then((success) => {
                  state.PDU[model].list = success["data"].attribute;
                  state.PDU[model].obj = success["data"];
                  resolve(success["data"]);
                  state.PDU[model].isLoading = false;
                })
                .catch((error) => {
                  console.log(error);
                  dispatch("fnHandleError", {
                    model,
                    error,
                  })
                });
            }
            break;
          case "DevicesAttributes":
            {
              flag = true;
              state.PDU.http = axios.create({
                baseURL: mainhost,
                headers: {
                  Accept: "application/json",
                },
              });
              state.PDU[model].params = {
                user_name: state.PDU.userSession.username,
              };
              state.PDU[model].isLoading = true;
              await dispatch("fnCheckToken");
              //lấy danh sách thiết bị
              state.PDU.http
                .get("/devices/attributes/", {
                  headers: {
                    Authorization: state.PDU.userSession.token,
                  },
                  params: state.PDU[model].params,
                })
                .then((success) => {
                  if (success.data.success) {
                    state.PDU[model].list = success["data"].attribute_list;
                    dispatch("fnSkeletonData", model);

                    state.PDU[model].obj = success["data"];
                    resolve(success["data"]);
                    state.PDU[model].isLoading = false;
                  } else {
                    Vue.set(state.PDU[model], 'list', [])
                    dispatch("fnHandleError", {
                      model,
                      error: success.data.error_msgs,
                    });
                  }
                })
                .catch((error) => {
                  console.log("hree", error);
                  dispatch("fnHandleError", {
                    model,
                    error,
                  });
                });
            }
            break;
          case "NewDevice":
            {
              flag = true;
              state.PDU["NewDevice"].params = {
                user_name: state.PDU.userSession.username,
              };
            }
            break;
          case "group":
            {
              flag = true;
              state.PDU[model].isLoading = false;
              state.PDU.http = axios.create({
                baseURL: mainhost,
                headers: {
                  Accept: "application/json",
                },
              });
              let user = await Amplify.Auth.currentUserInfo();
              state.PDU[model].params = {
                user_name:
                  state.PDU.userSession.username || user.attributes.sub,
              };
              await dispatch("fnCheckToken");
              //lấy danh sách thiết bị
              state.PDU.http
                .get("/user/groups/", {
                  headers: {
                    Authorization: state.PDU.userSession.token,
                  },
                  params: state.PDU[model].params,
                })
                .then((success1) => {
                  state.PDU[model].list = success1["data"].group_list;
                  dispatch("fnCreateGroup", state.PDU[model].list);
                  resolve(success1["data"]);
                  state.PDU[model].isLoading = true;
                })
                .catch((error) =>
                  dispatch("fnHandleError", {
                    model,
                    error,
                  })
                );
            }
            break;
          case "ShareDevice":
            {
              flag = true;
              state.PDU[model].isLoading = false;
              state.PDU.http = axios.create({
                baseURL: mainhost,
                headers: {
                  Accept: "application/json",
                },
              });
              state.PDU["ShareDevice"].params = {
                user_name: state.PDU.userSession.username,
                device_id: state.PDU["DeviceDetail"].obj.device_id,
              };
              await dispatch("fnCheckToken");
              //lấy danh sách thiết bị
              await state.PDU.http
                .get("/user/share/", {
                  headers: {
                    Authorization: state.PDU.userSession.token,
                  },
                  params: state.PDU[model].params,
                })
                .then((success1) => {
                  Vue.set(
                    state.PDU[model],
                    "list",
                    success1["data"].share_list
                  );

                  resolve(success1["data"]);
                })
                .catch((error) =>
                  dispatch("fnHandleError", {
                    model,
                    error,
                  })
                );
            }
            break;
          case "firmware":
            {
              try {
                var hardware =
                  state.PDU["DeviceDetail"].obj.hardwareVersion &&
                  Object.values(
                    state.PDU["DeviceDetail"].obj.hardwareVersion
                  )[0]
                    ? Object.values(
                        state.PDU["DeviceDetail"].obj.hardwareVersion
                      )[0].Da
                    : null;
                if (hardware) {
                  state.PDU[model].isLoading = false;
                  state.PDU.http = axios.create({
                    baseURL: mainhost,
                    headers: {
                      Accept: "application/json",
                    },
                  });
                  await dispatch("fnCheckToken");
                  state.PDU[model].params = {
                    hardware_version: hardware,
                    firmware_level: "release",
                  };
                  //lấy danh sách thiết bị
                  state.PDU.http
                    .get("/firmware/", {
                      headers: {
                        Authorization: state.PDU.userSession.token,
                      },
                      params: state.PDU[model].params,
                    })
                    .then((success1) => {
                      state.PDU[model].list = success1["data"].firmware[0];

                      resolve(success1["data"]);
                      state.PDU[model].isLoading = true;
                    })
                    .catch((error) => {
                      dispatch("fnHandleError", {
                        model,
                        error,
                      });
                    });
                }
              } catch (err) {
                dispatch("fnToast", {
                  message: "Có lỗi xảy ra. Vui lòng kiểm tra lại!",
                  type: "error",
                });
                console.log(err);
              }
            }
            break;
          case 'NotiShare':
            state.PDU[model].params = {
              user_name: state.PDU.userSession.username,
              device_id: state.PDU["DeviceDetail"].obj.device_id
            }
            state.PDU.http = axios.create({
              baseURL: mainhost,
              headers: {
                Accept: "application/json",
              },
            });
            state.PDU.http
              .get("/notification/telegram/", {
                headers: {
                  Authorization: state.PDU.userSession.token,
                },
                params: state.PDU[model].params,
              })
              .then((success1) => {
                state.PDU[model].list = success1["data"].noti_share_list;

                resolve(success1["data"]);
              })
              .catch((error) => {
                dispatch("fnHandleError", {
                  model,
                  error,
                });
              });
            break
        }
      }),
    fnCreateGroup: async ({ state, dispatch }, groups) => { // function create device group
      try {
        var list = state.PDU["devices"].list;
        state.PDU["group"].group_list = [];
        groups.forEach((group) => {
          var ids = group.device_ids; // array
          var name = group.group_descriptions; // string
          var group_id = group.group_id;
          var namechild = "";
          var active = false;
          var children = [];
          ids.forEach((id) => {
            var idx = list.findIndex((e) => {
              return e.device_id == id;
            });
            if (idx == -1) {
              (namechild = id + "[Không tìm thấy]"), (active = false);
            } else if (idx != -1) {
              namechild =
                list[idx].user_device_descriptions || list[idx].device_id;
              active = list[idx].active;
            }
            children.push({
              name: namechild,
              active: active,
              id: id,
              items: [],
            });
          });

          var data = {
            name: name,
            children: children,
            group_id: group_id,
            select: false,
          };
          state.PDU["group"].group_list.push(data);
        });
        dispatch("fnCheckGroupStatus");
      } catch (err) {
        console.log(err);
      }
    },
    fnCheckGroupStatus: ({ state }) => { // function check device group status
      var list = state.PDU["devices"].list;

      state.PDU["group"].group_list.forEach((group) => {
        Vue.set(group, "status", true);
        var child = group.children;

        for (var i in child) {
          var idx = list.findIndex((e) => {
            return e.device_id == child[i].id;
          });

          Vue.set(
            child[i],
            "active",
            list[idx] && list[idx].active ? list[idx].active : false
          );

          if (child[i].active == false) {
            Vue.set(group, "status", false);
          }
        }
      });
    },
    fnLoadData: async ({ dispatch, state }, model) => { // function get api firmware, ...
      switch (model) {
        case "firmware":
          {
            await dispatch("fnRead", model);
            try {
              state.PDU[model].obj = {
                ...state.PDU[model].obj,
                firmwareAvailable:
                  state.PDU[model].list.firmware_version >
                  Object.values(
                    state.PDU["DeviceDetail"].obj.firmwareVersion
                  )[0].Da
                    ? state.PDU[model].list
                    : null,
                firmwareURL: state.PDU[model].list.firmware_url,
              };
              Vue.set(
                state.PDU["DeviceDetail"].obj,
                "firmwareURL",
                state.PDU[model].obj.firmwareURL
              );
              Vue.set(
                state.PDU["DeviceDetail"].obj,
                "firmwareAvailable",
                state.PDU[model].obj.firmwareAvailable
              );
            } catch (error) {
              // dispatch('fnHandleError', {
              // 	model,
              // 	error
              // })

              console.log(error);
            }
          }
          break;
        case "Music": {
          state.PDU[model].obj = {
            ...state.PDU[model].obj,
            devicesOnlineAvailable: [],
            devices: [],
          };
        }
      }
    },
    fnSkeletonData: ({ dispatch, state }, model) => { // function get skeleton data (basic data) for all device
      try {
        var list = []
        var list_parent = []
        var list_child = []

        const searching = (list, key) =>
          list.find((i) => {
            return i && Object.keys(i)[0] == key
          });

        var attributes = state.PDU[model].list // this is list of all items in a user
        for (let [k, v] of Object.entries(attributes)) {
          dispatch("fnsplit", v);
          var list = state.PDU["DeviceAttributes"].tempt;

          var parent = searching(list, "64");
          if (
            parent &&
            (Object.values(parent)[0].Da == 3 ||
            Object.values(parent)[0].Da == 7 ||
            Object.values(parent)[0].Da == 10 ||
            Object.values(parent)[0].Da == 20 ||
            Object.values(parent)[0].Da == 11)) {
            list_parent.push(list);
          } else {
            list_child.push(list);
          }
        }
        state.PDU.list_child = list_child

        list_parent.forEach((e) => {
          var hardwareVersion = searching(e, "64")
          var childevice = searching(e, "192")
          var device_id = searching(e, "device_id");
          var active = searching(e, "384")
          var hardware = hardwareVersion
            ? Object.values(hardwareVersion)[0].Da
            : null
          var child = childevice
            ? Object.values(childevice)[0].Da
            : []

          var d = state.PDU["devices"].list.find((device) => {
            return device.device_id == device_id.device_id
          });
          if (d) {
            Vue.set(d, "active", Object.values(active)[0].Da)
            Vue.set(d, "attribute", e)
            Vue.set(d, "list_child", child)
            Vue.set(d, "hardware", hardware)
          }
        });
      } catch (err) {
        console.log(err)
      }
    },
    fnSkeletonDataOne: ({ dispatch, state }, model) => { // function get skeleton data (basic data) for 1 device
      var attributes = state.PDU[model].list[0]
      var device_id = attributes.device_id

      var d = state.PDU["devices"].list.find((device) => {
        return device.device_id == device_id
      })
      if (d) {
        Vue.set(d, "active", attributes['384'].Da)
        dispatch("fnsplit", attributes);
        var list = state.PDU["DeviceAttributes"].tempt;
        Vue.set(d, "attribute", list)
        Vue.set(d, "list_child", attributes['192'].Da)
        Vue.set(d, "hardware", attributes['64'].Da)
      }
    },
    fnCallResetView: ({ dispatch, state }, model) => { // function reset view (reset some varaible)
      dispatch("fnResetFlagView", model);
      state.PDU[model].selected = [];
    },
    fnCreate: async ({ dispatch, state }, attribute) => { // function call api create device
      await dispatch("fnCheckToken");
      axios({
        method: "PUT",
        url: mainhost + "/user/devices/",
        data: attribute,
        headers: {
          "content-type": "application/json",
          authorization: state.PDU.userSession.token,
        },
      })
        .then((success) => {
          if (success["data"].success == true) {
            flag = true;
            dispatch("fnToast", {
              message: `Thêm thiết bị thành công!`,
              type: "success",
            });
            // dispatch('fnRead', 'devices')
            // dispatch('fnRead', 'DevicesAttributes')
          } else {
            var error = {
              message: success["data"].error_msgs,
            };
            dispatch("fnHandleError", {
              model: "DeviceAttribute",
              error: error,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    fnrequest: async ({ dispatch, state }, device) => { // function get and assign data in api /devices/attributes/
      var http = axios.create({
        baseURL: mainhost,
        headers: {
          Accept: "application/json",
        },
      });
      //lấy danh sách thiết bị
      await dispatch("fnCheckToken");
      await http
        .get("/devices/attributes/", {
          headers: {
            Authorization: state.PDU.userSession.token,
          },
          params: {
            user_name: state.PDU.userSession.username,
            device_id: device.device_id,
          },
        })
        .then((success) => {
          try {
            var attributes = success["data"].attribute_list[0];
            state.PDU["devices"].isSelected = false;
            device.attribute = attributes;
            state.PDU.attribute = device.attribute;
          } catch (err) {}
        })
        .catch((error) =>
          dispatch("fnHandleError", {
            model,
            error,
          })
        );
    },
    fnlistfullDeviceAttributes: async ({ dispatch, state }) => { // function get and assign data in api /devices/attributes/
      var listdevice = state.PDU["devices"].list;
      for (var i in listdevice) {
        dispatch("fnrequest", listdevice[i]);
      }
    },
    fnsplitDevice: ({ dispatch, state }, device) => { // function attribute of 1 device
      var device_copy = Object.assign({}, device);
      state.PDU["devices"].list_copy.push(device);
      var list = device_copy.attribute;

      dispatch("fnsplit", list);
      var xxx = state.PDU["DeviceAttributes"].tempt;
      state.PDU["DeviceDetail"].obj;
    },
    fnsplit: ({ state }, list) => { // function attribute of 1 device
      state.PDU["DeviceAttributes"].tempt = [];
      var arr = [];
      try {
        var x = Object.entries(list);
        for (var j in x) {
          let y = Object.assign(x[j]);
          let y1 = y[0];
          let y2 = y[1];
          var output = {};
          output[y1] = y2;
          arr.push(output);
        }
        // var tempt = arr.reduce(function(result,item){
        // 	var key= Object.keys(item)[0]
        // 	result[key] = item[key]
        // 	return result
        // })
        state.PDU["DeviceAttributes"].tempt = arr;
        return state.PDU["DeviceAttributes"].tempt;
      } catch (err) {}
    },
    fnDeviceDetail: async ( // function get detail information of 1 device
      { state, dispatch },
      { device, hardware, child_device_id }
    ) => {
      // state.PDU['DeviceDetail'].isLoading = false
      state.SKL.loading = true;
      // state.PDU['DeviceDetail'].obj = {}

      try {
        const seach_default = (list, key) =>
          list.find((i) => Object.keys(i)[0] == key);
        const seaching = (list, token) =>
          list.filter(
            (i) =>
              i &&
              Object.values(i)[0] &&
              Object.values(i)[0].Na &&
              Object.values(i)[0].Na.includes(token)
          );

        var attributes = device.attribute;

        var obj = attributes.find((attribute) => {
          return Object.keys(attribute)[0] == "attribute_descriptions";
        });
        if (obj) {
          var obj_data = Object.values(obj)[0].Da;

          for (let [obj_data_key, obj_data_value] of Object.entries(obj_data)) {
            attributes.forEach((attribute) => {
              if (Object.keys(attribute)[0] == obj_data_key) {
                Vue.set(
                  Object.values(attribute)[0],
                  "attribute_descriptions",
                  obj_data_value
                );
                Vue.set(
                  Object.values(attribute)[0],
                  "attribute_descriptions_copy",
                  obj_data_value
                );
              }
            });
          }
        }

        state.PDU["Scene"].list_scene = seaching(attributes, "Auto");
        
        var cdevice_id = seach_default(attributes, "device_id")
        var cnoti_content = seach_default(attributes, "noti_content")
        var cactive = seach_default(attributes, "384")
        var chardware_1 = seach_default(attributes, "64")
        var cIPaddr = seach_default(attributes, "1024")
        var cchildDevice = seach_default(attributes, "192")
        var cUSBFormat = seach_default(attributes, "1344")
        var cMusicUSBIn = seach_default(attributes, "2752")
        var cmusicProgress = seach_default(attributes, "1600")
        var cchildRefresh = seach_default(attributes, "960")
        var cfirmwareUpdateStatus = seach_default(attributes, "1152")
        var cRebootScheduler = seach_default(attributes, "2880")
        var cLoTempAlarmSetting = seach_default(attributes, "3008")
        var cHiTempAlarmSetting = seach_default(attributes, "2994")
        var cLoHumAlarmSetting = seach_default(attributes, "3136")
        var cHiHumAlarmSetting = seach_default(attributes, "3072")

        state.PDU["DeviceDetail"].obj = {
          ...state.PDU["DeviceDetail"].obj,
          device_id: cdevice_id
            ? Object.values(cdevice_id)[0]
            : null,
          noti_content: cnoti_content ? Object.values(cnoti_content)[0].Da : {},
          active: cactive
            ? Object.values(cactive)[0].Da
            : null,
          list_outled: seaching(attributes, "Outlet"),
          firmwareVersion: seach_default(attributes, "128"),
          hardwareVersion: chardware_1,
          hardware: hardware,
          child_device_id: child_device_id,
          hardware_1: chardware_1
            ? Object.values(chardware_1)[0].Da
            : null,
          IPaddr: cIPaddr
            ? Object.values(cIPaddr)[0].Da
            : null,
          firmwareAvailable: state.PDU["firmware"].obj.firmwareAvailable,
          firmwareURL: state.PDU["firmware"].obj.firmwareURL,
          childDevice: cchildDevice
            ? Object.values(cchildDevice)[0].Da
            : null,
          list_MusicChannel: seaching(attributes, "MusicChannel"),
          list_VolumeChannel: seaching(attributes, "VolumeChannel"),
          list_ListMusic: seaching(attributes, "ListMusic")[0],
          AutoScene: seaching(attributes, "Auto"),
          USBRemain: seach_default(attributes, "1280"),
          USBFormat: cUSBFormat
            ? Object.values(cUSBFormat)[0].Da
            : "Not existed",
          RebootScheduler: cRebootScheduler,
          MusicUSBIn: cMusicUSBIn
            ? Object.values(cMusicUSBIn)[0].Da
            : "Not existed",
          musicProgress: cmusicProgress
            ? Object.values(cmusicProgress)[0].Da
            : "Not existed",
          musicProgressName: null,
          childRefresh: cchildRefresh
            ? Object.values(cchildRefresh)[0].Da
            : null,
          firmwareUpdateStatus: cfirmwareUpdateStatus
            ? Object.values(cfirmwareUpdateStatus)[0].Da
            : null,
          list_sceneID: [],
          list_CMAudioStatus: seaching(attributes, "AudioStatus"),
          list_CMAudioHealth: seaching(attributes, "AudioHealth"),
          list_CMAudioTimeOff: seaching(attributes, "AudioTimeOff"),
          list_CMAudioTimerHealthy: seaching(attributes, "AudioTimerHealthy"),
          list_CMAudioUsed: seaching(attributes, "AudioUsed"),
          // breadcrumbs: []
          isRestart: false,
          isManualReboot: false,
          countUpReboot: 0,
          cLoTempAlarmSetting: cLoTempAlarmSetting
            ? Object.values(cLoTempAlarmSetting)[0].Da
            : [],
          cHiTempAlarmSetting: cHiTempAlarmSetting
            ? Object.values(cHiTempAlarmSetting)[0].Da
            : [],
          cLoHumAlarmSetting: cLoHumAlarmSetting
            ? Object.values(cLoHumAlarmSetting)[0].Da
            : [],
          cHiHumAlarmSetting: cHiHumAlarmSetting
            ? Object.values(cHiHumAlarmSetting)[0].Da
            : []
        };
        state.PDU["DeviceDetail"].obj.AutoScene.forEach((e) => {
          if (Object.values(e)[0].Da && Object.values(e)[0].Da.output) {
            state.PDU["DeviceDetail"].obj.list_sceneID.push(Object.keys(e)[0]);
          }
        });
        
        // Check device is manual rebooting
        var arrREB = []
        var arrManualReboot = localStorage.getItem('REB')
        arrREB = arrManualReboot ? JSON.parse(arrManualReboot) : []
        if (arrREB && arrREB.length > 0) {
          var index = arrREB.findIndex(e => { return e.device_id === state.PDU["DeviceDetail"].obj.device_id })
          if (index >= 0) {
            Vue.set(state.PDU["DeviceDetail"].obj, 'isManualReboot', true)
            Vue.set(state.PDU["DeviceDetail"].obj, 'countUpReboot', null)
          }
        }

        // dispatch('fnDecodeListMusic')

        state.PDU["devices"].list.forEach((e) => {
          if (e.device_id == state.PDU["DeviceDetail"].obj.device_id) {
            Vue.set(
              state.PDU["DeviceDetail"].obj,
              "user_device_descriptions",
              e.user_device_descriptions
            );
            Vue.set(state.PDU["DeviceDetail"].obj, "user_role", e.user_role);
          }
        });
        dispatch("fnSetBreadcumm", hardware);

        if (state.PDU["DeviceDetail"].obj.isSubcribe != null) {
        } else {
          Vue.set(state.PDU["DeviceDetail"].obj, "isSubcribe", false);
        }

        //map outled and current
        var list_outled = state.PDU["DeviceDetail"].obj.list_outled;
        var list_current = state.PDU["DeviceDetail"].obj.list_current;
        var length = state.PDU["DeviceDetail"].obj.list_outled.length;
        for (var i = 0; i < length; i++) {
          Vue.set(Object.values(list_outled[i])[0], "current", list_current[i]);
        }

        //description của các kênh trong nesthouse
        var list_MusicChannel = state.PDU["DeviceDetail"].obj.list_MusicChannel;
        var list_VolumeChannel =
          state.PDU["DeviceDetail"].obj.list_VolumeChannel;
        var len_2 = state.PDU["DeviceDetail"].obj.list_MusicChannel.length;

        await dispatch("fnSet_listSceneTimer", list_MusicChannel);

        for (var i = 0; i < len_2; i++) {
          try {
            Vue.set(
              Object.values(list_MusicChannel[i])[0],
              "volume",
              list_VolumeChannel[i]
            );
            Vue.set(Object.values(list_MusicChannel[i])[0], "list_timer", []);
            Vue.set(Object.values(list_MusicChannel[i])[0], "timeout", false);

            if (Object.values(list_MusicChannel[i])[0].attribute_descriptions) {
            } else {
              var name = Object.values(list_MusicChannel[i])[0].Na;
              Vue.set(
                Object.values(list_MusicChannel[i])[0],
                "attribute_descriptions",
                fnRename(name)
              );
            }
          } catch (err) {}
        }

        var list_CMAudioStatus =
          state.PDU["DeviceDetail"].obj.list_CMAudioStatus;
        var len_3 = state.PDU["DeviceDetail"].obj.list_CMAudioStatus.length;
        var list_CMAudioTimeOff =
          state.PDU["DeviceDetail"].obj.list_CMAudioTimeOff;

        for (var i = 0; i < len_3; i++) {
          try {
            if (
              Object.values(list_CMAudioStatus[i])[0].attribute_descriptions
            ) {
            } else {
              var name = Object.values(list_CMAudioStatus[i])[0].Na;

              Vue.set(
                Object.values(list_CMAudioStatus[i])[0],
                "attribute_descriptions",
                fnRename(name)
              );
              Vue.set(
                Object.values(list_CMAudioStatus[i])[0],
                "attribute_descriptions_copy",
                fnRename(name)
              );
            }

            Vue.set(Object.values(list_CMAudioTimeOff[i])[0], "timeout", false);
          } catch (err) {}
        }

        var list_CMAudioUsed = state.PDU["DeviceDetail"].obj.list_CMAudioUsed;
        var len_4 = state.PDU["DeviceDetail"].obj.list_CMAudioUsed.length;
        for (var i = 0; i < len_4; i++) {
          try {
            var Da = Object.values(list_CMAudioUsed[i])[0].Da;

            Vue.set(Object.values(list_CMAudioUsed[i])[0], "Da_copy", Da);
          } catch (err) {}
        }
      } catch (err) {}
    },
    fnSetBreadcumm: ({ state }, hardware) => { // function set breadcumbar
      state.PDU.breadcrumbs = [];
      state.PDU.breadcrumbs.push({
        text:
          state.PDU["DeviceDetail"].obj.user_device_descriptions ||
          state.PDU["DeviceDetail"].obj.device_id,
        disable: false,
        device: state.PDU["DeviceDetail"].obj,
        hardware: hardware,
      });
      if (hardware == 3 || hardware == 10 || hardware == 20 || hardware == 11) {
        state.PDU.breadcrumbs.push({
          text: "Điều khiển âm ly",
          disable: false,
          device: state.PDU["DeviceDetail"].obj,
          hardware: hardware,
        });
      } else if (hardware == 4) {
        state.PDU.breadcrumbs.push({
          text: "Điều khiển nguồn",
          disable: false,
          device: state.PDU["DeviceDetail"].obj,
          hardware: 4,
        });
      } else if (hardware == 6) {
        state.PDU.breadcrumbs.push({
          text: "Nhiệt độ, độ ẩm",
          disable: false,
          device: state.PDU["DeviceDetail"].obj,
          hardware: 6,
        });
      }
    },
    fnDecodeListMusic: ({ state }) => { // function decode music name
      var list_ListMusic =
        state.PDU["DeviceDetail"].obj.list_ListMusic &&
        state.PDU["DeviceDetail"].obj.list_ListMusic["1728"] &&
        state.PDU["DeviceDetail"].obj.list_ListMusic["1728"].Da
          ? state.PDU["DeviceDetail"].obj.list_ListMusic["1728"].Da
          : [];
      for (var i in list_ListMusic) {
        list_ListMusic[i] = decodeURIComponent(list_ListMusic[i]);
      }
    },
    fnSet_listSceneTimer: ({ dispatch, state }, list_MusicChannel) => { // function find and assign list scene timer
      try {
        list_MusicChannel.forEach((e) => {
          var arr = state.PDU["DeviceDetail"].obj.AutoScene.filter((scene) => {
            var key = Object.keys(scene)[0];
            var value = Object.values(scene)[0];
            if (value.Da) {
              var output = value.Da.output ? value.Da.output[0] : null;
              var input = value.Da.input ? value.Da.input[0] : null;
              if (output) {
                for (let [k, v] of Object.entries(output)) {
                  if (
                    k == Object.keys(e)[0] &&
                    input &&
                    input.day_min &&
                    input.day_min.Da != 0
                  ) {
                    Vue.set(Object.values(e)[0], "timeout", false);
                    return scene;
                  }
                }
              }
            }
          });
          Vue.set(Object.values(e)[0], "list_scene_timer", arr);
        });
      } catch (err) {
        console.log(err);
      }
    },
    fnSet_listSceneTimer_pdu: ({ dispatch, state }, list_outlet) => { // function find and assign list scene timer for pdu device
      try {
        list_outlet.forEach((e) => {
          var arr = state.PDU["DeviceDetail"].obj.AutoScene.filter((scene) => {
            var key = Object.keys(scene)[0];
            var value = Object.values(scene)[0];
            if (value.Da) {
              var output = value.Da.output ? value.Da.output[0] : null;
              var input = value.Da.input ? value.Da.input[0] : null;
              if (output) {
                for (let [k, v] of Object.entries(output)) {
                  if (
                    k == Object.keys(e)[0] &&
                    input &&
                    input.day_min &&
                    input.day_min.Da != 0 &&
                    output.device_id == Object.values(e)[0].device_id
                  ) {
                    Vue.set(Object.values(e)[0], "timeout", false);
                    return scene;
                  }
                }
              }
            }
          });
          Vue.set(Object.values(e)[0], "list_scene_timer", arr);
        });

        list_outlet.forEach((e) => {
          var arr = state.PDU["DeviceDetail"].obj.AutoScene.filter((scene) => {
            var key = Object.keys(scene)[0];
            var value = Object.values(scene)[0];
            if (value.Da) {
              var output = value.Da.output ? value.Da.output[0] : null;
              var input = value.Da.input ? value.Da.input[0] : null;
              if (output) {
                for (let [k, v] of Object.entries(output)) {
                  if (
                    k == Object.keys(e)[0] &&
                    input &&
                    input.day_min &&
                    output.device_id == Object.values(e)[0].device_id
                  ) {
                    return scene;
                  }
                }
              }
            }
          });
          Vue.set(Object.values(e)[0], "list_scene_timer_1", arr);
        });
      } catch (err) {
        console.log(err);
      }
    },
    fnUpdate_user_device_des: async ({ dispatch, state }, attribute) => { 
      await dispatch("fnCheckToken");
      axios({
        method: "POST",
        url: mainhost + "/user/devices/",
        data: attribute,
        headers: {
          "content-type": "application/json",
          authorization: state.PDU.userSession.token,
        },
      })
        .then((success) => {
          if (success["data"]) {
            flag = true;
            var idx = state.PDU["devices"].list.findIndex((e) => {
              return e.device_id == state.PDU["DeviceDetail"].obj.device_id;
            });
            if (idx != -1) {
              Vue.set(
                state.PDU["devices"].list[idx],
                "user_device_descriptions",
                state.PDU["devices"].obj.user_device_descriptions
              );
              Vue.set(
                state.PDU["DeviceDetail"].obj,
                "user_device_descriptions",
                state.PDU["devices"].obj.user_device_descriptions
              );

              dispatch(
                "fnSetBreadcumm",
                state.PDU["DeviceDetail"].obj.hardware
              );
            }

            dispatch("fnToast", {
              message: `Thao tác thành công`,
              type: "success",
            });
          } else {
            dispatch("fnToast", {
              message: `Thao tác thất bại`,
              type: "error",
            });
          }
          dispatch("fnCallResetView", "devices");
        })
        .catch((err) => {
          dispatch("fnToast", {
            message: err,
            type: "error",
          });
        });
      Vue.set(state.PDU["devices"].obj, "isNameDevice", false);
    },
    // function update attribute description in attribute list
    fnUpdate_AttributeDescriptions: ({ dispatch, state }, attribute) =>
      new Promise(async (resolve) => {
        await dispatch("fnCheckToken");
        axios({
          method: "POST",
          url: mainhost + "/device/attribute/descriptions/",
          data: attribute,
          headers: {
            "content-type": "application/json",
            authorization: state.PDU.userSession.token,
          },
        })
          .then((success) => {
            resolve(success["data"]);
          })
          .catch((error) => {
            dispatch("fnToast", {
              message: `Thao tác thất bại`,
              type: "error",
            });
          });
    }),
    // function update notify content
    fnUpdate_NotiContent: ({ dispatch, state }, attribute) =>
    new Promise(async (resolve) => {
      await dispatch("fnCheckToken");
      axios({
        method: "POST",
        url: mainhost + "/device/attribute/noti-content",
        data: attribute,
        headers: {
          "content-type": "application/json",
          Authorization: state.PDU.userSession.token,
        },
      })
        .then((success) => {
          resolve(success["data"]);
        })
        .catch((error) => {
          console.log(error)
          dispatch("fnToast", {
            message: `Cài đặt nội dung thông báo thất bại`,
            type: "error",
          });
      });
    }),
    // function call api delete
    fnDelete: ({ dispatch, state }, model) =>
      new Promise(async (resolve) => {
        var obj = fnCopy(state.PDU["group"].obj);
        state.PDU[model].isLoading = true;
        switch (model) {
          case "devices":
            {
              await dispatch("fnCheckToken");
              state.PDU.http = axios.create({
                baseURL: mainhost,
                headers: {
                  Accept: "application/json",
                },
              });
              state.PDU[model].params = {
                user_name: state.PDU.userSession.username,
                device_id: state.PDU["DeviceDetail"].obj.device_id,
              };
              state.PDU.http
                .delete("/user/devices/", {
                  headers: {
                    Authorization: state.PDU.userSession.token,
                  },
                  params: state.PDU[model].params,
                })
                .then((success1) => {
                  if (success1["data"]) {
                    flag = true;
                    dispatch("fnToast", {
                      message: `Xóa thiết bị thành công`,
                      type: "success",
                    });
                  }
                  resolve(success1["data"]);
                })
                .catch((error) =>
                  dispatch("fnHandleError", {
                    model,
                    error,
                  })
                );
              state.PDU[model].isLoading = false;
            }
            break;
          case "group":
            {
              await dispatch("fnCheckToken");
              state.PDU.http = axios.create({
                baseURL: mainhost,
                headers: {
                  Accept: "application/json",
                },
              });
              state.PDU[model].params = {
                user_name: state.PDU.userSession.username,
                group_id: obj.group_id,
              };
              state.PDU.http
                .delete("/user/groups/", {
                  headers: {
                    Authorization: state.PDU.userSession.token,
                  },
                  params: state.PDU[model].params,
                })
                .then((success1) => {
                  if (success1["data"]) {
                    flag = true;
                    dispatch("fnToast", {
                      message: `Thao tác thành công`,
                      type: "success",
                    });
                  }
                  resolve(success1["data"]);
                })
                .catch((error) =>
                  dispatch("fnHandleError", {
                    model,
                    error,
                  })
                );
            }
            break;
          case "ShareDevice":
            {
              state.PDU.http = axios.create({
                baseURL: mainhost,
                headers: {
                  Accept: "application/json",
                },
              });
              state.PDU[model].params = state.PDU["ShareDevice"].obj;
              await dispatch("fnCheckToken");

              state.PDU.http
                .delete("/user/share/", {
                  headers: {
                    Authorization: state.PDU.userSession.token,
                  },
                  params: state.PDU["ShareDevice"].params,
                })
                .then((success1) => {
                  if (success1.data.success == true) {
                    flag = true;
                    dispatch("fnToast", {
                      message: "Thao tác thành công!",
                      type: "success",
                    });
                  } else {
                    dispatch("fnToast", {
                      message: success1.data.error_msgs,
                      type: "error",
                    });
                  }
                  resolve(success1["data"]);
                })
                .catch((error) =>
                  dispatch("fnHandleError", {
                    model,
                    error,
                  })
                );
              state.PDU[model].isLoading = false;
            }
            break;
          case "NotiShare":
            {
              state.PDU.http = axios.create({
                baseURL: mainhost,
                headers: {
                  Accept: "application/json",
                },
              });
              state.PDU[model].params = state.PDU[model].obj;
              await dispatch("fnCheckToken");

              state.PDU.http
                .delete("/notification/telegram/", {
                  headers: {
                    Authorization: state.PDU.userSession.token,
                  },
                  params: state.PDU[model].params,
                })
                .then((success1) => {
                  if (success1.data.success == true) {
                    flag = true;
                    dispatch("fnToast", {
                      message: "Thao tác thành công!",
                      type: "success",
                    });
                  } else {
                    dispatch("fnToast", {
                      message: success1.data.error_msgs,
                      type: "error",
                    });
                  }
                  resolve(success1["data"]);
                })
                .catch((error) =>
                  dispatch("fnHandleError", {
                    model,
                    error,
                  })
                );
              state.PDU[model].isLoading = false;
            }
            break;
        }
      }),
    fnCallCreateView: ({ dispatch, state }, model) => { // function call create view
      dispatch("fnResetFlagView", model);
      state.PDU[model].isCreate = true;
      // state.PDU[model].obj.isForm = true
      dispatch("fnLoadData", model);
    },
    fnCallDeleteView: ({ dispatch, state }, { model, item }) => { // function call delete view (assign some vailable)
      try {
        dispatch("fnResetFlagView", model);
        state.PDU[model].isDelete = true;
        state.PDU[model].obj = fnCopy(item);
        state.PDU[model].obj.isDel = true;
        //dispatch('fnLoadData', model)
      } catch (err) {
        console.log(err);
      }
    },
    fnCallUpdateView: ({ dispatch, state }, { model, item }) => { // function call update view (assign some vailable)
      if (item === null || item === undefined) {
        return
      }
      dispatch("fnResetFlagView", model);
      state.PDU[model].isUpdate = true;
      state.PDU[model].obj = fnCopy(item);
      state.PDU[model].obj.isForm = true;
      // dispatch('fnLoadData', model)
    },
    fnResetFlagView: ({ state }, model) => { // function call delete view (assign some vailable)
      state.PDU[model] = {
        ...state.PDU[model],
        obj: {},
        isLoading: false,
        isCreate: false,
        isUpdate: false,
        isDelete: false,
      };
    },
    fnSetMusicStorage({ dispatch, state }, model, item) { // function set music name to local storage
      localStorage.setItem("Music", JSON.stringify(state.PDU[model]));

      dispatch("fnCallDeleteView", model, item);
    },
    fnChangePassword: ({ dispatch, state }, model) => { // function change password
      state.PDU[model].isLoading = true;
      Amplify.Auth.currentAuthenticatedUser()
        .then((user) =>
          Amplify.Auth.changePassword(
            user,
            state.PDU[model].obj.passwordOld,
            state.PDU[model].obj.passwordNew
          )
        )
        .then(() => {
          dispatch("fnCallResetView", model);
          dispatch("fnRemoveUserSession");
          dispatch("fnToast", {
            message: "Cập nhật thành công",
            type: "success",
          });
        })
        .catch((error) => {
          dispatch("fnToast", {
            message: "Có lỗi, vui lòng kiểm tra lại",
            type: "error",
          });
          dispatch("fnCallResetView", model);
        });
    },
    // function call api POST
    fnIotPost: async ({ dispatch, state }, attribute_list) =>
      new Promise(async (resolve) => {
        await dispatch("fnCheckToken");
        axios({
          method: "POST",
          url: mainhost + "/device/attributes/",
          data: attribute_list,
          headers: {
            "content-type": "application/json",
            authorization: state.PDU.userSession.token,
          },
        })
          .then((success) => {
            flag = true;

            resolve(success["data"]);
          })
          .catch((err) => {
            dispatch("fnHandleError", {
              model: "DeviceAttribute",
              error: err,
            });
          });
      }),
    fnIotPut_Group: async ({ dispatch, state }, attribute) => { // function call api PUT a group
      await dispatch("fnCheckToken");
      axios({
        method: "PUT",
        url: mainhost + "/user/groups/",
        data: attribute,
        headers: {
          "content-type": "application/json",
          authorization: state.PDU.userSession.token,
        },
      })
        .then((success) => {
          if (success["data"]) {
            // dispatch('fnRead', 'group')

            dispatch("fnToast", {
              message: `Tạo nhóm thành công`,
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    fnIotPost_Group: async ({ dispatch, state }, attribute) => { // function call api POST a group
      await dispatch("fnCheckToken");
      axios({
        method: "POST",
        url: mainhost + "/user/groups/",
        data: attribute,
        headers: {
          "content-type": "application/json",
          authorization: state.PDU.userSession.token,
        },
      })
        .then((success) => {
          if (success["data"]) {
            dispatch("fnRead", "group");

            dispatch("fnToast", {
              message: `Thao tác thành công`,
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    fnIotPut_ShareDevice: async ({ dispatch, state }, attribute) => { // function call api PUT share a device
      await dispatch("fnCheckToken");
      axios({
        method: "PUT",
        url: mainhost + "/user/share/",
        data: attribute,
        headers: {
          "content-type": "application/json",
          authorization: state.PDU.userSession.token,
        },
      })
        .then((success) => {
          flag = true;
          if (success.data.success == true) {
            dispatch("fnToast", {
              message: "Thao tác thành công!",
              type: "success",
            });
            state.PDU["ShareDevice"].list.push(attribute.share_list[0]);
          } else {
            var message =
              success.data.error_msgs &&
              success.data.error_msgs.includes("User does not exist")
                ? "Tài khoản không tồn tại!"
                : success.data.error_msgs;
            dispatch("fnToast", {
              message: message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // fnIotPost_zalo: async ({ dispatch, state }, attribute_list) => {
    //   try {
    //     await dispatch("fnCheckToken");

    //     axios({
    //       method: "POST",
    //       url: mainhost + "/notification/zalo/",
    //       data: attribute_list,
    //       headers: {
    //         "content-type": "application/json",
    //         authorization: state.PDU.userSession.token,
    //       },
    //     })
    //       .then((success) => {
    //         flag = true;
    //       })
    //       .catch((err) => {
    //         console.log("err zalo", err);
    //       });
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
    fnDownload: async ({ dispatch, state }, arr_link) => { // function download music
      let promise = new Promise((res) => {
        state.PDU["Music"].obj.file_list.forEach((e) => {
          Amplify.Storage.put(
            `${state.PDU.userSession.username}/${e.name}`,
            e,
            {
              progressCallback(progress) {
                state.PDU["Music"].obj.progress = Math.round(
                  (progress.loaded / progress.total) * 100
                );
              },
            }
          )
            .then(() => {
              arr_link.push(
                `${state.PDU.host_bucket}/${state.PDU.userSession.username}/${e.name}`
              );
            })
            .catch((error) => {
              dispatch("fnHandleError", {
                model: "Music",
                error,
              });
              return;
            });
        });
        setTimeout(() => res("Now it's done!"), 1000);
      });
    },
    fnUpload: async ({ dispatch, state }, model) => { // function upload music
      var arr_link = [];
      state.PDU[model].obj = {
        ...state.PDU[model].obj,
        arr_link: [],
        progress: null,
      };

      var arr = state.PDU[model].obj.file_list.filter((e) => e.name);
      var len = arr.length;
      for (var i = 0; i < len; i++) {
        var item = arr[i];

        await Storage.put(
          `${state.PDU.userSession.username}/${item.name}`,
          item,
          {
            progressCallback(progress) {
              state.PDU[model].obj.progress = Math.round(
                (progress.loaded / progress.total) * 100
              );
            },
          }
        )
          .then((data) => {
            arr_link.push(
              `${state.PDU.host_bucket}/${
                state.PDU.userSession.username
              }/${fnCopy(encodeURIComponent(item.name))}`
            );
          })
          .catch((error) => {
            dispatch("fnHandleError", {
              model,
              error,
            });
            return;
          });

        if (!state.PDU[model].obj.noreset && i == len - 1) {
          dispatch("fnCallResetView", "Music");

          state.PDU["Scene"].obj = {
            device_id: state.PDU["DeviceDetail"].obj.device_id,
            "1536": {
              Da: arr_link,
              Dt: "ARRAY",
            },
          };

          var attribute = {
            user_name: state.PDU.userSession.username,
            attribute_list: [state.PDU["Scene"].obj],
          };

          console.log(attribute);

          if (arr_link) {
            await dispatch("fnIotPost", attribute);
          } else {
            dispatch("fnToast", {
              message: "Thất bại! Vui lòng thử lại sau",
              type: "error",
            });
          }
          return;
        }
      }
    },
    fnRemoveDeviceIDOfLocalREB: ({ state }, { device_id }) => { // function set list reboot device to local storage
      var arrREB = []
      var arrManualReboot = localStorage.getItem('REB')
      arrREB = arrManualReboot ? JSON.parse(arrManualReboot) : []
      if (arrREB && arrREB.length > 0) {
        var index = arrREB.findIndex(e => { return e.device_id === device_id })
        if (index >= 0) {
          arrREB.splice(index, 1)
          if (arrREB.length > 0) {
            localStorage.setItem('REB', JSON.stringify(arrREB))
          } else {
            localStorage.removeItem('REB')
          }
        }
      }
    },
    fnIotSubscribe: ({ dispatch, state }, { device_id, unSub }) => { // function subcribe topic and handle topic data
      try {
        let topics = "thesis/v1/up/attributes/" + device_id;
        if (unSub == true) {
          var unsub = state.PDU.subcribe.findIndex((e) => {
            return e.device_id == device_id;
          });
          if (unsub >= 0) {
            state.PDU.subcribe[unsub].sub.unsubscribe();
            state.PDU.subcribe.splice(unsub, 1);
            return;
          }

          return;
        }
        const sub = Amplify.PubSub.subscribe(topics, { clientId: `web-${state.PDU.userSession.username}-${new Date().getTime()}` }).subscribe({
          next: (data) => {
            data = data.value;

            const topic = data[Object.getOwnPropertySymbols(data)[0]];
            const [, , , , id] = (topic && topic.split("/")) || [];

            let idx = state.PDU["devices"].list.find((e) => {
              return e.device_id == id;
            });
            var device_result = state.PDU["DeviceDetail"].obj.child_attribute
              ? state.PDU["DeviceDetail"].obj.child_attribute.find((device) => { return device.device_id == id; })
              : {};

            try {
              dispatch("fnsplit", data);
              var x = state.PDU["DeviceAttributes"].tempt;
              if (device_result) {
                x.forEach((e) => {
                  if (state.PDU["DeviceDetail"].obj.device_id == device_result.parent_id) {
                    if (device_result.list_outled) {
                      var outlet = device_result.list_outled.find((outlet) => {
                        return Object.keys(outlet)[0] == Object.keys(e)[0];
                      });
                      if (outlet) {
                        Vue.set(Object.values(outlet)[0], "Da", Object.values(e)[0].Da);
                        Vue.set(Object.values(outlet)[0], "timeout", false);
                      }

                      var device_list_temper = state.PDU["DeviceDetail"].obj.child_attribute.filter((e) => {
                        return (e.isSensor && e.list_temper && e.list_temper.length > 0);
                      });
                      if (device_list_temper.length > 0) {
                        device_list_temper.forEach((d) => {
                          dispatch("onAddList_scene_temper_to_temper", {
                            list_temper: d.list_temper,
                            device: d,
                            list_outlet: device_result.list_outled,
                          });
                        });
                      }
                    }
                    if (Object.keys(e)[0] == "384") {
                      flag = false;
                      Vue.set(device_result, "active", Object.values(e)[0].Da);

                      if (state.PDU["DeviceDetail"].obj.isRestart == true) {
                        if (device_result.active == true) {
                          dispatch("fnToast", {
                            message: `Thiết bị [${
                              device_result.hardware == 4
                                ? "Điều khiển nguồn"
                                : "Nhiệt độ, độ ẩm"
                            } -> ${
                              device_result.parent_id ==
                              state.PDU["DeviceDetail"].obj.device_id
                                ? state.PDU["DeviceDetail"].obj
                                    .user_device_descriptions ||
                                  state.PDU["DeviceDetail"].obj.device_id
                                : device_result.parent_id
                            }]: ${
                              device_result.active == false
                                ? "mất kết nối"
                                : "đã kết nối"
                            }`,
                            type:
                              device_result.active == false
                                ? "error"
                                : "success",
                          });
                        }
                      } else if (
                        state.PDU["DeviceDetail"].obj.isRestart == false
                      ) {
                        dispatch("fnToast", {
                          message: `Thiết bị [${
                            device_result.hardware == 4
                              ? "Điều khiển nguồn"
                              : "Nhiệt độ, độ ẩm"
                          } -> ${
                            device_result.parent_id ==
                            state.PDU["DeviceDetail"].obj.device_id
                              ? state.PDU["DeviceDetail"].obj
                                  .user_device_descriptions ||
                                state.PDU["DeviceDetail"].obj.device_id
                              : device_result.parent_id
                          }]: ${
                            device_result.active == false
                              ? "mất kết nối"
                              : "đã kết nối"
                          }`,
                          type:
                            device_result.active == false ? "error" : "success",
                        });
                      }
                    }

                    if (device_result.list_temper) {
                      var temper = device_result.list_temper.find((tem) => { return Object.keys(tem)[0] == Object.keys(e)[0]; });
                      var humi = device_result.list_temper.find((tem) => { return (Object.keys(Object.values(tem)[0].Humi)[0] == Object.keys(e)[0]); });
                      if (temper) {
                        Vue.set(Object.values(temper)[0], "Da", Object.values(e)[0].Da);
                      }
                      if (humi) {
                        Vue.set(Object.values(Object.values(humi)[0].Humi)[0], "Da", Object.values(e)[0].Da);
                      }
                    }
                    if (device_result.list_audio_status) {
                      var outlet = device_result.list_audio_status.find(
                        (outlet) => {
                          return Object.keys(outlet)[0] == Object.keys(e)[0];
                        }
                      );
                      if (outlet) {
                        Vue.set(Object.values(outlet)[0], "Da", Object.values(e)[0].Da);
                        Vue.set(Object.values(outlet)[0], "Re", Object.values(e)[0].Re);
                      }
                    }
                  }
                });
              }
              if (idx) {
                let list = idx["attribute"];

                x.forEach(async (e) => {
                  if (state.PDU["DeviceDetail"].obj.device_id == id) {
                    list.forEach((d) => {
                      if (Object.keys(d)[0] == Object.keys(e)[0]) {
                        Vue.set(Object.values(d)[0], "Da", Object.values(e)[0].Da);
                        Vue.set(Object.values(d)[0], "Re", Object.values(e)[0].Re);
                        if (Object.values(d)[0].timeout && Object.values(d)[0].timeout == true) {
                          Vue.set(Object.values(d)[0], "timeout", false);
                        }
                        if (Object.keys(e)[0] >= 2048 && Object.keys(e)[0] <= 2063) {
                          flag == true
                            ? dispatch("fnToast", {
                                message: `Thao tác thành công`,
                                type: "success",
                              })
                            : "";
                        }
                        if (Object.keys(e)[0] >= 2816 && Object.keys(e)[0] <= 2831) {
                          flag == true
                            ? dispatch("fnToast", {
                                message: `Thao tác thành công`,
                                type: "success",
                              })
                            : "";
                        }
                        if (Object.keys(e)[0] >= 2112 && Object.keys(e)[0] <= 2127) {
                          flag == true
                            ? dispatch("fnToast", {
                                message: `Thao tác thành công`,
                                type: "success",
                              })
                            : "";
                          flag = false;
                        }
                      }
                    });
                  }
                  if (Object.keys(e)[0] == "384") {
                    flag = false;
                    var device = state.PDU["devices"].list.find((device) => {
                      return device.device_id == id;
                    });
                    Vue.set(device, "active", Object.values(e)[0].Da);
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      Vue.set(
                        state.PDU["DeviceDetail"].obj,
                        "active",
                        Object.values(e)[0].Da
                      );
                    }

                    dispatch("fnToast", {
                      message: `Thiết bị [${device.user_device_descriptions ||
                        device.device_id}]: ${
                        device.active == false ? "ngưng" : ""
                      } hoạt động`,
                      type:
                        Object.values(e)[0].Da == false ? "error" : "success",
                    });

                    if (device.active == true) {
                      Vue.set(state.PDU["DeviceDetail"].obj, "isRestart", true);
                      Vue.set(state.PDU["DeviceDetail"].obj, "isManualReboot", false);
                      dispatch('fnRemoveDeviceIDOfLocalREB', { device_id: device.device_id })
                    }
                    dispatch("fnCheckGroupStatus");
                  } else
                  if (Object.values(e)[0].Na && (Object.values(e)[0].Na.includes("MusicChannel") || Object.values(e)[0].Na.includes("VolumeChannel"))) {
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      var channel = state.PDU["DeviceDetail"].obj.list_MusicChannel.find((music) => {
                        return Object.keys(music)[0] == Object.keys(e)[0];
                      });
                      var volume = state.PDU["DeviceDetail"].obj.list_VolumeChannel.find((music) => {
                        return Object.keys(music)[0] == Object.keys(e)[0];
                      });

                      if (channel) {
                        Vue.set(Object.values(channel)[0], "Da", Object.values(e)[0].Da);
                      }
                      if (volume) {
                        Vue.set(Object.values(volume)[0], "Da", Object.values(e)[0].Da);
                      }
                    }
                  } else
                  if (
                    (Object.keys(e)[0] >= 320 && Object.keys(e)[0] <= 384) ||
                    (Object.keys(e)[0] >= 2176 && Object.keys(e)[0] <= 2240) ||
                    Object.keys(e)[0] == 2880
                  ) {
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      var key = Object.keys(e)[0];
                      var data = Object.values(e)[0].Da;

                      var idx = state.PDU[
                        "DeviceDetail"
                      ].obj.AutoScene.findIndex((e) => {
                        return Object.keys(e)[0] == key;
                      });
                      var idx1 = state.PDU[
                        "DeviceDetail"
                      ].obj.list_sceneID.findIndex((e) => {
                        return e == key;
                      });
                      if (data.output) {
                        if (idx >= 0) {
                          state.PDU["DeviceDetail"].obj.AutoScene.splice(
                            idx,
                            1
                          );

                          state.PDU["DeviceDetail"].obj.AutoScene.push(e);
                        } else {
                          state.PDU["DeviceDetail"].obj.AutoScene.push(e);
                        }

                        if (idx1 == -1) {
                          state.PDU["DeviceDetail"].obj.list_sceneID.push(key);
                        }
                      } else {
                        // var idx2 = state.PDU['DeviceDetail'].obj.AutoScene.findIndex(e => {return Object.keys(e)[0] == key})
                        if (idx >= 0) {
                          state.PDU["DeviceDetail"].obj.AutoScene.splice(
                            idx,
                            1
                          );
                          state.PDU["DeviceDetail"].obj.AutoScene.push(e);
                        }
                        idx1 >= 0 &&
                          state.PDU["DeviceDetail"].obj.list_sceneID.splice(
                            idx1,
                            1
                          );
                      }
                      // dispatch('fnResetListSceneID')

                      flag == true
                        ? dispatch("fnToast", {
                            message: "Thao tác thành công",
                            type: "success",
                          })
                        : "";
                      flag = false;
                    }
                  } else
                  if (Object.keys(e)[0] == "1728") {
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      Vue.set(state.PDU["DeviceDetail"].obj,"list_ListMusic",e);
                    }

                    // dispatch('fnDecodeListMusic')
                  } else
                  if (Object.keys(e)[0] == "1600") {
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      var DaMusicProgress = Object.values(e)[0].Da
                      Vue.set(
                        state.PDU["DeviceDetail"].obj,
                        "musicProgress",
                        DaMusicProgress
                      );
                      var listmusic = localStorage.getItem("MusicUploading");
                      var listmusic1 = JSON.parse(listmusic)
                      if (listmusic1 && listmusic1.length > 0) {
                        var fileNameIndex = Math.floor(DaMusicProgress * listmusic1.length / 100)
                        Vue.set(state.PDU["DeviceDetail"].obj,
                          "musicProgressName",
                          fileNameIndex >= 0 ? listmusic1[fileNameIndex] : listmusic1[0] )
                      }
                    }
                  } else
                  if (Object.keys(e)[0] == "1344") {
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      Vue.set(
                        state.PDU["DeviceDetail"].obj,
                        "USBFormat",
                        Object.values(e)[0].Da
                      );
                      flag == true
                        ? dispatch("fnToast", {
                            message: `Định dạng bộ nhớ trong ${
                              Object.values(e)[0].Da == false ? "không" : ""
                            } thành công`,
                            type:
                              Object.values(e)[0].Da == false
                                ? "error"
                                : "success",
                          })
                        : "";
                    }
                  } else
                  if (Object.keys(e)[0] == "2752") {
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      Vue.set(
                        state.PDU["DeviceDetail"].obj,
                        "MusicUSBIn",
                        Object.values(e)[0].Da
                      );
                      flag == true
                        ? dispatch("fnToast", {
                            message: `Sao chép Âm ${
                              Object.values(e)[0].Da == false ? "không" : ""
                            } thành công`,
                            type:
                              Object.values(e)[0].Da == false
                                ? "error"
                                : "success",
                          })
                        : "";
                    }
                  } else
                  if (Object.keys(e)[0] == "1280") {
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      if (state.PDU["DeviceDetail"].obj.device_id == id) {
                        Vue.set(state.PDU["DeviceDetail"].obj, "USBRemain", e);
                      }
                    }
                  } else
                  if (Object.keys(e)[0] == "960") {
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      Vue.set(
                        state.PDU["DeviceDetail"].obj,
                        "childRefresh",
                        Object.values(e)[0].Da
                      );
                      flag == true
                        ? dispatch("fnToast", {
                            message: `Tìm kiếm ${
                              Object.values(e)[0].Da == true ? "" : "không"
                            } thành công`,
                            type: "success",
                          })
                        : "";
                      if (Object.values(e)[0].Da == true && flag == true) {
                        // window.location.reload()

                        await dispatch("fnRead", "DevicesAttributes");

                        var device_before_reload = localStorage.getItem(
                          "DeviceDetail"
                        );
                        // dispatch('fnCallResetView','devices');

                        if (device_before_reload != null) {
                          var device_before_reload_splited = device_before_reload.split(
                            "/"
                          );
                          var device_after_finding = state.PDU[
                            "devices"
                          ].list.find((e) => {
                            return (
                              e.device_id == device_before_reload_splited[0]
                            );
                          });
                          if (device_after_finding) {
                            dispatch("onDeviceDetail", {
                              device: device_after_finding,
                              hardware: device_before_reload_splited[1],
                            });
                          }
                        } else {
                          dispatch("onDeviceDetail", {
                            device: state.PDU["devices"].list[0],
                            hardware: 3,
                          });
                        }

                        for (var i in state.PDU["DeviceDetail"].obj
                          .childDevice) {
                          var item =
                            state.PDU["DeviceDetail"].obj.childDevice[i];
                          dispatch("fnIotSubscribe", {
                            device_id: item,
                            unSub: false,
                          });
                        }
                        // for(var j in state.PDU['DeviceDetail'].obj.childDevice){
                        // 	var item1 = state.PDU['DeviceDetail'].obj.childDevice[j]
                        // 	// var su = state.PDU.subcribe.findIndex(e=>{return e.device_id == item})
                        // 	dispatch('fnIotSubscribe', {device_id: item1,unSub:false})

                        // }
                      }
                    }
                  } else
                  if (Object.keys(e)[0] == "1152") {
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      Vue.set(
                        state.PDU["DeviceDetail"].obj,
                        "firmwareUpdateStatus",
                        Object.values(e)[0].Da
                      );
                      flag == true
                        ? dispatch("fnToast", {
                            message: `Cập nhật phần mềm ${
                              Object.values(e)[0].Da == false ? "không" : ""
                            } thành công`,
                            type:
                              Object.values(e)[0].Da == false
                                ? "error"
                                : "success",
                          })
                        : "";
                    }
                  } else
                  if (Object.keys(e)[0] == "128") {
                    if (state.PDU["DeviceDetail"].obj.device_id == id) {
                      Vue.set(
                        state.PDU["DeviceDetail"].obj,
                        "firmwareVersion",
                        e
                      );
                      dispatch("fnLoadData", "firmware");
                    }
                  }
                });
                dispatch("fnCheckScene", "");
              }
            } catch (err) {
              console.log("subcription error:", err);
            }
          },
          error: (error) => {
            console.log("error from amplify sub: ", error);
            dispatch("fnToast", {
              message: error,
              type: "error",
            });
          },
          close: () => console.log("Done"),
        });

        state.PDU.subcribe.push({
          sub: sub,
          device_id: device_id,
        });
      } catch (err) {
        console.log(err);
      }
    },
    fnCheckScene: async ({ dispatch, state }, scene) => { // function handle topic data
      await dispatch(
        "fnSet_listSceneTimer",
        state.PDU["DeviceDetail"].obj.list_MusicChannel
      );
      if (
        state.PDU["DeviceDetail"].obj.child_attribute &&
        state.PDU["DeviceDetail"].obj.child_attribute.length > 0
      ) {
        var device_list = state.PDU["DeviceDetail"].obj.child_attribute.filter(
          (e) => {
            return e.isOutlet && e.list_outled && e.list_outled.length > 0;
          }
        );

        var device_list_temper = state.PDU[
          "DeviceDetail"
        ].obj.child_attribute.filter((e) => {
          return e.isSensor && e.list_temper && e.list_temper.length > 0;
        });

        device_list.forEach((e) => {
          dispatch("fnSet_listSceneTimer_pdu", e.list_outled);
          dispatch("onAddList_scene_temper_to_pdu", {
            list_outlet: e.list_outled,
            device: e,
          });
          dispatch("onCheckEnableforSwitch", e.list_outled);

          device_list_temper.forEach((d) => {
            dispatch("onAddList_scene_temper_to_temper", {
              list_temper: d.list_temper,
              device: d,
              list_outlet: e.list_outled,
            });
          });
        });
      }
    },
    fnResetListSceneID: ({ state }) => { // function reset list scene ID
      state.PDU["DeviceDetail"].obj.list_sceneID = [];
      state.PDU["DeviceDetail"].obj.AutoScene.forEach((e) => {
        if (Object.values(e)[0].Da && Object.values(e)[0].Da.output) {
          state.PDU["DeviceDetail"].obj.list_sceneID.push(Object.keys(e)[0]);
        }
      });
    },
  },
});
