 <!-- tel-input.vue -->
<template>
  <vue-tel-input style="background-color: #e8f0fe;" @onInput="onInput" v-model="value" :placeholder="schema.placeholder"></vue-tel-input>
</template>

  <script>
import { abstractField } from "vue-form-generator";

export default {
  name: "TelephoneInput",
  mixins: [abstractField],
  methods: {
    onInput({ number, isValid, country }) {
      if (isValid == true) {
        this.$set(this.PDU["Registry"].obj, "phone_number", number.e164);
      } else {
      }
    },
  },
};
</script>
<style>
fieldset {
  border: none !important;
}
</style>
