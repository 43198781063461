<template>
<v-layout align-center justify-center class="bg">
  <div class="BG-CT">
  <v-layout align-center justify-center class="bg_color da-nh">
    <title>Đăng Ký</title>
    
      <v-form  v-model="PDU[model].isValid" @submit.prevent="onRegister">
        <v-card flat class="bg_color">
          <v-list-item-content>
            <v-layout row justify-center>
          <p class="text-xs-center ng-nhp">Đăng Ký</p>
          </v-layout></v-list-item-content>
          <v-card-text>
            <v-alert v-if="PDU[model].obj.sendCode" color="error" outlined>
              <v-list-item class="px-0">
                <v-icon color="error">warning</v-icon>
                <v-list-item-content>
                  <p class="mb-0 mt-1 mx-2" id="mess" style="color:#ef1e02">{{PDU[model].obj.message}}</p>
                </v-list-item-content>
                <strong id="timer" :class="isResend==false?'mt-1':'d-none'" style="color:#ef1e02"></strong>

                <v-btn @click="onResendSMSCode()" :class="isResend==true?'thu-lai':'d-none'">Thử lại</v-btn>
              </v-list-item>
            </v-alert>
            
            <v-list-item-content>
              <v-text-field class="ip-bo Rectangle-5 Nhp-mt-khu mb-5 px-3" :color="PDU.teal_color" label="Tên đăng nhập*" 
              name="username" required prepend-inner-icon="person_outline"  
              v-model="PDU[model].obj.username" :rules="[PDU.rules.required]"/>
            </v-list-item-content>

            <v-list-item-content>
              <v-text-field class="ip-bo Rectangle-5 Nhp-mt-khu mt-4 mb-5 px-3" :color="PDU.teal_color" label="E-mail" 
              name="email" prepend-inner-icon="alternate_email" 
              v-model="PDU[model].obj.email"/>
            </v-list-item-content>

            <vue-form-generator :model="model_1" :schema="schema"></vue-form-generator>
            
            
            <v-list-item-content>
              <v-text-field class=" ip-bo Rectangle-5 Nhp-mt-khu mt-4 mb-5 px-3" :color="PDU.teal_color" label="Mật khẩu*" 
              name="password" v-model="PDU[model].obj.password" required :rules="[PDU.rules.required, rules.minPass]"
              prepend-inner-icon="lock_outline" :append-icon="ePass ? 'visibility_off' : 'visibility'" 
              @click:append="() => ePass = !ePass" :type="ePass ? 'password' : 'text'" />
            </v-list-item-content>
            
            <v-list-item-content>
              <v-text-field class="ip-bo Rectangle-5 Nhp-mt-khu mt-4 mb-5 px-3" :color="PDU.teal_color" label="Nhập Lại Mật khẩu*" 
              name="confirmPass" v-model="PDU[model].obj.confirmPass" required :rules="[PDU.rules.required, rules.minPass, rules.confirmPass]"
              prepend-inner-icon="lock_outline" :append-icon="ePass ? 'visibility_off' : 'visibility'" 
              @click:append="() => ePass = !ePass" :type="ePass ? 'password' : 'text'" />
            </v-list-item-content>
            
            <v-list-item-content>
              <v-text-field class="ip-bo Rectangle-5 Nhp-mt-khu mt-4 mb-5 px-3" :color="PDU.teal_color" 
              v-if="PDU[model].obj.sendCode" label="Mã xác thực" name="code" required prepend-inner-icon="code" 
              v-model="PDU[model].obj.code" :rules="[PDU.rules.required]" />
            </v-list-item-content>
            
          </v-card-text>
          <v-card-actions>

            <v-layout column>

              <v-layout row wrap justify-center align-center>
                <v-btn class="Rectangle-7 my-4" type="submit" :color="PDU.teal_color" :loading="PDU[model].isLoading" :disabled="!PDU[model].isValid">
                  <p class="ng-nhp_1 ma-0 ">{{ PDU[model].obj.sendCode ? 'Xác nhận' : 'Đăng Ký' }}</p>
                </v-btn>
              </v-layout>

            <v-layout  row wrap justify-center align-center ><v-divider class="Line-4 mb-4" /></v-layout>

              <v-layout row wrap justify-center align-center>
                <router-link class="ml-3 teal--text" to="/login">
                  <v-avatar role="button" ><img style="width: 42px; height: 42px" :src="image_router_left"/></v-avatar>
                </router-link>
                <p class="To-ti-khon mb-0 ml-2">Đăng nhập</p> 
              </v-layout>
            </v-layout>

          </v-card-actions>
        </v-card>
      </v-form>
      
  </v-layout>
  </div>
  <v-dialog max-width="290" persistent v-model="PDU.notification.dialog_notification_only">
      <v-card>
        <v-list-item-content><img style="width: 100px; height: 100px" :src="PDU.notification.type"/></v-list-item-content>
        <v-list-item><v-layout row justify-center align-center align-content-center><p class="mb-0" style="text-align: center">{{PDU.notification.message}}</p></v-layout></v-list-item>
        <v-card-actions>
          <v-list-item-content :class="PDU.notification.reload == false? '':'d-none'"><v-layout row justify-center><v-btn @click="PDU.notification.dialog_notification_only = false">Đồng ý</v-btn></v-layout></v-list-item-content>
          <v-list-item-content :class="PDU.notification.reload == true? '':'d-none'"><v-layout row justify-center><v-btn @click="onReload()">Đồng ý</v-btn></v-layout></v-list-item-content>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
  data() {
    return {
      model: "Registry",
      ePass: true,
      rules: {
        minPass: v =>
          (!!v && v.length >= 6) || "Mật khẩu phải có ít nhất 6 ký tự",
        confirmPass: v =>
          (!!v && v == this.PDU[this.model].obj.password) ||
          "Nhập Lại Mật khẩu Không Đúng",
      },
      image_router_left: require("@/assets/router_left.svg"),
      phone: "",
      model_1: {
        phone: "",
      },
      schema: {
        fields: [
          {
            type: "tel-input",
            model: "phone",
            placeholder: "Số điện thoại",
            required: true,
          },
        ],
      },
      isResend: false,
    };
  },
  methods: {
    async onRegister() {
      var check = this.fnCheckUserName(this.model).then(check => {
        if (check == true) {
          this.fnRegistry(this.model);
          return;
        }
      });
    },
    onCountDownTimer() {
      var presentTime = document.getElementById("timer").innerHTML;
      var timeArray = presentTime.split(/[:]+/);
      var m = timeArray[0];

      var s = this.checkSecond(timeArray[1] - 1);
      if (s == 59) {
        m = m - 1;
      }

      if (m < 0) {
        // this.PDU[this.model].obj.username = null
        // this.PDU[this.model].obj.message = null;
        // this.PDU[this.model].obj.phone_number = null;
        // return;
      }
      if (m == 0 && s == 15) {
        document.getElementById("mess").innerHTML = "Thử lại sau ";
      }
      if (m == 0 && s == 0) {
        document.getElementById("mess").innerHTML =
          "Bạn chưa nhận được tin nhắn? Nhấp để gửi lại!";
        this.isResend = true;
        this.PDU[this.model].obj.sendCode = true;
        return;
      }

      document.getElementById("timer").innerHTML = m + ":" + s;
      setTimeout(this.onCountDownTimer, 1000);
    },

    checkSecond(sec) {
      if (sec < 10 && sec >= 0) {
        sec = "0" + sec;
      } // add zero in front of numbers < 10
      if (sec < 0) {
        sec = "59";
      }
      return sec;
    },
    async onResendSMSCode() {
      if (
        this.PDU[this.model].obj.username != "" &&
        this.PDU[this.model].obj.username != null
      ) {
        var attribute = {
          user_name: this.PDU[this.model].obj.username,
          client_id: this.PDU.userPoolWebClientID,
        };

        this.isResend = false;

        await this.fnReSendSMS(attribute).then(data => {
          if (data.success == true) {
            document.getElementById("mess").innerHTML =
              "Mã xác thực đang được gửi đến số điện thoại của bạn.";
            document.getElementById("timer").innerHTML = 2 + ":" + 0;
            this.onCountDownTimer();
          } else {
            this.isResend = true;
            var error = data.error_msgs;
            error.code = NoUsernameFound;
            if (error.code === "NoUsernameFound") {
              error = { ...error, message: "Tên tài khoản không khả dụng" };
            }
            dispatch("fnHandleError", {
              model,
              error,
            });
          }
        });
      } else {
        this.fnToastNotificationOnly({
          message: "Vui lòng nhập tên tài khoản và chọn 'Thử lại'",
          type: "error",
          reload: false,
        });
        return;
      }
    },
  },
};
</script>

<style>
.thu-lai {
  border-radius: 20px !important;
  background-color: #00897b !important;
  color: #fff !important;
}
.vti__dropdown {
  padding: 0 !important;
  height: 50px !important;
}
.vue-tel-input {
  border-radius: 8px !important;
  border: none !important;
  height: 54px;
  background-color: #f0f0f0;
  box-shadow: none !important;
}
.vue-tel-input:hover {
  border: 1px solid #00897b !important;
}
.vti__input::-webkit-input-placeholder::after {
  content: "cnsad";
}
</style>
