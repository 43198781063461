<template>

<v-layout align-center justify-center class="bg">
  <div class="BG-CT " >
  <v-layout row align-center  justify-center class="bg_color da-nh">
    <title>Đăng Nhập</title>
      <v-form v-model="PDU[model].isValid" @submit.prevent="onCheckUserName">
        <v-card  flat class="bg_color">
          <v-list-item-content>
            <v-layout row justify-center>
              <p  class="text-xs-center ng-nhp mb-0">Đăng nhập</p></v-layout></v-list-item-content>
          <v-card-text>
            
            
            <v-list-item-content>
              <v-text-field fill class="ip-bo Rectangle-5 Nhp-mt-khu mb-5 px-3" clearable :color="PDU.teal_color" label="Tên đăng nhâp/Số điện thoại" name="username"
             v-model="PDU[model].obj.username" required :rules="[PDU.rules.required]" prepend-inner-icon="person_outline">
              
             </v-text-field>
             </v-list-item-content>
            
            <v-list-item-content><v-text-field class=" ip-bo Rectangle-5 Nhp-mt-khu mt-4 mb-4 px-3" :color="PDU.teal_color" label="Mật khẩu" name="password" v-model="PDU[model].obj.password" required 
              :rules="[PDU.rules.required, rules.minPass]"
              prepend-inner-icon="lock_outline" :append-icon="ePass ? 'visibility_off' : 'visibility'" @click:append="() => ePass = !ePass" :type="ePass ? 'password' : 'text'" >
            </v-text-field></v-list-item-content>
          
          </v-card-text>
          <v-card-actions>
            <v-layout column>

              <v-layout row wrap style="width:100%">
                <v-flex xs12 sm12>
                  <router-link  class="ml-3 Qun-mt-khu" to="/missingPassword">Quên Mật Khẩu</router-link>
                </v-flex>
              </v-layout>

              <v-layout row wrap justify-center align-center>
                
                  <v-btn  class="Rectangle-7 my-4 white--text" :ripple="false"  type="submit" :color="PDU.teal_color" :loading="PDU[model].isLoading" :disabled="!PDU[model].isValid">
                    <p class="ng-nhp_1 ma-0 ">Đăng nhập</p></v-btn>
                
              </v-layout>

              <v-layout  row wrap justify-center align-center ><v-divider class="Line-4 mb-4" /></v-layout>
              <v-layout row wrap justify-center align-center>
                <p class="To-ti-khon mb-0 mr-2">Tạo tài khoản</p> 
                <router-link  class="ml-2 Qun-mt-khu" to="/register">
                  <v-avatar role="button" ><img style="width: 42px; height: 42px" :src="image_router"/></v-avatar>
                </router-link>
                
              </v-layout>

            </v-layout>
          </v-card-actions>
        </v-card>
      </v-form>
  </v-layout>
  </div>
  <v-dialog max-width="290" persistent v-model="PDU.notification.dialog_notification_only">
      <v-card>
        <v-list-item-content><img style="width: 100px; height: 100px" :src="PDU.notification.type"/></v-list-item-content>
        <v-list-item><v-layout row justify-center align-center align-content-center><p class="mb-0" style="text-align: center">{{PDU.notification.message}}</p></v-layout></v-list-item>
        <v-card-actions>
          <v-list-item-content :class="PDU.notification.reload == false? '':'d-none'"><v-layout row justify-center><v-btn @click="PDU.notification.dialog_notification_only = false">Đồng ý</v-btn></v-layout></v-list-item-content>
          <v-list-item-content :class="PDU.notification.reload == true? '':'d-none'"><v-layout row justify-center><v-btn @click="onReload()">Đồng ý</v-btn></v-layout></v-list-item-content>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-layout>

</template>

<script>
export default {
  data() {
    return {
      model: "Login",
      ePass: true,
      rules: {
        minPass: v =>
          (!!v && v.length >= 6) || "Mật khẩu phải có ít nhất 6 ký tự",
      },
      image_person: require("@/assets/person.svg"),
      image_person_img: require("@/assets/person_img.png"),
      image_lock: require("@/assets/lock.svg"),
      image_eyes: require("@/assets/eyes.svg"),
      image_router: require("@/assets/router.svg"),
    };
  },
  created() {
    if (this.PDU.userSession) {
      this.$router.push("/");
    }
  },
  methods: {
    onCheckUserName() {
      this.fnCheckUserName(this.model).then(check => {
        if (check == true) {
          this.fnLogin(this.model);
          return;
        }
      });
    },
  },
};
</script>
<style>
.v-input--is-focused {
  /* */
}
.ip-bo:hover {
  border: solid 1px #009688 !important;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  border: none !important;
}
.image_person_img::after {
  content: url("../assets/person_img.png");
}
.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.border_none {
  border-style: none !important;
}
.BG-CT {
  width: 1396px;

  min-height: 816px;

  box-shadow: 0 10px 19px 0 rgba(0, 150, 150, 0.2);

  background-color: transparent;
}

.ng-nhp {
  font-family: Roboto;

  font-size: 48px !important;

  font-weight: 500 !important;

  font-stretch: normal;

  font-style: normal !important;

  line-height: normal !important;

  letter-spacing: normal !important;

  color: #009688;
}
.ng-nhp_1 {
  width: 96px;

  height: 23px;

  font-family: Roboto;

  font-size: 20px;

  font-weight: 500;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: normal;

  color: #ffffff;

  text-transform: initial;
}

.Rectangle-5 {
  width: 80%;

  height: 54px;

  border-radius: 8px;

  background-color: #e8f0fe;
}

.Nhp-mt-khu {
  font-family: Roboto;

  font-size: 16px !important;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: normal !important;

  color: #424242 !important ;
}
.Qun-mt-khu {
  font-family: Roboto;

  font-size: 16px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: normal;

  color: #fc9003 !important;
}
.Rectangle-7 {
  width: 270px;

  height: 58px !important;

  border-radius: 100px !important;

  background-color: #009688;

  color: #ffffff;
}

.To-ti-khon {
  font-family: Roboto;

  font-size: 18px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: normal;

  color: #424242;
}
.Line-4 {
  width: 550px;

  height: 1px;

  background-color: #424242;
}
.bg {
  background-image: url("../assets/BG.svg");
}
.bg_color {
  width: 550px;
  background-color: transparent !important;
  margin: 0 auto !important;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .Line-4,
  .bg_color {
    width: 400px;
  }
}
</style>

