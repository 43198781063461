<template>
    <v-footer class="py-1" inset app style="height:30px; z-index:10" v-if="PDU.userSession" >
      <a target="_blank" href="https://epcb.vn/collections/giai-phap-nha-yen" style="font-size: 13px;text-decoration: none;color: #323232">Powered By EPCB (2.2.0)</a>
      <v-spacer/>
      <v-chip @click="onCreateTelegram()" style="height: 100%;border-radius: 5px;padding: 0 8px;" :color="PDU.teal_color" dark>
        <img :src="image_telegram" style="width: 24px;height: 24px;margin-right: 4px;"/>
        Đăng ký thông báo
        </v-chip>
    </v-footer>
</template>
<script>
export default {
  data () {
    return {
      image_telegram: require("@/assets/telegram.png"),
    }
  },
  methods: {
    async onCreateTelegram () {
      window.open(`${this.PDU.host_tele}?start=${this.PDU.userSession.username}`, '_blank')
    }
  }
};
</script>

