<template>
  <v-layout align-center justify-center class="bg">
    <div class="BG-CT">
    <v-layout row align-center justify-center class="bg_color da-nh">
        <title>Quên Mật Khẩu</title>
        
          <v-form v-model="PDU[model].isValid" @submit.prevent>
            <v-card flat  class="bg_color">
              <v-list-item-content>
            <v-layout row justify-center>
              <p class="text-xs-center ng-nhp">Quên Mật Khẩu</p>
              </v-layout></v-list-item-content>
              <v-card-text>

                <v-alert v-if="PDU[model].obj.sendCode"  color="error" icon="warning" outlined>{{ PDU[model].obj.message }}</v-alert>
                
                <v-list-item-content><v-text-field class="ip-bo Rectangle-5 Nhp-mt-khu mb-5 px-3" :color="PDU.teal_color" label="Tên đăng nhập" name="username" v-model="PDU[model].obj.username"
                required :rules="[PDU.rules.required]" prepend-icon="person_outline" v-if="!PDU[model].obj.sendCode" />
                </v-list-item-content>
                
                <v-list-item-content><v-text-field class="ip-bo Rectangle-5 Nhp-mt-khu mt-4 mb-5 px-3" :color="PDU.teal_color" type="number" label="Mã Xác Thực" name="code" v-model="PDU[model].obj.code"
                required :rules="[PDU.rules.required]" prepend-icon="code" v-if="PDU[model].obj.sendCode" />
                 </v-list-item-content>

                <v-list-item-content><v-text-field class="ip-bo Rectangle-5 Nhp-mt-khu mt-4 mb-5 px-3" :color="PDU.teal_color" label="Mật Khẩu Mới" name="password" v-model="PDU[model].obj.password" required :rules="[PDU.rules.required, rules.minPass]" prepend-icon="lock_open"  v-if="PDU[model].obj.sendCode"
                  :append-icon="ePass ? 'visibility_off' : 'visibility'" @click:append="() => ePass = !ePass" :type="ePass ? 'password' : 'text'" />
                   </v-list-item-content>
              </v-card-text>
              <v-card-actions>


                <v-layout column>

                  <v-layout row wrap justify-center align-center>
                    <v-btn class="Rectangle-7 my-4 white--text" :color="PDU.teal_color" :loading="PDU[model].isLoading" :disabled="!PDU[model].isValid" @click="onCheckUserName">
                      <p class="ng-nhp_1 ma-0 ">{{PDU[model].obj.sendCode ? 'Xác nhận' : 'Đồng ý'}}</p>
                    </v-btn>
                  </v-layout>

                <v-layout  row wrap justify-center align-center ><v-divider :class="PDU[model].isValid? 'Line-4 mb-4':'d-none'" /></v-layout>

                  <v-layout row wrap justify-center align-center>
                    <router-link class="ml-3 teal--text" to="/login">
                      <v-avatar role="button" ><img style="width: 42px; height: 42px" :src="image_router_left"/></v-avatar>
                    </router-link>
                    <p class="To-ti-khon mb-0 mr-2">Đăng nhập</p> 
                  </v-layout>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-form>
          
    </v-layout>
    </div>
    <v-dialog max-width="290" persistent v-model="PDU.notification.dialog_notification_only">
      <v-card>
        <v-list-item-content><img style="width: 100px; height: 100px" :src="PDU.notification.type"/></v-list-item-content>
        <v-list-item><v-layout row justify-center align-center align-content-center><p class="mb-0" style="text-align: center">{{PDU.notification.message}}</p></v-layout></v-list-item>
        <v-card-actions>
          <v-list-item-content :class="PDU.notification.reload == false? '':'d-none'"><v-layout row justify-center><v-btn @click="PDU.notification.dialog_notification_only = false">Đồng ý</v-btn></v-layout></v-list-item-content>
          <v-list-item-content :class="PDU.notification.reload == true? '':'d-none'"><v-layout row justify-center><v-btn @click="onReload()">Đồng ý</v-btn></v-layout></v-list-item-content>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
  
</template>

<script>
export default {
  data() {
    return {
      model: "ForgotPass",
      ePass: true,
      rules: {
        minPass: v =>
          (!!v && v.length >= 6) || "Mật khẩu phải có ít nhất 6 ký tự",
      },
      image_router_left: require("@/assets/router_left.svg"),
    };
  },
  created() {
    if (this.PDU.userSession) this.$router.push("/");
  },
  methods: {
    onCheckUserName() {
      var check = this.fnCheckUserName(this.model).then(check => {
        if (check == true) {
          this.fnForgotPassword(this.model);
        }
      });
    },
  },
};
</script>
