<template>
    <v-navigation-drawer   dark v-model="APP.drawer" app clipped class="bg_nav pt-4 mobile-padd" 
      style="margin-bottom:50px; background-color: #323232; " width="300">
        <v-list dense nav class="px-4">
          <v-list-group v-for="(i,j) in deviceSort" :key="j" :value="true" @click="i.value = !i.value" active-class="Thit-b">
            <template v-slot:activator>

              <v-list-item-icon><img style="width: 24px" :src="!i.value? image_device_hub_color: image_device_hub"/></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="!i.value? 'Thit-b':'Nh-yn-A'">{{i.user_device_descriptions}}</v-list-item-title>
              </v-list-item-content>

            </template>

            <v-list-item ripple @click="fnCallCreateView('NewDevice')" class="px-0">
                  <v-list-item class="ml-6 Nh-yn-A px-1" style="color: #aeaeae !important;">Thêm thiết bị mới</v-list-item><v-spacer/>
                  <v-list-item-icon><v-icon>add</v-icon></v-list-item-icon>
            </v-list-item>

            <v-list v-for="(head, k) in i.items" :key="k" class="py-0">
              <v-skeleton-loader
                :loading="SKL.devices_loading"
                :transition="SKL.transition"
                height="94"
                type="list-item-two-line"
              >
                <v-list-group   no-action v-if="head.ischild == true" class="Thit-b_2" active-class="Thit-b_1">
                  <template v-slot:activator>
                    <v-tooltip bottom color="#009688">
                      <template v-slot:activator="{ on }">
                          <v-list-item class="px-0" v-on="on">
                              <v-list-item-icon><img :src="head.online? image_hub_color: image_hub"/></v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title :class="PDU['DeviceDetail'].obj.device_id == head.device_id? 'iu-khin-m-ly': 'Nh-yn-A'">
                                  {{head.user_device_descriptions}} ({{head.user_role == 'owner'? 'sở hữu': (head.user_role == 'admin'? 'quản trị': 'Khách')}})
                                </v-list-item-title>
                              </v-list-item-content>
                          </v-list-item >
                      </template>
                      <span> {{head.user_device_descriptions}} ({{head.user_role == 'owner'? 'sở hữu': (head.user_role == 'admin'? 'quản trị': 'Khách')}})</span>
                    </v-tooltip>
                  </template>
                  <v-list-item :class="PDU['DeviceDetail'].obj.device_id == sub.parent_device_id &&
                    PDU['DeviceDetail'].obj.hardware == sub.hardware &&
                    PDU['DeviceDetail'].obj.child_device_id == sub.device_id
                    ? 'tile py-0':'tile_hover py-0'"
                    role="button" v-for="(sub, m) in head.items" :key="m" @click="gotoDeviceDetail(sub)">
                      <v-list-item-title v-if="sub.hardware != 4" :class="
                        PDU['DeviceDetail'].obj.device_id == sub.parent_device_id &&
                        PDU['DeviceDetail'].obj.hardware == sub.hardware
                        ? 'iu-khin-m-ly ml-0': 'ml-0 Nh-yn-A'">
                        {{sub.title}}
                      </v-list-item-title>
                      <v-list-item-title v-else-if="sub.hardware==4" :class="
                        PDU['DeviceDetail'].obj.device_id == sub.parent_device_id &&
                        PDU['DeviceDetail'].obj.hardware == sub.hardware &&
                        PDU['DeviceDetail'].obj.child_device_id == sub.device_id 
                        ? 'iu-khin-m-ly ml-0': 'ml-0 Nh-yn-A'">
                        {{sub.title}}
                      </v-list-item-title>
                  </v-list-item>
                </v-list-group>

                <!--Channel monitor childrent-->
                <v-list-item  v-else-if="head.ischild == false" @click="gotoDeviceDetail(head)" class="px-0">
                  <v-tooltip bottom color="#009688">
                      <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on"><img :src="head.online? image_hub_color: image_hub"/></v-list-item-icon>
                        <v-list-item-content v-on="on">

                        <v-list-item-title :class="PDU['DeviceDetail'].obj.device_id == head.device_id? 'iu-khin-m-ly': 'Nh-yn-A'">
                            {{head.user_device_descriptions}} ({{head.user_role == 'owner'? 'sở hữu': (head.user_role == 'admin'? 'quản trị': 'Khách')}})
                        </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <span>{{head.user_device_descriptions}} ({{head.user_role == 'owner'? 'sở hữu': (head.user_role == 'admin'? 'quản trị': 'Khách')}})</span>
                  </v-tooltip>
                </v-list-item>

              </v-skeleton-loader>

            </v-list>

          </v-list-group>

        </v-list>
        <!--device groups-->
        <v-list dense nav  class="px-4">
          <v-list-group @click="group_value = !group_value">
            <template v-slot:activator>

              <v-list-item-icon><img style="width: 24px" :src="!group_value ? image_group_color: image_group"/></v-list-item-icon>
              <v-list-item-content>
                  <v-list-item-title :class="group_value? 'Nh-yn-A':' Thit-b'">
                    Nhóm thiết bị
                  </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item ripple @click="GROUP.dialog_add_group = true" class="px-0">
                  <v-list-item class="ml-6 Nh-yn-A px-1" style="color: #aeaeae !important;">Tạo nhóm mới</v-list-item><v-spacer/>
                  <v-list-item-icon><v-icon>add</v-icon></v-list-item-icon>
            </v-list-item>

            <v-list-item  ripple @click="GROUP.dialog_edit_group_1=true" class="px-0">
                  <v-list-item class="ml-6 Nh-yn-A px-1" style="color: #aeaeae !important;">Sửa nhóm</v-list-item><v-spacer/>
                  <v-list-item-icon><img style="width:24px" :src="image_edit"/></v-list-item-icon>
            </v-list-item>

            <v-list>
                <v-list-group v-for="(item,i) in group_list " :key="i" no-action @click="item.select = !item.select" class="Thit-b_2">
                  <template v-slot:activator>
                    <v-list-item-icon><v-btn fab :class="item.status == true? 'btn_gr_on mt-1':'mt-1 btn_gr_off'"></v-btn></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="Nh-yn-A" >
                          {{item.name}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list>
                  <v-list-group v-for="(device,j) in item.children" :key="j">
                    <template v-slot:activator>
                        <v-list-item>
                              <v-list-item-icon><img :src="device.active? image_hub_color: image_hub"/></v-list-item-icon>
                              <v-list-item-content  :class="PDU['DeviceDetail'].obj && PDU['DeviceDetail'].obj.device_id && device.id == PDU['DeviceDetail'].obj.device_id && 'Nh-yn-A_1' || 'Nh-yn-A px-0'">
                              {{device.name}}</v-list-item-content>
                        </v-list-item>
                    </template>

                    <span v-for="(item,k) in device.items" :key="k"> 
                    <v-list-item-content :class="PDU['DeviceDetail'].obj.device_id == item.parent_device_id && PDU['DeviceDetail'].obj.hardware == item.hardware? 'tile':'tile_hover'" role="button"  @click="gotoDeviceDetail(item)">
                      <v-list-item-title :class="PDU['DeviceDetail'].obj.device_id == item.parent_device_id && PDU['DeviceDetail'].obj.hardware == item.hardware? 'iu-khin-m-ly ml-0': 'ml-0 Nh-yn-A'">
                        {{item.title}}
                      </v-list-item-title>
                    </v-list-item-content>
                    </span>
                  </v-list-group>
                  </v-list>
                </v-list-group>
            </v-list>
          </v-list-group>
        </v-list>

        <v-list-item id="share_telegram" ripple class="px-0 mx-4 telegram" @click="onCreateTelegram()" style="min-height:10px; height:40px;">
          <img style="width: 24px" :src="isOver ? telegram_color : telegram_grey"/>
          <v-list-item class="Nh-yn-A px-1" :style="`color: ${isOver ? '#00ffe7' : '#aeaeae'} !important`">Chia sẻ thông báo</v-list-item><v-spacer/>
        </v-list-item>
      </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      group_value: true,
      image_device_hub: require("@/assets/device_hub.svg"),
      image_device_hub_color: require("@/assets/device_hub_color.svg"),
      image_hub_color: require("@/assets/hub_color.svg"),
      image_hub: require("@/assets/hub.svg"),
      image_group: require("@/assets/nav_group_white.png"),
      image_group_color: require("@/assets/nav_group_green.png"),
      image_edit: require("@/assets/edit.svg"),
      telegram_grey: require("@/assets/telegram_grey.png"),
      telegram_color: require("@/assets/telegram_color.png"),
      isOver: false
    };
  },
  computed: {
    deviceSort() {
      var arr = [];
      arr = [
        {
          value: false,
          device_id: null,
          action: "settings",
          user_device_descriptions: "Thiết bị",
          active: true,
          items: [],
        },
      ];

      this.PDU["devices"].list.forEach(e => {
        var len = 0;
        var child_device_id_list = [];

        for (var i in e.list_child) {
          var item = e.list_child[i];

          this.PDU.list_child.forEach(d => {
            //danh sach cac attribute cua child device

            var attributes = d.find(x => {
              return x.device_id == item;
            });
            if (attributes) {
              var hardware = d.find(x => {
                return x["64"] && x["64"].Da == 4;
              });

              if (hardware) {
                child_device_id_list.push(attributes.device_id);
                len++;
              }
            }
          });
        }

        len == 2
          ? child_device_id_list.sort((a, b) => {
              return a > b ? 1 : -1;
            })
          : "";

        if (this.CheckHardwareAvailble({ data: e }) == true) {
          var items = [
            {
              title: "Điều khiển âm ly",
              hardware: e.hardware,
              parent_device_id: e.device_id,
              list_child: e.list_child,
            },
            {
              title: "Điều khiển nguồn",
              hardware: 4,
              parent_device_id: e.device_id,
              list_child: e.list_child,
              device_id: child_device_id_list[0],
            },
            {
              title: "Nhiệt độ, độ ẩm",
              hardware: 6,
              parent_device_id: e.device_id,
              list_child: e.list_child,
            },
          ];
          len == 2
            ? (items = [
                {
                  title: "Điều khiển âm ly",
                  hardware: e.hardware,
                  parent_device_id: e.device_id,
                  list_child: e.list_child,
                },
                {
                  title: "Điều khiển nguồn 1",
                  hardware: 4,
                  parent_device_id: e.device_id,
                  list_child: e.list_child,
                  device_id: child_device_id_list[0] || null,
                },
                {
                  title: "Điều khiển nguồn 2",
                  hardware: 4,
                  parent_device_id: e.device_id,
                  list_child: e.list_child,
                  device_id: child_device_id_list[1] || null,
                },
                {
                  title: "Nhiệt độ, độ ẩm",
                  hardware: 6,
                  parent_device_id: e.device_id,
                  list_child: e.list_child,
                },
              ])
            : "";

          arr[0].items.push({
            ischild: true,
            device_id: e.device_id,
            user_role: e.user_role,
            online: e.active,
            action: "device_hub",
            hardware: e.hardware,
            user_device_descriptions: e.user_device_descriptions || e.device_id,
            active: true,
            items: items,
          });
        } else if (e.hardware == 7) {
          arr[0].items.push({
            ischild: false,
            value: false,
            device_id: e.device_id,
            user_role: e.user_role,
            online: e.active,
            action: "device_hub",
            hardware: 7,
            user_device_descriptions: e.user_device_descriptions || e.device_id,
            parent_device_id: e.device_id,
          });
        }
      });
      arr[0].items.sort(
        (a, b) =>
          a.user_device_descriptions.toLowerCase() <
          b.user_device_descriptions.toLowerCase()
            ? 1
            : -1
      );
      arr[0].items.sort(
        (a, b) => ((a.online || false) > (b.online || false) ? 1 : -1)
      );
      this.SEA.content = this.fnCopy(arr[0].items);
      return arr;
    },
    group_list() {
      var arr = [];

      arr = [
        {
          value: false,
          device_id: null,
          action: "settings",
          user_device_descriptions: "Thiết bị",
          active: true,
          items: [],
        },
      ];

      this.PDU["group"].group_list.forEach(e => {
        for (var i in e.children) {
          var device = this.PDU["devices"].list.find(d => {
            return d.device_id == e.children[i].id;
          });

          if (!e.children[i].name.includes("Không tìm thấy") && device) {
            if (this.CheckHardwareAvailble({ data: device })) {
              e.children[i].items = [
                {
                  title: "Điều khiển âm ly",
                  hardware: device.hardware,
                  parent_device_id: e.children[i].id,
                  list_child: device.list_child || [],
                },
                {
                  title: "Điều khiển nguồn",
                  hardware: 4,
                  parent_device_id: e.children[i].id,
                  list_child: device.list_child || [],
                },
                {
                  title: "Nhiệt độ, độ ẩm",
                  hardware: 6,
                  parent_device_id: e.children[i].id,
                  list_child: device.list_child || [],
                },
              ];
            } else if (device.hardware == 7) {
              e.children[i].items = [
                {
                  ischild: false,
                  value: false,
                  device_id: device.device_id,
                  user_role: device.user_role,
                  online: device.active,
                  action: "device_hub",
                  hardware: 7,
                  title: device.user_device_descriptions || device.device_id,
                  parent_device_id: e.children[i].id,
                },
              ];
            }
          }
        }
      });
      return this.PDU["group"].group_list || [];
    },
  },
  methods: {
    CheckHardwareAvailble ({ data }) {
      if (data.hardware && 
      ( data.hardware == 3 || 
        data.hardware == 10 || 
        data.hardware == 20 || 
        data.hardware == 11)) {
        return true
      } else { return false }
    },
    onHideMenu() {
      if (this.$vuetify.breakpoint.name == "xs") this.APP.drawer = false;
    },
    async gotoDeviceDetail(obj) {
      this.onHideMenu();
      if(this.$route.name == 'contact'){
            this.$router.push('/')
          }
      if (obj.parent_device_id != this.PDU["DeviceDetail"].obj.device_id) {
        this.$set(this.PDU["DeviceDetail"].obj, "isSubcribe", false);
      }

      try {
        await this.fnAttachPrincipalPolicy();

        if (this.PDU["DeviceDetail"].obj.isSubcribe == false) {
          /////////////////unSub all master//////////////////////////
          this.PDU["devices"].list.forEach(device => {
            this.fnIotSubscribe({ device_id: device.device_id, unSub: true });
          });
          //////////////////unSub all child devices//////////////////
          if (this.PDU["DeviceDetail"].obj.device_id) {
            if (this.PDU["DeviceDetail"].obj.childDevice) {
              var childDevice = this.fnCopy(
                this.PDU["DeviceDetail"].obj.childDevice
              );
              childDevice.forEach(child => {
                this.fnIotSubscribe({ device_id: child, unSub: true });
              });
            }
          }
        } else {
        }
        ////////////////////////////////////////////////////////////////////////
        this.SKL.loading = true;
        await this.fnRead("DevicesAttributes");

        //////////////////Call device Detail (if existed)//////////////////
        var device = this.PDU["devices"].list.find(e => {
          return e.device_id == obj.parent_device_id;
        });
        if (device) {
          var hardware = obj.hardware;
          var child_device_id = obj.device_id || null;
          await this.onDeviceDetail({
            device,
            hardware,
            child_device_id: child_device_id,
          });
          this.PDU.breadcrumbs = [];
          this.PDU.breadcrumbs.push({
            text: device.user_device_descriptions || device.device_id,
            disable: false,
            device: device,
            hardware: hardware,
          });
          if (hardware != 7) {
            this.PDU.breadcrumbs.push({
              text: obj.title,
              disable: false,
              device: device,
              hardware: obj.hardware,
            });
          }
          
        } else {
          this.fnToast({ message: `Chưa có thiết bị này`, type: "error" });
        }
      } catch (err) {
        this.SKL.loading = false;
        console.log(err);
        this.fnToast({
          message: `Có lỗi xảy ra! Vui lòng kiểm tra lại`,
          type: "error",
        });
      }
    },
    async onCreateTelegram () {
      var item = `201;${this.PDU.userSession.username}`
      var str = this.encodeBase64(item)
      let item1 = `${this.PDU.host_tele}?start=${str}`
      window.open(item1)
    },
    encodeBase64 (str) {
      try {
        return window.btoa(str)
      } catch (err) {
        return null
      }
    }
  },
  mounted () {
    let item = document.getElementById("share_telegram");
    item.addEventListener("mouseover", () => {
      this.isOver = true
    }, false);
    item.addEventListener("mouseout", () => {
      this.isOver = false
    }, false);
  }
};
</script>
<style lang="scss" scoped>
.telegram {
  &::before{
    border-radius: 4px;
  }
}
</style>

