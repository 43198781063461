<template>
    <v-app-bar  app flat clipped-left color="#fff" class="v-toolbar__content">
      
      <div class="Rectangle-12" @click.stop="APP.drawer = !APP.drawer">
        <v-list-item>
          <img class="mr-2 mb-1" style="width:140px;height:40px" :src="image_logo_40px" />
          <v-spacer />
          <v-btn icon @click.stop="APP.drawer = !APP.drawer"><img style="width:24px" :src="image_icon_side"/></v-btn>
        </v-list-item>
      </div>

      <v-spacer/>
        <v-avatar class="hidden-sm-and-down"><v-icon large>account_circle</v-icon></v-avatar>

        <v-menu  bottom right offset-y min-width="250" left>
          <template v-slot:activator="{ on }">
              <v-list-item class="layout_flex px-0" style="height: 100%" align-center v-on="on">
                <v-list-item-content><v-list-item-title><p role="button" style="color:#424242" class="mb-0 mx-4">{{ PDU.userSession.name }}</p></v-list-item-title></v-list-item-content>
                <v-icon role="button" color="#424242" class="arrow_down_toolbar_color">keyboard_arrow_down</v-icon>
              </v-list-item>
          </template>

          <v-layout column>
            <v-card  >
                <v-list-item class="fontstyle" @click="fnCallUpdateView({ model: 'ChangePass', item: {} })">
                  <v-icon class="mr-2">lock_open</v-icon>
                  Đổi mật khẩu
                </v-list-item>
                <v-divider/>

                <v-list-item role="button" class="fontstyle" @click="fnRemoveUserSession()">
                  <v-icon class="mr-2">exit_to_app</v-icon>
                  Đăng xuất
                </v-list-item>
            </v-card>
          </v-layout>

      </v-menu>
    </v-app-bar>
</template>
<script>
export default {
  data() {
    return {
      image_icon_side: require("@/assets/icon_side.svg"),
      image_logo_40px: require("@/assets/logo_full.svg"),
    };
  },
};
</script>

