export const fnCopy = (data) => JSON.parse(JSON.stringify(data));

export const fnSlug = (str, replaceSpaceBy = "") => {
  // remove diacritic marks
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, "a");
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, "e");
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, "i");
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, "o");
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, "u");
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, "y");
  str = str.replace(/(đ)/g, "d");
  str = str.replace(/(À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ)/g, "A");
  str = str.replace(/(È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ)/g, "E");
  str = str.replace(/(Ì|Í|Ị|Ỉ|Ĩ)/g, "I");
  str = str.replace(/(Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ)/g, "O");
  str = str.replace(/(Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ)/g, "U");
  str = str.replace(/(Ỳ|Ý|Ỵ|Ỷ|Ỹ)/g, "Y");
  str = str.replace(/(Đ)/g, "D");
  // remove special leter
  str = str.replace(/([^0-9A-Za-z-.\s])/g, "");
  // replace white pace by -
  str = str.replace(/(\s+)/g, replaceSpaceBy);
  // remove - on top
  str = str.replace(/^-+/g, "");
  // remove - on tail
  str = str.replace(/-+$/g, "");
  // return
  return str;
};

export const fnRefixName = (str, replaceSpaceBy = " ") => {
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, "a");
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, "e");
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, "i");
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, "o");
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, "u");
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, "y");
  str = str.replace(/(đ)/g, "d");
  str = str.replace(/(À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ)/g, "A");
  str = str.replace(/(È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ)/g, "E");
  str = str.replace(/(Ì|Í|Ị|Ỉ|Ĩ)/g, "I");
  str = str.replace(/(Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ)/g, "O");
  str = str.replace(/(Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ)/g, "U");
  str = str.replace(/(Ỳ|Ý|Ỵ|Ỷ|Ỹ)/g, "Y");
  str = str.replace(/(Đ)/g, "D");

  return str;
};
export const fnRename = (str, replaceSpaceBy = " ") => {
  str = str.replace(/(AudioStatus)/g, "Kênh");
  str = str.replace(/(Outlet)/g, "Ổ cắm");
  str = str.replace(/(Temperature)/g, "Nhiệt độ, độ ẩm");
  str = str.replace(/(MusicChannel)/g, "Âm ly");
  str = str.replace(/(_)/g, replaceSpaceBy);
  str = str.replace(/\d+/g, function(x) {
    return parseInt(x, 10) + 1;
  });

  return str;
};

export const fnRename_temperature = (str, replaceSpaceBy = " ") => {
  str = str.replace(/(Temperature)/g, "Nhiệt độ, độ ẩm");
  str = str.replace(/(_)/g, replaceSpaceBy);
  return str;
};
